Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account/accounts";

exports.apiMethodTypeAddDetail = "POST";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";
exports.nameText='Name';
exports.userNameText='Email ID';
exports.createPasswordText='Create Password';
exports.employeeText='Employee';
exports.managerText='Manager';
exports.notNowText='Not Now';
exports.addEmployeeText='+ Add Employee';
exports.getUserApiEndPoint='/bx_block_user/users/current_user_info';

exports.namePlaceholderText = "Enter Name";
exports.emailPlaceholderText = "Enter Email ID";
exports.pass1PlaceholderText = "Create New Password";
exports.pass2PlaceholderText = "Re-enter Password";
exports.departmentPlaceholderText = "Select Department";
exports.rolePlaceholderText = "Select Role";
exports.passWordErrorText = "The entered password doesn't match the confirm password. Check again";
exports.errorText="An account already exist";
exports.transportText="Transport";
exports.managementText="Management";
exports.departmentText="Department";
exports.profileImageError="Please upload profile picture";
exports.passWordValidationText="Minimum 8 characters , with One uppercase & a number or symbol";
exports.roleText="Role";
exports.okayButtonText="Okay";
exports.searchHeading="Search employee name";
exports.selectText="Select";
exports.selectAllText="Select all";
exports.cancelText="Cancel";
exports.searchPlaceholder="Search Employee";
exports.applyText="Apply";
exports.departmentText1="Department : ";
exports.moveText="Move";
exports.copyText="Copy";
exports.apiMethodTypePost = "POST";
exports.copyMoveApiEndPoint="/bx_block_admin/admins/copy_or_move_employees";
exports.adminText='Admin';
exports.departmentOption1='Sales';
exports.departmentOption2='Customer Service';
exports.departmentOption3='Operations';
exports.departmentOption4='Linehaul';
exports.departmentOption5='Finance';
exports.departmentOption6='Billing';
exports.departmentOption7='Pricing';
exports.departmentOption8='Human Resources';
exports.departmentOption9='Information Technology';
exports.departmentOption10='Executive';
exports.jobErrorText="Job is required";
exports.administration="VP, Administration";
exports.linehaul="VP, Linehaul";
exports.operations="VP, Operations";
exports.salesClient="VP, Sales and Client Services";
exports.sfo="SCM, SFO";
exports.ont="SCM, ONT";
exports.ewr="SCM, EWR";
exports.dal="SCM, DAL";
exports.htx="SCM, HTX";
exports.atm="SCM, ATM";
exports.lax="SCM, LAX";
exports.lrd="SCM, LRD";
exports.pricing="Director, Pricing";
exports.agent="Director, Agent Operations";
exports.directorOperations="Director, Operations";
exports.directorSales="Director, Sales";
exports.crossBorder="Director, Cross Border Business";
exports.applicationDevelopment="Director, Application Development";
exports.infrastructure="Director, Infrastructure";
exports.humanResources="Director, Human Resources";
exports.servicesManager="Client Services Manager";
exports.safetyManager="Safety Manager";
exports.chiefTechnology="Chief Technology Officer";
exports.chiefInformation="Chief Information Officer";
exports.chiefFinancial="Chief Financial Officer";
exports.executiveOfficer="Executive Officer";
exports.administrationValue="VP_Administration";
exports.linehaulValue="VP_Linehaul";
exports.operationsValue="VP_Operations";
exports.salesClientValue="VP_Sales_and_Client_Services";
exports.sfoValue="SCM_SFO";
exports.ontValue="SCM_ONT";
exports.ewrValue="SCM_EWR";
exports.dalValue="SCM_DAL";
exports.htxValue="SCM_HTX";
exports.atmValue="SCM_ATM";
exports.laxValue="SCM_LAX";
exports.lrdValue="SCM_LRD";
exports.pricingValue="Director_Pricing";
exports.agentValue="Director_Agent_Operations";
exports.directorOperationsValue="Director_Operations";
exports.directorSalesValue="Director_Sales";
exports.crossBorderValue="Director_Cross_Border_Business";
exports.applicationDevelopmentValue="Director_Application_Development";
exports.infrastructureValue="Director_Infrastructure";
exports.humanResourcesValue="Director_Human_Resources";
exports.servicesManagerValue="Client_Services_Manager";
exports.safetyManagerValue="Safety_Manager";
exports.chiefTechnologyValue="Chief_Technology_Officer";
exports.chiefInformationValue="Chief_Information_Officer";
exports.chiefFinancialValue="Chief_Financial_Officer";
exports.executiveOfficerValue="Executive_Officer";
exports.jobPlaceholderText="Select Job Title";
exports.businessAnalyst='Business_Analyst_Controller';
exports.businessAnalystKey='Business Analyst Controller';
exports.srNetwork='Sr_Network_Engineer';
exports.srNetworkKey='Sr. Network Engineer';
exports.srSystems='Sr_Systems_Engineer';
exports.srSystemsKey='Sr. Systems Engineer';
exports.projectManager='Project_Manager';
exports.projectManagerKey='Project Manager';
exports.applicationDeveloper='Application_Developer';
exports.applicationDeveloperKey='Application Developer';
exports.srApplications='Sr_Applications_Developer';
exports.srApplicationsKey='Sr. Applications Developer';
exports.srSystemsAdministrator='Sr_Systems_Administrator';
exports.srSystemsAdministratorKey='Sr. Systems Administrator';
exports.systemsAdministrator='Systems_Administrator';
exports.systemsAdministratorKey='Systems Administrator';
exports.technicalArchitect='Technical_Architect';
exports.technicalArchitectKey='Technical Architect';
exports.employeeOpp='Employee';
exports.supervisor='Supervisor';
exports.managerOpp='Manager';
exports.vicePresident='Vice_President';
exports.vicePresidentKey='Vice President';
// Customizable Area End
