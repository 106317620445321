import React from "react";

import MergeEngineUtilities from "../../utilities/src/MergeEngineUtilities";
export const configJSON = require("./config");
import { BlockComponent } from "../../../framework/src/BlockComponent";

// Customizable Area Start
// Customizable Area End

export interface Props {
  title: string;
  icons?: any;
  colors?: any;
  selectedIcon?: any;
  selectedColor?: any;
  onIconPress?: any;
  onColorPress?: any;
  type: string;
  open: string | null;
  openToggle: Function;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  currentXOffset: any;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class SelectOptionsAvatarController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  svgRef: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);

    this.state = {
      // Customizable Area Start
      currentXOffset: 0,
      // Customizable Area End
    };

    // Customizable Area Start
    this.svgRef = React.createRef();
    // Customizable Area End
  }

  // Customizable Area Start
  rightArrow = () => {
    this.svgRef.scrollTo({
      x: this.state.currentXOffset + 200,
      animated: true,
    });
  };

  leftArrow = () => {
    this.svgRef.scrollTo({
      x: this.state.currentXOffset - 200,
      animated: true,
    });
  };

  handleScroll = (event: any) => {
    this.setState({
      currentXOffset: event.nativeEvent.contentOffset.x,
    });
  };
  // Customizable Area End
}
