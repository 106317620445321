Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.getMethod = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.postMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "OrganisationHierarchy";
exports.labelBodyText = "OrganisationHierarchy Body";
exports.loginBodyType = "email_account";

exports.postApiMethodType = "POST";
exports.loginURLEndPoint = "bx_block_login/logins";
exports.getAllTasks = "bx_block_tasks/tasks";
exports.getEmployeeTask = "bx_block_tasks/employee_tasks";
exports.getEmployees = "bx_block_employee/employees";
exports.getEmployeeHierarchy = "bx_block_employee/employees/get_hierarchy";
exports.email = "buraktest@gmail.com";
exports.securedText = "123456";

exports.btnExampleTitle = "CLICK ME";
exports.departmentopt1 = "Sales";
exports.departmentopt2 = "Customer Service";
exports.departmentopt3 = "Operations";
exports.departmentopt4 = "Linehaul";
exports.departmentopt5 = "Finance";
exports.departmentopt6 = "Billing";
exports.departmentopt7 = "Pricing";
exports.departmentopt8 = "Human Resources";
exports.departmentopt9 = "Information Technology";
exports.departmentopt10 = "Executive";
// Customizable Area End
