import React from "react";
// Customizable Area Start
import {
  Button,
  Grid,
  Dialog,
  DialogContent,
  TextField,
  Typography,
  Divider,
  MenuItem,
  Select
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ManagerInfoController, {Props,configJSON} from "./ManagerProfileController.web";

const theme = createTheme({
  overrides: {
    MuiOutlinedInput: {
      notchedOutline: {
        border: "none"
      }
    }
  }
});
// Customizable Area End

export default class ManagerProfile extends ManagerInfoController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const disabledSave = !this.state.managerInfo.name || !this.state.managerInfo.email || !this.state.managerInfo.department || !this.state.managerInfo.role
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Dialog
          id="ar-manager-profile-dialog"
          open={this.props.openModalProfile}
          onClose={this.props.handleCloseModalProfile}
          PaperProps={{ style: { minWidth: 975, margin: "auto", borderRadius: 8 } }}
        >
          <DialogContent style={{ padding: 0, overflowY: "auto",overflowX:'hidden' }}>
            <div
              style={{
                width: "100%",
                borderRadius: "9px 9px 0 0",
                backgroundColor: "#F4F9FD",
                height: 70,
                display: "flex",
                alignItems: "center"
              }}
            >
              <span style={{
                fontSize: 20,
                fontWeight: 700,
                lineHeight: "28px",
                color: "#3C3C3C",
                padding: "0 25px",
                fontFamily: "Lato"
              }}>Edit Account
              </span>
            </div>
            {/* Add your content for the Modal here */}
            <Grid container spacing={2} style={{ ...styles.dialogMainContent }}>
              <Grid item sm={6} style={{ textAlign: "center" }}>
                {this.state.managerInfo.image ?
                  <img src={this.state.managerInfo.image} style={{ ...styles.avt, objectFit: "cover" }} /> :
                  <div style={{ ...styles.avt, display: "flex", justifyContent: "center", alignItems: "center", margin: 'auto' }}>
                    <Typography style={{ fontSize: 48 }}>{this.state.managerInfo.profileText}</Typography>
                  </div>
                }
                <p 
                  data-testid="btn-delete-account"
                  onKeyDown={this.handleOpenModalDelete}
                  onClick={this.handleOpenModalDelete} 
                  style={{ cursor: "pointer",
                   fontSize: 20, fontWeight: 700, lineHeight: "28px", textDecoration: "underline", color: "#DB2323", textUnderlineOffset: 5}}>
                  Delete account
                </p>
              </Grid>
              <Grid item sm={6} style={{ padding: "0 40px" }}>
                <p style={styles.inputLabel}>Name</p>
                <TextField
                  data-testid={"name_tid"}
                  type="text"
                  name="name"
                  variant="outlined"
                  value={this.state.managerInfo.name}
                  style={{...styles.textField, background: this.state.managerInfo.name===""?"#FFE7E7":"#F4F4F4"}}
                  InputProps={{ style: styles.InputProps }}
                  onChange={(e) => this.handleChangeManagerInfo("name", e.target.value) }
                  fullWidth
                  required
                />
                <p style={styles.inputLabel}>Email ID</p>
                <TextField
                  data-testid={"email_tid"}
                  type="text"
                  name="email"
                  variant="outlined"
                  value={this.state.managerInfo.email}
                  style={{...styles.textField, background: this.state.managerInfo.email===""?"#FFE7E7":"#F4F4F4" }}
                  InputProps={{ style: styles.InputProps }}
                  onChange={(e) => this.handleChangeManagerInfo("email", e.target.value) }
                  fullWidth
                  required
                />
                <p style={styles.inputLabel}>Department</p>
                <Select
                  native
                  labelId="select-department"
                  data-testid={"department_tid"}
                  disableUnderline
                  style={{
                    padding: "5px 10px",
                    border: "1px solid #CCCCCC",
                    borderRadius: 8,
                    width: "100%"
                  }}
                  onChange={(e) => this.handleChangeManagerInfo("department", e.target.value)}
                  value={this.state.managerInfo.department}
                  renderValue={(value: any) => value}
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "center"
                    },
                    TransitionProps: {
                      style: {
                        marginTop: 15,
                        borderRadius: 8,
                        padding: 8,
                      }
                    },
                    transformOrigin: {
                      horizontal: "center",
                      vertical: "top",
                    },
                  }}
                >
                  <option value='Sales'>{configJSON.departmentopt1}</option>
                  <option value="Customer_Service">{configJSON.departmentopt2}</option>
                  <option value="Operations">{configJSON.departmentopt3}</option>
                  <option value="Linehaul">{configJSON.departmentopt4}</option>
                  <option value="Finance">{configJSON.departmentopt5}</option>
                  <option value="Billing">{configJSON.departmentopt6}</option>
                  <option value="Pricing">{configJSON.departmentopt7}</option>
                  <option value="Human_Resources">{configJSON.departmentopt8}</option>
                  <option value="Information_Technology">{configJSON.departmentopt9}</option>
                  <option value="Executive">{configJSON.departmentopt10}</option>
                </Select>
                {/* <TextField
                  data-testid={"department_tid"}
                  type="text"
                  name="department"
                  variant="outlined"
                  value={this.state.managerInfo.department}
                  style={{...styles.textField,  background: this.state.managerInfo.department===""?"#FFE7E7":"#F4F4F4"}}
                  InputProps={{ style: styles.InputProps }}
                  onChange={(e) => this.handleChangeManagerInfo("department", e.target.value) }
                  fullWidth
                  required
                /> */}
                <p style={styles.inputLabel}>Role</p>
                <Select
                  data-testid={"role_tid"}
                  labelId="demo-customized-select-label"
                  id="role-select"
                  disableUnderline
                  value={this.state.managerInfo.role}
                  renderValue={(value: any) => value}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "center"
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "center"
                    },
                    getContentAnchorEl: null
                  }}
                  style={{...styles.textField, padding: 10, width: "100%",  background: this.state.managerInfo.role===""?"#FFE7E7":"#F4F4F4"}}
                >
                  <MenuItem data-testid="select-manager" onClick={() => this.handleChangeManagerInfo("role", "Manager")}
                    style={{ display: "flex", justifyContent: "space-between" }} value="Manager">
                    <span>Manager</span>
                  </MenuItem>
                  <MenuItem data-testid="select-employee" onClick={() => this.handleChangeManagerInfo("role", "Employee")}
                    style={{ display: "flex", justifyContent: "space-between" }} value="Employee">
                    <span>Employee</span>
                  </MenuItem>
                </Select>
                <Divider style={{ marginTop: "20" }} />
                
                <Grid container item xs={12} style={{ marginTop: 20 }}>
                  <Grid item xs={6}>
                    <Button
                      id="btn-cancel"
                      type="submit"
                      variant="contained"
                      color="primary"
                      style={styles.btnCancel}
                      onClick={this.props.handleCloseModalProfile}
                    >
                      <b>Cancel</b>
                    </Button>
                  </Grid>
                  <Grid item xs={6} style={{display: 'flex', justifyContent: 'end'}}>
                    <Button
                      id="btn-apply"
                      type="submit"
                      variant="contained"
                      color="primary"
                      style={{...styles.btnOK, background: disabledSave? "#c4c4c4":"#014A80"}}
                      onClick={this.handleSaveManagerInfo}
                      disabled={disabledSave}
                    >
                      <b>Save</b>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>

        <Dialog
          id="dialog-delete-account"
          onClose={this.handleCloseModalDelete}
          open={this.state.openModalDelete}
          PaperProps={{
            style: { width: 501, margin: "auto", borderRadius: 8 },
          }}
        >
          <DialogContent>
            {/* Add your content for the Modal here */}
            <Grid container spacing={2} style={{ marginTop: 30, padding: "0 40px 10px 40px" }}>
              <p style={{textAlign: "center", width: "100%"}}>
                Are you sure you want to delete this account?
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 20,
                  width: "100%"
                }}
              >
                 <Button
                  id="btn-close-delete"
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={styles.btnCancel}
                 onClick={this.handleCloseModalDelete}
                >
                  <b>No</b>
                </Button>
                <Button
                  id="btn-confirm-delete-account"
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={styles.btnOK}
                  onClick={this.handleDeleteManager}
                >
                  <b>Yes</b>
                </Button>
              </div>
            </Grid>
          </DialogContent>
        </Dialog> 
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
  avt: { 
    width: 204, 
    height: 204, 
    borderRadius: 100, 
    border: '4px solid #f4f4f4'
  },
  dialogMainContent: {
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "20px 0",
  },
  btnOK: {
    width: "95%",
    height: 48,
    borderRadius: 8,
    backgroundColor: "#014A80",
    marginBottom: 20,
    boxShadow: "none"
  },
  btnCancel: {
    width: "95%",
    height: 48,
    borderRadius: 8,
    border: "1px solid #979797",
    marginBottom: 20,
    marginRight: 20,
    color: "#979797",
    background: "transparent",
    boxShadow: "none"
  },
  inputLabel: { 
    fontSize: 14, 
    fontWeight: 500, 
    lineHeight: "22px", 
    fontFamily: 'Lato',
    marginBottom: 5
  },
  textField:  { 
    backgroundColor: "#F4F4F4", 
    borderRadius: 8,
    marginBottom: 5
  },
  InputProps: { 
    borderRadius: 8, 
    fontSize: 14 }
};
// Customizable Area End
