// Customizable Area Start
import React, { CSSProperties } from "react";
import {
    Table,
    TableBody,
    TableRow,
    TableHead,
    TableCell,
    Select,
    MenuItem,
    Dialog,
    DialogContent,
    Button,
    Typography,
    Grid,
    Divider,
    ListSubheader,
    TextField
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { Pagination } from "@material-ui/lab";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import RelationshipTableController, { Props } from "./RelationshipTableController";
import { StyledTextField } from "./OrganisationHierarchy2.web";
import { icBubble, icTick2 } from "./assets";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
// Customizable Area End


export default class RelationshipTable extends RelationshipTableController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    RTRenderLoading = (isRTLoading: boolean) => {
        if (isRTLoading) {
            return <p style={{ textAlign: "center" }}>Loading...</p>
        }
    }

    RTRenderSelectPlaceHolder = (placeholder: any) => {
        return <span style={styles.selectPlaceHolder}>{placeholder}</span>
    }

    renderValueRelationship = (value: any) => {
        if (value === "") {
            return this.RTRenderSelectPlaceHolder(" Please Select Relationship")
        }
        if(value==="can_view") return "Can View";
        if(value==="can_edit") return "Can Edit"
    }

    renderSearch = (testId: string, name: string, value: string, placeholder: string) => {
        return <StyledTextField
            data-testid={testId}
            type="text"
            name={name}
            variant="outlined"
            value={value}
            style={{ ...styles.dialogSearchBar, marginTop: 0, height: 40 }}
            InputProps={{
                style: styles.dialogSearchInputProps, startAdornment: (
                    <img style={{ objectFit: "cover", marginRight: 5, width: 24, height: 24 }} src={icBubble} />
                )
            }}
            onClick={(e)=>{e.stopPropagation()}}
            onChange={(e) => this.onChangeSearch(name, e.target.value)}
            placeholder={placeholder}
            fullWidth
        />
    }

    renderListId = () => {
        return this.state.listId.map(item =>{
            return <div data-testid={"listID"} key={item.data?.id}>
                <MenuItem data-testid="item-id" onClick={()=>this.handleSelectId(item.data?.id)} style={{ display: "flex" }} value={item.data?.id}>
                    <img alt="" src={item.data?.attributes?.image?.url} style={{ width: 44, height: 44, border: "2px solid #CCC", marginRight: 10, borderRadius: 100 }} />
                    <span>{item.data?.attributes?.data?.Name}, ID: {item.data?.attributes?.data?.ID}</span>
                </MenuItem>
                <Divider style={styles.dialogDivider} />
            </div>}
        )
    }

    renderListConnectedUser = () => {
        return this.state.listConnectedUser.map(item =>
            <div data-testid={"listConnectedUser"} key={item.data?.id}>
                <MenuItem data-testid="item-connectedUser" onClick={()=>this.handleSelectConnectedUser(item.data?.id)} style={{ display: "flex" }} value={item.data?.id}>
                    <img alt="" src={item.data?.attributes?.image?.url} style={{ width: 44, height: 44, border: "2px solid #CCC", marginRight: 10, borderRadius: 100 }} />
                    <span>{item.data?.attributes?.data?.Name}, ID: {item.data?.attributes?.data?.ID}</span>
                </MenuItem>
                <Divider style={styles.dialogDivider} />
            </div>
        )
    }

    checkBtnStyle = () => {
        return this.state.selectedConnectedUser === "" || this.state.selectedId === "" || this.state.selectedSequence === "" || this.state.selectedRelationship === "" ?
            "#979797" : "#014A80"
    }

    renderPagination = (item: any) => {
        if (item.type === "page") {
            return <div
                id="manager-pagination-item"
                onKeyDown={() => this.onChangeRTPage(item.page)}
                onClick={() => this.onChangeRTPage(item.page)}
                style={item.page === this.state.page ? styles.activePaginationRTItem : styles.paginationRTItem}>
                {item.page}
            </div>
        }
        return <div id="pagination-dot" style={styles.paginationRTItem}>...</div>
    }

    renderTable = () => {
        return this.state.listRTUser.length === 0 ? <p>No record found</p> :
            <Table style={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow style={{ backgroundColor: "#F4F9FD" }}>
                        <TableCell style={{ width: "10%" }} align="center">Key</TableCell>
                        <TableCell style={{ width: "22%" }}>ID</TableCell>
                        <TableCell style={{ width: "23%" }}>Relationship</TableCell>
                        <TableCell style={{ width: "22%" }}>Connected User</TableCell>
                        <TableCell style={{ width: "23%" }}>Sequence</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {this.state.listRTUser.map(user =>
                        <TableRow key={user.data?.id} >
                            <TableCell align="center">
                                {user.data?.attributes?.id}
                            </TableCell>
                            <TableCell>
                                {user.data?.attributes?.manager_user_id|| user.data?.attributes?.manager_name}
                            </TableCell>
                            <TableCell>
                                <Select
                                    labelId="select-relationship"
                                    id="select-relationship"
                                    disableUnderline
                                    value={user.data?.attributes?.access?.permission ||'can_view'}
                                    style={styles.select}
                                    renderValue={(value: any) => value === "can_view" ? "Can View" : "Can Edit"}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "center"
                                        },
                                        TransitionProps: { style: styles.menuContainer },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "center"
                                        },
                                        getContentAnchorEl: null
                                    }}
                                >
                                    <MenuItem
                                        data-testid="select-relationship-view"
                                        onClick={() => { this.handleChangeUserData(user.data.id, 'access', "can_view") }}
                                        style={{ display: "flex", justifyContent: "space-between" }} value="can_view">
                                        <span>Can View</span>
                                    </MenuItem>
                                    <Divider />
                                    <MenuItem
                                        data-testid="select-relationship-edit"
                                        onClick={() => { this.handleChangeUserData(user.data.id, 'access', "can_edit") }}
                                        style={{ display: "flex", justifyContent: "space-between" }} value="can_edit">
                                        <span>Can Edit</span>
                                    </MenuItem>
                                </Select>
                            </TableCell>
                            <TableCell>
                                {user.data?.attributes?.employee_user_id || user.data?.attributes?.employee_name}
                            </TableCell>
                            <TableCell>
                                <TextField
                                    id="select-sequence"
                                    type="tel"
                                    name="name"
                                    variant="outlined"
                                    value={user.data?.attributes.sequence}
                                    style={{ ...styles.textField }}
                                    InputProps={{ style: styles.InputProps }}
                                    onChange={(e) => { this.handleChangeUserData(user.data.id, 'sequence', e.target.value) }}
                                    fullWidth
                                    required
                                />
                            </TableCell>
                        </TableRow>)}
                </TableBody>
            </Table>
    }

    renderAlert=()=>{
     return <Snackbar open={this.state.open} autoHideDuration={6000} onClose={this.handleClose} id='alertId'>
            <MuiAlert elevation={6} id='autoCloseId' variant="filled" onClose={this.handleClose} severity={this.state.showType?'success':'error'}>
            Key {this.state.updateUserData.id} {this.state.errorMsg}
            </MuiAlert>
        </Snackbar>
    } 
    // Customizable Area End

    render() {

        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                {this.state.isRTLoading ? this.RTRenderLoading(this.state.isRTLoading) : this.renderTable()}
                {this.renderAlert()}
                <Pagination
                    id="user-table-pagination"
                    count={Math.ceil(this.state.totalUser / 8)}
                    page={this.state.page}
                    style={styles.paginationRT}
                    hideNextButton
                    hidePrevButton
                    boundaryCount={2}
                    siblingCount={1}
                    renderItem={(item) => {return this.renderPagination(item)}}
                />
                <Dialog
                    id="dialog-add-new-relationship"
                    open={this.props.openAddNewRelationship}
                    onClose={this.props.handleCloseAddRelationshipDialog}
                    PaperProps={{ style: styles.dialogContainer as CSSProperties }}
                >
                    <DialogContent style={{ overflow: "auto", position: "relative" }}>
                        {/* Add your content for the Modal here */}
                        <Typography style={styles.dialogTitle as CSSProperties}>Add New Relationship</Typography>
                        <p style={styles.dialogNote as CSSProperties}>Please fill in below details to add a new relationship</p>
                        <Grid container style={{ width: "90%", margin: "auto" }}>
                            <Grid item xs={12}>
                                <Typography style={styles.dialogLabel}>ID</Typography>
                                <Select
                                    labelId="create-select-id"
                                    id="create-select-id"
                                    disableUnderline
                                    value={this.state.selectedId}
                                    placeholder="Please Select ID"
                                    style={{ ...styles.select, ...styles.dialogSelect }}
                                    displayEmpty
                                    renderValue={(value: any) => value !== '' ? this.state.selectedIdName : this.RTRenderSelectPlaceHolder(" Please Select ID")}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "center"
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "center"
                                        },
                                        getContentAnchorEl: null,
                                        TransitionProps: { style: styles.menuContainer },
                                        autoFocus: false
                                    }}
                                    onOpen={this.handleOpenSelectId}
                                    onClose={this.handleCloseSelectId}
                                >
                                    <ListSubheader>
                                        {this.renderSearch("input-search-id", "search-id", this.state.searchIDValue, "Search ID")}
                                        {this.RTRenderLoading(this.state.isLoading)}
                                    </ListSubheader>
                                    <div id="list-id" onScroll={() => this.handleLoadMore("list-id", this.state.listId, this.state.totalManager)} style={styles.listId as CSSProperties}>
                                        {this.renderListId()}
                                    </div>
                                </Select>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography style={styles.dialogLabel}>Connected User</Typography>
                                <Select
                                    labelId="create-select-connectedUser"
                                    id="create-select-connectedUser"
                                    disableUnderline
                                    value={this.state.selectedConnectedUser}
                                    renderValue={(value: any) => value !== '' ? this.state.selectedConnectedUserName : this.RTRenderSelectPlaceHolder(" Please Select Connected User")}
                                    displayEmpty
                                    style={{ ...styles.select, ...styles.dialogSelect }}
                                    MenuProps={{
                                        TransitionProps: { style: styles.menuContainer },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "center"
                                        },
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "center"
                                        },
                                        getContentAnchorEl: null,
                                    }}
                                    onOpen={this.handleOpenSelectEmp}
                                    onClose={this.handleCloseSelectEmp}
                                >
                                    <ListSubheader>
                                        {this.renderSearch("input-search-connected-user", "search-emp", this.state.searchEmpValue, "Search ID")}
                                        {this.RTRenderLoading(this.state.isLoading)}
                                    </ListSubheader>
                                    <div id="list-emp" onScroll={() => this.handleLoadMore("list-emp", this.state.listConnectedUser, this.state.totalEmployee)}
                                        style={styles.listId as CSSProperties}>
                                        {this.renderListConnectedUser()}
                                    </div>
                                </Select>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography style={styles.dialogLabel}>Relationship</Typography>
                                <Select
                                    labelId="create-select-relationship"
                                    id="create-select-relationship"
                                    disableUnderline
                                    value={this.state.selectedRelationship}
                                    style={{ ...styles.select, ...styles.dialogSelect }}
                                    displayEmpty
                                    renderValue={(value: any) => this.renderValueRelationship(value)}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "center"
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "center"
                                        },
                                        getContentAnchorEl: null,
                                        TransitionProps: { style: styles.menuContainer }
                                    }}
                                >
                                    <MenuItem data-testid="select-add-relationship-view" onClick={()=>this.handleSelectRelationship("can_view")} 
                                        style={{ display: "flex", justifyContent: "space-between" }} value="can_view">
                                        <span>Can View</span>
                                    </MenuItem>
                                    <Divider style={styles.dialogDivider} />
                                    <MenuItem onClick={()=>this.handleSelectRelationship("can_edit")} 
                                         style={{ display: "flex", justifyContent: "space-between" }} value="can_edit">
                                        <span>Can Edit</span>
                                    </MenuItem>
                                </Select>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography style={styles.dialogLabel}>Sequence</Typography>
                                <TextField
                                    id="create-select-sequence"
                                    type="tel"
                                    name="name"
                                    variant="outlined"
                                    placeholder="Please Input Sequence"
                                    value={this.state.selectedSequence}
                                    style={{ ...styles.textField, marginTop: 20 }}
                                    InputProps={{ style: styles.InputProps }}
                                    onChange={(e) => this.handleSelectSequence(e.target.value)}
                                    fullWidth
                                    required
                                />

                            </Grid>
                            {this.state.alertMessage && <div style={{color: 'red',fontSize: '12px',fontWeight: 400,
                            marginTop: '2px'}}>{this.state.alertMessage}</div>}
                        </Grid>
                        <Button
                            data-testid="btn-add-new-relationship"
                            disabled={this.state.selectedConnectedUser === "" || this.state.selectedId === "" || this.state.selectedSequence === "" || this.state.selectedRelationship === ""}
                            onClick={this.createRelationship}
                            style={{
                                ...styles.buttonAddNewRelationship,
                                background: this.checkBtnStyle()
                            } as CSSProperties}>
                            Add New Relationship
                        </Button>
                    </DialogContent>
                </Dialog>
                <Dialog
                    data-testid="dialog-success-add"
                    PaperProps={{ style: { width: 500, padding: "20px 0 20px 0" } }}
                    open={this.state.openSuccessAddNewRelationship}
                    onClose={this.handleCloseSuccessAddNewRelationship}>
                    <DialogContent>
                        <Grid container>
                            <img src={icTick2} style={{margin: "auto"}} />
                            <p style={{textAlign: "center", marginBottom: 40}}>
                                You have successfully added a New Relationship between Id: {this.state.managerId} & Connected User: {this.state.employeeId}
                            </p>
                            <Button onClick={this.handleCloseSuccessAddNewRelationship} 
                                style={{...styles.buttonAddNewRelationship, width: "90%", margin: "auto"} as CSSProperties}>
                                Okay
                            </Button>
                        </Grid>
                    </DialogContent>
                </Dialog>
            </ThemeProvider>

            // Customizable Area End
        );
    }
}

// Customizable Area Start
const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
const styles = {
    paginationRT: {
        display: 'flex',
        justifyContent: 'center',
        margin: "2rem 0",
        cursor: "pointer"
    },
    activePaginationRTItem: {
        backgroundColor: '#026AB7',
        padding: '0.25rem 0.65rem',
        width: "max-content",
        borderRadius: '2rem',
        color: "#FFFFFF",

        fontWeight: 400,
        lineHeight: '22px',
        fontSize: 14,
        margin: '0 0.5rem'
    },
    select: {
        padding: "5px 10px",
        border: "1px solid #CCCCCC",
        borderRadius: 8,
        width: "70%"
    },
    textField:  { 
        backgroundColor: "#F4F4F4", 
        borderRadius: 8,
        marginBottom: 5,
        background: "#F1F5F9",
      },
    dialogSearchInputProps: {
        borderRadius: 8,
        fontSize: 16,
        border: "1px solid #CBD5E1",
        height: 40,
        padding: "4px 8px"
    },
    dialogSearchBar: {
        backgroundColor: "#F1F5F9",
        borderRadius: 8,
        margin: "20px auto",
        width: "100%",
        height: 56
    },
    InputProps: {
        fontSize: 16, 
        borderRadius: 8, 
    },
    paginationRTItem: {
        fontWeight: 400,
        lineHeight: '22px',
        fontSize: 14,
        margin: '0 0.5rem',
        padding: '0.25rem 0.65rem',
        width: "max-content",
        borderRadius: '2rem',
        color: "#CCCCCC",
    },
    dialogTitle: {
        color: "#014A80",
        fontFamily: "Lato",
        fontSize: 32,
        fontWeight: 800,
        lineHeight: "40px",
        textAlign: "center"
    },
    buttonAddNewRelationship: {
        backgroundColor: "#014A80",
        color: "#fff",
        fontSize: 20,
        fontWeight: 700,
        lineHeight: "28px",
        padding: "10px 16px",
        position: "relative",
        bottom: 0,
        marginTop: 40,
        borderRadius: 8,
        textTransform: "none",
        width: "100%",
    },
    dialogContainer: {
        minWidth: 545,
        margin: "auto",
        borderRadius: 8,
        padding: 20,
    },
    dialogNote: {
        textAlign: "center",
        fontSize: 16,
        lineHeight: "24px"
    },
    dialogSelect: {
        width: '100%',
        background: "#F1F5F9",
        border: "1px solid #CBD5E1",
        padding: "10px 8px",
        margin: "20px auto",
        fontSize: 16
    },
    dialogLabel: {
        fontFamily: "Lato",
        fontSize: 14,
        lineHeight: "22px",
    },
    dialogDivider: {
        margin: "10px auto",
        width: "90%",
        color: "#CCCCCC"
    },
    menuContainer: {
        marginTop: 15,
        borderRadius: 8,
        padding: "10px 8px",
    },
    selectPlaceHolder: {
        color: "#979797"
    },
    listId: { maxHeight: 180, overflow: 'auto', scrollbarWidth: "thin", scrollbarColor: "#44AFFF #F3F5F7" }
};
// Customizable Area End
