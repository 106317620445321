import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  popup: any;
  index: number;
  reportId:string;
  userImage:string;
  getListComment?: (id: string) => {}
  closeAddNewComment?: () => void,
  selectedComment?: number,
  handleSelectComment?: (id: number) => void
  havePermissionComment?: boolean
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  commentData: any;
  token: string;
  loading: boolean;
  comment: string;
  commentsArray: any;
  replyCommentId: any;
  comments:any;
  isOpen:boolean;
  repliedComment: any[]
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class CommentController extends BlockComponent<Props, S, SS> {
  apiCommentItemCallId: string = "";
 // Customizable Area Start
 createCommentsCallId:string = "";
 getCommentsCallId:string = "";
 // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      commentData: [],
      token: "",
      loading: false,
      comment: "",
      commentsArray: [],
      replyCommentId: null,
      comments:[],
      isOpen:false,
      repliedComment: []
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.setState({repliedComment: this.props.popup.attributes?.data?.comments})
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      
      if(apiRequestCallId === this.createCommentsCallId){
        if(responseJson.data) {
          if(this.props.getListComment) this.props.getListComment(this.props.reportId)
        }
      } else{
        console.log(errorReponse);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void { 
    console.log("hehe", this.props.index, this.props.popup.attributes?.data?.comments !== prevProps.popup.attributes?.data?.comments)
    if(this.props.popup.attributes?.data?.comments !== prevProps.popup.attributes?.data?.comments) {
      this.setState({repliedComment: [...this.props.popup.attributes?.data?.comments ?? []], comments: {...this.state.comments, [this.props.index]: ""}})
    }
    if (this.props.selectedComment !== prevProps.selectedComment && this.props.selectedComment === this.props.index && !prevState.isOpen ) {
      this.setState({ isOpen: true});
      if (this.props.closeAddNewComment) this.props.closeAddNewComment()
    } else if (this.props.selectedComment !== prevProps.selectedComment && prevProps.selectedComment === this.props.index && prevState.isOpen) {
      this.setState({isOpen: false})
    }
  }


  apiCall = async (data: any) => {
    const token = await getStorageData("token");
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
      token: token,
    };

    const requestMes = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMes.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMes.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMes.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMes.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    runEngine.sendMessage(requestMes.id, requestMes);
    return requestMes.messageId;
  };

  handleComments =(event: any,index:number)=>{
    event.stopPropagation(); 
    const value=event.target.value;
    this.setState((prev)=>({comments:{...prev.comments,[index]:value}}))
    

  };

  createComments = async (body:any) => {
    this.createCommentsCallId = await this.apiCall({
      // contentType: configJSON.jsonApiContentType,
      method: configJSON.commentPOSTAPiMethod,
      endPoint: 'bx_block_comments/comments/create_comment',
      body,
    });
  };

  createComment =(event:any,index:number,data:any)=>{
    event.stopPropagation();
    const body = new FormData();
    body.append("comment[commentable_type]","BxBlockComments::Comment");
    body.append("comment[commentable_id]", data.id);
    body.append("comment[comment]",this.state.comments[index]);
    if(!["",undefined].includes(this.state.comments[index]))this.createComments(body)
  };

  handlePopupElementClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    this.setState({isOpen:true})
    if(this.props.handleSelectComment) this.props.handleSelectComment(this.props.index)
    if(this.props.closeAddNewComment) this.props.closeAddNewComment()
  };

  closePopup = (event: React.MouseEvent<HTMLDivElement>) =>{
    event.stopPropagation();
    this.setState({isOpen:false})
    if(this.props.handleSelectComment) this.props.handleSelectComment(0)
  }

  // Customizable Area End
}