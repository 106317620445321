// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import PhoneNumberInput from '../../blocks/mobile-account-registration/src/PhoneNumberInput';
import AdditionalDetailForm from '../../blocks/mobile-account-registration/src/AdditionalDetailForm';
import EducationalUserProfile from '../../blocks/educational-user-profile/src/EducationalUserProfile';
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth';
import VisualAnalytics from '../../blocks/visualanalytics/src/VisualAnalytics.web';
import Adminconsole2 from '../../blocks/adminconsole2/src/Adminconsole2';
import Avatar from '../../blocks/avatar/src/Avatar';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import Rolesandpermissions from '../../blocks/rolesandpermissions/src/Rolesandpermissions';
import CreateComment from '../../blocks/comments/src/CreateComment';
import PostCreation from '../../blocks/postcreation/src/PostCreation';
import Posts from '../../blocks/postcreation/src/Posts';
import PostDetails from '../../blocks/postcreation/src/PostDetails';
import SocialMediaAccountLoginScreen from '../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen';
import SocialMediaAccountRegistrationScreen from '../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen';
import Automaticreminders from '../../blocks/automaticreminders/src/Automaticreminders';
import LandingPage from '../../blocks/landingpage/src/LandingPage';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration.web';
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories';
import TermsConditions from '../../blocks/termsconditions/src/TermsAndCondition.web';
import EmailAccountLogin from '../../blocks/social-media-account-login/src/EmailAccountLogin.web';
import EmployeeHistory from '../../blocks/visualanalytics/src/History.web';
import EmployeeReport from '../../blocks/visualanalytics/src/EmployeeReport.web';
import UserProfile from '../../blocks/user-profile-basic/src/UserProfileBasicBlock.web';
import AssignRelationships from "../../blocks/organisationhierarchy/src/AssignRelationships.web";
import Adminconsole from '../../blocks/adminconsole2/src/Adminconsole2.web';
import OrganisationHierarchy from "../../blocks/organisationhierarchy/src/OrganisationHierarchy2.web"


const routeMap = {
  HomeScreen: {
    component: VisualAnalytics,
    path: '/',
    exact: true
  },
  EmployeeReport: {
    component: EmployeeReport,
    path: '/employeeReport'
  },
  History: {
    component: EmployeeHistory,
    path: '/history'
  },
  EmailAccountLogin: {
    component: EmailAccountLogin,
    path: '/login'
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: '/EmailAccountRegistration'
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: '/PhoneNumberInput'
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: '/AdditionalDetailForm'
  },
  EducationalUserProfile: {
    component: EducationalUserProfile,
    path: '/EducationalUserProfile'
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth'
  },
  VisualAnalytics: {
    component: VisualAnalytics,
    path: '/VisualAnalytics'
  },
  EditHistory: {
    component: VisualAnalytics,
    path: '/edit-history/:id'
  },
  EditReport: {
    component: VisualAnalytics,
    path: '/edit-report/:id'
  },
  Adminconsole2: {
    component: Adminconsole2,
    path: '/Adminconsole2'
  },
  Avatar: {
    component: Avatar,
    path: '/Avatar'
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector'
  },
  UserProfileBasic: {
    component: UserProfile,
    path: '/userProfile'
  },
  Rolesandpermissions: {
    component: Rolesandpermissions,
    path: '/Rolesandpermissions'
  },
  Comment: {
    component: Comment,
    path: '/Comment'
  },
  CreateComment: {
    component: CreateComment,
    path: '/CreateComment'
  },
  PostCreation: {
    component: PostCreation,
    path: '/PostCreation'
  },
  Posts: {
    component: Posts,
    path: '/Posts'
  },
  PostDetails: {
    component: PostDetails,
    path: '/PostDetails'
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: '/SocialMediaAccountLoginScreen'
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: '/SocialMediaAccountRegistrationScreen'
  },
  Automaticreminders: {
    component: Automaticreminders,
    path: '/Automaticreminders'
  },
  TermsConditions: {
    component: TermsConditions,
    path: '/TermsConditions'
  },
  LandingPage: {
    component: LandingPage,
    path: '/LandingPage'
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories'
  },

  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  },
  AssignRelationships: {
    component: AssignRelationships, 
    path: "/assignRelationships"
  },
  Adminconsole: {
    component: Adminconsole,
    path: '/adminconsole'
  },
  OrganisationHierarchy: {
    component: OrganisationHierarchy,
    path: "/organisation-hierarchy"
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3'
});

class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <View style={{backgroundColor: "#CCE9FF"}}>
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
