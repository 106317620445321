import React from "react";

// Customizable Area Start
import { Container, Box, Button } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Edit from "@material-ui/icons/Edit";

import { userAvatar } from "./assets";
import { PersonasAvatar } from "./personas-avatar";
import EditAvatar from "./EditAvatar.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import AvatarController, { Props } from "./AvatarController";

export default class Avatar extends AvatarController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <Container maxWidth={"sm"}>
          <Box sx={webStyle.mainWrapper}>
            {this.state.isEdit ? (
              <EditAvatar
                data-test-id={"editAvatar"}
                onSave={this.onSaveHandle}
                character={this.state.character}
              />
            ) : (
              <Box style={webStyle.avatarWrapper}>
                {this.state.character ? (
                  <PersonasAvatar
                    characters={this.state.character}
                    style={webStyle.persionalAvatar}
                  />
                ) : (
                  <img src={userAvatar} style={webStyle.avatar} />
                )}
                <Button
                  data-test-id={"btnEdit"}
                  style={webStyle.editBtn}
                  onClick={this.onEditHandle}
                >
                  <Edit />
                </Button>
              </Box>
            )}
          </Box>
        </Container>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  avatarWrapper: {
    position: "relative" as "relative",
  },
  avatar: {
    width: 180,
    marginTop: 20,
  },
  editBtn: {
    height: 50,
    width: 50,
    padding: 0,
    minWidth: 50,
    borderRadius: "50%",
    backgroundColor: "#6200ee",
    color: "#fff",
    position: "absolute" as "absolute",
    bottom: 0,
    right: 0,
  },
  persionalAvatar: {
    alignSelf: "center" as "center",
    height: 180,
    width: 180,
  },
};
// Customizable Area End
