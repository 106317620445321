import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  passwordStrength: number;
  showPassword: boolean;
  showConfirmPassword: boolean;
  name: string;
  username: string;
  password: string;
  confirmPassword: string;
  department: string;
  profileImage: any;
  errors: any;
  role: any;
  strength: number;
  isCreate: boolean;
  userInfo: any;
  errorMessage: string;
  isError: string;
  imageUrl: string;
  uploadUrl: any;
  isAddEmployee: boolean;
  isChecked: any;
  applyType: string;
  isAddSuccessfully: boolean;
  isApply: boolean;
  listEmp: any[];
  empPage: number;
  totalEmp: number;
  isLoading: boolean;
  isCancel: boolean;
  managerId: string;
  inputKey:any;
  job:string;
  showName:string
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  signUpCallId: string = "";
  userCallID: string = "";
  getListEmpId: string = "";
  moveCopyEmpId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      passwordStrength: 0,
      showPassword: false,
      showConfirmPassword: false,
      name: "",
      username: "",
      password: "",
      confirmPassword: "",
      department: "",
      profileImage: null,
      errors: {},
      role: "",
      strength: 1,
      isCreate: false,
      userInfo: {},
      errorMessage: "",
      isError: '#F3F5F7',
      imageUrl: '',
      uploadUrl: {},
      isAddEmployee: false,
      isChecked: [],
      applyType: "",
      isAddSuccessfully: false,
      isApply: false,
      listEmp: [],
      empPage: 1,
      totalEmp: 0,
      isLoading: false,
      isCancel: false,
      managerId: "",
      inputKey:Date.now(),
      job:"",
      showName:"",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    this.getUserInfo();
    this.getListEmployee(this.state.empPage,'');
  }
  validatePassword = (passwordValue: string) => {
    const hasUppercase = /[A-Z]/.test(passwordValue);
    const hasNumberOrSymbol = /[\d!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(
      passwordValue
    );
    const minLength = passwordValue.length >= 8;
    const strength =
      (hasUppercase ? 1 : 0) +
      (hasNumberOrSymbol ? 1 : 0) +
      (minLength ? 1 : 0);
    this.setState({ passwordStrength: strength / 3 });
  };
 
  handleChange = (event: any) => {
    const { name, value } = event.target;
    this.validatePassword(value);
    this.setState({ inputKey: Date.now() });
    switch (name) {
      case "name":
        this.setState({ name: value,showName:value });
        break;
      case "username":
        this.setState({ username: value.replace(/\s/g, '')});
        break;
      case "password":
        this.setState({ password: value });
        const strength = this.calculatePasswordStrength(value);
        this.setState({ strength: strength });
        break;
      case "confirmPassword":
        this.setState({ confirmPassword: value });
        break;
      case "department":
        this.setState({ department: value });
        break;
      case "role":
        this.setState({ role: value });
        break;
      case "profileImage":
        this.handleImageUpload(event)
        break;
      case "job":
        this.setState({job:value})
    }
  };

  handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const imageUrl = event.target.files[0]
      this.setState({ profileImage: URL.createObjectURL(imageUrl), uploadUrl: imageUrl });
    }
  };

  handleSubmit = () => {
    const validationErrors: { [key: string]: string } = {};
    if (!this.state.name) {
      validationErrors.name = "Name is required";
    }
    if (!this.state.username) {
      validationErrors.username = "Username is required";
    }
    if (!this.state.password) {
      validationErrors.password = "Password is required";
    }
    if (this.state.password !== this.state.confirmPassword) {
      validationErrors.confirmPassword = configJSON.passWordErrorText;
    }
    if(!this.state.job){
      validationErrors.job = configJSON.jobErrorText;
    }
    if (!this.state.department) {
      validationErrors.department = "Department is required";
    }
    if (!this.state.role) {
      validationErrors.role = "Role is required";
    }
    if (
      this.state.password.length < 8 ||
      !/[A-Z]/.test(this.state.password) ||
      !/[\d!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(this.state.password)
    ) {
      validationErrors.password =
        "Password must have at least 8 characters, one uppercase letter, and one number or symbol.";
    }
    if (!this.state.profileImage) {
      validationErrors.profileImage = configJSON.profileImageError;
    }
    if (Object.keys(validationErrors).length > 0) {
      this.setState({ errors: validationErrors });
    } else {
      // Prepare the form data for submission
      this.signUpHandler();
    }
  };

  signUpHandler = async () => {
    const formBody = new FormData()
    formBody.append('account[name]', this.state.name)
    formBody.append('account[email]', this.state.username)
    formBody.append('account[department]', this.state.department)
    formBody.append('account[password]', this.state.password)
    formBody.append('account[confirm_password]', this.state.confirmPassword)
    formBody.append('account[role]', this.state.role)
    formBody.append('account[image]', this.state.uploadUrl)
    formBody.append('account[user_job_title]', this.state.job)
    this.signUpCallId = await this.apiCall({
      method: "POST",
      endPoint: "account_block/account_sessions/signup",
      formBody,
    });
  };

  apiCall = async (data: any) => {
    const { contentType, method, endPoint, body, formBody } = data;
    const token = await getStorageData("token") ?? "";
    const content = body ? { "Content-Type": contentType } : undefined;
    const header = {
      ...content,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    formBody &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formBody
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  calculatePasswordStrength = (value: string) => {
    let score = 1;
    if (value.length >= 8) {
      score += 1;
    }
    if (/[A-Z]/.test(value)) {
      score += 1;
    }
    if (/\d+/.test(value)) {
      score += 1;
    }
    if (/[!@#$%^&*]/.test(value)) {
      score += 1;
    }
    return score;
  };
  colorMapping: { [key: number]: string } = {
    1: "#DB2323",
    2: "#ffdb4c",
    3: "#4caf50",
    4: "#4caf50",
    5: "#4caf50",
  };
  textColorMapping: { [key: number]: string } = {
    1: '#979797',
    2: '#DB2323',
    3: '#DB2323',
    4: '#14B823',
    5: '#14B823'
  };
  handleModalClose = () => {
    this.setState({ isCreate: false},()=>this.clearAllValue())
  };

  clearAllValue=()=>{
    this.setState({ strength:1,name:'',username:'',password:'',confirmPassword:'',department:'',role:"",profileImage:'',uploadUrl:{},imageUrl:'',job:"" })

  }

  getUserInfo = async () => {
    this.userCallID = await this.apiCall({
      contentType: configJSON.contentTypeApiAddDetail,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getUserApiEndPoint,
    });
  };

  moveCopyEmployee = async (perform_action: string) => {
    this.moveCopyEmpId = await this.apiCall({
      contentType: configJSON.contentTypeApiAddDetail,
      method: configJSON.apiMethodTypePost,
      endPoint: configJSON.copyMoveApiEndPoint,
      body: {
        manager_id: this.state.managerId,
        employee_ids: this.state.isChecked,
        perform_action: perform_action
      }
    });
  };

  addEmployeeModal = () => {
    this.setState((prev) => ({ isAddEmployee: !prev.isAddEmployee, isCreate: false }), () => {
      this.clearAllValue();
    })

  };
  handleCheckBox = (id: any) => {
    this.setState((prev) => {
      const isChecked = prev.isChecked.includes(id)
        ? prev.isChecked.filter((item: any) => item !== id)
        : [...prev.isChecked, id];
      return { isChecked };
    });
  };
  
  handleApply = () => {
    this.setState((prev) => ({ isApply: !prev.isApply, isAddEmployee: false }))
  };
  actionTpyeMapping : { [key: string]: string } = {
    'copy':'copied',
    'move':'moved'
  }
  handleCopyMove = (performType:string) => {
    this.setState({ applyType: this.actionTpyeMapping[performType], isAddSuccessfully: true });
    this.moveCopyEmployee(performType)
  };

  handleClose = () => {
    this.setState((prev) => ({ isApply: !prev.isApply, isChecked: [], isAddSuccessfully: false, empPage:1 }),()=>this.clearAllValue())
  };

  getListEmployee = async (page: number,searchText:string) => {
    this.getListEmpId = await this.apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_user/users/emp_list?page=${page}&keyword=${searchText}`,
    });
  };

  loadMoreEmployee = () => {
    const element = document.getElementById("list-emp");
    
    const scrollTop = element?.scrollTop ?? 0;
    const scrollHeight = element?.scrollHeight ?? 0;
    const clientHeight = element?.clientHeight ?? 0;

    const needLoadMore = this.state.listEmp.length < this.state.totalEmp;

    if (scrollTop + 3 * clientHeight >= scrollHeight && !this.state.isLoading && needLoadMore) {
      this.setState({ empPage: this.state.empPage + 1, isLoading: true });
      this.getListEmployee(this.state.empPage + 1,'')
    }
  };

  selectAll = () => {
    const selectData = this.state.listEmp.map((item) => item.data.id)
    this.setState({ isChecked: selectData, isCancel: true })
  };

  handleCancel = () => {
    this.setState({ isChecked: [], isCancel: false })
  };

  fieldType: { [key in 'true' | 'false']: string } = {
    'true': "text",
    'false': "password",
  };

  handleSearchResponse = (searchText: string) => {
    this.getListEmployee(1,searchText)
    console.log(searchText,'searchText')

  };

  debouncedSearch = this.debounce(this.handleSearchResponse, 300);

  debounce(func: any, delay: number) {
    let timeout: string | number | NodeJS.Timeout | undefined;
    return function (...args: any) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  handleSearch=(e: any)=>{
    this.setState({listEmp:[]})
    const searchText=e.target.value
    this.debouncedSearch(searchText);
  };
  
  readonly jobTitle:any[]=[
    {
      key:configJSON.administration,
      value:configJSON.administrationValue
    },
    {
      key:configJSON.linehaul,
      value:configJSON.linehaulValue
    },
    {
      key:configJSON.operations,
      value:configJSON.operationsValue
    },
    {
      key:configJSON.salesClient,
      value:configJSON.salesClientValue
    },
    {
      key:configJSON.sfo,
      value:configJSON.sfoValue
    },
    {
      key:configJSON.ont,
      value:configJSON.ontValue
    },
    {
      key:configJSON.ewr,
      value:configJSON.ewrValue
    },
    {
      key:configJSON.dal,
      value:configJSON.dalValue
    }, 
    {
      key:configJSON.htx,
      value:configJSON.htxValue
    },
    {
      key:configJSON.atm,
      value:configJSON.atmValue
    },
    {
      key:configJSON.lax,
      value:configJSON.laxValue
    },
    {
      key:configJSON.lrd,
      value:configJSON.lrdValue
    },
    {
      key:configJSON.pricing,
      value:configJSON.pricingValue
    },
    {
      key:configJSON.agent,
      value:configJSON.agentValue
    },
    {
      key:configJSON.directorOperations,
      value:configJSON.directorOperationsValue
    },
    {
      key:configJSON.directorSales,
      value:configJSON.directorSalesValue
    },
    {
      key:configJSON.crossBorder,
      value:configJSON.crossBorderValue
    },
    {
      key:configJSON.applicationDevelopment,
      value:configJSON.applicationDevelopmentValue
    },
    {
      key:configJSON.infrastructure,
      value:configJSON.infrastructureValue
    },
    {
      key:configJSON.humanResources,
      value:configJSON.humanResourcesValue
    },
    {
      key:configJSON.servicesManager,
      value:configJSON.servicesManagerValue
    },
    {
      key:configJSON.safetyManager,
      value:configJSON.safetyManagerValue
    },
    {
      key:configJSON.chiefTechnology,
      value:configJSON.chiefTechnologyValue
    },
    {
      key:configJSON.chiefInformation,
      value:configJSON.chiefInformationValue
    },
    {
      key:configJSON.chiefFinancial,
      value:configJSON.chiefFinancialValue
    },
    {
      key:configJSON.executiveOfficer,
      value:configJSON.executiveOfficerValue
    },{
      key:configJSON.businessAnalystKey,
      value:configJSON.businessAnalyst
    },
    {
      key:configJSON.srNetworkKey,
      value:configJSON.srNetwork
    },
    {
      key:configJSON.srSystemsKey,
      value:configJSON.srSystems
    },
    {
      key:configJSON.projectManagerKey,
      value:configJSON.projectManager
    },
    {
      key:configJSON.applicationDeveloperKey,
      value:configJSON.applicationDeveloper
    },{
      key:configJSON.srApplicationsKey,
      value:configJSON.srApplications
    },{
      key:configJSON.srSystemsAdministratorKey,
      value:configJSON.srSystemsAdministrator
    },{
      key:configJSON.systemsAdministratorKey,
      value:configJSON.systemsAdministrator
    },{
      key:configJSON.technicalArchitectKey,
      value:configJSON.technicalArchitect
    },{
      key:configJSON.employeeOpp,
      value:configJSON.employeeOpp
    },{
      key:configJSON.supervisor,
      value:configJSON.supervisor
    },{
      key:configJSON.managerOpp,
      value:configJSON.managerOpp
    },{
      key:configJSON.vicePresidentKey,
      value:configJSON.vicePresident
    }
  ]
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.signUpCallId) {
        console.log("--api response->>>", responseJson);
        const { error, account } = responseJson
        if (error) {
          this.setState({ errorMessage: configJSON.errorText, isError: '#FFE7E7' })
        } else {
          this.setState({ isCreate: true })
          this.setState({ errorMessage: "", isError: '#F3F5F7', managerId: account.data.id, errors:{} })
        }
      } else if (apiRequestCallId === this.userCallID) {
        const {data}=responseJson;
        const {attributes}=data;
        this.setState({ userInfo: {...attributes,...attributes.data} });
      } else if (apiRequestCallId === this.getListEmpId) {
        this.setState({
          listEmp: [...this.state.listEmp, ...responseJson.data],
          totalEmp: responseJson.No_of_Members,
          isLoading: false
        })
      } else {
        console.log("--api error response->>>", errorReponse);
      }
    }
    // Customizable Area End
  }
}
