export const anonymousPng = require("../assets/anonim.jpeg");
export const icEdit = require("../assets/ic_edit.png")
export const icCheck = require("../assets/ic_check.png")
export const icMenu = require("../assets/ic_menu.png")
export const icBubble = require("../assets/ic_bubble.png")
export const icTick = require("../assets/ic_tick.png");
export const icNoti = require("../assets/ic_noti.png")
export const icTick2 = require("../assets/ic_tick2.png");
export const icEye = require("../assets/ic_eye.png");
export const icEditArrow = require("../assets/ic_edit_arrow.png");




