import React from "react";
// Customizable Area Start
import {
  StyleSheet,
} from "react-native";

import { Avatar } from "@material-ui/core"
import { icArrowComment } from "../../visualanalytics/src/assets";
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
// Customizable Area End

import CommentController, { Props } from "./CommentsController.web";

export default class Comments extends CommentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  commentData = this.props.popup;
  attributes = this.commentData.attributes;
  coordY = this.attributes.data.coordY;
  coordX = this.attributes.data.coordX;

  renderComment = (item: any) => {
    return <div key={item.created_at}>
      <div style={{ display: 'flex', backgroundColor: '#fff', alignItems: 'center' }}>
        <div style={{ display: 'flex' }}>
          <Avatar
            src={item.image_url}
            style={{ width: 25, height: 25 }}
          />
        </div>
        <div style={{ display: 'flex', width: '100px', margin: '0 5px', borderLeft: '1px solid #CCC' }}>
          <div style={{
            whiteSpace: 'nowrap',
            overflow: this.state.isOpen ? '' : 'hidden',
            textOverflow: 'ellipsis',
            paddingLeft: '5px',
            width: '30px'
          }}>
            {this.state.isOpen ? item.name : item.comment}
          </div>
          <div style={{ display: this.state.isOpen ? 'none' : 'block' }}>...</div>
        </div>
      </div>
      <div style={{
        display: this.state.isOpen ? 'block' : 'none', color: '#979797',
        margin: '2px 0 2px 33px',
        fontSize: '14px',
        width: '180px',
      }}>
        {item.comment}
      </div>
    </div>
  }
  // Customizable Area End 

  render() {
    // Customizable Area Start
    return (
      <div
        style={{
          position: 'absolute',
          top: this.commentData.y || this.coordY,
          left: this.commentData.x || this.coordX,
          border: '1px solid #ccc',
          backgroundColor: '#fff',
          zIndex:this.state.isOpen ? 1000:200,
          overflowX: 'hidden',
          borderRadius: ' 0px 8px 8px 8px',
          padding: '8px 12px 8px 8px',
          boxShadow: ' 0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)',
        }}
        onClick={this.handlePopupElementClick}
        data-test-id="comment-popup"

      >
         <div 
         style={{
          position: 'absolute',
          right: '1px',
          top: '1px',
          display: this.state.isOpen ? 'block' :'none' ,
          cursor:'pointer'
         }}  
         data-test-id="close-popup"
         onClick={this.closePopup}>
          <CancelOutlinedIcon />
        </div>
        {this.renderComment(this.attributes.data)}

        {this.state.isOpen && this.state.repliedComment?.map((item: any) => this.renderComment(item))}
        {(this.state.isOpen && this.props.havePermissionComment) &&
          <div style={{ display: 'flex', backgroundColor: '#F4F4F4', marginTop: '10px', padding: '8px', borderRadius: '8px' }}>
            <div>
              <Avatar
                src={this.props.userImage}
                style={{ width: 25, height: 25 }}
              />

            </div>
            <input
              style={{ border: 'none', margin: '0 10px', borderLeft: '1px solid #CCC', width: '140px', backgroundColor: '#F4F4F4' }}
              onChange={(event) => this.handleComments(event, this.props.index)}
              value={this.state.comments[this.props.index]}
              data-test-id="comment-report"
            />
            <img src={icArrowComment}
              data-test-id="create-comment" alt='arrow'
              onClick={(event) => this.createComment(event, this.props.index, this.commentData)} />
          </div>}
      </div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  list: {
    marginTop: 15,
    marginBottom: 20,
    backgroundColor: "#FFFFFF",
  },

});
// Customizable Area End
