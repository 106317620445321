import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
import moment from "moment";
export const sampleListUser = [
  {
    key: "11",
    id: "e_howard",
    name: "Esther Howard",
    role: "Manager",
    department: "Transport",
    status: "active",
    timestamp: "Dec 4, 2019 21:42"
  },
  {
    key: "12",
    id: "v_fox",
    name: "Victoria Fox",
    role: "Employee",
    department: "Transport",
    status: "active",
    timestamp: "Dec 4, 2019 21:42"
  },
  {
    key: "13",
    id: "a_hawkins",
    name: "Ann Hawkins",
    role: "Employee",
    department: "Transport",
    status: "active",
    timestamp: "Dec 4, 2019 21:42"
  },
  {
    key: "14",
    id: "d_richards",
    name: "Darrel Richards",
    role: "Manager",
    department: "Transport",
    status: "In-active",
    timestamp: "Dec 4, 2019 21:42"
  }
]

export interface User {
  name: string,
  department: string, 
  role: string,
  image: string,
  profile_text:string,
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  needGetListUserTable : boolean,
  searchKeyword: string
  // Customizable Area  End
}

interface S {
  // Customizable Area Start
  isLoading: boolean,
  page: number,
  totalUser: number,
  error: string
  searchValue: string,
  listUser: any[],
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class UserTableController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUserInfoId: string = "";
  getListUserId: string="";
  updateUserId: string=""
  userTableTimeOut: ReturnType<typeof setTimeout> = setTimeout(()=>{})
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isLoading: true,
      page: 1,
      totalUser: 0,
      error: "",
      searchValue: "",
      listUser: []
      // Customizable Area End
    };
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  async componentDidMount() {
    this.getListUser(1);
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if(this.props.needGetListUserTable===true && this.state.searchValue !== this.props.searchKeyword) {
      clearTimeout(this.userTableTimeOut)
      this.setState({isLoading: true, page: 1, searchValue: this.props.searchKeyword})
      this.userTableTimeOut = setTimeout(() => {
        this.getListUser(1);
      }, 1000)
    
    }
  }

  onChangePage = (page: number) => {
    this.setState({page, isLoading: true, listUser: []})
    this.getListUser(page)
  }

  onChangeUserData = (userId: string, field: 'Department' | 'status' | 'role', value: any) => {
    const {listUser} = this.state;
    listUser.forEach((user: any) => {
      if(user.data?.id === userId) {
        const {attributes} = user.data;
        const {data} = attributes;
        data[`${field}`] = value;
        this.updateUser(userId, { [field]: value})
      }
    })
    this.setState({
      listUser: [...listUser]
    })
  }

  getListUser = async (page: number) => {
    this.getListUserId = await this.apiUserTableCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_admin/admins/get_users?page=${page}&keyword=${this.state.searchValue}`,
    });
  }

  updateUser = async (id: string, data: any) => {
    const body = new FormData();
    const {Department, role, status} = data
    if(Department) body.append("account[department]", Department);
    if(status) body.append("account[status]", data.status);
    if(role) body.append("account[role_attributes][name]", data.role);

    this.updateUserId = await this.apiUserTableCall({
        method: "PUT",
        endPoint: `bx_block_user/users/${id}`,
        body
    });
  }

  apiUserTableCall = async (data: any) => {
    const userToken = await getStorageData("token");
    const { contentType, method, endPoint, body } = data;
    const userTableHeader = {
      "Content-Type": contentType,
      token: userToken,
    };

    const reqUserTable = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    reqUserTable.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(userTableHeader)
    );
    reqUserTable.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    reqUserTable.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    reqUserTable.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    runEngine.sendMessage(reqUserTable.id, reqUserTable);
    return reqUserTable.messageId;
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );
      let responseUTJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );
      let errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
      );
      // Customizable Area Start
      if (apiRequestCallId === this.getListUserId) {
        if (responseUTJson.data) {
          responseUTJson.data.forEach((user: any) => {
            user.data.attributes.data.created_at = moment.utc(user.data?.attributes?.data?.created_at + " UTC").local().format("MMM DD, YYYY HH:mm").toString()
          });

          this.setState({
            listUser: responseUTJson.data, 
            totalUser: responseUTJson.No_of_Members,
            isLoading: false
          });
        }
      } else if (apiRequestCallId === this.updateUserId) {
        this.setState({isLoading: true})
        this.getListUser(this.state.page)
      }
      else if (errorResponse) {
        console.log(errorResponse, responseUTJson, apiRequestCallId)
      }
      // Customizable Area End

    }
  }
  // Customizable Area End
}
