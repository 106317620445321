import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start

export interface User {
  name: string,
  department: string, 
  role: string,
  image: string,
  profile_text:string,
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  openAddNewRelationship: boolean,
  handleCloseAddRelationshipDialog: () => void,
  searchKeyword: string,
  needGetListRelationshipTable: boolean
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isRTLoading: boolean,
  page: number,
  listRTUser: any[],
  totalUser: number,
  searchIDValue: string,
  searchEmpValue: string,
  listId: any[],
  listConnectedUser: any[],
  openSuccessAddNewRelationship: boolean,
  error: any,
  searchValue: string | null
  selectedId: string,
  selectedConnectedUser: string
  selectedRelationship: string,
  selectedSequence: string,
  idPage: number,
  empPage: number,
  isLoading: boolean,
  totalManager: number,
  totalEmployee: number,
  selectedIdName: string,
  selectedConnectedUserName: string,
  alertMessage:string,
  managerId:string,
  employeeId:string,
  errorMsg:string,
  open:boolean,
  showType:boolean,
  updateUserData:any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class RelationshipTableController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getListRelationshipId: string=""
  updateUserCallId: string=""
  getListManagerId: string=""
  getListEmployeeId: string=""
  createRelationshipId: string=""
  relationshipTimeOut: ReturnType<typeof setTimeout> = setTimeout(()=>{})
  searchTimeout: ReturnType<typeof setTimeout> = setTimeout(()=>{})
  sequenceTimeout: ReturnType<typeof setTimeout> = setTimeout(()=>{})
  numberRegex: any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isRTLoading: true,
      page: 1,
      listRTUser: [],
      searchIDValue: "",
      searchEmpValue: "",
      listId: [],
      listConnectedUser: [],
      openSuccessAddNewRelationship: false,
      totalUser: 0,
      error: "",
      searchValue: null,
      selectedId: "", 
      selectedConnectedUser: "",
      selectedRelationship: "",
      selectedSequence: "",
      idPage: 1,
      empPage: 1,
      isLoading: false,
      totalManager: 0,
      totalEmployee: 0,
      selectedConnectedUserName: "",
      selectedIdName: "",
      alertMessage:"",
      managerId:"",
      employeeId:"",
      errorMsg:"",
      open:false,
      showType:true,
      updateUserData:{}
      // Customizable Area End
    };
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.numberRegex = /^\d*$/
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    this.getListRelationship(1)
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if(this.props.needGetListRelationshipTable===true && this.state.searchValue !== this.props.searchKeyword) {
      clearTimeout(this.relationshipTimeOut)
      this.setState({isRTLoading: true, page: 1, searchValue: this.props.searchKeyword})
      this.relationshipTimeOut = setTimeout(() => {
        this.getListRelationship(1);
      }, 1000)
    }

    if(this.props.openAddNewRelationship !== prevProps.openAddNewRelationship && !this.props.openAddNewRelationship) {
      this.setState({
        selectedConnectedUser: "",
        selectedId: "",
        selectedConnectedUserName: "",
        selectedIdName: "",
        selectedRelationship: "",
        selectedSequence: ""
      })
    }
  }

  onChangeRTPage = (page: number) => {
    this.setState({page, isLoading: true})
    this.getListRelationship(page)
  }

  handleOpenSuccessAddNewRelationship = () => {
    this.setState({openSuccessAddNewRelationship: true})
    this.props.handleCloseAddRelationshipDialog()
  }
  handleCloseSuccessAddNewRelationship = () => {
    this.setState({openSuccessAddNewRelationship: false})
  }

  handleSelectId = (id: string) => {
    const item = this.state.listId.find((item: any) =>{ 
      return (item.data.id) === id.toString()})
    this.setState({selectedId: id, selectedIdName: item?.data?.attributes?.data?.Name,managerId:item?.data?.attributes?.data?.ID})
  }

  handleLoadMore = (key: string, currentList: any, totalList: number) => {
    const listEmp = document.getElementById(key);
    const seScrolltop = listEmp?.scrollTop ?? 0;
    const seClientHeight = listEmp?.clientHeight??0;
    const seScrollHeight = listEmp?.scrollHeight??0;
    const needLoadMore = currentList.length < totalList;

    if(seScrolltop + 3*seClientHeight >= seScrollHeight && !this.state.isLoading && needLoadMore){
      if(key==="list-id") this.handleLoadMoreManager()
      else if(key==="list-emp") this.handleLoadMoreEmployee()
    }
  }

  handleLoadMoreManager = () => {
    this.setState({ idPage: this.state.idPage + 1, isLoading: true },
      () => { this.getListManager(this.state.idPage, this.state.searchIDValue) });
  }

  handleLoadMoreEmployee = () => {
    this.setState({empPage: this.state.empPage+1, isLoading: true}, () => {
      this.getListEmployee(this.state.empPage, this.state.searchEmpValue)
    })
  }

  handleSelectConnectedUser = (id: string) => {
    const item = this.state.listConnectedUser.find((item: any) =>{ 
      return (item.data.id) === id.toString()})
    this.setState({selectedConnectedUser: id, selectedConnectedUserName: item?.data?.attributes?.data?.Name,employeeId:item?.data?.attributes?.data?.ID})
  }

  handleSelectRelationship = (relationship: string) => {
    this.setState({selectedRelationship: relationship})
  }

  handleSelectSequence= (sequence: string) => {
    if(this.numberRegex.test(sequence)) this.setState({selectedSequence: sequence})
  }

  handleSearchId = (keyword: string) => {
    clearTimeout(this.searchTimeout);
    this.setState({searchIDValue: keyword, listId: [], idPage: 1})
    this.searchTimeout = setTimeout(()=>{
      this.getListManager(1, keyword)
    },1000)
  }

  handleOpenSelectId = () => {
    this.setState({searchIDValue: "", isLoading: true});
    this.getListManager(1, "")
  }

  handleCloseSelectId = ( ) => {
    this.setState({listId: [], idPage: 1})
  }

  handleSearchEmp = (keyword: string) => {
    clearTimeout(this.searchTimeout);
    this.setState({searchEmpValue: keyword, listConnectedUser: [], empPage: 1})
    this.searchTimeout = setTimeout(()=>{
      this.getListEmployee(1, keyword)
    },1000)
  }

  handleOpenSelectEmp = () => {
    this.setState({searchEmpValue: "", isLoading: true});
    this.getListEmployee(1, "")
  }

  handleCloseSelectEmp = ( ) => {
    this.setState({listConnectedUser: [], empPage: 1})
  }

  onChangeSearch = (key: string, value: string) => {
    if (key==="search-id") this.handleSearchId(value)
    else if(key === "search-emp") this.handleSearchEmp(value)
  }

  handleChangeUserData = (userId: string, field: 'access' | 'sequence', value: any) => {
    const {listRTUser} = this.state;
    listRTUser.forEach((user: any) => {
      if(user.data?.id === userId) {
        const {attributes} = user.data;
        if (field === "sequence") {
          if (this.numberRegex.test(value)) {
            attributes[`${field}`] = value;
            clearTimeout(this.sequenceTimeout)
            this.sequenceTimeout = setTimeout(() => { this.updateUser(userId, { [field]: value, attributes }) }, 1000)
          }
        }
        else if (field === "access") {
          attributes[`${field}`]['permission'] = value;
          this.updateUser(userId, { [field]: value, attributes })
        } 
      }
    })
    this.setState({
      listRTUser: [...listRTUser]
    })
  }

  updateUser = async (id: string, data: any) => {
    let endpoint = ""
    const {attributes} = data
    if(attributes.access.permission ==="can_edit") endpoint+=`&can_edit=true&can_view=true`;
    if(attributes.access.permission ==="can_view") endpoint+=`&can_view=true&can_edit=false`;
    endpoint+=`&sequence=${attributes.sequence}`
    this.setState({updateUserData:attributes})
    this.updateUserCallId = await this.apiRelationshipCall({
        method: "PUT",
        endPoint: `bx_block_admin/admins/update_new_relationship?id=${id}${endpoint}`
    });
  }

  getListManager = async (page: number, keyword?: any) => {
    this.getListManagerId = await this.apiRelationshipCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_user/users/search_manager?page=${page}&keyword=${keyword}`,
    })
  }

  getListEmployee = async (page: number, keyword?: any) => {
    const endpoint = keyword !== "" ? `&keyword=${keyword}`:""
    this.getListEmployeeId = await this.apiRelationshipCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_user/users/emp_list?page=${page}${endpoint}`,
    })
  }

  getListRelationship = async (page: number) => {
    let endpoint = ''
    if(this.state.searchValue) endpoint = `&keyword=${this.state.searchValue}`
    this.getListRelationshipId = await this.apiRelationshipCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_admin/admins/list_relationship?page=${page}${endpoint}`,
    })
  }

  createRelationship = async () => {
    const body = {
      manager_id: parseInt(this.state.selectedId),
      "employee_id": parseInt(this.state.selectedConnectedUser),
      "can_view": this.state.selectedRelationship === 'can_view',
      "can_edit": this.state.selectedRelationship === 'can_edit',
      "sequence": parseInt( this.state.selectedSequence)
    }
    this.createRelationshipId = await this.apiRelationshipCall({
      contentType: "application/json",
      method: "POST",
      endPoint: `bx_block_admin/admins/add_relationship`,
      body: JSON.stringify(body)
    })
  }
   
  createResponse =(responseRTJson:any)=>{
    if(responseRTJson.error){
      this.setState({'alertMessage':responseRTJson.error})
      return
    }
    if (!responseRTJson.status) {
      this.handleOpenSuccessAddNewRelationship()
      this.getListRelationship(1)
      this.setState({
        listRTUser: [],
        isRTLoading: true,
        selectedConnectedUser: "",
        selectedId: "",
        selectedRelationship: "",
        selectedSequence: "",
      })
    }
  }

  apiRelationshipCall = async (data: any) => {
    const userToken = await getStorageData("token");
    const { contentType, method, endPoint, body } = data;
    const relationshipHeader = {
      "Content-Type": contentType,
      token: userToken,
    };

    const reqRelationshipMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    reqRelationshipMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(relationshipHeader)
    );
    reqRelationshipMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    reqRelationshipMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    reqRelationshipMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    runEngine.sendMessage(reqRelationshipMessage.id, reqRelationshipMessage);
    return reqRelationshipMessage.messageId;
  };

  handleClose=()=>{
    this.setState((prev)=>({open:!prev.open}))
  }

  updateUsers=(responseRTJson:any)=>{
    const {error,message}=responseRTJson;
    if(error){
      this.setState({open:true,errorMsg:error,showType:false})
      this.getListRelationship(this.state.page);
    }else{
    this.getListRelationship(this.state.page);
    this.setState({open:true,errorMsg:message,showType:true})
    }
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestRTCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );
      let responseRTJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );
      let errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
      );

      if(apiRequestRTCallId === this.getListRelationshipId) {
          if(responseRTJson.data) {
            this.setState({
              isRTLoading: false, 
              listRTUser: responseRTJson.data,
              totalUser: responseRTJson.No_of_Members
            })
          } else {
            this.setState({error: "Error"})
          }
      } else if (apiRequestRTCallId === this.updateUserCallId) {
        this.updateUsers(responseRTJson)

      } else if (apiRequestRTCallId === this.getListManagerId) {
        this.setState({
          listId: responseRTJson.data,
          totalManager: responseRTJson.No_of_Members,
          isLoading: false
        })
      } else if (apiRequestRTCallId === this.getListEmployeeId) {
        this.setState({
          listConnectedUser: [...this.state.listConnectedUser, ...responseRTJson.data],
          totalEmployee: responseRTJson.No_of_Members,
          isLoading: false
        })
      } else if (apiRequestRTCallId === this.createRelationshipId) {
        this.createResponse(responseRTJson)
      }
      else if (errorResponse) {
        console.log(errorResponse, responseRTJson, apiRequestRTCallId)
        this.setState({error: "Error"})
      }
      // Customizable Area End

    }
  }
  // Customizable Area End
}
