Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.ACCOUNT_TYPE_EMAIL = "EmailAccount";
exports.ACCOUNT_TYPE_SOCIAL = "SocialAccount";
exports.ACCOUNT_TYPE_PHONE = "SmsAccount";

exports.contentTypeApiUpdateUser = "application/json";
exports.apiEndPointUpdateUser = "profile/profile";
exports.apiUpdateUserType = "PUT";

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";

exports.contenttypeApiValidateMobileNo = "application/json";
exports.endPointApiValidateMobileNo = "profile/change_phone_validation";
exports.callTypeApiValidateMobileNo = "POST";

exports.endPointApiGetUserProfile = "profile/profile";
exports.contentTypeApiGetUserProfile = "application/json";
exports.methodTypeApiGetUserProfile = "GET";

// Customizable Area Start
exports.placeHolderEmail = "Email";
exports.labelHeader =
  "This is your profile, Here you can see and update your personal information.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelArea = "Area";
exports.labelMobile = "Mobile";
exports.labelEmail = "Email";
exports.labelCurrentPassword = "Current password";
exports.labelNewPassword = "New Password";
exports.labelRePassword = "Re-Type Password";
exports.btnTextCancelPasswordChange = "Cancel";
exports.btnTextSaveChanges = "Save Changes";
exports.btnTextChangePassword = "Change Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorMobileNoNotValid = "Phone number is not valid.";
exports.errorTitle = "Error";
exports.errorBothPasswordsNotSame = "Passwords must match.";
exports.errorCurrentNewPasswordMatch = "New password cannot match current password.";
exports.errorCurrentPasswordNotValid = "Current password not valid.";
exports.errorNewPasswordNotValid = "New password not valid.";
exports.errorReTypePasswordNotValid = "Re-type password not valid.";
exports.hintCountryCode = "Select Country";
exports.errorBlankField = "can't be blank";
exports.errorEmailNotValid = "Email not valid.";
exports.getUserApiEndPoint = "bx_block_user/users/current_user_info";
exports.noteText="For any changes or Queries Please contact your admin";
exports.updatedText="Last Updated on";
exports.employeeText='Employee';
exports.managerText='Manager';

exports.nameText='Name';
exports.departmentText='Department';
exports.roleText='Role';
exports.statusText='Status';
exports.startDateText='Start Date';
exports.editButtonText='Edit Profile';
exports.profilePictureText='Profile Picture';
exports.profileText='Profile';
exports.saveButtonText='Save & Update Profile';
exports.avatarText='Choose Your Avatar';
exports.uploadPhoto='Upload Photo';
exports.avatarApiEndPoint='bx_block_user/users/avatar/avatar';
exports.adminText = 'Admin';
exports.rolePlaceholderText = "Select Role";
exports.statusPlaceholderText= "Select Status"
exports.statusOption1="Active";
exports.statusOption2="In-active";
exports.sales='Sales';
exports.customerService='Customer_Service';
exports.operations='Operations';
exports.linehaul='Linehaul';
exports.finance='Finance';
exports.billing='Billing';
exports.pricing='Pricing';
exports.humanResources='Human_Resources';
exports.informationTechnology='Information_Technology';
exports.executive='Executive';
exports.departmentProfilePlaceholderText = "Select Department";
exports.jobProfilePlaceholder="Select Job Title";
exports.administrationProfile="VP, Administration";
exports.linehaulProfile="VP, Linehaul";
exports.operationsProfile="VP, Operations";
exports.salesClientProfile="VP, Sales and Client Services";
exports.sfoProfile="SCM, SFO";
exports.ontProfile="SCM, ONT";
exports.ewrProfile="SCM, EWR";
exports.dalProfile="SCM, DAL";
exports.htxProfile="SCM, HTX";
exports.atmProfile="SCM, ATM";
exports.laxProfile="SCM, LAX";
exports.lrdProfile="SCM, LRD";
exports.pricingProfile="Director, Pricing";
exports.agentProfile="Director, Agent Operations";
exports.directorOperationsProfile="Director, Operations";
exports.directorSalesProfile="Director, Sales";
exports.crossBorderProfile="Director, Cross Border Business";
exports.applicationDevelopmentProfile="Director, Application Development";
exports.infrastructureProfile="Director, Infrastructure";
exports.humanResourcesProfile="Director, Human Resources";
exports.servicesManagerProfile="Client Services Manager";
exports.safetyManagerProfile="Safety Manager";
exports.chiefTechnologyProfile="Chief Technology Officer";
exports.chiefInformationProfile="Chief Information Officer";
exports.chiefFinancialProfile="Chief Financial Officer";
exports.executiveOfficerProfile="Executive Officer";
exports.administrationValueProfile="VP_Administration";
exports.linehaulValueProfile="VP_Linehaul";
exports.operationsValueProfile="VP_Operations";
exports.salesClientValueProfile="VP_Sales_and_Client_Services";
exports.sfoValueProfile="SCM_SFO";
exports.ontValueProfile="SCM_ONT";
exports.ewrValueProfile="SCM_EWR";
exports.dalValueProfile="SCM_DAL";
exports.htxValueProfile="SCM_HTX";
exports.atmValueProfile="SCM_ATM";
exports.laxValueProfile="SCM_LAX";
exports.lrdValueProfile="SCM_LRD";
exports.pricingValueProfile="Director_Pricing";
exports.agentValueProfile="Director_Agent_Operations";
exports.directorOperationsValueProfile="Director_Operations";
exports.directorSalesValueProfile="Director_Sales";
exports.crossBorderValueProfile="Director_Cross_Border_Business";
exports.applicationDevelopmentValueProfile="Director_Application_Development";
exports.infrastructureValueProfile="Director_Infrastructure";
exports.humanResourcesValueProfile="Director_Human_Resources";
exports.servicesManagerValueProfile="Client_Services_Manager";
exports.safetyManagerValueProfile="Safety_Manager";
exports.chiefTechnologyValueProfile="Chief_Technology_Officer";
exports.chiefInformationValueProfile="Chief_Information_Officer";
exports.chiefFinancialValueProfile="Chief_Financial_Officer";
exports.executiveOfficerValueProfile="Executive_Officer";
exports.profileJobTitle="Job Title";
exports.businessAnalystProfile='Business_Analyst_Controller';
exports.businessAnalystKeyProfile='Business Analyst Controller';
exports.srNetworkProfile='Sr_Network_Engineer';
exports.srNetworkKeyProfile='Sr. Network Engineer';
exports.srSystemsProfile='Sr_Systems_Engineer';
exports.srSystemsKeyProfile='Sr. Systems Engineer';
exports.projectManagerProfile='Project_Manager';
exports.projectManagerKeyProfile='Project Manager';
exports.applicationDeveloperProfile='Application_Developer';
exports.applicationDeveloperKeyProfile='Application Developer';
exports.srApplicationsProfile='Sr_Applications_Developer';
exports.srApplicationsKeyProfile='Sr. Applications Developer';
exports.srSystemsAdministratorProfile='Sr_Systems_Administrator';
exports.srSystemsAdministratorKeyProfile='Sr. Systems Administrator';
exports.systemsAdministratorProfile='Systems_Administrator';
exports.systemsAdministratorKeyProfile='Systems Administrator';
exports.technicalArchitectProfile='Technical_Architect';
exports.technicalArchitectKeyProfile='Technical Architect';
exports.employeeOppProfile='Employee';
exports.supervisorProfile='Supervisor';
exports.managerOppProfile='Manager';
exports.vicePresidentProfile='Vice_President';
exports.vicePresidentKeyProfile='Vice President';
// Customizable Area End

