// Customizable Area Start
import React from "react";
import {
    Table,
    TableBody,
    TableRow,
    TableHead,
    TableCell,
    Select,
    MenuItem
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { Pagination } from "@material-ui/lab";
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import UserTableController, { Props } from "./UserTableController";
// Customizable Area End


export default class UserTable extends UserTableController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    UTRenderLoading = (isLoading: boolean) => {
        if (isLoading) {
            return <p style={{ textAlign: "center" }}>Loading...</p>
        }
        return this.state.listUser.length === 0 ?
            <p>No record found</p> :
            <Table style={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow style={{ backgroundColor: "#F4F9FD" }}>
                        <TableCell>Key</TableCell>
                        <TableCell>ID</TableCell>
                        <TableCell>Employee Name</TableCell>
                        <TableCell>Role</TableCell>
                        <TableCell>Department</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Time Stamp</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {this.state.listUser.map(user =>
                        <TableRow key={user.data?.id}>
                            <TableCell>
                                {user.data?.id}
                            </TableCell>
                            <TableCell>
                                {user.data?.attributes?.data?.ID}
                            </TableCell>
                            <TableCell>
                                {user.data?.attributes?.data?.Name}
                            </TableCell>
                            <TableCell>
                                <Select
                                    labelId="select-role"
                                    id="select-role"
                                    disableUnderline
                                    value={user.data?.attributes?.data?.role}
                                    style={styles.select}
                                    renderValue={(value: any) => <div>{value}</div>}
                                    MenuProps={{
                                        TransitionProps: { style: styles.transitionProps },
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "center"
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "center"
                                        },
                                        getContentAnchorEl: null
                                    }}
                                >
                                    <MenuItem data-testid="select-role-manager" value="Manager" onClick={() => this.onChangeUserData(user.data?.id, "role", "Manager")}>
                                        <span>Manager</span>
                                    </MenuItem>
                                    <MenuItem data-testid="select-role-employee" value="Employee" onClick={() => this.onChangeUserData(user.data?.id, "role", "Employee")}>
                                        <span>Employee</span>
                                    </MenuItem>
                                </Select>
                            </TableCell>
                            <TableCell>
                                <Select
                                    labelId="select-department"
                                    id="select-department"
                                    disableUnderline
                                    value={user.data?.attributes?.data?.Department?.replace(/_/g, ' ')}
                                    style={styles.select}
                                    renderValue={(value: any) => value}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "center"
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "center"
                                        },
                                        TransitionProps: { style: styles.transitionProps },
                                        getContentAnchorEl: null
                                    }}
                                >
                                    <MenuItem data-testid="select-department-sales" value="Sales" onClick={() => this.onChangeUserData(user.data?.id, "Department", "Sales")}>
                                        <span>Sales</span>
                                    </MenuItem>
                                    <MenuItem data-testid="select-department-customer" value="Customer_Service" onClick={() => this.onChangeUserData(user.data?.id, "Department", "Customer_Service")}>
                                        <span>Customer Service</span>
                                    </MenuItem>
                                    <MenuItem data-testid="select-department-operations" value="Operations" onClick={() => this.onChangeUserData(user.data?.id, "Department", "Operations")}>
                                        <span>Operations</span>
                                    </MenuItem>
                                    <MenuItem data-testid="select-department-linehaul" value="Linehaul" onClick={() => this.onChangeUserData(user.data?.id, "Department", "Linehaul")}>
                                        <span>Linehaul</span>
                                    </MenuItem>
                                    <MenuItem data-testid="select-department-finance" value="Finance" onClick={() => this.onChangeUserData(user.data?.id, "Department", "Finance")}>
                                        <span>Finance</span>
                                    </MenuItem>
                                    <MenuItem data-testid="select-department-billing" value="Billing" onClick={() => this.onChangeUserData(user.data?.id, "Department", "Billing")}>
                                        <span>Billing</span>
                                    </MenuItem>
                                    <MenuItem data-testid="select-department-pricing" value="Pricing" onClick={() => this.onChangeUserData(user.data?.id, "Department", "Pricing")}>
                                        <span>Pricing</span>
                                    </MenuItem>
                                    <MenuItem data-testid="select-department-human" value="Human_Resources" onClick={() => this.onChangeUserData(user.data?.id, "Department", "Human_Resources")}>
                                        <span>Human Resources</span>
                                    </MenuItem>
                                    <MenuItem data-testid="select-department-information" value="Information_Technology" onClick={() => this.onChangeUserData(user.data?.id, "Department", "Information_Technology")}>
                                        <span>Information Technology</span>
                                    </MenuItem>
                                    <MenuItem data-testid="select-department-executive" value="Executive" onClick={() => this.onChangeUserData(user.data?.id, "Department", "Executive")}>
                                        <span>Executive</span>
                                    </MenuItem>
                                </Select>
                            </TableCell>
                            <TableCell>
                                <Select
                                    labelId="select-status"
                                    id="select-status"
                                    disableUnderline
                                    value={user.data?.attributes?.data?.status}
                                    style={styles.select}
                                    renderValue={(value: any) => value === 'Deactive' ? "In-active" : value}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "center"
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "center"
                                        },
                                        getContentAnchorEl: null,
                                        TransitionProps: { style: styles.transitionProps },
                                    }}
                                >
                                    <MenuItem data-testid="select-status-active" value="Active" onClick={() => this.onChangeUserData(user.data?.id, "status", "Active")}>
                                        <span>Active</span>
                                    </MenuItem>
                                    <MenuItem data-testid="select-status-inactive" value="Deactive" onClick={() => this.onChangeUserData(user.data?.id, "status", "Deactive")}>
                                        <span>In-active</span>
                                    </MenuItem>
                                </Select>
                            </TableCell>
                            <TableCell>
                                {user.data?.attributes?.data?.created_at}
                            </TableCell>
                        </TableRow>)}
                </TableBody>
            </Table>
    }
    // Customizable Area End

    render() {

        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                {this.UTRenderLoading(this.state.isLoading)}
                <Pagination
                    id="user-table-pagination"
                    count={Math.ceil(this.state.totalUser / 8)}
                    page={this.state.page}
                    style={styles.paginationUT}
                    hideNextButton
                    hidePrevButton
                    boundaryCount={2}
                    siblingCount={1}
                    renderItem={(item) => {
                        if (item.type === "page") {
                            return <div
                                id="manager-pagination-item"
                                onKeyDown={() => this.onChangePage(item.page)}
                                onClick={() => this.onChangePage(item.page)}
                                style={item.page === this.state.page ? styles.activePaginationUTItem : styles.paginationUTItem}>
                                {item.page}
                            </div>
                        }
                        return <div id="pagination-dot" style={styles.paginationUTItem}>...</div>
                    }}
                />
            </ThemeProvider>

            // Customizable Area End
        );
    }
}

// Customizable Area Start

const StyledMenuItem = styled(MenuItem)({

})

const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
const styles = {
    paginationUT: {
        display: 'flex',
        justifyContent: 'center',
        margin: "2rem 0",
        cursor: "pointer"
    },
    activePaginationUTItem: {
        backgroundColor: '#026AB7',
        padding: '0.25rem 0.65rem',
        width: "max-content",
        borderRadius: '2rem',
        color: "#FFFFFF",

        fontWeight: 400,
        lineHeight: '22px',
        fontSize: 14,
        margin: '0 0.5rem'
    },
    select: {
        padding: "5px 10px",
        border: "1px solid #CCCCCC",
        borderRadius: 8,
        width: "100%"
    },
    paginationUTItem: {
        fontWeight: 400,
        lineHeight: '22px',
        fontSize: 14,
        margin: '0 0.5rem',
        padding: '0.25rem 0.65rem',
        width: "max-content",
        borderRadius: '2rem',
        color: "#CCCCCC",
    },
    transitionProps: {
        marginTop: 15,
        borderRadius: 8,
        padding: 8,
    }
};
// Customizable Area End
