import React from "react";
// Customizable Area Start
import {
  Container,
  Card,
  CardContent,
  TextField,
  Button,
  Typography,
  AppBar,
  InputAdornment,
  Toolbar,
  Grid,
  IconButton,
  Select,
  FormControl,
  Dialog,
  DialogTitle,
  Checkbox,
  Avatar
} from "@material-ui/core";
import { ImageBackground } from "react-native";
import { Visibility, VisibilityOff, CameraAlt } from "@material-ui/icons";
import EmailAccountRegistrationController, {
  Props,
  configJSON,
} from "./EmailAccountRegistrationController.web";
import Sidebar from "../../../components/src/Sidebar.web";
import { imgCamera, imgSearch,imgSuccess } from "./assets";
import { isEmpty } from "../../../framework/src/Utilities";
const drawerWidth = 280;
type FieldTypeKey = 'true' | 'false';

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div>
        {/* Sidebar */}
        <Sidebar />
        {/* Sidebar  end*/}
        {/* header */}
        <AppBar style={styles.appBar}>
          <Toolbar>
            <Grid container
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              style={{ margin: "12px 0px 20px" }}>
              <Grid item  style={{marginLeft: 280, color: "#014A80" }}>
                <Typography
                  style={styles.topHeading}
                >
                  <b>Create Account</b>
                </Typography>
              </Grid>

              <Grid
                item
                style={{
                  minWidth: 170,
                  height: 56,
                  display: "flex",
                  alignItems: "center",
                  borderRadius: 8,
                  backgroundColor: "white",
                }}
              >
                <div>
                <Avatar
                      variant="square"
                      src={this.state.userInfo?.image?.url}
                      style={{ width: 44, height: 45 }}
                  >{this.state.userInfo.profile_text}
                </Avatar>
                </div>
                <div style={{ marginLeft: 20 }}>
                  <p
                    style={{
                      fontFamily: "Lato",
                      fontSize: 16,
                      fontWeight: 400,
                      fontStyle: "normal",
                      textAlign: "right",
                      color: "#64748B",
                    }}
                  >
                    {`${this.state.userInfo.name || " "} `}
                  </p>
                </div>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        {/* header end */}
        <main style={styles.content}>
          <Container className="flex"  style={{maxWidth:'100%'}}>
            <Card>
              <CardContent>
                <Grid container style={{ margin: 15, marginTop: 70 }}>
                  <Grid item sm={12} xs={12} md={6} xl={6}>
                    <div style={{display:'grid',placeItems: 'center'}}>
                      <div>
                      <div style={{ marginTop: 80 }}>
                        <div
                          style={{
                            color: "rgb(2, 106, 183)",
                            fontSize: "15px",
                            textAlign: 'center',
                            fontWeight: 600
                          }}
                        >
                          Profile Picture
                        </div>
                        <img
                          src={this.state.profileImage || require("../../global_assets/viewcircle.png")}
                          style={{
                            width: 195,
                            height: 195,
                            marginTop: 10,
                            border: '1px solid #d9d9d9',
                            borderRadius: '100px',
                            overflow: 'hidden'

                          }}
                        />
                      </div>

                      {/* Upload Button */}
                      <label htmlFor="fileInput">
                        <Button
                          variant="outlined"
                          startIcon={<img src={imgCamera} alt="" width={24} height={24} />}
                          component="span" // Make the button look like a normal button
                          style={{
                            width: "100%",
                            marginTop: 25,
                            textTransform: "none"
                          }}
                        >
                          Upload Photo
                          <input
                            key={this.state.inputKey}
                            data-testid="ProfileImageInputTestID"
                            type="file"
                            accept=".jpg,.png,.jpeg"
                            onChange={this.handleChange}
                            style={{ display: "none" }}
                            data-test-id="imageUploadBtn"
                            id="fileInput"
                            name='profileImage'
                          />
                        </Button>
                        <div style={{ padding: '5px', ...styles.error }}>{this.state.errors.profileImage}</div>
                      </label>
                      </div>
                    </div>
                  </Grid>
                  <Grid item sm={12} xs={12} md={6} xl={6}>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 20,
                          maxWidth: 400,
                          marginTop: 20,
                          padding:'10px'
                        }}
                      >
                        <div>
                          <label>{configJSON.nameText}</label>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 20,
                              maxWidth: 400,
                              margin: "0 auto"
                            }}
                          >
                            <TextField
                              data-testid="nameInputTestID"
                              name="name"
                              value={this.state.name}
                              onChange={(e: any) => this.handleChange(e)}
                              variant="outlined"
                              style={{ backgroundColor: this.state.isError }}
                              error={!!this.state.errors.name}
                              placeholder={configJSON.namePlaceholderText}
                            />
                          </div>
                          <div style={styles.error}>{this.state.errors.name}</div>
                        </div>

                        <div>
                          <label style={{ marginRight: "8px" }}>
                            {configJSON.userNameText}
                          </label>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 20,
                              maxWidth: 400,
                              margin: "0 auto"
                            }}
                          >
                            <TextField
                              data-testid="userNameTestID"
                              name="username"
                              value={this.state.username}
                              onChange={(e: any) => this.handleChange(e)}
                              variant="outlined"
                              style={{ backgroundColor: this.state.isError }}
                              error={!!this.state.errors.username}
                              placeholder={configJSON.emailPlaceholderText}
                            />
                          </div>
                        <div style={styles.error}>{this.state.errors.username}</div>

                        </div>

                        <div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "baseline"
                            }}
                          >
                            <label style={{ marginRight: "8px" }}>
                              {configJSON.createPasswordText}
                            </label>
                            <div
                              style={{
                                width: 100,
                                height: 5,
                                borderRadius: "13px",
                                background: "#f3f5f7",
                                overflow: "hidden"
                              }}
                            >
                              <div
                                style={{
                                  width: `${(this.state.strength / 4) * 100}%`,
                                  height: 5,
                                  backgroundColor: this.colorMapping[
                                    this.state.strength
                                  ]
                                }}
                              />
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 20,
                              maxWidth: 400,
                              margin: "0 auto"
                            }}
                          >
                            <TextField
                              data-testid="passwordInputTestID"
                              name="password"
                              placeholder={configJSON.pass1PlaceholderText}
                              type={
                                this.fieldType[this.state.showPassword.toString() as FieldTypeKey] 
                              } // Toggle between 'text' and 'password' based on showPassword state
                              value={this.state.password}
                              onChange={(e: any) => this.handleChange(e)}
                              variant="outlined"
                              style={{ backgroundColor: this.state.isError }}
                              error={!!this.state.errors.password}
                              // Add the InputAdornment to display the hide/show password icon
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      data-testid="passwordShowButtonTestID"
                                      onClick={() =>
                                        this.setState({
                                          showPassword: !this.state.showPassword
                                        })
                                      }
                                      edge="end"
                                    >
                                      {this.state.showPassword ? (
                                        <Visibility />
                                      ) : (
                                        <VisibilityOff />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }}
                            />
                          </div>
                          <div style={{ ...styles.error, color: this.textColorMapping[this.state.strength] }}>{configJSON.passWordValidationText}</div>
                        </div>

                        <div>
                          <label style={{ marginRight: "8px" }}>
                            Confirm Password
                          </label>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 20,
                              maxWidth: 400,
                              margin: "0 auto"
                            }}
                          >
                            <TextField
                              data-testid="confirmPasswordInputTestID"
                              name="confirmPassword"
                              placeholder={configJSON.pass2PlaceholderText}
                              type={
                                this.fieldType[this.state.showConfirmPassword.toString() as FieldTypeKey]      
                              } // Toggle between 'text' and 'password' based on showConfirmPassword state
                              value={this.state.confirmPassword}
                              onChange={(e: any) => this.handleChange(e)}
                              variant="outlined"
                              style={{ backgroundColor: this.state.isError }}
                              error={!!this.state.errors.confirmPassword}
                              // Add the InputAdornment to display the hide/show password icon
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment
                                    position="end"
                                    data-testid="showConfirmPasswordShowButtonTestID"
                                  >
                                    <IconButton
                                      onClick={() =>
                                        this.setState({
                                          showConfirmPassword: !this.state
                                            .showConfirmPassword
                                        })
                                      }
                                      edge="end"
                                    >
                                      {this.state.showConfirmPassword ? (
                                        <Visibility />
                                      ) : (
                                        <VisibilityOff />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }}
                            />
                          </div>
                          <div style={styles.error}>{this.state.errors.confirmPassword}</div>

                        </div>
                        <div>
                          <FormControl variant="outlined" fullWidth>
                            <label style={{ marginRight: "8px" }}>{configJSON.departmentText}</label>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 20,
                                maxWidth: 400,
                              }}
                            >
                              <Select
                                native
                                value={this.state.department}
                                onChange={(e: any) => { this.handleChange(e) }}
                                inputProps={{
                                  name: 'department',
                                  id: 'outlined-simple',
                                }}
                                data-testid="departmentdInputTestID"
                                name="department"
                                error={!!this.state.errors.department}
                                style={{ backgroundColor: this.state.isError, color: !this.state.department ? 'rgb(174 176 177)' : '' }}
                              >
                                <option aria-label="None" value="" style={{ color: 'rgb(174 176 177)' }}>
                                  {configJSON.departmentPlaceholderText}
                                </option>
                                <option value={configJSON.departmentOption1}>{configJSON.departmentOption1}</option>
                                <option value='Customer_Service'>{configJSON.departmentOption2}</option>
                                <option value={configJSON.departmentOption3}>{configJSON.departmentOption3}</option>
                                <option value={configJSON.departmentOption4}>{configJSON.departmentOption4}</option>
                                <option value={configJSON.departmentOption5}>{configJSON.departmentOption5}</option>
                                <option value={configJSON.departmentOption6}>{configJSON.departmentOption6}</option>
                                <option value={configJSON.departmentOption7}>{configJSON.departmentOption7}</option>
                                <option value='Human_Resources'>{configJSON.departmentOption8}</option>
                                <option value='Information_Technology'>{configJSON.departmentOption9}</option>
                                <option value={configJSON.departmentOption10}>{configJSON.departmentOption10}</option>
                              </Select>
                            </div>
                            <div style={styles.error}>{this.state.errors.department}</div>
                          </FormControl>
                        </div>
                        <div>
                          <FormControl variant="outlined" fullWidth>
                            <label style={{ marginRight: "8px" }}>Role</label>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 20,
                                maxWidth: 400,
                              }}
                            >
                              <Select
                                native
                                value={this.state.role}
                                onChange={(e: any) => { this.handleChange(e) }}
                                inputProps={{
                                  name: 'role',
                                  id: 'outlined-simple',
                                }}
                                data-testid="roleInputTestID"
                                name="role"
                                error={!!this.state.errors.role}
                                style={{ backgroundColor: this.state.isError, color: !this.state.role ? 'rgb(174 176 177)' : '' }}
                              >
                                <option aria-label="None" value="" >
                                  {configJSON.rolePlaceholderText}
                                </option>
                                <option value='Employee'>{configJSON.employeeText}</option>
                                <option value='Manager'>{configJSON.managerText}</option>
                                <option value='Admin'>{configJSON.adminText}</option>
                              </Select>
                            </div>
                          </FormControl>
                           <div style={styles.error}>{this.state.errors.role}</div>
                        </div>
                        <div>
                          <FormControl variant="outlined" fullWidth>
                            <label style={{ marginRight: "8px" }}>Job Title</label>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 20,
                                maxWidth: 400,
                              }}
                            >
                              <Select
                                native
                                value={this.state.job}
                                onChange={(e: any) => { this.handleChange(e) }}
                                inputProps={{
                                  name: 'job',
                                  id: 'outlined-simple',
                                }}
                                data-testid="jobInputTestID"
                                name="job"
                                error={!!this.state.errors.job}
                                style={{ backgroundColor: this.state.isError, color: !this.state.job ? 'rgb(174 176 177)' : '' }}
                              >
                                <option aria-label="None" value="" >
                                  {configJSON.jobPlaceholderText}
                                </option>
                                {this.jobTitle.map((item)=><option key={item.value} value={item.value}>{item.key}</option>)}
                              </Select>
                            </div>
                          </FormControl>
                           <div style={styles.error}>{this.state.errors.job}</div>
                        </div>
                        <div style={styles.error}>{this.state.errorMessage}</div>

                        <Button
                          data-testid="CreateAccountButtonTestID"
                          onClick={this.handleSubmit}
                          type="submit"
                          variant="contained"
                          color="primary"
                          style={{
                            backgroundColor: "#014A80",
                            width: "100%",
                            textTransform: "none"
                          }}
                        >
                          Create Account
                        </Button>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Container>
          <Dialog
            open={this.state.isCreate}
            onClose={this.handleModalClose}
            data-testid="dialogId"
            PaperProps={{
              style: {
                minWidth: "500px",
              },
            }}
          >
            <DialogTitle id="alert-dialog-title" 
            style={{ 
            background: "#F4F9FD",
            color:'#014A80',
            fontFamily: 'Lato',
            fontSize: '24px',
            fontWeight: 700
          }}
            >{"Account created"}
            </DialogTitle>
            <div style={{ display: 'flex', justifyContent: 'space-evenly', margin: '30px 0' }}>
              <img
                src={this.state.profileImage}
                style={{
                  width: 135,
                  height: 135,
                  marginTop: 10,
                  marginLeft: 5,
                  border: '1px solid #d9d9d9',
                  borderRadius: '100px',
                  overflow: 'hidden'
                }} />
              <div style={{ marginTop: '10px' }}>
                <div style={styles.name as React.CSSProperties}>{configJSON.nameText} : <span style={styles.nameValue as React.CSSProperties}>{this.state.name}</span></div>
                <div style={styles.name as React.CSSProperties}>{configJSON.departmentText} : <span style={styles.nameValue as React.CSSProperties}>{this.state.department}</span></div>
                <div style={styles.name as React.CSSProperties}>{configJSON.roleText} : <span style={styles.nameValue as React.CSSProperties}>{this.state.role}</span></div>
                <div style={styles.name as React.CSSProperties}>{configJSON.labelEmail} : <span style={styles.nameValue as React.CSSProperties}>{this.state.username}</span></div>
              </div>

            </div>
            <div style={{ margin: 'auto' }}>
              <div style={{ margin: '15px 0px' }}>
                {['Employee','Admin'].includes(this.state.role) ? <Button
                  variant="outlined"
                  onClick={this.handleModalClose}
                  data-testid="okayId"
                  style={{ height: '52px', width: '400px', color: '#fff', background: '#014A80' }}
                >{configJSON.okayButtonText}
                </Button> :
                  <div><Button
                    variant="outlined"
                    onClick={this.handleModalClose}
                    data-testid="notNowId"
                    style={{ height: '52px', width: '177px',color:'#979797',fontWeight: 600 }}
                  >{configJSON.notNowText}
                  </Button>
                    <Button
                      onClick={this.addEmployeeModal}
                      variant="outlined"
                      data-testid="addEmployeId"
                      style={{ backgroundColor: '#F9DB45', marginLeft: 10, height: '52px', width: '177px',fontSize: '14px',fontWeight: 600 }}
                    >{configJSON.addEmployeeText}</Button>
                  </div>}
              </div>
            </div>
          </Dialog>
          <Dialog open={this.state.isAddEmployee} onClose={this.addEmployeeModal} data-testid="addEmployeeModalId"
            PaperProps={{
              style: {
                minWidth: "500px",
              },
            }} >
            <DialogTitle id="alert-dialog-title" style={{ background: "#F4F9FD" }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={styles.heading}>{configJSON.searchHeading}</div> 
                {isEmpty(this.state.isChecked) && <div style={styles.selectButton}>{configJSON.selectText} </div>}
                {!isEmpty(this.state.isChecked) && this.state.isCancel && <div style={styles.selectButton} onClick={this.handleCancel} data-testid="cancelAllId">{configJSON.cancelText} </div>}
                {!isEmpty(this.state.isChecked) && !this.state.isCancel &&  <div style={styles.selectButton} onClick={this.selectAll}  data-testid="selectAllId">{configJSON.selectAllText}</div>}
              </div>
            </DialogTitle>
            <Card style={styles.card}>
              <TextField
                // label="Search"
                variant="outlined"
                fullWidth
                onChange={this.handleSearch}
                data-testid="searchId"
                placeholder={configJSON.searchPlaceholder}
                style={{ background: '#F1F5F9', borderRadius: '8px' }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={imgSearch} alt="" />
                    </InputAdornment>
                  ),
                }}
              />
              <div onScroll={this.loadMoreEmployee} data-testid='scrollId' id="list-emp" style={styles.cardBox as React.CSSProperties}>
                {this.state.listEmp?.map((emp) => {
                  return <Grid
                    item
                    xs={12}
                    key={emp.data.id}
                    style={styles.gridCard}>
                    <div style={{ display: "flex" }}>
                      <img
                        src={emp.data.attributes.image?.url}
                        alt=""
                        style={{
                          width: 44,
                          height: 45,
                          border: '2px solid #CCC',
                          borderRadius: 100,
                          overflow: "hidden",
                        }} />
                      <div style={{ marginLeft: 7 }}>
                        <Typography
                          variant="body1"
                          style={{
                            fontSize: 16,
                            color: "#595959",
                            fontWeight: 400,
                          }}
                        >
                          {emp.data.attributes.data.Name}
                        </Typography>
                        <Typography
                          style={{
                            fontSize: 16,
                            color: "#595959",
                          }}
                        >
                          <span style={{ fontWeight: 700 }}>{configJSON.departmentText1}</span> 
                          <span style={{ fontWeight: 400 }}>{emp.data.attributes.data.Department}</span>
                        </Typography>
                      </div>
                    </div>

                    <Checkbox
                      checked={this.state.isChecked.includes(emp.data.id)}
                      name="employeeCheck"
                      style={{ color: this.state.isChecked.includes(emp.data.id) ? '#44AFFF' : "#CCC" }}
                      onChange={() => this.handleCheckBox(emp.data.id)}
                      data-testid="selectEmployeeId"
                    />
                  </Grid>
                })}

              </div>
            </Card>
            <div style={{ padding: '18px' }}>
              <Button
                variant="outlined"
                onClick={this.addEmployeeModal}
                data-testid="cancelId"
                style={{ height: '52px', width: '230px', borderRadius: '8px' }}
              >{configJSON.cancelText}
              </Button>
              <Button
                onClick={this.handleApply}
                variant="outlined"
                data-testid="applyId"
                style={{ backgroundColor: '#014A80', marginLeft: 10, height: '52px', width: '230px', color: '#fff', borderRadius: '8px' }}
              >{configJSON.applyText}</Button>
            </div>
          </Dialog>
          <Dialog open={this.state.isApply} onClose={this.handleApply} data-testid="employeeMoveId" PaperProps={{
            style: {
              minWidth: "500px",
            },
          }}>
            <div style={{ textAlign: 'center', padding: '35px' }}>
              
            <div style={styles.des}>
            {this.state.isAddSuccessfully ? (
              <>
                <img src={imgSuccess} alt="" />
                <div dangerouslySetInnerHTML={{ __html: `You have successfully ${this.state.applyType} ${this.state.isChecked.length} employees to <b>${this.state.showName}</b> profile` }} />
              </>
            ) : (
              <div dangerouslySetInnerHTML={{ __html: `Do you want to move or copy ${this.state.isChecked.length} employees to <b>${this.state.showName}</b> profile?` }} />
            )}
          </div>


             {this.state.isAddSuccessfully?
              <Button
              variant="outlined"
              data-testid="okaylId"
              onClick={this.handleClose}
              style={{ backgroundColor: '#014A80',textTransform:'none', marginLeft: 10, height: '52px', width: '100%', color: '#fff', borderRadius: '8px' }}
              >{configJSON.okayButtonText}
            </Button> :
            <div>
                <Button
                  variant="outlined"
                  onClick={()=>this.handleCopyMove('copy')}
                  data-testid="copyId"
                  style={styles.cancelButton as React.CSSProperties}
                >{configJSON.copyText}
                </Button>
                <Button
                  onClick={()=>this.handleCopyMove('move')}
                  variant="outlined"
                  data-testid="moveId"
                  style={styles.applyButton as React.CSSProperties}
                >{configJSON.moveText}
                </Button>
              </div>}
            </div>
          </Dialog>
        </main>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  appBar: {
    border: 0,
    boxShadow: "none",
    backgroundColor: "#CCE9FF",
    padding:'0px 10px'
  },
  content: {
    backgroundColor: "#CCE9FF",
    flexGrow: 1,
    //padding: theme.spacing(3),
    marginLeft: drawerWidth,
    // height: "100vh"
    padding:'10px'
  },
  name: {
    color: '#979797',
    fontFamily: 'Lato',
    fontWeight: '600',
    margin: '10px 0'
  },
  nameValue: {
    color: '#3C3C3C',
    fontWeight: '600',
    fontFamily: 'Lato',
  },
  error: {
    color: 'red',
    fontSize: '12px',
    fontWeight: 400,
    marginTop: '2px',
  },
  heading: {
    color: '#3C3C3C',
    fontFamily: 'Lato',
    fontWeight: 700,
  },
  selectButton: {
    color: '#014A80',
    fontFamily: 'Lato',
    fontWeight: 700,
    textDecorationLine: 'underline',
    cursor: 'pointer',
  },
  card: {
    margin: '18px',
    padding: '16px 16px 10px 16px',
    borderRadius: '8px',
    background: '#FFF',
  },
  cardBox: {
    minHeight: '220px',
    overflowY: 'scroll',
    maxHeight: '250px',
    scrollbarWidth: "thin",
    scrollbarColor: "#44AFFF #F3F5F7",
    marginTop: '10px',
  },
  gridCard: {
    display: "flex",
    padding: '10px 0',
    borderBottom: '1px solid #CCC',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer'
  },
  cancelButton:{ 
    height: '52px',
    width: '230px',
    borderRadius: '8px',
    textTransform:'none',
    color:'#979797' 
  },
  applyButton:{ 
   backgroundColor: '#014A80',
   textTransform:'none', 
   marginLeft: 10,
   height: '52px', 
   width: '230px', 
   color: '#fff',
   borderRadius: '8px' },
   des:{
    color: '#595959',
    fontFamily: 'Lato',
    fontSize: '20px',
    fontWeight: 400,
    marginBottom: '30px',
  },
  topHeading: {
    fontFamily: "Lato",
    fontSize: '32px',
    fontWeight: 700,
    display:'flex'
  },
};

// Customizable Area End
