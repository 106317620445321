import React from "react";

import {
  Container,
  Button,
  // Customizable Area Start
  Grid,
  Avatar,
  Card,
  CardContent,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  DialogContent,
  Dialog,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { Pagination } from "@material-ui/lab";
// Customizable Area End

import AdminListController, { Props, configJSON } from "./AdminListController";
import { icTrash, imgSuccess } from "./assets";

export default class Adminlist extends AdminListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderCheck = () => {
    if(this.state.loading){
        return <CardContent style={{textAlign:'center'}}>loading...</CardContent>
    }
    if (this.state.adminList.length <= 0 && !this.state.loading) {
      return <CardContent style={{textAlign:'center'}}>You have no records yet!</CardContent>
    }
    return (
      <CardContent style={{ padding: 0 }}>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow style={{ backgroundColor: "#F4F9FD" }}>
                <TableCell align="center" style={style.tabelHead}>
                  {configJSON.userTest}
                </TableCell>
                <TableCell align="center" style={style.tabelHead}>
                  {configJSON.employeeTest}
                </TableCell>
                <TableCell align="center" style={style.tabelHead}>
                  {configJSON.departmentText}
                </TableCell>
                <TableCell align="center" style={style.tabelHead}>
                  {configJSON.roleText}
                </TableCell>
                <TableCell align="center" style={style.tabelHead}>
                  {configJSON.emailText}
                </TableCell>
                <TableCell align="center" style={style.tabelHead}>
                  {configJSON.actionText}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.adminList.map((item: any) => {
                return (
                  <TableRow key={item.id}>
                    <TableCell align="center">
                      <Avatar
                        src={item.url}
                        style={{ width: 43, height: 43 }}
                      />
                    </TableCell>
                    <TableCell align="center" style={style.tableBody}>
                      {item.Name}
                    </TableCell>
                    <TableCell align="center" style={style.tableBody}>
                      {item.Department?.replace(/_/g, " ")}
                    </TableCell>
                    <TableCell align="center" style={style.tableBody}>
                      {item.role}
                    </TableCell>
                    <TableCell align="center" style={style.tableBody}>
                      {item.email}
                    </TableCell>

                    <TableCell
                      align="center"
                      data-test-id="deleteId"
                      onClick={() => this.handleOpenCloseModal(item)}
                    >
                      <img src={icTrash} style={style.viewMoreIcon} />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <Pagination
            data-test-id="adminlistPaginationID"
            count={Math.ceil(this.state.totalUser / this.state.pageSize)}
            page={this.state.page}
            style={style.paginationAr}
            hideNextButton
            hidePrevButton
            boundaryCount={2}
            siblingCount={1}
            renderItem={(item) => {
              return item.type === "page" ? (
                <div
                  data-test-id="managerPaginationId"
                  onClick={() => this.onPageChanges(item.page)}
                  style={
                    item.page === this.state.page
                      ? style.activePaginationArItems
                      : style.paginationArItems
                  }
                >
                  {item.page}
                </div>
              ) : (
                <div
                  data-test-id="paginationIconId"
                  style={style.paginationArItems}
                >
                  ...
                </div>
              );
            }}
          />
        </TableContainer>
      </CardContent>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div>
        <main style={style.content}>
          <Container
            className="flex"
            style={{ paddingTop: 10, maxWidth: "100%" }}
          >
            <Card style={{ marginTop: 90, borderRadius: 8 }}>
              {this.renderCheck()}
            </Card>
          </Container>
        </main>
        <Dialog
          data-test-id="dialogCloseId"
          open={this.state.isOpenDeleteModal}
          onClose={this.handleOpenCloseModal}
          PaperProps={{
            style: { width: 501, margin: "auto", borderRadius: 8 },
          }}
        >
          <DialogContent>
            <Grid
              container
              spacing={2}
              style={{
                textAlign: "center",
                marginTop: 30,
                padding: "0 40px 10px 40px",
              }}
            >
              <div style={{ margin: "auto", width: "100%" }}>
                <img src={imgSuccess} alt="success" />
              </div>
              <p style={{ textAlign: "center", width: "100%" }}>
                {configJSON.alertText}
                <b>{this.state.selectName}</b>
              </p>
              <p style={{color:'red',fontSize:15}}>{this.state.errorMessage}</p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 20,
                  marginBottom: 35,
                  width: "100%",
                }}
              >
                <Button
                  data-test-id="btnCloseId"
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{
                    width: 177,
                    height: 56,
                    borderRadius: 8,
                    background: "transparent",
                    border: "1px solid #979797",
                    color: "#979797",
                    boxShadow: "none",
                    textTransform: "none",
                    fontSize: 20,
                  }}
                  onClick={this.handleSuccessfully}
                >
                  <b>{configJSON.noText}</b>
                </Button>
                <Button
                  data-test-id="btnDeleteId"
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{
                    width: 177,
                    height: 56,
                    borderRadius: 8,
                    backgroundColor: "#014A80",
                    textTransform: "none",
                    boxShadow: "none",
                    fontSize: 20,
                  }}
                  onClick={this.handleDeleteConf}
                >
                  <b>{configJSON.yesText}</b>
                </Button>
              </div>
            </Grid>
          </DialogContent>
        </Dialog>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const style = {
  content: {
    backgroundColor: "#CCE9FF",
    flexGrow: 1,
  },
  tabelHead: {
    fontWeight: 600,
  },
  paginationAr: {
    display: "flex",
    justifyContent: "center",
    margin: "2rem 0",
    cursor: "pointer",
  },
  activePaginationArItems: {
    backgroundColor: "#026AB7",
    padding: "0.25rem 0.65rem",
    width: "max-content",
    borderRadius: "2rem",
    color: "#FFFFFF",

    fontWeight: 400,
    lineHeight: "22px",
    fontSize: 14,
    margin: "0 0.5rem",
  },
  paginationArItems: {
    padding: "0.25rem 0.65rem",
    width: "max-content",
    borderRadius: "2rem",
    color: "#CCCCCC",

    fontWeight: 400,
    lineHeight: "22px",
    fontSize: 14,
    margin: "0 0.5rem",
  },
  viewMoreOption: {
    padding: "15px 20px",
    fontFamily: "Lato",
    fontSize: 16,
  },
  viewMoreIcon: {
    marginRight: 10,
  },
  tableBody: {
    fontFamily: "Lato",
    fontSize: 16,
    color: "#979797",
  },
  label: {
    fontSize: "16px",
    fontWeight: 500,
    color: "rgb(151, 151, 151)",
  },
  inputBox: {
    marginTop: "5px",
    backgroundColor: "#F3F5F7",
    border: "none",
    width: 400,
  },
  box: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
    maxWidth: 500,
    margin: "0 auto",
  },
  profile: {
    color: "#014A80",
    fontFamily: "Lato",
    fontSize: "24px",
    fontWeight: 700,
    marginBottom: "20px",
    textAlign: "center",
  },
  showAvatar: {
    width: 150,
    height: 150,
    border: "7px solid rgb(2 106 183",
    borderRadius: "100px",
    overflow: "hidden",
    fontSize: "48px",
    fontWeight: 700,
    fontFamily: "Inter",
  },
};
// Customizable Area End
