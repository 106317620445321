import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
export interface User {
  name: string,
  department: string, 
  role: string,
  image: string,
  profile_text: string,
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  menuViewMore: HTMLElement | null;
  viewMoreEmployeeId: string;
  page: number;
  pageSize: number;
  currentUser: User | null;
  openDeleteModal: boolean;
  isDelete: boolean;
  userList: any[];
  totalUser: number,
  selectUser: any,
  isEdit: boolean,
  isLoading: boolean,
  showConfirmPassword: boolean;
  confirmPassword: string;
  isEditPassword: boolean;
  selectedImage: string;
  profileText: string;
  selectUserId:string;
  editImage:string;
  userDetails :any;
  name : string;
  email : string;
  department :string;
  role :string;
  changeImage : any;
  currentPassword:string;
  isSuccessfullyUpdate:boolean;
  oldPassword:string;
  showOldPassword:boolean;
  newPassword:string;
  showNewPassword:boolean;
  passwordChangeSuccessfully:boolean;
  isSave:boolean;
  errors: any;
  passWordError:string;
  job:string;
  isAdminList:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Adminconsole2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUserInfoId: string = "";
  getUserListId: string = "";
  changeUserRoleId: string = "";
  getDeleteUserId: string = "";
  getUserDetailsId: string = "";
  updateAccountId: string = "";
  passwordChangeId: string = "";
  getPasswordId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      menuViewMore: null,
      viewMoreEmployeeId: "",
      page: 1,
      pageSize: 8,
      currentUser: null,
      openDeleteModal: false,
      isDelete: true,
      userList: [],
      totalUser: 0,
      selectUser: {},
      isEdit: false,
      isLoading: false,
      showConfirmPassword: false,
      confirmPassword: "",
      isEditPassword: false,
      selectedImage: "",
      profileText: "",
      selectUserId:"",
      editImage:"",
      userDetails:{},
      name : "",
      email : "",
      department : "",
      role : "",
      changeImage : {},
      currentPassword:"",
      isSuccessfullyUpdate:false,
      oldPassword:"",
      showOldPassword:false,
      newPassword:"",
      showNewPassword:false,
      passwordChangeSuccessfully:false,
      isSave:true,
      errors:{},
      passWordError:"",
      job:"",
      isAdminList:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.getUserInfoId) {
        const { attributes } = responseJson.data;
        const imgUrl = attributes.image && attributes.image.url;

        this.setState({
          profileText: attributes.profile_text,
          currentUser: {
            ...attributes, ...attributes.data, 
          image: imgUrl
          }
        });
      }
      else if (apiRequestCallId === this.getUserListId) {
        const { data } = responseJson;
        const modifyData = data.map((item: any) => ({
          ...item.data.attributes.data,
          ...item.data.attributes.access,
          ...item.data.attributes.image,
          ...item.data
        }));
        this.setState({ userList: modifyData, totalUser: responseJson.No_of_Members, isLoading: false })
      }
      else if (apiRequestCallId === (this.changeUserRoleId || this.getDeleteUserId) ) {
        this.getRender(responseJson)
      } 
      else if (apiRequestCallId === this.getUserDetailsId) {
        const {data}=responseJson;
        const {attributes}=data;
        const apiResponseData={...attributes,...attributes.image,...attributes.data,...data};
        this.setUserData(apiResponseData)
       
      }
      else if (apiRequestCallId === this.updateAccountId){
        this.updateOpenModal(responseJson)
      }
      else if (apiRequestCallId === this.passwordChangeId){
        this.openModal(responseJson)
      }
      else if (apiRequestCallId === this.getPasswordId){
        const {password}=responseJson;
        this.setState({currentPassword:password})
        this.upload()
      }
      else {
        console.log(errorReponse);
      }
    }
    // Customizable Area End
  }

  txtWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtMobileProps = {
    ...this.txtWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  textInputProps = this.isPlatformWeb()
    ? this.txtWebProps
    : this.txtMobileProps;

  buttonShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.textInputProps.secureTextEntry = !this.state.enableField;
      this.buttonShowHideImageProps.source = this.textInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  buttonShowHideImageProps = {
    source: this.textInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };



  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }


  // Customizable Area Start
 
  apiCall = async (data: any) => {
    const token = await getStorageData("token");
    
    const { contentType, method, endPoint, body,formBody } = data;
    const content = body ? { "Content-Type": contentType } : undefined;

    const header = {
      ...content,
      token: token,
    };

    const requestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body) || formBody
    );
    runEngine.sendMessage(requestMsg.id, requestMsg);
    return requestMsg.messageId;
  };

  async componentDidMount() {
    this.getCurrentUser();
    this.getUserList(this.state.page,'');
  }

  getRender=(responseJson:any)=>{
    const { message } = responseJson;
    if (message) {
      this.getUserList(1, '');
    }
  };

  openModal = (responseJson:any) =>{
    const { message,error } = responseJson;
    if(error){
      this.setState({passWordError:error})
    }
    if (message) {
      this.setState({passwordChangeSuccessfully:true})
    }
  };

  updateOpenModal =  (responseJson:any) =>{
    const { message } = responseJson;
    if (message) {
      this.setState({isSuccessfullyUpdate:true,isSave:true})
    }
  };

  getCurrentUser = async () => {
    this.getUserInfoId = await this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: "GET",
      endPoint: configJSON.currentUserApiEndPoint,
    });
  };

  getUserList = async (page: number, searchText: string) => {
    this.setState({ isLoading: true })
    this.getUserListId = await this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: "GET",
      endPoint: `${configJSON.usersListApiEndPoint}?page=${page}&keyword=${searchText}`,
    });
  };

  changeUserRole = async (item: any) => {
    const { id, permission } = item;
    let can_view = permission === 'can_view' ? false : true;
    const can_edit = permission === 'can_edit' ? false : true;
    if(can_edit) can_view = true
    this.changeUserRoleId = await this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: "POST",
      endPoint: `${configJSON.userChangeRoleApiEndPoint}?can_view=${can_view}&can_edit=${can_edit}&user_id=${id}`,
    });
  };

  deleteUserAccount = async () => {
    this.getDeleteUserId = await this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: "DELETE",
      endPoint: `${configJSON.deleteUserAccountApiEndPoint}/${this.state.viewMoreEmployeeId}`,
    });
  };

  getUserDetails =async () => {
    this.getUserDetailsId = await this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: "GET",
      endPoint: `${configJSON.getAccountApiEndPoint}/${this.state.viewMoreEmployeeId}`,
    });
  };

 updateAccount = async () => {
    const formBody = new FormData()
    formBody.append('account[name]', this.state.name)
    formBody.append('account[email]', this.state.email)
    formBody.append('account[department]', this.state.department)
    formBody.append('account[password]', this.state.currentPassword)
    formBody.append('account[role_attributes[name]]', this.state.role)
    formBody.append('account[image]', this.state.changeImage)
    formBody.append('account[user_job_title]', this.state.job)
    this.updateAccountId = await this.apiCall({
      method: "PUT",
      endPoint: `${configJSON.updateUserAccountApiEndPoint}/${this.state.viewMoreEmployeeId}`,
      formBody,
    });
  };

  getChangesPassword =async () => {
    this.passwordChangeId = await this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: "PUT",
      endPoint: `${configJSON.changePasswordApiEndPoint}`,
      body: {
        current_password:this.state.oldPassword,
        create_new_password:this.state.newPassword,
        confirm_password:this.state.confirmPassword,
        user_id:this.state.viewMoreEmployeeId,
      }
    });
  };

  getPasswordEncryption =async (id:any) => {
    this.getPasswordId = await this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: "GET",
      endPoint: `bx_block_login/encryptions/${id}?account[passvalue]=Decrypt`
    });
  };

  handleView = (event: React.MouseEvent<HTMLButtonElement>, item: any) => {
    this.setState({ menuViewMore: event.currentTarget, viewMoreEmployeeId: item.id, selectUser: item })
  };

  onPageChange = (page: number) => {
    this.setState({ page, isLoading: true })
    this.getUserList(page, '')
  };

  handleCloseMenu = () => {
    this.setState({ menuViewMore: null, viewMoreEmployeeId: "" })
  };


  redireactNav = (item: string) => {
    this.props.navigation.navigate(item);
  };

  handleDeleteModal = () => {
    this.setState((prevState) => ({ openDeleteModal: !prevState.openDeleteModal }))
  };

  handleDeleteSuccessfully = () => {
    this.setState({ openDeleteModal: false, isDelete: true ,menuViewMore: null,viewMoreEmployeeId:''})
    this.getUserList(this.state.page, '');
  };

  handleEditUser = () => {
   this.setState({ isEdit: true },()=>this.getUserDetails())
  };

  handleDeleteConform = () => {
    this.setState((prevState) => ({ isDelete: !prevState.isDelete,menuViewMore: null }))
    this.deleteUserAccount()
  };

  changeRole = (item: any) => {
    this.changeUserRole(item)
  };

  headingMapping: { [key in 'true' | 'false']: string } = {
    'false': configJSON.headingText,
    'true': configJSON.headingEditText
  };

  fieldType: { [key in 'true' | 'false']: string } = {
    'true': "text",
    'false': "password",
  };

  changePassword = () => {
    this.setState((prev) => ({ isEditPassword: !prev.isEditPassword }))
  };

  handleSearchResponse = (searchText: string) => {
    this.setState({ isLoading: true });
    this.getUserList(1, searchText)
      };

  debouncedSearch = this.debounce(this.handleSearchResponse, 300);

  debounce(func: any, delay: number) {
    let timeout: string | number | NodeJS.Timeout | undefined;
    return function (...args: any) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func(...args);
      }, delay);
    };
  }

  handleSearch = (e: any) => {
    const searchText = e.target.value
    this.debouncedSearch(searchText);
  };

  handleUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const imageUrl = event.target.files[0];
      this.setState({
        editImage: URL.createObjectURL(imageUrl),
        changeImage: imageUrl,
        isSave:false,
      });
    }
  };

  handleChange = (event: any) => {
    const { name, value } = event.target;
    this.setState({isSave:false})
    switch (name) {
      case "name":
        this.setState({ name: value });
        break;
      case "email":
        this.setState({ email: value });
        break;
      case "currentPassword":
        this.setState({ currentPassword: value });
        break;
      case "department":
        this.setState({ department: value });
        break;
      case "role":
        this.setState({ role: value });
        break;
      case "job":
        this.setState({ job: value });
        break;
      case "oldPassword":
        this.setState({ oldPassword: value });
        break;
      case "createNewPassword":
        this.setState({newPassword:value});
        break;
      case "confirmPassword":
        this.setState({confirmPassword:value});
        break;
    }
  };

  handleSubmit=()=>{
    const validationErrors: { [key: string]: string } = {};
    if (!this.state.name) {
      validationErrors.name = "Name is required";
    }
    if (!this.state.email) {
      validationErrors.email = "Email is required";
    }

    if (!this.state.department) {
      validationErrors.department = "Department is required";
    }
    if (!this.state.role) {
      validationErrors.role = "Role is required";
    }
    if (!this.state.job) {
      validationErrors.job = configJSON.jobErrorText;
    }
    if (!this.state.changeImage) {
      validationErrors.profileImage = configJSON.profileImageError;
    }
    if (Object.keys(validationErrors).length > 0) {
      this.setState({ errors: validationErrors });
    } else {
      this.setState({ errors: {} });
      this.updateAccount()
    }
  };

  handleCloseModal = () =>{
  this.setState((prev)=>({isSuccessfullyUpdate:!prev.isSuccessfullyUpdate}))
  };

  successfullyUpdate = () => {
    this.setState((prev)=>(
      {isSuccessfullyUpdate:!prev.isSuccessfullyUpdate,isEdit:false}),
      ()=>{this.handleCloseMenu();this.getUserList(1, '')})
  };
  
  urlToFile = (url: any, filename: any, mimeType: any) => {
    return fetch(url)
      .then(response => response.arrayBuffer())
      .then(buffer => new File([buffer], filename, { type: mimeType }));
  };
  upload = () => {
    this.urlToFile(
      this.state.selectedImage,
      `${this.state.name}.png`,
      "image/png"
    ).then(file => {
      this.setState({ changeImage: file});
    });
  };

  handleChangePassword = () =>{
    const validationErrors: { [key: string]: string } = {};
    if (!this.state.oldPassword) {
      validationErrors.oldPassword = "Old password is required";
    }
    if (this.state.newPassword !== this.state.confirmPassword) {
      validationErrors.confirmPassword = configJSON.passWordErrorText;
    }
    if (
      this.state.newPassword.length < 8 ||
      !/[A-Z]/.test(this.state.newPassword) ||
      !/[\d!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(this.state.newPassword)
    ) {
      validationErrors.newPassword =
        "Password must have at least 8 characters, one uppercase letter, and one number or symbol.";
    }
    if (Object.keys(validationErrors).length > 0) {
      this.setState({ errors: validationErrors });
    } else {
      this.setState({ errors: {} });
      this.getChangesPassword();
    }
  };

  passwordSuccessfullyChange = () => {
    this.setState((prev)=>(
      {passwordChangeSuccessfully:!prev.passwordChangeSuccessfully,isEdit:false,isEditPassword:false}),
      ()=>{this.handleCloseMenu();this.getUserList(1, '')})
  };

  handleClosePasswordModal = () => {
    this.setState({passwordChangeSuccessfully:false})
  };

  goBack =() => {
    this.setState({ errors: {},passWordError:"",menuViewMore: null });
    if(!this.state.isEditPassword){
      this.setState((prev) => ({ isEdit: !prev.isEdit,isEditPassword: !prev.isEditPassword,viewMoreEmployeeId: "" }))

    }
    this.setState((prev) => ({ isEditPassword: !prev.isEditPassword }))
  };

  setUserData = (apiResponseData:any) =>{
    const {Name,Email,Department,Role,Password,id,user_job_title}=apiResponseData
    if(Password && id){
      this.getPasswordEncryption(id)
    }
    this.setState({
      editImage:apiResponseData && apiResponseData.url,
      userDetails:apiResponseData,
      name:Name,
      email:Email,
      department:Department,
      role:Role,
      job:user_job_title,
      selectedImage:apiResponseData && apiResponseData.url
    },()=>this.upload());
  };

  readonly jobTitle:any[]=[
    {
      key:configJSON.administrationAdmin,
      value:configJSON.administrationValueAdmin
    },
    {
      key:configJSON.linehaulAdmin,
      value:configJSON.linehaulValueAdmin
    },
    {
      key:configJSON.operationsAdmin,
      value:configJSON.operationsValueAdmin
    },
    {
      key:configJSON.salesClientAdmin,
      value:configJSON.salesClientValueAdmin
    },
    {
      key:configJSON.sfoAdmin,
      value:configJSON.sfoValueAdmin
    },
    {
      key:configJSON.ontAdmin,
      value:configJSON.ontValueAdmin
    },
    {
      key:configJSON.ewrAdmin,
      value:configJSON.ewrValueAdmin
    },
    {
      key:configJSON.dalAdmin,
      value:configJSON.dalValueAdmin
    }, 
    {
      key:configJSON.htxAdmin,
      value:configJSON.htxValueAdmin
    },
    {
      key:configJSON.atmAdmin,
      value:configJSON.atmValueAdmin
    },
    {
      key:configJSON.laxAdmin,
      value:configJSON.laxValueAdmin
    },
    {
      key:configJSON.lrdAdmin,
      value:configJSON.lrdValueAdmin
    },
    {
      key:configJSON.pricingAdmin,
      value:configJSON.pricingValueAdmin
    },
    {
      key:configJSON.agentAdmin,
      value:configJSON.agentValueAdmin
    },
    {
      key:configJSON.directorOperationsAdmin,
      value:configJSON.directorOperationsValueAdmin
    },
    {
      key:configJSON.directorSalesAdmin,
      value:configJSON.directorSalesValueAdmin
    },
    {
      key:configJSON.crossBorderAdmin,
      value:configJSON.crossBorderValueAdmin
    },
    {
      key:configJSON.applicationDevelopmentAdmin,
      value:configJSON.applicationDevelopmentValueAdmin
    },
    {
      key:configJSON.infrastructureAdmin,
      value:configJSON.infrastructureValueAdmin
    },
    {
      key:configJSON.humanResourcesAdmin,
      value:configJSON.humanResourcesValueAdmin
    },
    {
      key:configJSON.servicesManagerAdmin,
      value:configJSON.servicesManagerValueAdmin
    },
    {
      key:configJSON.safetyManagerAdmin,
      value:configJSON.safetyManagerValueAdmin
    },
    {
      key:configJSON.chiefTechnologyAdmin,
      value:configJSON.chiefTechnologyValueAdmin
    },
    {
      key:configJSON.chiefInformationAdmin,
      value:configJSON.chiefInformationValueAdmin
    },
    {
      key:configJSON.chiefFinancialAdmin,
      value:configJSON.chiefFinancialValueAdmin
    },
    {
      key:configJSON.executiveOfficerAdmin,
      value:configJSON.executiveOfficerValueAdmin
    },
    {
      key:configJSON.businessAnalystKeyText,
      value:configJSON.businessAnalystText
    },
    {
      key:configJSON.srNetworkKeyText,
      value:configJSON.srNetworkText
    },
    {
      key:configJSON.srSystemsKeyText,
      value:configJSON.srSystemsText
    },
    {
      key:configJSON.projectManagerKeyText,
      value:configJSON.projectManagerText
    },
    {
      key:configJSON.applicationDeveloperKeyText,
      value:configJSON.applicationDeveloperText
    },{
      key:configJSON.srApplicationsKeyText,
      value:configJSON.srApplicationsText
    },{
      key:configJSON.srSystemsAdministratorKeyText,
      value:configJSON.srSystemsAdministratorText
    },{
      key:configJSON.systemsAdministratorKeyText,
      value:configJSON.systemsAdministratorText
    },{
      key:configJSON.technicalArchitectKeyText,
      value:configJSON.technicalArchitectText
    },{
      key:configJSON.employeeOppText,
      value:configJSON.employeeOppText
    },{
      key:configJSON.supervisorText,
      value:configJSON.supervisorText
    },{
      key:configJSON.managerOppText,
      value:configJSON.managerOppText
    },{
      key:configJSON.vicePresidentKeyText,
      value:configJSON.vicePresidentText
    }
  ];

  handleAdminList=()=>{
    this.setState((prevState)=>({isAdminList:!prevState.isAdminList}))
  }
  
  // Customizable Area End
}
