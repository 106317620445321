import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData, isEmpty, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  isReminder: boolean;
  isLastDay: boolean;
  isEmptyReminder: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AutomaticremindersController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAutomaticRemainderCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      isReminder:false,
      isLastDay:false,
      isEmptyReminder:true,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.getAutomaticRemainderCallId) {
        if(isEmpty(responseJson)){
          this.setState({isEmptyReminder:false})
        }
        this.checkReminder(responseJson)
      }
      else {
        console.log(errorReponse);
      }
    }
    // Customizable Area End
  }


  // Customizable Area Start
  async componentDidMount() {
    // Customizable Area Start
    this.getLatestReport();
    // Customizable Area End
  }

  apiCall = async (data: any) => {
    const { contentType, method, endPoint, body } = data;
    const token = await getStorageData("token");
    const reqMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    const header = {
      "Content-Type": contentType,
      token: token,
    };
    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
    reqMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(reqMessage.id, reqMessage);
    return reqMessage.messageId;
  };

  getLatestReport = async () => {
    this.getAutomaticRemainderCallId = await this.apiCall({
      contentType: configJSON.jsonApiContentType,
      method: configJSON.getApiMethodType,
      endPoint:configJSON.automaticRemainderApiEndPoint,
    });
  };

  handleReminderLater = () => {
    this.setState((prev)=>({isReminder:!prev.isReminder}),()=>setStorageData('reminder',true))
  };

  handleGoToReport = () => {
    this.setState((prev)=>({isReminder:!prev.isReminder}),()=>
    {setStorageData('reminder',true);
    this.props.navigation.navigate('VisualAnalytics')
  })
  };

  setTextShow = (reminderAlert:any) => {
    const check =Object.keys(reminderAlert);
    if(check.length === 0 ){
      this.setState({isEmptyReminder:false})
    }
    if(reminderAlert.last_submission_day){
     this.setState({isLastDay:true})
    }else{
    this.setState({isLastDay:false})
    }
   };

  checkReminder = async(reminderAlert:any) =>{
    const reminderCheck = await getStorageData('reminder');
    this.setTextShow(reminderAlert)
    if(!isEmpty(reminderAlert) && !reminderCheck){
      this.setReminder(reminderAlert)
    }

  };

  setReminder = (reminderAlert:any) => {
   if(reminderAlert.last_submission_day){
    this.setState({isReminder:true,isLastDay:true})
   }
   this.setState({isReminder:true,isLastDay:false})
  };

  handleReminder = () => {
    this.setState((prev)=>({isReminder:!prev.isReminder}))
  }

  // Customizable Area End
}
