import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { sampleListUser } from "./UserTableController";

export interface User {
  name: string,
  department: string, 
  role: string,
  image: string,
  profile_text:string,
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  currentUser: User | null,
  isLoading: boolean,
  error: string
  activeTab: any,
  searchValue: string,
  listUser: any[],
  openDialogAddNewRelationship: boolean,
  needGetListUserTable: boolean, 
  needGetListRelationshipTable: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class OrganisationHierarchyController2 extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUserInfoId: string = "";
  timeOut: ReturnType<typeof setTimeout> = setTimeout(()=>{})
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      currentUser: null,
      isLoading: false,
      error: "",
      activeTab: 0,
      searchValue: "",
      listUser: sampleListUser,
      openDialogAddNewRelationship: false,
      needGetListRelationshipTable: false,
      needGetListUserTable: false
      // Customizable Area End
    };
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );
      let responseOH = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );
      let errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
      );
      // Customizable Area Start
      if (apiRequestCallId === this.getUserInfoId) {
        this.handleExpiredTokenError(responseOH);
        if (responseOH.data) {
          const { attributes } = responseOH.data;
          const { data } = attributes
          const currentUser = {
            name: data.name,
            department: data.department,
            role: data.role,
            image: attributes.image?.url,
            profile_text: attributes.profile_text,
          }
          this.setState({ currentUser: { ...currentUser } });
}
      } else if(errorResponse){
        console.log(errorResponse)
      }
      // Customizable Area End

    }
  }

  // web events

  // Customizable Area Start

  async componentDidMount() {
    this.getCurrentUserInfo();
  }

  getCurrentUserInfo = async () => {
    this.getUserInfoId = await this.apiOHCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_user/users/current_user_info`,
    });
  }

  handleSearch = () => {
    clearTimeout(this.timeOut);
    let needGetListRelationshipTable = false;
    let needGetListUserTable = false
    this.timeOut = setTimeout(() => {
      if(this.state.activeTab === 0) needGetListUserTable = true
      else if (this.state.activeTab === 1) needGetListRelationshipTable = true
      this.setState({needGetListUserTable, needGetListRelationshipTable})
    }, 1000)
  }

  handleExpiredTokenError = (response: any) => {
    if (
      response.errors &&
      response.errors.length > 0 &&
      response.errors[0].token
    ) {
      this.props.navigation.navigate("EmailAccountLogin")
    }
  }

  apiOHCall = async (data: any) => {
    const userToken = await getStorageData("token");
    const { contentType, method, endPoint, body } = data;
    const ohHeader = {
      "Content-Type": contentType,
      token: userToken,
    };

    const reqOhMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    reqOhMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(ohHeader)
    );
    reqOhMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    reqOhMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    reqOhMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    runEngine.sendMessage(reqOhMessage.id, reqOhMessage);
    return reqOhMessage.messageId;
  };

  handleChange = (event: React.ChangeEvent<{}>, value: number) => {
    this.setState({activeTab: value})
  }
  handleChangeSearchValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({searchValue: event.target.value})
    this.handleSearch()
  }
  handleOpenAddRelationshipDialog = () => {
    this.setState({openDialogAddNewRelationship: true})
  }
  handleCloseAddRelationshipDialog = () => {
    this.setState({openDialogAddNewRelationship: false})
  }
  // Customizable Area End
}
