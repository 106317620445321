import React from "react";

// Customizable Area Start
import {
  Container,
  Card,
  TextField,
  Typography,
  AppBar,
  Toolbar,
  Grid,
  Select,
  FormControl,
  Button,
  Avatar
} from "@material-ui/core";
import Sidebar from "../../../components/src/Sidebar.web";
import { CameraAlt } from "@material-ui/icons";
// Customizable Area End

import UserProfileBasicBlockController, {
  Props,
  configJSON
} from "./UserProfileBasicBlockController.web";
import Automaticreminders from "../../automaticreminders/src/Automaticreminders.web";

const drawerWidth = 280;

export default class UserProfileBasicBlock extends UserProfileBasicBlockController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const disable=!this.state.isEdit || this.state.isDisable
    return (
      <div>
        {/* Sidebar */}
        <Sidebar />
        <style>

{`

.editButton {
    background-color: #F9DB45;
    margin-top: 50px;
    height: 56px;
    width: 400px;
    font-weight: 700;
  }
  .editButton:hover {
    background-color: #F9DB45;
  }
  .saveButton {
    background-color: #014A80;
    height: 56px;
    width: 371px;
    color: #fff !important;
    fon-weight: 700                      
  }
  .saveButton:hover{
    background-color: #014A80;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    margin: 8px 91px;
  }

  @media (max-width: 960px) {
    .footer {
      display: block; 
      text-align:center;

    }
  }
  @media (max-width: 1190px) {
    .editButton {
      width: 300px; 
    }
  }

  @media (max-width: 1110px) {
    .saveButton{
      width: 290px;
    }
  }

`}

</style>

        <main style={styles.content}>
          {/* header */}
          <AppBar style={styles.appBar}>
            <Toolbar>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                style={{ margin: "12px 0px 20px" }}
              >
                <Grid item style={{marginLeft: 280, color: "#014A80" }}>
                  <Typography  style={styles.topHeading}>
                    <b>{configJSON.profileText}</b>
                  </Typography>
                </Grid>
                <Grid
                  item
                  style={{ display: "flex" }}
                >
                  <div  style={{
                    minWidth: 170,
                    height: 56,
                    display: "flex",
                    alignItems: "center",
                    borderRadius: 8,
                    backgroundColor: "white",
                    padding: '0 8px',
                    marginRight:'10px'
                  }}>
                  <div>
                    <Avatar
                      variant="square"
                      src={this.state.profileImage}
                      style={{ width: 44, height: 45 }}
                    >
                      {this.state.profileText}
                    </Avatar>
                  </div>
                  <div style={{ marginLeft: 20 }}>
                    <p
                      style={{
                        fontFamily: "Lato",
                        fontSize: 16,
                        fontWeight: 400,
                        fontStyle: "normal",
                        textAlign: "right",
                        color: "#64748B"
                      }}
                    >
                      {this.state.userInfo.name || " "}
                    </p>
                  </div>
                  </div>
                  {!this.state.isAdmin &&<Automaticreminders
                    navigation={this.props.navigation}
                    id=""
                  />}
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          {/* header end */}

          <Container style={{ marginTop: 70,minWidth:'100%' }}>
            <Card >
              <div>
                <div>
              <Grid container style={{ marginTop: 50 }}>
                <Grid
                  container
                  item
                  sm={12} xs={12} md={6} xl={6}
                  style={{
                    marginBottom: 30,
                    justifyContent: "center",
                    alignItems: "center",
                    padding:'10px'
                  }}
                >
                  <div>
                    <div style={styles.profile as React.CSSProperties}>
                      {configJSON.profilePictureText}
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Avatar
                        src={this.state.selectImage}
                        style={{
                          ...styles.showAvatar,
                          backgroundColor: this.state.selectImage
                            ? ""
                            : "#026AB7"
                        }}
                      >
                        {this.state.profileText}
                      </Avatar>
                      
                    </div>
                    <div style={{textAlign:'center',color:'#DB2323',fontSize:'12px',marginTop:'5px'}}>{this.state.imageError}</div>
                    {!this.state.isEdit ? (
                      <div>
                        <Button
                          variant="outlined"
                          className="editButton"
                          data-test-id="edit-profile"
                          onClick={this.handleEdit}
                        >
                          {configJSON.editButtonText}
                        </Button>
                      </div>
                    ) : (
                      <div>
                        <label htmlFor="fileInput">
                          <Button
                            variant="outlined"
                            startIcon={<CameraAlt />}
                            component="span" // Make the button look like a normal button
                            style={{
                              width: "100%",
                              marginTop: 25,
                              textTransform: "none",
                              height: "56px",
                              color: "#979797",
                              fontSize: "16px"
                            }}
                          >
                            {configJSON.uploadPhoto}
                            <input
                              data-test-id="upload-image"
                              type="file"
                              accept=".jpg,.png,.jpeg"
                              onChange={this.handleUpload}
                              style={{ display: "none" }}
                              id="fileInput"
                              name="profileImage"
                            />
                          </Button>
                        </label>

                        <div
                          style={{
                            color: "#979797",
                            fontSize: "18px",
                            fontWeight: 600,
                            textAlign: "center",
                            margin: "10px 0px"
                          }}
                        >
                          Or
                        </div>
                        <div
                          style={{
                            color: "#000",
                            fontSize: "18px",
                            fontWeight: 600,
                            textAlign: "center",
                            margin: "10px 0px"
                          }}
                        >
                          {configJSON.avatarText}
                        </div>
                        <div style={{ display: "flex" }}>
                          {this.state.avatarData.map(item => {
                            return (
                              <div
                                key={item.url}
                                onClick={() => this.chooseAvatar(item)}
                                data-test-id="choose-avatar"
                              >
                                <Avatar
                                  src={item.url}
                                  style={styles.selectAvatar}
                                >
                                  {this.state.profileText}
                                </Avatar>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                </Grid>
                {/* <Grid item xs={1} /> */}
                <Grid item sm={12} xs={12} md={6} xl={6} style={{ marginBottom: 30 }}>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 18,
                        maxWidth: 370,
                        margin: "0 auto",
                        padding:'10px'
                      }}
                    >
                      <div>
                        <label style={styles.label}>
                          {configJSON.nameText}
                        </label>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 20,
                            maxWidth: 400,
                            margin: "0 auto"
                          }}
                        >
                          <TextField
                            data-test-id="userNameTestID"
                            name="name"
                            value={this.state.name}
                            variant="outlined"
                            style={styles.inputBox}
                            disabled={disable}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>

                      <div>
                         <FormControl variant="outlined" fullWidth>
                         <label style={styles.label}>
                          {configJSON.departmentText}
                        </label>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 20,
                                maxWidth: 400,
                              }}
                            >
                              <Select
                                native
                                value={this.state.department}
                                disabled={disable}
                                onChange={this.handleChange}
                                inputProps={{
                                  name: 'department',
                                  id: 'outlined-simple',
                                }}
                                data-test-id="departmentTestID"
                                name="department"
                                style={styles.inputBox}
                              >
                                <option aria-label="None" value="" style={{ color: 'rgb(174 176 177)' }}>
                                  {configJSON.departmentProfilePlaceholderText}
                                </option>
                                {this.departmentOption.map((option, index) => (
                                  <option key={index} value={option}>
                                    {option.replace(/_/g, ' ')}
                                  </option>
                                ))}
                              </Select>
                            </div>
                          </FormControl>
                      </div>
                      <div>
                        <FormControl variant="outlined" fullWidth>
                          <label style={styles.label}>
                            {configJSON.roleText}
                          </label>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              maxWidth: 400
                            }}
                          >
                            <Select
                              native
                              onChange={(e: any) => {
                                this.handleChange(e);
                              }}
                              inputProps={{
                                name: "role",
                                id: "outlined-simple"
                              }}
                              data-test-id="roleInputTestID"
                              name="role"
                              style={styles.inputBox}
                              disabled
                              value={this.state.role}
                            >
                              <option aria-label="None" value="">
                                {configJSON.rolePlaceholderText}
                              </option>
                              <option value="Employee">
                                {configJSON.employeeText}
                              </option>
                              <option value="Manager">
                                {configJSON.managerText}
                              </option>
                              <option value="Admin">
                                {configJSON.adminText}
                              </option>
                            </Select>
                          </div>
                        </FormControl>
                      </div>

                      <div
                        style={{ display: this.state.isEmployee ? "" : "none" }}
                      >
                        <label style={styles.label}>
                          {configJSON.managerText}
                        </label>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 20,
                            maxWidth: 400,
                            margin: "0 auto"
                          }}
                        >
                          <TextField
                            name="manager"
                            data-test-id="managerTestID"
                            value={this.state.manager}
                            variant="outlined"
                            style={styles.inputBox}
                            disabled={disable}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div>
                      <FormControl variant="outlined" fullWidth>
                        <label style={styles.label}>
                          {configJSON.statusText}
                        </label>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            maxWidth: 400,
                          }}
                        >
                           <Select
                              native
                              onChange={this.handleChange}
                             
                              inputProps={{
                                name: "status",
                                id: "outlined-simple"
                              }}
                              data-test-id="statusTestID"
                              name="status"
                              style={styles.inputBox}
                              disabled
                              value={this.state.status}
                            >
                              <option aria-label="None" value="">
                                {configJSON.statusPlaceholderText}
                              </option>
                              <option value={configJSON.statusOption1}>
                                {configJSON.statusOption1}
                              </option>
                              <option value='Deactive'>
                                {configJSON.statusOption2}
                              </option>
                            </Select>
                        </div>
                        </FormControl>
                      </div>
                      <div>
                    <FormControl variant="outlined" fullWidth>
                      <label style={styles.label}>
                          {configJSON.profileJobTitle}
                        </label>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          maxWidth: 400,
                        }}
                      >
                        <Select
                          native
                          value={this.state.job}
                          onChange={this.handleChange}
                          inputProps={{
                            name: 'job',
                            id: 'outlined-simple',
                          }}
                          data-test-id="jobInputID"
                          name="job"
                          style={styles.inputBox}
                          disabled={disable}
                        >
                          <option aria-label="None" value="" >
                            {configJSON.jobProfilePlaceholder}
                          </option>
                          {this.jobTitle.map((item) => <option key={item.value} value={item.value}>{item.key}</option>)}
                        </Select>
                      </div>
                    </FormControl>
                  </div>
                      <div>
                        <label style={styles.label}>
                          {configJSON.startDateText}
                        </label>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 20,
                            maxWidth: 400,
                            margin: "0 auto"
                          }}
                        >
                          <TextField
                            name="startDate"
                            data-test-id="startDateTestID"
                            value={this.state.startDate}
                            variant="outlined"
                            style={styles.inputBox}
                            disabled
                            onChange={this.handleChange}
                            type="date"
                          />
                        </div>
                      </div>
                      {this.state.isEdit && (
                        <Button
                          variant="outlined"
                          data-test-id="save-report"
                          className="saveButton"
                          onClick={this.handleSave}
                          disabled={this.state.imageError?true:false}
                        >
                          {configJSON.saveButtonText}
                        </Button>
                      )}
                    </div>
                  </div>
                </Grid>
              </Grid>
              <div  className="footer">
                <div style={styles.text}>
                  {configJSON.updatedText} {this.state.lastUpdated}
                </div>
                <div style={styles.text}>{configJSON.noteText}</div>
              </div>
              </div>
              </div>
            </Card>
          </Container>
        </main>
      </div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = {
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  appBar: {
    border: 0,
    boxShadow: "none",
    backgroundColor: "#CCE9FF",
  },
  content: {
    backgroundColor: "#CCE9FF",
    flexGrow: 1,
    marginLeft: drawerWidth,
    // height: "100vh",
    padding: 9
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    margin: "8px 91px"
  },
  text: {
    fontSize: "12px",
    fontWeight: 500,
    color: "#979797"
  },
  label: {
    fontSize: "16px",
    fontWeight: 500,
    color: "rgb(151, 151, 151)"
  },
  inputBox: {
    marginTop: "5px",
    backgroundColor: "#F3F5F7",
    border: "none"
  },
  profile: {
    color: "#014A80",
    fontFamily: "Lato",
    fontSize: "24px",
    fontWeight: 700,
    marginBottom: "20px",
    textAlign: "center"
  },
  selectAvatar: {
    width: 120,
    height: 120,
    border: "2px solid rgb(2 106 183",
    borderRadius: "100px",
    overflow: "hidden",
    margin: 6,
    cursor: "pointer",
    backgroundColor: "#026AB7",
    fontSize: "48px",
    fontWeight: 700,
    fontFamily: "Inter"
  },
  showAvatar: {
    width: 150,
    height: 150,
    border: "7px solid rgb(2 106 183",
    borderRadius: "100px",
    overflow: "hidden",
    fontSize: "48px",
    fontWeight: 700,
    fontFamily: "Inter"
  },
  topHeading: {
    fontFamily: "Lato",
    fontSize: '32px',
    fontWeight: 700,
    display:'flex'
  },
};
// Customizable Area End
