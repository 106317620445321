import React, { CSSProperties } from "react";
// Customizable Area Start
import {
  Button,
  Typography,
  Grid,
  Dialog,
  DialogContent,
  Avatar
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { icArrowComment, icArrowLeftFilled, icArrowRightFilled, icMessageAdd, icMessageBig, iconMessage } from "./assets"
import ReportPreviewController, { Props } from "./ReportPreviewController.web";
import Comments from "../../comments/src/Comments.web";
import jobTitleMapping from "../../../components/src/JobTitleMapping";
const drawerWidth = 280;
const theme = createTheme({
  overrides: {
    MuiMenu: {
      list: {
        padding: 0
      }
    },
    MuiListItem: {
      button: {
        "&:hover": {
          backgroundColor: "#CCCCCC",
        }
      }
    }
  }
});
// Customizable Area End

export default class ReportPreview extends ReportPreviewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderPopup(popup: any, index: number) {
    const { y, x } = popup;
    return (
      <div
        style={{
          position: 'absolute',
          top: y,
          left: x,
          border: '1px solid #ccc',
          backgroundColor: '#fff',
          zIndex: 1000,
          overflowX: 'hidden',
          borderRadius: ' 0px 8px 8px 8px',
          padding: '8px 12px 8px 8px',
          boxShadow: ' 0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)',
        }}
        onClick={this.handlePopupElementClick}
        data-test-id="comment-popup"

      >
        <div style={{ display: 'flex', backgroundColor: '#F4F4F4', padding: '8px', borderRadius: '8px' }}>
          <div>
            <Avatar
              src={this.props.userImage}
              style={{ width: 25, height: 25 }}
            />

          </div>
          <input
            style={{ border: 'none', margin: '0 10px', borderLeft: '1px solid #CCC', width: '100px', backgroundColor: '#F4F4F4' }}
            onChange={(event) => this.handleComments(event, index)}
            data-test-id="comment-report"
          />
          <img style={{ cursor: 'pointer' }} src={icArrowComment} data-test-id="create-comment" alt='arrow' onClick={(event) => this.createComment(event, index, popup)} />
        </div>
        {/* <button onClick={() => this.handleClosePopup(index)}></button> */}
      </div>
    );
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Dialog
          id="report-detail-dialog"
          open={this.props.openModal}
          onClose={this.handleClose}
          PaperProps={{
            style: { minWidth: 1109, margin: "auto", borderRadius: 8 },
          }}
        >
          <DialogContent>
            <Grid container spacing={2} justifyContent="space-around" style={{ marginTop: 20 }}>
              {/* left grid */}
              <Grid container item xs={this.state.openRightBar ? 7 : 11} spacing={2} style={styles.commonGrid as CSSProperties}>
                <Grid
                  item
                  xs={12}
                  style={styles.topGrid}
                >
                  <div style={{ display: 'flex', alignItems: "center" }}>
                    <img src={this.props.detailReport.image} style={styles.avt as CSSProperties} />

                    <div style={{ marginLeft: 10, color: "#647488", fontFamily: 'Lato', fontSize: 16 }}>
                      <Typography variant="body1" style={{ fontSize: 14 }}><b>Name:</b> {this.props.detailReport.user_name}, {jobTitleMapping[this.props.detailReport.user_job_title]}</Typography>
                      <Typography variant="body1" style={{ fontSize: 14 }}><b>Department:</b> {this.props.detailReport.department.replace(/_/g, ' ')}</Typography>
                    </div>
                  </div>
                  <Button
                    disabled
                    id={"button-view-only"}
                    style={styles.btnViewOnly as CSSProperties}
                  >
                    {this.state.status}
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={styles.bottomGrid as CSSProperties}
                >
                  <div
                    ref={this.divRef}
                    onClick={this.handleTextAreaClick}
                    data-test-id="open-popup"
                    style={{ ...styles.reportContent, scrollbarColor: "#CCE9FF #F3F5F7" } as CSSProperties}
                  >
                    <p
                      style={{
                        fontFamily: "Lato",
                        fontSize: 16,
                        fontStyle: "normal",
                        color: "#979797",
                        padding: "10px",
                        whiteSpace: 'pre-line'
                      }}
                    >
                      {this.props.detailReport.report}
                    </p>
                    {(this.state.isCommentShow && this.state.havePermissionComment && this.props.isCommentAdd) && this.state.addPopups.map((popup: any, index: number) => (
                      this.renderPopup(popup, index)
                    ))}

                    {this.state.isCommentShow && this.state.comments.map((popup: any, index: number) =>
                      <Comments key={popup.id}
                        navigation={""}
                        id=""
                        popup={popup}
                        index={popup.id}
                        reportId={this.props.detailReport.id}
                        selectedComment={this.state.selectedComment}
                        getListComment={this.getListComment}
                        handleSelectComment={this.handleSelectedComment}
                        userImage={this.props.userImage}
                        havePermissionComment={this.state.havePermissionComment}
                      />)}

                  </div>
                  <div
                    style={{
                      borderRadius: "2rem",
                      margin: 'auto',
                      backgroundColor: "#CCE9FF",
                      padding: "5px 18px"
                    }}>
                    <span style={{ ...styles.time_badge, marginRight: "2rem" }}>Report last save: {this.props.detailReport.date}</span>
                    <span style={styles.time_badge}>{this.props.detailReport.time}</span>
                  </div>
                </Grid>
              </Grid>

              {/* right grid */}
              {this.state.openRightBar ?
                <Grid data-testid="maximized-rightbar" container item xs={5} spacing={2} style={{ ...styles.commonGrid } as CSSProperties}>
                  <Button id="ic-arrow-right" style={styles.iconArrow as CSSProperties} onClick={this.handleCloseRightBar}>
                    <img src={icArrowRightFilled} />
                  </Button>

                  <Grid
                    item
                    xs={12}
                    style={{ ...styles.topGrid, boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.1)", borderBottom: 0 }}
                  >
                    <div style={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
                      <img src={iconMessage} />
                      <span style={{ color: "#026AB7", fontFamily: "Lato", fontSize: 16, fontWeight: 600, marginLeft: 10 }}>Comments</span>
                    </div>
                    {this.state.isCommentShow ? <Button id="btn-hide-comment" style={{ border: "1px solid #026AB7", height: "max-content", borderRadius: 100, textTransform: "none", color: "#026AB7" }}
                      onClick={this.handleCommentShow}>Hide Comment</Button> :
                      <div id="comment-show" style={{ backgroundColor: "#CCE9FF", width: 40, height: 40, borderRadius: 4, display: "flex", cursor: 'pointer' }} onClick={this.handleCommentShow}>
                        <img src={icMessageAdd} style={{ margin: "auto" }} />
                      </div>}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      ...styles.bottomGrid,
                      borderTop: 0, boxShadow: "rgba(0, 0, 0, 0.14) 0px 2px 8px 0px"
                    } as CSSProperties}
                  >
                    <div style={{ ...styles.commentContainer } as CSSProperties}>
                      {this.state.comments.length === 0 ?
                        <div id="message-no-comment" style={{ display: 'flex', justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                          <img src={icMessageBig} style={{ width: 73, height: 73 }} />
                          <p style={{ fontFamily: "Lato", fontWeight: 600, fontSize: 16, color: "#026AB7" }}>No comments found!</p>
                          <p style={{ fontFamily: "Lato", margin: 0, fontSize: 14, lineHeight: "22px", color: "#979797", textAlign: "center", width: "80%" }}>
                            No one has commented on your report yet. All the comments will be shown here.</p>
                        </div> :
                        this.state.comments.map(item =>
                          <Grid onClick={() => this.handleSelectedComment(item.id)} data-testid="comment-item" key={item.id} container spacing={2} style={{ ...styles.commentItem, background: item.id === this.state.selectedComment ? 'rgb(216, 233, 250)' : '' }}>
                            <Grid item xs={2} >
                              <img src={item.attributes?.data.image_url} style={{ ...styles.avt, border: "2px solid #007BE5" } as CSSProperties} />
                            </Grid>
                            <Grid item xs={10}>
                              <span style={styles.commentUsername}>{item.attributes?.data.name}, <span style={styles.commentTime}>{item.lastChange}</span></span>
                              <p style={styles.commentSumary as CSSProperties}>{item.attributes?.data.comment}</p>
                            </Grid>
                          </Grid>)}
                    </div>
                  </Grid>
                </Grid> :
                <Grid data-testid="minimized-rightbar" container item xs={1} spacing={2} style={{ ...styles.commonGrid } as CSSProperties}>
                  <Button id="ic-arrow-left" style={styles.iconArrow as CSSProperties} onClick={this.handleOpenRightBar}>
                    <img src={icArrowLeftFilled} />
                  </Button>
                  <Grid
                    item
                    xs={12}
                    style={{ ...styles.topGrid, ...styles.closedGrid }}
                  >
                    <img src={iconMessage} />
                  </Grid>
                </Grid>}
            </Grid>
            <div
              id="autosave-div"
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 25,
                marginBottom: 35,
                width: "100%"
              }}
            >
              <div>
                <p style={styles.note}>Every changes you make is automatically saved in the system</p>
                <p style={styles.note}>Last change {this.props.lastChange} ago</p>
              </div>
              <Button
                id="btn-close-dialog"
                type="submit"
                variant="contained"
                color="primary"
                style={styles.buttonClose as CSSProperties}
                onClick={() => { this.props.handleCloseModal(); this.setState({ addPopups: [] }) }}
              >
                <b>Close Report</b>
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
  avt: {
    width: 44,
    height: 44,
    objectFit: "cover",
    borderRadius: 100,
    border: "4px solid #CCE9FF"
  },
  content: {
    backgroundColor: "#CCE9FF",
    flexGrow: 1,
    marginLeft: drawerWidth,
    height: "100vh"
  },
  reportContent: {
    flexGrow: 1,
    overflow: "auto",
    maxHeight: "50vh",
    position: "relative",
    width: "100%",
    scrollbarWidth: "thin",
    scrollbarColor: "#44AFFF #F3F5F7"
  },
  note: {
    color: "#979797",
    fontFamily: "Lato",
    fontSize: 12,
    lineHeight: "24px",
    margin: 0
  },
  time_badge: {
    fontFamily: "Lato",
    fontSize: 14,
    fontStyle: "normal",
    color: "#595959",
    lineHeight: "24px"
  },
  commentContainer: {
    padding: 10,
    scrollbarWidth: "thin",
    scrollbarColor: "#44AFFF #F3F5F7",
    overflowY: "auto",
    overflowX: "hidden",
    margin: "0 auto",
    width: "100%"
  },
  commentItem: {
    display: "flex",
    boxShadow: "rgba(0, 0, 0, 0.08) 0px 2px 8px 2px",
    borderRadius: 8,
    padding: 10,
    width: "97%",
    margin: "auto",
    marginBottom: 10,
    cursor: 'pointer'
  },
  commentTime: {
    color: "#CCC",
    fontFamily: "Lato",
    fontSize: 12,
    lineHeight: "18px"
  },
  commentSumary: {
    color: "#979797",
    fontFamily: "Lato",
    fontSize: 14,
    lineHeight: "22px",
    margin: 0,
    textAlign: "justify"
  },
  commentBubble: {
    borderRadius: 8,
    boxShadow: "0px 6px 15px -3px rgba(0,0,0,0.2)",
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    background: "#fff",
    display: "flex",
    top: 200,
    left: 100,
    cursor: "pointer",
    maxWidth: 150
  },
  commentUsername: {
    color: "#595959",
    fontFamily: "Lato",
    fontSize: 16,
    lineHeight: "24px"
  },
  viewMoreIcon: {
    marginRight: 10
  },
  btnViewOnly: {
    borderRadius: 80,
    background: "transparent",
    padding: 8,
    textTransform: "none",
    color: "#979797",
    border: "1px solid #979797",
    width: 125,
    height: 40,
    fontFamily: "Lato",
    fontSize: 14,
    fontWeight: 600
  },
  commonGrid: {
    position: "relative"
  },
  topGrid: {
    border: "1px solid #BDCCD9",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "8px 8px 0 0",
    borderBottom: 0,
    padding: 15,
    height: 80
  },
  bottomGrid: {
    border: "1px solid #BDCCD9",
    display: "flex",
    alignItems: "center",
    borderRadius: "0 0 8px 8px",
    overflowY: "auto",
    flexDirection: "column",
    height: 470,
    overflowX: "hidden"
  },
  iconArrow: {
    minWidth: 30,
    height: 40,
    boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.14)",
    borderRadius: 8,
    position: "absolute",
    top: "50%",
    left: -7,
    background: "#fff",
    border: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  closedGrid: {

    boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.1)",
    borderRadius: 8,
    borderBottomWidth: 1,
    height: 550,
    justifyContent: 'center',
    alignItems: 'start'

  },
  buttonClose: {
    width: 150,
    height: 48,
    borderRadius: 8,
    backgroundColor: "#fff",
    textTransform: "none",
    fontFamily: "Inter",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#979797",
    border: "1px solid #979797",
    boxShadow: "none"
  }
};
// Customizable Area End
