import React, { CSSProperties } from "react";
// Customizable Area Start
import {
  Container,
  Card,
  CardContent,
  Button,
  Typography,
  AppBar,
  Toolbar,
  Grid,
  Table,
  TableBody,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  Dialog,
  DialogContent,
  Select,
  Divider,
  MenuItem,
  Menu,
  Checkbox,
  Avatar
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Sidebar from "../../../components/src/Sidebar.web";
import AssignRelationshipsController, {
  Props,
} from "./AssignRelationshipsController.web";
import {icEdit, icMenu, icCheck} from "./assets";
import ManagerProfile from "./ManagerProfile.web";
import SearchEmployee from "./SearchEmployee.web";
const drawerWidth = 280;
const theme = createTheme({
  overrides: {
    MuiListItem: {
      button: {
        "&:hover": {
          backgroundColor: "#F1F5F9", 
        }
      }
    }
  }
});
// Customizable Area End

export default class AssignRelationships extends AssignRelationshipsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderSelect = () => {
    if (this.state.isSelect === 'select') {
      return <span data-testid="select-listemp"
        onClick={this.handleChangeSelect} onKeyDown={this.handleChangeSelect} style={{...styles.textSelect, marginRight: 20}} >Select</span>
    }
    if (this.state.isSelect === "all") {
      return <div style={{ display: "flex", alignItems: "center" }}>
        <span data-testid="selectall-listemp" onClick={this.handleChangeSelect} onKeyDown={this.handleChangeSelect} style={styles.textSelect}>Select All</span>
        <Button id="button-menu" style={{ background: "rgb(244, 249, 253)" }} onClick={this.onOpenMenuMoveCopy}>
          <img src={icMenu} style={{ objectFit: "contain" }} />
        </Button>
      </div>
    }
    if (this.state.isSelect === "cancel") {
      return <div style={{ display: "flex", alignItems: "center" }}>
        <span data-testid="cancel-listemp" onClick={this.handleChangeSelect} onKeyDown={this.handleChangeSelect} style={styles.textSelect}>Cancel</span>
        <Button style={{ background: "rgb(244, 249, 253)" }} onClick={this.onOpenMenuMoveCopy}>
          <img src={icMenu} style={{ objectFit: "contain" }} />
        </Button>
      </div>
    }
  }

  renderLoading = (isLoading: boolean) => {
    if (isLoading) {
      return  <p style={{ textAlign: "center" }}>Loading...</p>
    }
  }

  renderAvatar = (managerInfo: any) => {
    const { image, profileText } = managerInfo
    if (image) {
      return <img src={this.state.managerInfo.image}
        style={styles.bigAvatar as CSSProperties} />
    }
    return <div style={{...styles.bigAvatar as CSSProperties, display: "flex", justifyContent: "center", alignItems: "center"}}>
      <Typography style={{ fontSize: 48 }}>{profileText}</Typography>
    </div>
  }

  renderSelectRelationship = () => {
    return <Select
      labelId="demo-customized-select-label"
      id="demo-customized-select"
      disableUnderline
      disabled
      value={this.state.managerRelationship}
      style={{...styles.select, width: this.state.managerRelationship===""?100:'auto'}}
      renderValue={(value: any) => {
        return value==='edit'? 'Can Edit' : 'Can View'
      }}
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center"
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "center"
        },
        getContentAnchorEl: null
      }}
    >
      <MenuItem data-testid="select-edit" onClick={() => { this.handleChangeRelationship("edit") }} style={{ display: "flex", justifyContent: "space-between" }} value="edit">
        <span>Can Edit</span>
        {this.state.managerRelationship === 'edit' && <img src={icCheck} style={{ objectFit: "contain", marginLeft: 20 }} />}
      </MenuItem>
      <MenuItem data-testid="select-view" onClick={() => { this.handleChangeRelationship("view") }} style={{ display: "flex", justifyContent: "space-between" }} value="view">
        <span>Can View</span>
        {this.state.managerRelationship === 'view' && <img src={icCheck} style={{ objectFit: "contain", marginLeft: 20 }} />}
      </MenuItem>
    </Select>
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Sidebar />

        <AppBar style={styles.arAppBar}>
          <Toolbar>
            <Container maxWidth="lg"
              style={styles.arContainer}>
              <Grid item xs={8}>
                <Typography
                  style={styles.pageTitle}
                >
                  <b>Assign Relationships</b>
                </Typography>
              </Grid>
              <Grid item style={styles.userContainer}>
                <div>
                <Avatar
                      variant="square"
                      src={this.state.currentUser?.image}
                      style={{ width: 44, height: 45 }}
                  >{this.state.currentUser?.profile_text}
                </Avatar>                
                </div>
                <div style={{ marginLeft: 20 }}>
                  <p style={{...styles.username, wordBreak: "break-word"}}>
                    {this.state.currentUser?.name}
                  </p>
                </div>
              </Grid>
            </Container>
          </Toolbar>
        </AppBar>

        <main style={styles.content}>
          <Container className="flex"  style={{paddingTop: 10,maxWidth:'100%'}}>
            <Card style={{marginTop: 90, borderRadius: 8}}>
              <CardContent style={{ padding: 0 }}>
                {this.state.error?  <p style={{textAlign: 'center'}}>{this.state.error}</p> 
                :
                  <TableContainer>
                    <Table style={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow style={{ backgroundColor: "#F4F9FD" }}>
                          <TableCell align="center">Serial No.</TableCell>
                          <TableCell align="center">Name</TableCell>
                          <TableCell align="center">Department</TableCell>
                          <TableCell align="center">E-mail ID</TableCell>
                          <TableCell align="center">Total employees</TableCell>
                          <TableCell align="center">Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                     
                        {this.state.listManagerData?.map((manager, index) => (
                          <TableRow key={manager.data.id}>
                            <TableCell align="center">
                              {manager.data.attributes.data.Serial_No}
                            </TableCell>
                            <TableCell align="center">
                              {manager.data.attributes.data.Name}
                            </TableCell>
                            <TableCell align="center">
                              {manager.data.attributes.data.Department?.replace(/_/g, ' ')}
                            </TableCell>
                            <TableCell align="center">
                              {manager.data.attributes.data.email}
                            </TableCell>
                            <TableCell align="center">
                              {manager.data.attributes.data.Total_no_of_employees}
                            </TableCell>
                            <TableCell align="center">
                              <Button
                                style={{
                                  width: 82,
                                  height: 36,
                                  borderRadius: 8,
                                  backgroundColor: "#F4F4F4",
                                  textTransform: "none",
                                }}
                                id="btn-view-ar-detail"
                                onClick={() => this.viewDetail(manager.data.id)}
                              >
                                View
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                        {this.renderLoading(this.state.isLoading)}
                      </TableBody>
                    </Table>
                    <Pagination
                      id="manager-pagination"
                      count={Math.ceil(this.state.totalManager / this.state.pageSize)}
                      page={this.state.page}
                      style={styles.paginationAr}
                      hideNextButton
                      hidePrevButton
                      boundaryCount={2}
                      siblingCount={1}
                      renderItem={(item) => {
                        if (item.type === "page") {
                          return <div
                            id="manager-pagination-item"
                            onKeyDown={() => this.onChangePage(item.page)}
                            onClick={() => this.onChangePage(item.page)}
                            style={item.page === this.state.page ? styles.activePaginationArItem : styles.paginationArItem}>
                            {item.page}
                          </div>
                        }
                        return <div id="pagination-dot" style={styles.paginationArItem}>...</div>
                      }}
                    />
                  </TableContainer>
                }
               
              </CardContent>
            </Card>
          </Container>
        </main>
        <ManagerProfile navigation={this.props.navigation} 
          id={this.state.managerInfo?.id} 
          openModalProfile={this.state.openModalProfile}
          handleCloseModalProfile={this.handleCloseModalProfile} 
          handleCloseModal={this.handleCloseModal}
          getListManager={this.handleReGetListManager}
          getManagerInfo={this.getManagerInfo} />
        {this.state.openSearch &&
          <SearchEmployee
            navigation={this.props.navigation} id="" addEmpType={this.state.addEmpType}
            openSearch={this.state.openSearch} handleCloseSearch={this.handleCloseSearch}
            listSelectedEmployee={this.state.listSelectedEmp}
            handleSetDataDefault={this.handleClearSelectedEmp}
            currentManager={this.state.managerInfo} 
            getListManager={this.handleReGetListManager}
            selectAll={this.state.selectAll}
          />}

        <Dialog
          id="ar-detail-dialog"
          open={this.state.openModal}
          onClose={this.handleCloseModal}
          PaperProps={{style: { minWidth: 975, margin: "auto", borderRadius: 8, minHeight: 720 }}}
        >
          <DialogContent style={{overflow: "hidden"}}>
            {/* Add your content for the Modal here */}
            <Grid container spacing={2}>
              {/* First Grid */}
              <Grid
                item
                xs={12}
                style={{...styles.firstGrid, flexDirection: "column", marginBottom: 20}}
              >
                {/* Add your image/logo here */}
                <div style={{height: 102, borderRadius: "8px 8px 0px 0px", backgroundColor: "#CCE9FF", width: "100%"}}></div>
                {/* Add your image/logo here */}
                <div style={{ display: 'flex', width: "100%", position: "relative", height: 180 }}>
                  <div style={{ display: "flex", padding: "20 0 20 20" }}>
                    {this.renderAvatar(this.state.managerInfo)}
                  </div>
                  <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 20, borderRadius: "0px 0px 8px 8px" }}>
                    <div style={{ marginLeft: 10, }}>
                      <div style={{ display: "flex" }}>
                        <div style={{ maxWidth: 300 }}>
                          <Typography
                            style={{
                              fontWeight: 700,
                              fontSize: 32,
                              lineHeight: "40px",
                              marginRight: 20,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                            title={this.state.managerInfo.name}
                            variant="body1">
                            {this.state.managerInfo.name}
                          </Typography>
                        </div>
                        
                        <img onClick={this.handleOpenModalProfile}
                          onKeyDown={this.handleOpenModalProfile}
                          data-testid="btn-edit-manager"
                          src={icEdit} style={{ cursor: "pointer", objectFit: "contain" }} />
                      </div>
                      <Typography
                        style={{
                          fontWeight: 700,
                          fontSize: 16,
                          lineHeight: "24px",
                          color: "#026AB7",
                          padding: "10px 0"
                        }}
                        variant="body1">
                        {this.state.managerInfo.department?.replace(/_/g, ' ')} {this.state.managerInfo.role}
                      </Typography>
                      <Typography
                        style={{
                          fontWeight: 700,
                          fontSize: 16,
                          lineHeight: "22px",
                          color: "#979797"
                        }}
                        variant="body1">
                        <u>{this.state.managerInfo.email}</u>
                      </Typography>
                    </div>
                    <Button
                      id={"button-add"}
                      aria-controls="add-emp"
                      style={{
                        borderRadius: 8,
                        padding: "16px 10px",
                        textTransform: "none",
                        backgroundColor: "#F9DB45",
                        fontSize: 20,
                        fontWeight: 700,
                        height: 56
                      }}
                      onClick={() => this.handleOpenSearch('add')}
                    >
                      +Add Employee
                    </Button>
                    
                    <div style={{display: 'flex', position: 'absolute', right: 20, bottom: 20, alignItems: "center"}}>
                      <span>Relationship</span>
                      {this.renderSelectRelationship()}
                    </div>
                  </div>
                </div>
              </Grid>
              {/* ... */}

              {/* Second Grid */}
              <Grid
                item
                xs={12}
                style={{
                  ...styles.firstGrid,
                  flexGrow: 1,
                  overflowY: "auto",
                  flexDirection: "column",
                  height: 319
                }}
              >
                <div
                  style={{
                    padding: "20px 0",
                    position: "relative",
                    width: "100%",
                    borderRadius: "9px 9px 0 0",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundColor: "#F4F9FD"
                  }}
                >
                  <div style={{display: "flex", paddingLeft: 20, alignItems: "center"}}>
                    <span style={{
                      fontSize: 20,
                      fontWeight: 700,
                      lineHeight: "28px",
                      color: "#3C3C3C"
                    }}>List of Employees
                    </span>
                    <div style={{ 
                      backgroundColor: "#CCE9FF", 
                      padding: "4px 16px", 
                      textAlign: "center", 
                      borderRadius: 16,
                      marginLeft: 10 }}>
                      <span
                        style={{
                          fontSize: 14,
                          fontWeight: 400,
                          lineHeight: "28px",
                          color: "#026AB7"
                        }}
                      >No of Members - {this.state.totalEmp} </span>
                    </div>
                  </div>
                  <div style={{ cursor: "pointer" }}>
                   {this.renderSelect()}
                    <Menu
                      TransitionProps={{ style: { width: 192, padding: "0 4px", marginBottom: 5, borderRadius: 8 } }}
                      getContentAnchorEl={null}
                      anchorOrigin={{ vertical: "top", horizontal: "right" }}
                      transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      anchorEl={this.state.ancholAddEmp}
                      keepMounted onClose={this.onCloseMenuMoveCopy}
                      id="menu-add-emp"
                      open={Boolean(this.state.ancholAddEmp)}>
                      <MenuItem data-testid="option-move"
                        onClick={() => this.handleOpenSearch('move')} style={styles.selectAddEmp}>Move</MenuItem>
                      <Divider style={{ width: "90%", margin: "3px auto" }} />
                      <MenuItem data-testid="option-copy" onClick={() => this.handleOpenSearch("copy")} style={styles.selectAddEmp}>Copy</MenuItem>
                    </Menu>
                  </div>
                </div>
                <div onScroll={this.loadMoreEmployee} id="list-emp" style={styles.listEmpContainer as CSSProperties}>
                  {this.state.listEmp?.map((emp, index) => (
                    <div key={this.state.managerInfo.id + emp.data.id}>
                      <div style={{ display: "flex", justifyContent: "space-between", padding: "10px 20px" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <img src={emp.data.attributes.image?.url}
                            style={{
                              width: 40, height: 40, borderRadius: 100, border: '2px solid #014A80',
                            }} />
                          <div style={{ marginLeft: 10 }}>
                            <Typography
                              style={{
                                fontWeight: 700,
                                fontSize: 16,
                                color: "#595959"
                              }}>
                              Name: <span style={{ fontWeight: 400 }}>{emp.data.attributes.data.Name}</span>
                            </Typography>
                            <Typography
                              style={{

                                fontWeight: 700,
                                fontSize: 16,
                                color: "#595959"
                              }}>
                              Department: <span style={{ fontWeight: 400 }}>{emp.data.attributes.data.Department}</span>
                            </Typography>
                          </div>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <p style={{ fontSize: 16, lineHeight: "24px", color: "#979797" }}>{this.convertRelationShip(emp.data.attributes?.access?.permission)}</p>
                          {this.state.isSelect !== 'select' &&
                            <Checkbox
                              data-testid="checkbox-select-emp"
                              onClick={() => this.handleSelectEmployee(emp.data.id)}
                              style={{ color: !this.state.listSelectedEmp.includes(emp.data.id) ? 
                                "#979797" : "#42B1FF" }}
                              checked={ this.state.listSelectedEmp.includes(emp.data.id)} />}
                        </div>
                      </div>
                    </div>)
                  )}
                  {this.renderLoading(this.state.isListEmpLoading)}
                </div>
              </Grid>
              <div style={{margin: "24 0 30 auto"}}>
                <Button
                  id="btn-cancel-dialog"
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={styles.btnCancel}
                  onClick={this.handleCloseModal}
                >
                  <b>Cancel</b>
                </Button>
                <Button
                  id="btn-ok-dialog"
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={styles.btnOK}
                  onClick={this.handleOkModal}
                >
                  <b>Okay</b>
                </Button>
              </div>
              
            </Grid>
          </DialogContent>
        </Dialog>
     </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  username :{
    
    fontSize: 16,
    fontWeight: 400,
    fontStyle: "normal",
    color: "#64748B"
  },
  pageTitle: {
    fontFamily: 'Lato',
    fontWeight: 800,
    fontSize: 32,
    lineHeight: "40px",
    color: "#014A80"
  },
  userContainer: {
    width: 170,
    height: 56,
    display: "flex",
    alignItems: "center",
    borderRadius: 8,
    backgroundColor: "white",
    padding: 8
  },
  arContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: 'center',
    padding: "20px 0",
    maxWidth:'100%'
  },
  arAppBar: {
    border: 0,
    boxShadow: "none",
    backgroundColor: "#CCE9FF",
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
  },
  content: {
    backgroundColor: "#CCE9FF",
    flexGrow: 1,
    marginLeft: drawerWidth,
    // height: "100vh"
  },

  paginationAr: {
    display: 'flex',
    justifyContent: 'center',
    margin: "2rem 0",
    cursor: "pointer"
  }, 
  activePaginationArItem: {
    backgroundColor: '#026AB7',
    padding: '0.25rem 0.65rem',
    width: "max-content",
    borderRadius: '2rem',
    color: "#FFFFFF",
    
    fontWeight: 400, 
    lineHeight: '22px',
    fontSize: 14,
    margin: '0 0.5rem'
  },
  paginationArItem: {
    padding: '0.25rem 0.65rem',
    width: "max-content",
    borderRadius: '2rem',
    color: "#CCCCCC",
    
    fontWeight: 400, 
    lineHeight: '22px',
    fontSize: 14,
    margin: '0 0.5rem'
  },
  firstGrid: {
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "8px 8px 0 0",
    borderColor: "rgba(204, 204, 204, 1.0)",
    borderBottom: 0, 
    boxShadow: "2px 2px 10px 2px rgba(0, 0, 0, 0.1)",
    padding: 0
  },
  btnOK: {
    width: 150,
    height: 48,
    borderRadius: 8,
    backgroundColor: "#014A80",
    boxShadow: "none"
  },
  btnCancel: {
    width: 150,
    height: 48,
    borderRadius: 8,
    backgroundColor: "transparent",
    border: "1px solid #979797", 
    color: "#979797",
    marginRight: 16,
    boxShadow: "none"
  },
  textSelect: {
    fontSize: 20,
    fontWeight: 700,
    lineHeight: "28px",
    color: "#014A80",
    textDecoration: "underline",
    textUnderlineOffset: 5
  },
  select: {
    padding: "5px 10px",
    border: "1px solid #CCCCCC", 
    borderRadius: 4, 
    marginLeft: 10,
  },
  selectAddEmp: { 
    padding: "9px 20px", 
    borderRadius: 8 
  },
  bigAvatar: {
    width: 204, 
    height: 205, 
    borderRadius: 100, 
    border: '5px solid white',
    position: "relative", 
    top: -80, 
    background: "white", 
    objectFit: "cover",
  },
  listEmpContainer: { 
    flexGrow: 1, 
    overflowY: "auto", 
    position: "relative", 
    width: "100%",  
    scrollbarWidth: "thin", 
    scrollbarColor: "#44AFFF #F3F5F7" ,
    marginRight: 15,
    marginTop: 10,
    marginBottom: 10,
  }
};
// Customizable Area End
