// Customizable Area Start
import React, { CSSProperties } from "react";
import {
  Container,
  Card,
  CardContent,
  Typography,
  AppBar,
  Grid,
  TableContainer,
  Avatar,
  Tabs,
  Tab,
  TextField,
  Button
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import Sidebar from "../../../components/src/Sidebar.web";
import { icBubble, icNoti } from "./assets";
import OrganizationChart from "./OrganizationChart.web";
const drawerWidth = 280
// Customizable Area End

import OrganisationHierarchyController2, {
  Props,
} from "./OrganisationHierarchyController2.web";
import UserTable from "./UserTable.web";
import RelationshipTable from "./RelationshipTable.web";

export default class OrganisationHierarchy2 extends OrganisationHierarchyController2 {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {

    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Sidebar />

<AppBar style={styles.oHAppBar as CSSProperties}>
  <Container maxWidth="lg"
    style={styles.oHContainer as CSSProperties}>
    <Grid item lg={8}>
      <Typography
        style={styles.oHPageTitle as CSSProperties}
      >
        <b>Organization Hierarchy</b>
      </Typography>
    </Grid>
    <Grid item lg={4} style={{ display: "flex", justifyContent: "end" }}>
      <div style={styles.oHUserContainer as CSSProperties}>
        <Avatar
          variant="square"
          src={this.state.currentUser?.image}
          style={{ width: 48, height: 48, borderRadius: 4 }}
        >{this.state.currentUser?.profile_text}
        </Avatar>
        <div style={{ marginLeft: 20 }}>
          <p style={{ ...styles.username, wordBreak: "break-word" }}>
            {this.state.currentUser?.name}
          </p>
        </div>
      </div>
    </Grid>
  </Container>
</AppBar>

<main style={styles.oHcontent}>
  <Container className="flex" maxWidth="lg" style={{paddingTop: 10,maxWidth:'100%'}}>
    <Card style={{marginTop: 90, borderRadius: 8}}>
      <CardContent style={{ padding: 0, textAlign: 'center' }}>
        <AppBar position="static">
          <Tabs 
           data-testid="menu-tab"
           indicatorColor="primary"
           textColor="primary"
           variant="fullWidth"
           TabIndicatorProps={{
            style: {
              backgroundColor: '#014A80',
              boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)"
            }
           }}
           value={this.state.activeTab} onChange={this.handleChange} aria-label="simple tabs example">
            <StyledTab label="User Table" id="simple-tab-0" />
            <StyledTab label="Relationship Table" id="simple-tab-1" />
            <StyledTab label="Organization Chart" id="simple-tab-2" />
          </Tabs>
        </AppBar>
        <Grid container style={{padding: "0 20px"}} spacing={3}>
          {this.state.activeTab!==2&&<Grid item sm={this.state.activeTab === 0 ? 12 : 8}>
            <StyledTextField
              data-testid={"input-search-employee"}
              type="text"
              name="search-emp"
              variant="outlined"
              value={this.state.searchValue}
              onChange={this.handleChangeSearchValue}
              style={styles.searchBar}
              InputProps={{
                style: styles.SearchInputProps, startAdornment: (
                  <img style={{ objectFit: "cover", marginRight: 5, width: 24, height: 24 }} src={icBubble} />
                )
              }}
              placeholder="Search Employee"
              fullWidth
            />
          </Grid>}
          {this.state.activeTab === 1 &&
            <Grid item sm={4} style={{display: "flex", alignItems: "center"}}>
              <Button 
              data-testid="btn-add-relationship"
               onClick={this.handleOpenAddRelationshipDialog}
               style={{...styles.btnAddNewRelationship, textTransform: "none"}}>Add New Relationship</Button>
            </Grid>}
        </Grid>
       
                {this.state.error ? <p style={{ textAlign: 'center' }}>{this.state.error}</p>
                  :
                  <TableContainer>
                    {this.state.activeTab === 0 && <UserTable navigation={this.props.navigation} id=""
                      searchKeyword={this.state.searchValue}
                      needGetListUserTable={this.state.needGetListUserTable} />}
                    {this.state.activeTab === 1 &&
                      <RelationshipTable navigation={this.props.navigation} id=""
                        searchKeyword={this.state.searchValue}
                        needGetListRelationshipTable={this.state.needGetListRelationshipTable}
                        openAddNewRelationship={this.state.openDialogAddNewRelationship}
                        handleCloseAddRelationshipDialog={this.handleCloseAddRelationshipDialog} />}
                    {this.state.activeTab === 2 &&  <OrganizationChart navigation={this.props.navigation} id=""/>}
                  </TableContainer>
                }
       
      </CardContent>
    </Card>
  </Container>
</main>
</ThemeProvider>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const StyledTab = styled(Tab)({
  "&.Mui-selected": {
    color: "#014A80",
  },
  "&.MuiTab-fullWidth": {
    textTransform: "none",
    padding: 20,
    fontSize: 20,
    lineHeight: "28px",
  }
})

export const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#CBD5E1"
    }
}
})

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
const styles = {
  oHContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: 'center',
    paddingTop: 20,
    paddingBottom: 20,
    maxWidth:'100%'
  },
  oHAppBar: {
    border: 0,
    boxShadow: "none",
    backgroundColor: "#CCE9FF",
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
  },
  oHPageTitle: {
    fontWeight: 800,
    fontSize: 32,
    lineHeight: "40px",
    color: "#014A80"
  },
  oHUserContainer: {
    width: 170,
    height: 56,
    display: "flex",
    alignItems: "center",
    borderRadius: 8,
    backgroundColor: "white",
    padding: "0 4px"
  },
  oHcontent: {
    backgroundColor: "#CCE9FF",
    flexGrow: 1,
    marginLeft: drawerWidth,
    // height: "100vh"
    paddingBottom: 20
  },
  username :{
    fontSize: 16,
    fontWeight: 400,
    fontStyle: "normal",
    color: "#64748B"
  },
  SearchInputProps: {
    borderRadius: 8,
    fontSize: 16,
    border: "1px solid #CBD5E1"
  },
  searchBar: {
    backgroundColor: "#F1F5F9",
    borderRadius: 8,
    margin: "20px auto",
    width: "100%",
    height: 56
  },
  iconNotiContainer: {
    background: "white",
    marginLeft: 20,
    borderRadius: 8,
    width: 56,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px 4px"
  },
  btnAddNewRelationship: {
    width: "100%",
    backgroundColor: "#014A80",
    fontSize: 20,
    fontWeight: 700,
    lineHeight: "28px",
    color: "white",
    borderRadius: 8,
    height: 56,
    fontFamily: "Lato"
  }
};
// Customizable Area End
