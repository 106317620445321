import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start

export interface Manager {
  id: string,
  name: string, 
  department: string, 
  email: string, 
  image: string,
  role: string,
  profileText: string
}

// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  openModalProfile: boolean;
  handleCloseModalProfile: () => void
  getManagerInfo: (id: string) => void

  // Customizable Area Start
  handleCloseModal: () => void
  getListManager: () => void
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  managerInfo: Manager
  openModalDelete: boolean
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ManagerInfoController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getManagerInfoId: string="";
  updateMangerInfoId: string="";
  deleteManagerId: string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      managerInfo: {
        id: "", 
        name: "", 
        department: "",
        email: "",
        role: "",
        image: "",
        profileText: ""
      }, 
      openModalDelete: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
      if(this.props.id !== prevProps.id || 
            (this.props.openModalProfile && this.props.openModalProfile !== prevProps.openModalProfile)) {
        this.getManagerInfo(this.props.id);
      }
  }

  getManagerInfo = async (id: string) => {
    this.getManagerInfoId = await this.apiMpCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_user/users/${id}`,
    });
  }

  deleteManager = async () => {
    this.deleteManagerId = await this.apiMpCall({
      contentType: "application/json",
      method: "DELETE",
      endPoint: `bx_block_user/users/${this.state.managerInfo.id}`,
    });
  }

  handleDeleteManager = () => {
    this.deleteManager();
    this.handleCloseModalDelete()
  }

  handleChangeManagerInfo = (key: string, value: any) => {
    this.setState({managerInfo: {...this.state.managerInfo, [key]: value }})
  }

  handleOpenModalDelete = () => {
    this.setState({openModalDelete: true})
  }
  handleCloseModalDelete = () => {
    this.setState({openModalDelete: false})
  }

  handleSaveManagerInfo = async () => {
    const { id } = this.state.managerInfo
    const body = new FormData();
    body.append("account[name]", this.state.managerInfo.name);
    body.append("account[email]", this.state.managerInfo.email);
    body.append("account[department]", this.state.managerInfo.department);
    body.append("account[role_attributes][name]", this.state.managerInfo.role);

    this.updateMangerInfoId = await this.apiMpCall({
        method: "PUT",
        endPoint: `bx_block_user/users/${id}`,
        body
    });
  }

  apiMpCall = async (data: any) => {
    const token = await getStorageData("token");
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
      token: token,
    };

    const reqMpMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    reqMpMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    reqMpMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    reqMpMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    reqMpMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    runEngine.sendMessage(reqMpMessage.id, reqMpMessage);
    return reqMpMessage.messageId;
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiReqId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseMp = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorMp = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

    if (apiReqId === this.getManagerInfoId) {
        const {attributes} = responseMp.data;
        const {data} = attributes
        const managerInfo = {
          id: responseMp.data.id, 
          name: data.Name, 
          department: data.Department, 
          role: data.Role,
          email: data?.Email,
          image: attributes.image?.url,
          profileText: attributes.profile_text
        }
        this.setState({managerInfo: {...managerInfo}})
      } else if (apiReqId === this.deleteManagerId) {
        this.props.handleCloseModalProfile();
        this.props.handleCloseModal();
        this.props.getListManager()
      } else if (apiReqId === this.updateMangerInfoId) {
      this.props.handleCloseModalProfile();
      this.props.getManagerInfo(this.state.managerInfo.id)
      }
       else if (errorMp) {
        console.log(errorMp);
      }
    }
    // Customizable Area End
  }
}
