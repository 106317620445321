import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import React from "react";
import { getStorageData } from "../../../framework/src/Utilities";
import moment from "moment";
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start

export interface User {
  name: string,
  department: string, 
  role: string,
  image: string
}

export interface DetailReport { 
  id: string, 
  name: string,
  date: string, 
  time: string, 
  report: string,
  user_name: string, 
  department: string,
  image: string,
  lastChange: string,
  user_job_title:string, 
}
// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  openModal: boolean, 
  detailReport: DetailReport,
  lastChange: string,
  handleCloseModal: () => void,
  isCommentAdd:boolean,
  isHistory:boolean,
  userImage:string
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  openRightBar: boolean
  comments: any[],
  selectedCommentId: string
  popups:any
  addComments:any
  addPopups:any
  openIndex:number,
  selectedComment: number,
  havePermissionComment: boolean,
  status:string,
  isCommentShow:boolean,
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ReportPreviewController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getListCommentId: string=""
  getCommentsCallId:string ="";
  checkPermissionId: string="";
  createCommentsCallId:string ="";
  divRef: React.RefObject<HTMLDivElement>;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      openRightBar: true,
      comments: [],
      selectedCommentId: "",
      popups:[],
      addComments:{},
      addPopups:[],
      openIndex:0,
      selectedComment: 0,
      havePermissionComment: false,
      status:'',
      isCommentShow:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.divRef = React.createRef<HTMLDivElement>();

    // Customizable Area End
  }

  // Customizable Area Start
  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if((this.props.detailReport.id !== prevProps.detailReport.id ) && this.props.openModal) {
      this.getListComment(this.props.detailReport.id);
      this.getComments(this.props.detailReport.id)
      this.checkPermission(this.props.detailReport.id)
    }
  }

  handleSelectedComment = (id: number) => {
    this.setState({isCommentShow: true}, () => {
      this.setState({selectedComment: id, addPopups: []})
    })
  }

  handleOpenRightBar = () => {
    this.setState({openRightBar: true})
  }

  handleCloseRightBar = () => {
    this.setState({openRightBar: false})
  }

  formatTime = (timeInSec: number) => {
    const sec = Math.floor(timeInSec)
    const day = Math.floor(sec/86400);
    const hour = Math.floor((sec - day*86400)/3600);
    const min = Math.floor((sec - day*86400 - hour*3600)/60);

    if(sec>= 60 && sec<3600) return `${min} ${min>1? 'minutes':'minute'}`;
    if(sec>=3600 && sec<86400) return `${hour} ${hour>1? 'hours':'hour'}`;
    if(sec>=86400) return `${day} ${day>1? 'days':'day'}`;
    return "few minutes"
  }

  getListComment = async (id: string) => {
    const type=this.props.isHistory?'':'&type=Home';
    this.getListCommentId = await this.apiReportPreviewCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_comments/comments?commentable_id=${id}${type}`,
    });
  }

  apiReportPreviewCall = async (data: any) => {
    const userToken = await getStorageData("token");
    const { contentType, method, endPoint, body } = data;
    const reportPreviewHeader = {
      "Content-Type": contentType,
      token: userToken,
    };

    const reportPreviewReqMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    reportPreviewReqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(reportPreviewHeader)
    );
    reportPreviewReqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    reportPreviewReqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    reportPreviewReqMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    runEngine.sendMessage(reportPreviewReqMessage.id, reportPreviewReqMessage);
    return reportPreviewReqMessage.messageId;
  };
  // Customizable Area End

  getComments = async (id:string) => {
    this.getCommentsCallId = await this.apiReportPreviewCall({
      contentType: configJSON.jsonApiContentType,
      method: configJSON.getApiMethodType,
      endPoint: `${configJSON.createCommentsApiEndPoint}?commentable_id=${id}`,
    });
  };

  handleComments =(event: any,index:number)=>{
    event.stopPropagation(); 
    const value=event.target.value;
    this.setState((prev)=>({addComments:{...prev.addComments,[index]:value}}))
    
  };

  createComments = async (body:any) => {
    this.createCommentsCallId = await this.apiReportPreviewCall({
      // contentType: configJSON.jsonApiContentType,
      method: configJSON.exampleAPiMethod,
      endPoint: `${configJSON.createCommentsApiEndPoint}`,
      body,
    });
  };

  createComment =(event:any,index:number,position:any)=>{
    event.stopPropagation();
    this.setState({openIndex:index})
    const body = new FormData();
    console.log(this.props.detailReport.id,'this.props.detailReport.id')
    body.append("comment[commentable_type]","BxBlockReport::Report");
    body.append("comment[commentable_id]",this.props.detailReport.id);
    body.append("comment[comment]",this.state.addComments[index]);
    body.append("comment[coordX]",position.x);
    body.append("comment[coordY]",position.y);
    
    if(!["",undefined].includes(this.state.addComments[index]))this.createComments(body)
  };

  handlePopupElementClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  checkPermission =  async (reportId: any) => {
    this.checkPermissionId = await this.apiReportPreviewCall({
      contentType: configJSON.jsonApiContentType,
      method: configJSON.getApiMethodType,
      endPoint: `bx_block_comments/comments/check_access?report_id=${reportId}`, 
    });
  };

  handleTextAreaClick = (event: { clientX: number; clientY: number; }) => {
    let clickPosition = {}
    if (this.divRef.current) {
      const divRect = this.divRef.current.getBoundingClientRect();
      const scrollX = this.divRef.current.scrollLeft;
      const scrollY = this.divRef.current.scrollTop;
      clickPosition = {
        x: event.clientX - divRect.left + scrollX,
        y: event.clientY - divRect.top + scrollY,
      };
    }
    if (this.state.havePermissionComment) {
      this.setState({ addPopups: [clickPosition] });
    }
  };

  statusMapping: { [key: string]: string } ={
    "Permission granted":'View Edit',
    "Permission denied":'View Only'
  };

  handleClose =()=>{
    this.props.handleCloseModal();
    this.setState({addPopups:[],isCommentShow:false,status:'',comments:[]})
  };
 
  handleCommentShow =()=>{
    this.setState((prev)=>({isCommentShow:!prev.isCommentShow,selectedComment:0}));
  };
  
  checkPerm =(response:any)=>{
    this.setState({havePermissionComment: response.message === "Permission granted",status:this.statusMapping[response.message || response.error ]});

  };

  createCommentsResp =(response:any)=>{
    if(response.data){
      this.getListComment(this.props.detailReport.id);
      
      this.setState((prevPopups) => ({ addPopups: prevPopups.addPopups.filter((_popup: any, i: number) => i !== this.state.openIndex),addComments:{} }));
    }
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiReportPreviewId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiReportPreviewId === this.getListCommentId) {
        if(response.data) {
          response.data.forEach((item: any )=> {
            const {attributes} = item
            const current = new Date();
            const lastChange = moment.utc(attributes.data?.created_at).local().toDate()
            const duration = (current.valueOf() - lastChange.valueOf())/1000;
            item.lastChange = this.formatTime(duration)
          });
          this.setState({comments: [...response.data]})
        }
      }
      if (apiReportPreviewId === this.checkPermissionId) {
        this.checkPerm(response)
      }
      if (apiReportPreviewId === this.getCommentsCallId){
        const {data}=response;
        this.setState({popups:data||[]})
      } else if(apiReportPreviewId === this.createCommentsCallId){
        this.createCommentsResp(response)
       
      }
      else if (errorReponse) {
        console.log(errorReponse);
      }
    }
}}
