Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.jsonApiContentType = "application/json";
exports.getApiMethodType = "GET";
exports.audienceFollowersAPiEndPoint = "visual_analytics/audience_followers";
exports.userStatisticAPiEndPoint = "visual_analytics/user_statistic";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "visualanalytics";
exports.labelBodyText = "visualanalytics Body";
exports.labelNameText='Name';
exports.labelDepartmentText='Department';
exports.placeholderText='Type here..';
exports.updateAlertText='Your older report has been removed.';
exports.successfullyPublishedText='You have successfully published the report';
exports.clearAlertText='Are you sure you want to clear this report? It will be removed from the system once you have cleared it!';
exports.keepBothButtonText='Keep both';
exports.replaceButtonText='Replace';
exports.okayButtonText='Okay';
exports.publishButtonText='Publish';
exports.clearButtonText='Clear Report';
exports.commentHeadingText='Comments';
exports.reminderText='Reminder';
exports.reminderMsgText='Please submit your report before 9AM today';
exports.remindButtonText='Remind later';
exports.reportButtonText='Go to report';
exports.reportAlertText='The last date to submit your report is tomorrow, Please submit your report before 9AM tomorrow.';
exports.homeText='Home';
exports.editHistoryTitle='Edit History';
exports.editReportTitle="Edit Report";
exports.postApiMethodType ='POST';
exports.useBlockReportApiEndPoint='bx_block_report/reports';
exports.employeeReportApiEndPoint="bx_block_user/users/employees_report"
exports.autoSaveText='Every changes you make is automatically saved in the system';
exports.userInfoApiEndPoint='bx_block_user/users/current_user_info';
exports.latestReportApiEndPoint='bx_block_report/reports/show_last_report';
exports.deleteApiMethodType ='DELETE';
exports.getUserApiEndPoint='/bx_block_user/users/current_user_info';
exports.getEmployeeReport='/bx_block_user/users/employees_report';
exports.cancelButtonText='Cancel';
exports.noCommentstext="No Comments Found!";
exports.noEmployeeReportText="No Employee Report Found!";
exports.noEmployeeReportImportText="Currently no employee has submitted their reports that you can import.";
exports.emptyCommentText="No one has comments on your report yet. All the comments will be shown here.";
exports.importAllButtonText="Import all";
exports.reminderAlertText="Please submit your report before 9AM today.";
exports.remindButtonText="Remind later";
exports.goToReportButtonText="Go to report";
exports.tomorromRemindText="The last date to submit your report is tomorrow";
exports.tomorromRemindText1="Please submit your report before";
exports.tomorromRemindText2="9AM tomorrow.";
exports.createCommentsApiEndPoint="/bx_block_comments/comments";
exports.clearReportToast="Report Cleared Successfully!";
exports.createCommentsApiEndPoint="/bx_block_comments/comments";
exports.pageBreakText="\n \n ---------------------------------------------------- Page Break ---------------------------------------------------------- \n \n";
exports.employeeReportHeadingText="Employee Report";
exports.reportActionText="You already have a copy of your update published today. Do you want to replace it with this one?";
exports.keepButtonText="Keep both";
exports.replaceButtonText="Replace";
exports.replaceStatusMessageText="You have successfully published the report by replacing this report with the previous one";
exports.keepStatusMessageText="You’ve Successfully Published your report without replacing the previous copy of the report"
// Customizable Area End