import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import React from "react";
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start

// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  searchValue: string | null,
  userList:any,
  orgData:any,
  employeesData:any,
  isListLoading:boolean,
  isOpenClose:any,
  totalUser:number,
  page:number,
  selectUserId:number,
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class OrganizationChartController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUserListId: string="";
  getUserId: string="";
  timeOut: ReturnType<typeof setTimeout> = setTimeout(()=>{})
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      searchValue: null,
      userList:[],
      orgData:{},
      employeesData:[],
      isListLoading:false,
      isOpenClose:{},
      totalUser:0,
      page:1,
      selectUserId:0,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getUserList(1)
  }
  apiOrgCall = async (data: any) => {
    const token = await getStorageData("token");
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
      token: token,
    };

    const reqOrMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    reqOrMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    reqOrMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    reqOrMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    reqOrMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    runEngine.sendMessage(reqOrMessage.id, reqOrMessage);
    return reqOrMessage.messageId;
  };

  getUserList=async (page:number) => {
    let endpoint = ''
    this.setState({isListLoading:true})
    if(this.state.searchValue) endpoint = `&keyword=${this.state.searchValue}`
    this.getUserListId = await this.apiOrgCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_user/users/users_list?page=${page}${endpoint}`,
    })
  }

  loadMoreUser = () => {
    const element = document.getElementById("list-emp");
    const scrollTop = element?.scrollTop ?? 0;
    const scrollHeight = element?.scrollHeight ?? 0;
    const clientHeight = element?.clientHeight ?? 0;

    const needLoadMore = this.state.userList.length < this.state.totalUser;

    if (scrollTop + 3 * clientHeight >= scrollHeight && !this.state.isListLoading && needLoadMore) {
      this.setState({ page: this.state.page + 1, isListLoading: true });
      this.getUserList(this.state.page + 1)
    }
  };

  getUserData=async (id: number) => {
    this.setState({selectUserId:id})
    this.getUserId = await this.apiOrgCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_admin/admins/organization_chart?account_id=${id}`,
    })
  }

  showSelectUser = (item:any) =>{
    this.getUserData(item.id)
  }

  handleSearchValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value
    this.setState({searchValue})
    this.handleSearch(searchValue)
  }

  handleSearch = (keyword: string) => {
    clearTimeout(this.timeOut)
    this.timeOut = setTimeout(() => {
      this.setState({isListLoading: true, userList: [...[]]})
      this.getUserList(1)
    }, 1000)
  }

  handleDropDown = (id:any) => {
    this.setState((prev)=>({isOpenClose:{...prev.isOpenClose,[id]:!prev.isOpenClose[id]}}))
   
  };
  
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiOrgReqId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseOrgJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorSe = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

    if(apiOrgReqId === this.getUserListId){
      if(responseOrgJson?.data) {
        const data=responseOrgJson.data?.map((item:any)=>({...item.data.attributes,...item.data.attributes.data,...item.data.attributes.image,...item.data}))
        this.setState({
          userList: [...this.state.userList,...data],
          isListLoading:false,
          totalUser:responseOrgJson?.No_of_Members
        })
        this.getUserData(data[0].id)
      } 
    }else if(apiOrgReqId === this.getUserId){
      const {error}=responseOrgJson
      if(!error){
      this.setState({orgData:responseOrgJson,employeesData:responseOrgJson?.employees})
      }else{ this.setState({orgData:{}})}
    }
    else if (errorSe) {
        console.log(errorSe);
      }
    }
    // Customizable Area End
  }
}
