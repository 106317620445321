import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import AutomaticremindersController, {
  Props,
  configJSON,
} from "./AutomaticremindersController";
import { icReminder, imgNotification, noneNotification } from "./assets";

export default class Automaticreminders extends AutomaticremindersController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        {this.state.isEmptyReminder ?
        <Avatar
          data-test-id="avatar-id"
          variant="square"
          src={imgNotification}
          style={{ width: 52, height: 55,cursor:'pointer' }}
          onClick={this.handleReminder}
        />
        :
        <NoneNotification>
         <Avatar
          data-test-id="avatar-id"
          variant="circle"
          src={noneNotification}
          style={{ width: 34, height: 34,cursor:'pointer',position:"relative",top:10,left:9}}
        />
        </NoneNotification>
       }
        <Dialog
          data-test-id="dialog-reminder"
          open={this.state.isReminder && this.state.isEmptyReminder}
          PaperProps={{
            style: { width: 501, margin: "auto", borderRadius: 8 },
          }}
        >
          <DialogTitle id="alert-dialog-title"
            style={{
              background: "#F4F9FD",
              color: '#3C3C3C',
              fontFamily: 'Lato',
              fontSize: '24px',
              fontWeight: 700
            }}
          >
            {configJSON.reminderText}
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2} style={{ textAlign: "center", margin: '20px 0', padding: "0 40px 10px" }}>
              <div style={{ margin: 'auto',width:'100%' }}>
                <img src={icReminder} alt="success" />
              </div>
              <p style={{
                textAlign: "center",
                color: '#3C3C3C',
                fontFamily: 'Lato',
                marginBottom: '30px',
                width:'100%'
              }}>
                {!this.state.isLastDay ?
                  <><div>{configJSON.tomorromRemindText}</div>
                    <div>{configJSON.tomorromRemindText1}</div>
                    <div>{configJSON.tomorromRemindText2}</div></> :
                  <div>{configJSON.reminderAlertText}</div>}
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="outlined"
                  style={styles.cancelButton as React.CSSProperties}
                  data-test-id="remind-button"
                  onClick={this.handleReminderLater}
                >
                  {configJSON.remindButtonText}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={styles.primaryButton as React.CSSProperties}
                  data-test-id="remind-report-button"
                  onClick={this.handleGoToReport}
                >
                  {configJSON.goToReportButtonText}
                </Button>
              </div>
            </Grid>
          </DialogContent>
        </Dialog>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const NoneNotification=styled("div")({
   borderRadius:8,
   backgroundColor:"#fff",
   width:52,
   height:55
  })
const styles = {
  cancelButton: {
    height: 56,
    width: 177,
    borderRadius: 8,
    fontSize: 17,
    fontWeight: 700,
    color: "#979797",
    textTransform: "none",
    marginRight: 20,
  },
  primaryButton: {
    height: 56,
    width: 177,
    borderRadius: 8,
    fontSize: 17,
    fontWeight: 700,
    fontStyle: "normal",
    textTransform: "none",
    backgroundColor: "#014A80",
  },
};
// Customizable Area End
