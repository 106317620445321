import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  termsAndConditions: string;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class TermsAndConditionContoller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTermsCondsCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isLoading: false,
      termsAndConditions: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getTermsAndPrivacyPolicy();
  }

  getTermsAndPrivacyPolicy = async () => {
    this.setState({ isLoading: true });
    this.getTermsCondsCallId = await this.apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.getApiMethod,
      endPoint: configJSON.getTermsCondsWebApiEndPoint,
    });
  };
  navigateToLogin=()=>{
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailAcountLoginMessage)        
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props); 
    msg.addData(getName(MessageEnum.EmailAccountLoginPayload), {isAgree:true});
    this.send(msg);
  };
  backToLogin=()=>{
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailAcountLoginMessage)        
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props); 
    msg.addData(getName(MessageEnum.EmailAccountLoginPayload), {isAgree:false});
    this.send(msg);
    this.props.navigation.navigate("EmailAccountLogin")
      };
  apiCall = async (data: any) => {
    const { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.getTermsCondsCallId) {
        this.setState({
          isLoading: false,
          termsAndConditions:
            responseJson.data[0].attributes.terms_and_services,
        });
      } else if (errorReponse) {
        this.setState({ isLoading: false });
      }
    }
    // Customizable Area End
  }
}
