import React from "react";

// Customizable Area Start
import {
  Container,
  Card,
  CardContent,
  Button,
  Typography,
  AppBar,
  Toolbar,
  Grid,
  Dialog,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Chip,
  Tabs,
  Tab,
  Divider
} from "@material-ui/core";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import ReportPreview from "./ReportPreview.web";
import { icArrowComment, icMessageAdd } from "../../visualanalytics/src/assets";

import {
  imgSussess,
  ic_toast,
  ic_message_text,
  iconMessageBox,
  iconUploadBox,
  iconUploadFileBox,
  arrow_right,
  note,
  ic_success_icon,
  ic_filled_note,
  icArrowLeftBlue,
  icMessageBig,
  icArrowLeftFilled,
} from "./assets";
import Sidebar from "../../../components/src/Sidebar.web";
import Automaticreminders from "../../automaticreminders/src/Automaticreminders.web";
import Comments from "../../comments/src/Comments.web";
import jobTitleMapping from "../../../components/src/JobTitleMapping";

const drawerWidth = 280;
type GridSize = true | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
const theme = createTheme({
  overrides: {
    MuiTabs: {
      root: {
        "& .MuiTab-wrapper": {
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          overflow: "hidden",
        },
        "& .MuiTabs-flexContainer": {
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
        },
        "& .inactive-tab": {
          backgroundColor: "#F3F5F7",
          minWidth: "50%",
          width: "50%",
          color: "#64748B",
        },
        "& .active-tab": {
          backgroundColor: "#fff",
          minWidth: "50%",
          width: "50%",
          color: "#026AB7",
          "&.btn-emp .MuiTab-wrapper": {
            display: "flex",
            flexDirection: "row",
            justifyContent: "start !important",
            overflow: "hidden",
          },
        },
      },
    },
    MuiTab: {
      root: {
        textTransform: "none",
        backgroundColor: "#F3F5F7",
        minWidth: "0",
        width: "50%",
        color: "#64748B",
        "&.Mui-selected": {
          backgroundColor: "#fff",
          color: "#026AB7",
        },
      },
    },
  },
});
// Customizable Area End

import VisualAnalyticsController, {
  Props,
  configJSON,
} from "./VisualAnalyticsController";

export default class VisualAnalytics extends VisualAnalyticsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  clearOkButton = this.state.isCancel
    ? configJSON.clearButtonText
    : configJSON.okayButtonText;
  
  createReportBtn =  () => {
    return this.state.checkButtonStatus ? "#979797" : "#014A80"}

  isCancelUi() {
    return (
      <>
        {this.state.isCancel && (
          <div style={{ padding: "10px" }}>{configJSON.clearAlertText}</div>
        )}
        {this.state.showPublishStatus && (
          <>
            <img src={imgSussess} alt="sussess" />
            <div style={styles.modalText as React.CSSProperties}>
              {this.state.reportStatusMessage}
            </div>
          </>
        )}
      </>
    );
  }

  getTab(
    tabIndex: number,
    testId: string,
    label: string,
    withoutFillIcon: string,
    iconSrc: string
  ) {
    const role = this.state.userInfo?.data?.role;
    const classForEmp = role === "Employee" ? "btn-emp" :"";
    if ( role !== "Manager" && testId === "employee-report-tab") {  
      return <Tab style={{visibility: "hidden", width: 0, padding: 0, minWidth: 0}}></Tab>
    }
    return (
      <Tab
        data-test-id={testId}
        className={
          this.state.selectedTab === tabIndex ? `active-tab ${classForEmp}` : `inactive-tab ${classForEmp}`
        }
        label={label}
        style={{
          border: "none",
          boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.08)",
          width: role==="Employee"? "100%":"50%", 
          minWidth: role==="Employee"?"100%":"50%"
        }}
        icon={
          <Avatar
            alt={label}
            src={
              this.state.selectedTab === tabIndex ? iconSrc : withoutFillIcon
            }
            style={{
              height: "24px",
              width: "24px",
              borderRadius: "8px",
              marginRight: "10px",
            }}
          />
        }
      >
      </Tab>
    );
  }

  getBackgroundColor(index: number) {
    return this.state.isHovering[index] ? "#F3F5F7" : "#FFF";
  }

  renderCommentInput = () => {
    console.log('renderCommentInput data =>', this.state.isAddNewComment);
    return this.state.isAddNewComment &&
      <div style={{
        display: 'flex',
        position: "absolute",
        top: this.state.newCommentPosition.top,
        left: this.state.newCommentPosition.left,
        backgroundColor: '#F4F4F4', marginTop: '10px', padding: '8px', borderRadius: '8px',
        alignItems: "center",
        zIndex: 1000
      }}>
        <Avatar
          src={this.state.userProfile}
          style={{ width: 25, height: 25 }}
        />
        <Divider orientation="vertical" style={{ height: 20, margin: "0 10px" }} />
        <input
          style={{ border: 'none', width: '140px', backgroundColor: '#F4F4F4' }}
          onChange={(event) => this.handleChangeNewComment(event.target.value)}
          value={this.state.newComment}
          data-testid="comment-input"
        />
        <img src={icArrowComment}
          data-testid="btn-create-comment" alt='arrow'
          onClick={this.createComment}
          onKeyPress={this.createComment}
        />
      </div>
  }

  renderListComment = () => {
    return (this.state.selectedTab !== 0)
     && (
      <div data-test-id="comment-container">
        <div
          style={styles.card as React.CSSProperties}
        >
          {this.state.isDrower && (
            <div>
              {this.state.listComments.length > 0 ? (
                this.state.listComments.map(
                  (item: any, index: number) => {
                    return (
                      <div
                        key={item.id}
                        ref={item.id === this.state.selectedComment ? this.cmtRef : null}
                        style={{
                          ...styles.cardContent,
                          boxShadow: "rgba(0, 0, 0, 0.08) 0px 2px 8px 2px",
                          marginBottom: 10,
                          backgroundColor:
                            this.getBackgroundColor(
                              index
                            ),
                        }}
                        onClick={() => this.handleSelectComment(item.id)}
                        onKeyPress={() => this.handleSelectComment(item.id)}
                        onMouseEnter={() =>
                          this.handleMouseEnter(index)
                        }
                        onMouseLeave={() =>
                          this.handleMouseLeave(index)
                        }
                        data-test-id={`custom-card-${index}`}
                        data-testid="comment-item"
                      >
                        <Grid
                          item
                          xs={12}
                          style={{ display: "flex" }}
                        >
                          <Avatar src={item.attributes?.data.image_url}>H</Avatar>
                          <div
                            style={{ marginLeft: 7 }}
                          >
                            <Typography
                              variant="body1"
                              style={{
                                fontSize: 16,
                                color: "#595959",
                              }}
                            >
                              {item.attributes?.data.name}, <span style={{
                                color: "#979797",
                                fontFamily: "Lato",
                                fontSize: 12,
                                lineHeight: "18px"
                              }}>{item.lastChange} ago</span>
                            </Typography>
                            <Typography
                              style={{
                                fontSize: 13,
                                color: "#979797",
                              }}
                            >
                              {item.attributes?.data.comment}
                            </Typography>
                          </div>
                        </Grid>
                      </div>
                    );
                  }
                )
              ) : (
                <div
                  style={
                    styles.emptyBox as React.CSSProperties
                  }
                >
                  <div>
                    <img
                      src={iconMessageBox}
                      alt=""
                      width={50}
                      height={50}
                    />
                    <div
                      style={{
                        color: "#026AB7",
                        fontSize: "16px",
                        fontWeight: 600,
                      }}
                    >
                      {configJSON.noCommentstext}
                    </div>
                    <div style={{ color: "#979797" }}>
                      {configJSON.emptyCommentText}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    )
  }

  renderReportArea = () => {
    return this.state.selectedTab === 0 || (this.state.selectedTab === 1 && !this.state.showCommentBubble) ?
      <textarea
        style={styles.textArea as React.CSSProperties}
        onChange={(e) => this.setInputValue(e.target.value)}
        value={this.state.textInputValue}
        data-test-id="textarea-report"
        placeholder={configJSON.placeholderText}
        onDragOver={(event) => this.handleDragOver(event)}
        onDrop={(event) => this.handleDrop(event)}
      /> :
               <p style={{
        ...styles.textArea,
        minHeight: `calc(100vh - 403px)`,
        padding: '0 10px',
        fontFamily: "Lato",
        fontSize: 16,
        fontStyle: "normal",
        color: "#979797",
        height: 332,
        whiteSpace: 'pre-line'
      } as React.CSSProperties}
        data-testid="report-paragraph"
        onClick={this.handleShowCommentInput} >
          {this.state.textInputValue}
      </p>
        }

  renderPopupComment = () => {
    return ((this.state.selectedTab === 1 && this.state.userInfo?.data?.role === "Manager") || this.state.showCommentBubble) &&
      this.state.listComments.map((item, index) =>
        <Comments key={item.id}
          navigation={""}
          id=""
          popup={item}
          index={item.id}
          reportId={this.state.editReportId !== "" ? this.state.editReportId : this.state.reportId}
          getListComment={this.getListComments}
          selectedComment={this.state.selectedComment}
          handleSelectComment={this.handleSelectComment}
          closeAddNewComment={this.handleCloseAddComment}
          havePermissionComment={this.state.havePermissionComment}
          userImage={this.state.userProfile} />)
  }

  renderButtonComment = () => {
    return this.state.userInfo?.data?.role === "Employee" &&
      <div style={{ position: "absolute", top: 20, right: 20 }}>
        {this.state.showCommentBubble ?
          <Button data-testid="btn-hide-comment" style={{ border: "1px solid #026AB7", height: "max-content", borderRadius: 100, margin: "auto", textTransform: "none", color: "#026AB7" }}
            onClick={this.handleShowCommentBubble}>Hide Comment</Button>
          : <img src={icMessageAdd} style={{ objectFit: "contain", cursor: "pointer" }}
            onClick={this.handleShowCommentBubble} />}
      </div>
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Sidebar />
        <main style={styles.content}>
          {/* header */}
          <AppBar style={styles.appBar}>
            <Toolbar>
              <Grid
                data-test-id="header"
                container
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                style={{ margin: "12px 0px 20px" }}
              >
                <Grid item>
                  {this.state.editReportId ?
                    <div style={{ marginLeft: 280, display: "flex" }}>
                      <img style={{cursor: "pointer", objectFit: "contain", marginRight: 15}} 
                           src={icArrowLeftBlue} onClick={this.goBack} onKeyPress={this.goBack} data-testid = "btn-goback" />
                      <Typography style={{
                        color: "#026AB7", fontFamily: 'Lato',
                        fontWeight: 800,
                        fontSize: 32,
                        lineHeight: "40px"
                      }}>
                        <b> {configJSON.editReportTitle}</b>
                      </Typography>
                    </div>
                    :
                    <Typography style={{ marginLeft: 280,color: "#026AB7", fontFamily: 'Lato',
                    fontWeight: 800,
                    fontSize: 32,
                    lineHeight: "40px" }}><b>{configJSON.homeText}</b>
                    </Typography>
                  }
                </Grid>
                <Grid
                  style={{
                    display: "flex",
                    marginRight: "10px",
                  }}
                >
                  <Grid item style={{ display: "flex" }}>
                    <div
                      style={{
                        minWidth: 170,
                        height: 56,
                        display: "flex",
                        alignItems: "center",
                        borderRadius: 8,
                        backgroundColor: "white",
                        padding: '0 8px',
                        marginRight: '10px'
                      }}>
                      <div>
                        <Avatar
                          variant="square"
                          src={this.state.userProfile}
                          style={{ width: 44, height: 45 }}
                        >
                          {this.state.profileText}
                        </Avatar>
                      </div>

                      <div style={{ marginLeft: 20 }}>
                        <p
                          style={{
                            fontFamily: "Lato",
                            fontSize: 16,
                            fontWeight: 400,
                            fontStyle: "normal",
                            textAlign: "right",
                            color: "#64748B",
                          }}
                        >
                          {this.state.userName}
                        </p>
                      </div>
                    </div>
                    <Automaticreminders
                      navigation={this.props.navigation}
                      id=""
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          {/* header end */}

          <Container
            maxWidth={false}
            style={{ marginTop: 80, borderRadius: "15px" }}
          >
            <Card>
              <CardContent
                style={{
                  padding: "40px 15px 10px",
                  height: `calc(100vh - 150px)`,
                }}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="space-around"
                  alignItems="stretch"
                >
                  <Grid item xs={this.state.reportGrid as GridSize}>
                    <Grid
                      container
                      spacing={2}
                      style={styles.gridCard as React.CSSProperties}
                    >
                      <Grid
                        item
                        xs={12}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          borderRadius: 8,
                          width: "100%",
                          height: 623,
                        }}
                      >
                        <div style={{ display: 'flex' }}>
                          <Avatar
                            src={this.state.userProfile}
                            style={{ width: 44, height: 45 }}
                          >
                            {this.state.profileText}
                          </Avatar>
                          <div style={{marginLeft:6}}>
                            <Typography variant="body1" style={{fontSize:14}}>{configJSON.labelNameText}: <span style={{color:'#64748B'}}>{this.state.userName}, {jobTitleMapping[this.state.userInfo.user_job_title]}</span></Typography>
                            <Typography variant="body1" style={{fontSize:14}}>{configJSON.labelDepartmentText}: <span style={{color:'#64748B'}}>{this.state.userInfo.department?.replace(/_/g, ' ')}</span> </Typography>
                          </div>
                        </div>
                        <div style={{display:"flex", justifyContent:"space-between"}}>
                        <div
                          data-test-id="manually-save"
                          onClick={() =>
                            this.handleSearch(this.state.textInputValue)
                          }
                          style={{ position: "relative", cursor: "pointer" }}
                        >
                          <img src={iconUploadBox} alt="" height={40} />
                          <img
                            src={iconUploadFileBox}
                            alt=""
                            style={{
                              position: "absolute",
                              top: "21%",
                              left: "19%",
                            }}
                          />
                        </div>
                        </div>
                        <Dialog
                          open={this.state.isPublished}
                          onClose={this.handleModalClose}
                          data-test-id="cancel-dialog"
                          PaperProps={{
                            style: {
                              minWidth: "500px",
                              height: "270px",
                            },
                          }}
                        >
                          <div
                            style={styles.dialogContent as React.CSSProperties}
                          >
                            {this.isCancelUi()}
                            <Grid
                              container
                              justifyContent="center"
                              style={{ marginTop: "22px" }}
                            >
                              {this.state.isCancel && (
                                <Button
                                  variant="outlined"
                                  onClick={this.handleModalClose}
                                  data-test-id="clear-button"
                                  style={
                                    styles.cancelButton as React.CSSProperties
                                  }
                                >
                                  {configJSON.cancelButtonText}
                                </Button>
                              )}
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={this.handleModalAction}
                                data-test-id="publish-button"
                                style={
                                  styles.primaryButton as React.CSSProperties
                                }
                              >
                                {this.clearOkButton}
                              </Button>
                            </Grid>
                          </div>
                        </Dialog>
                      </Grid>
                      <div
                        style={{ border: "1px solid rgba(204, 204, 204, 1.0)" }}
                        className="container"
                      />
                      <Grid
                        item
                        xs={12}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexGrow: 1,
                          marginBottom: 10,
                          position: "relative",
                          overflow: "hidden auto",
                          scrollbarWidth: "thin",
                          scrollbarColor: "#44AFFF #F3F5F7",
                        }}
                      >
                        {this.renderReportArea()}
                        {this.state.textInputValue &&this.state.reportSaveTime.status==='Published' &&this.renderCommentInput()} 
                        {this.renderPopupComment()}
                      </Grid>
                      {this.state.showReportToast && (
                        <div style={styles.toastSuccess as React.CSSProperties}>
                          <img src={ic_success_icon} alt="toast-success-icon" />
                          <p style={{ marginLeft: "5px", fontWeight: "bold" }}>
                            {configJSON.clearReportToast}
                          </p>
                        </div>
                      )}
                      <div style={styles.chipBox}>
                        {this.state.reportSaveTime.date && (
                          <Chip
                            label={`Report last save : ${this.state.reportSaveTime.date} ${this.state.reportSaveTime.time}`}
                            style={styles.chip}
                          />
                        )}
                      </div>
                    </Grid>
                  </Grid>
                  {this.state.isDrower ? (
                    <Grid item xs={this.state.chartGrid as GridSize}>
                      <Grid
                        container
                        spacing={2}
                        style={styles.gridCard as React.CSSProperties}
                      >
                        <Grid
                          item
                          xs={12}
                          style={{
                            alignItems: "center",
                            borderRadius: 8,
                            boxShadow: "rgb(98 127 172 / 32%) 0px 0px 8px",
                            padding: 0,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              position: "relative",
                            }}
                          >
                            <img
                              data-test-id="open-close-button"
                              src={ic_toast}
                              alt="toast icon"
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: "-22px",
                                zIndex: 2,
                                cursor:"pointer"
                              }}
                              onClick={this.handleDrower}
                            />

                            <div style={{ width: "100%", position: "relative" }}>
                              <Tabs
                                data-test-id="employee-comment-tab"
                                value={this.state.selectedTab}
                                onChange={(e: any, newValue) =>
                                  this.handleTabChange(newValue)
                                }
                                TabIndicatorProps={{
                                  style: {
                                    display: "none",
                                    border: "none",
                                  },
                                }}
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "100%",
                                  borderRadius: "8px",
                                  minWidth: "50%",
                                }}
                                textColor="primary"
                              >
                                { this.getTab(
                                    0,
                                    "employee-report-tab",
                                    "Importable Reports",
                                    ic_filled_note,
                                    note
                                  )}
                                {this.getTab(
                                  1,
                                  "comment-tab",
                                  "Comments",
                                  ic_message_text,
                                  icMessageBig
                                )}
                              </Tabs>
                              {this.renderButtonComment()}

                              {/* Content for each tab */}
                              {this.state.selectedTab === 0 && (
                                <div data-test-id="employee-container">
                                  <div
                                    style={{
                                      display: "flex",
                                      padding: "0 8px",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography
                                      style={{
                                        textAlign: "left",
                                        padding: "15px 10px",
                                        fontFamily: 'Lato',
                                        fontSize: "14px"
                                      }}
                                    >
                                      {
                                        "Drag & Drop Names to Import & append to current report"
                                      }
                                    </Typography>
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      onClick={this.handleImportAll}
                                      data-test-id="importall-button"
                                      style={{
                                        ...styles.primaryButton as React.CSSProperties,fontSize:'14px',height:'42px'
                                      }}
                                    >
                                      {configJSON.importAllButtonText}
                                    </Button>
                                  </div>
                                  <List
                                    style={
                                      styles.listStyle as React.CSSProperties
                                    }
                                  >
                                    {this.state.employeeReportList?.length >
                                    0 ? (
                                      this.state.employeeReportList.map(
                                        (employee: any, index: number) => (
                                          <div
                                            data-test-id="each-employee-report"
                                            key={employee.id}
                                            draggable={this.checkReportType(employee)}
                                            onDragStart={() =>
                                              this.handleDragStart(index)
                                            }
                                          >
                                            <ListItem
                                              alignItems="flex-start"
                                              style={{
                                                borderRadius: "8px",
                                                boxShadow:
                                                  "0px 2px 4px 4px rgba(0, 0, 0, 0.08)",
                                                marginBottom: "10px",
                                                fontSize:'12px'
                                              }}
                                            >
                                              <ListItemAvatar>
                                                <Avatar
                                                  alt="avatar"
                                                  src={`${employee?.attributes?.image?.url}}`}
                                                />
                                              </ListItemAvatar>
                                              <ListItemText
                                                primary={<Typography variant="h6" style={{ fontSize:13 }}>Name: {employee.attributes.user_name}</Typography>}
                                                secondary={<Typography variant="h4" style={{ fontSize:12,color:'rgba(0, 0, 0, 0.54)' }}>Department: {employee.attributes?.department.replace(/_/g, ' ')}</Typography>}
                                              />
                                              <ListItemSecondaryAction>
                                                <div
                                                  data-test-id="view-report"
                                                  style={{ display: this.checkReportShow(employee.type)}}
                                                  onClick={(event) =>
                                                    this.handleViewReport(
                                                      event,
                                                      employee
                                                    )
                                                  }
                                                >
                                                  <Typography
                                                    style={{
                                                      textAlign: "right",
                                                      color: "#026AB7",
                                                      cursor: "pointer",
                                                      fontSize: 12
                                                    }}
                                                  >
                                                    {"View Report"}
                                                  </Typography>
                                                  <img
                                                    src={arrow_right}
                                                    alt="arrow_right"
                                                  />
                                                </div>
                                                <Typography
                                                  style={{ textAlign: "right", fontSize: 12, display:this.checkReportShow(employee.type)}}
                                                >{`${employee.attributes.date}`}</Typography>
                                              </ListItemSecondaryAction>
                                            </ListItem>
                                            {/* <Divider variant="inset" component="li" /> */}
                                          </div>
                                        )
                                      )
                                    ) : (
                                      <div
                                        style={
                                          styles.emptyBox as React.CSSProperties
                                        }
                                      >
                                        <div>
                                          <img
                                            src={iconMessageBox}
                                            alt=""
                                            width={50}
                                            height={50}
                                          />
                                          <div
                                            style={{
                                              color: "#026AB7",
                                              fontSize: "16px",
                                              fontWeight: 600,
                                              marginTop: "20px",
                                            }}
                                          >
                                            {configJSON.noEmployeeReportText}
                                          </div>
                                          <div
                                            style={{
                                              color: "#979797",
                                              marginTop: "10px",
                                            }}
                                          >
                                            {
                                              configJSON.noEmployeeReportImportText
                                            }
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </List>
                                  {/* </Grid> */}
                                </div>
                              )}
                              {this.renderListComment()}
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    <div style={styles.tabHide as React.CSSProperties}>
                      <img
                        data-test-id="open-close-tab-button"
                        src={icArrowLeftFilled}
                        alt="toast"
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "-8px",
                          cursor: "pointer",
                          zIndex: 2,
                        }}
                        onClick={this.handleDrower}
                      />
                      {this.state.userInfo?.data?.role === "Manager" &&
                      <div
                        data-test-id="employee-hide-tab"
                        style={{ padding: "20px", cursor: "pointer" }}
                        onClick={() => this.handleTabSelected(0)}
                      >
                        <img
                          src={
                            this.state.selectedTab === 0 ? note : ic_filled_note
                          }
                          alt="employee-report"
                          width="24px"
                          height="24px"
                        />
                      </div>}
                      <div
                        data-test-id="comment-hide-tab"
                        style={{ padding: "20px", cursor: "pointer" }}
                        onClick={() => this.handleTabSelected(1)}
                      >
                        <img
                          src={
                            this.state.selectedTab === 1
                              ? icMessageBig
                              : ic_message_text
                          }
                          alt="message-text"
                          width="24px"
                          height="24px"
                        />
                      </div>
                    </div>
                  )}
                </Grid>

                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  style={{ padding: "0px 10px" }}
                >
                  {this.state.lastChange ? (
                    <div style={{ color: "#979797" }}>
                      <div>{configJSON.autoSaveText}</div>
                      <div>Last change {this.state.lastChange} ago</div>
                    </div>
                  ) : (
                    <div />
                  )}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      disabled={this.state.checkButtonStatus}
                      variant="outlined"
                      style={styles.cancelButton as React.CSSProperties}
                      data-test-id="cancel-button"
                      onClick={this.handleModalOpenAction}
                    >
                      {configJSON.clearButtonText}
                    </Button>
                    <Button
                      disabled={this.state.checkButtonStatus}
                      variant="contained"
                      color="primary"
                      style={{
                        ...(styles.primaryButton as React.CSSProperties),
                        backgroundColor: this.createReportBtn(),
                        color: "FFF",
                      }}
                      onClick={this.createReport}
                      data-test-id="create-employee-report"
                    >
                      {configJSON.publishButtonText}
                    </Button>
                  </div>
                </Grid>
              </CardContent>
            </Card>
          </Container>
          <ReportPreview
            navigation={""}
            id={this.state.viewMoreReportId}
            openModal={this.state.openModal}
            handleCloseModal={this.handleCloseModal}
            lastChange={this.state.lastChange}
            detailReport={this.state.detailReport}
            isCommentAdd={true}
            isHistory={true}
            userImage={this.state.userProfile}
          />
        </main>
        <Dialog
          open={this.state.isReportAction}
          onClose={this.actionModalClose}
          data-test-id="action-dialog"
          PaperProps={{
            style: {
              minWidth: "500px",
              height: "270px",
            },
          }}
        >
          <div
            style={styles.dialogContent as React.CSSProperties}
          >
            {configJSON.reportActionText}
            <Grid
              container
              justifyContent="center"
              style={{ marginTop: "22px" }}
            >
              <Button
                variant="outlined"
                onClick={this.keepBothReport}
                data-test-id="keep-report-button"
                style={
                  styles.cancelButton as React.CSSProperties
                }
              >
                {configJSON.keepButtonText}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={this.replaceReport}
                data-test-id="replace-report-button"
                style={
                  styles.primaryButton as React.CSSProperties
                }
              >
                {configJSON.replaceButtonText}
              </Button>
            </Grid>
          </div>
        </Dialog>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const styles = {
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  appBar: {
    border: 0,
    boxShadow: "none",
    backgroundColor: "#CCE9FF",
  },
  title: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
  },
  logo: {
    width: 30,
    height: 30,
  },
  searchInput: {
    backgroundColor: "white",
  },
  searchButton: {
    marginRight: 0,
  },
  content: {
    flexGrow: 1,
    marginLeft: drawerWidth,
    // height: "100vh",
    backgroundColor: "#CCE9FF",
    padding: 9,
  },
  Container: {
    backgroundColor: "#CCE9FF",
  },
  label: {
    color: "#979797",
  },
  progressBar: {
    marginBottom: 10,
  },
  card: {
    margin: "5px 15px",
    // height: "406px",
    minHeight: `calc(100vh - 330px)`,
    maxHeight: `calc(100vh - 330px)`,
    padding: "5px",
    overflowY: "auto",
    scrollbarWidth: "thin",
    scrollbarColor: "#44AFFF #F3F5F7",
  },
  cardContent: {
    borderRadius: "8px",
    padding: "10px",
    boxShadow: "rgba(98, 127, 172, 0.2) 0px 0px 8px",
    margin: "5px 0",
  },
  textArea: {
    fontFamily: "Lato",
    fontSize: 16,
    fontStyle: "normal",
    color: "#979797",
    padding: "10px",
    width: "100%",
    minHeight: `calc(100vh - 370px)`,
    border: "none",
    resize: "none",
    outline: "none",
    scrollbarWidth: "thin",
    scrollbarColor: "#44AFFF #F3F5F7",
  },
  dialogButton: {
    display: "flex",
    justifyContent: "end",
    marginTop: "12px",
  },
  chipBox: {
    display: "flex",
    justifyContent: "center",
    margin: "5px 20px",
    height: "33px",
  },
  gridCard: {
    display: "flex",
    border: "1px solid rgba(204, 204, 204, 1.0)",
    borderRadius: 8,
    flexDirection: "column",
    marginBottom: 15,
    marginTop: 0,
    position: "relative",
  },
  dialogTitle: {
    fontSize: 23,
    fontFamily: "Lato",
    fontStyle: "normal",
    lineHeight: 32,
    color: "#3C3C3C",
    background: "#F4F9FD",
  },
  primaryButton: {
    height: 56,
    width: 177,
    borderRadius: 8,
    fontSize: 17,
    fontWeight: 700,
    fontStyle: "normal",
    textTransform: "none",
    backgroundColor: "#014A80",
  },
  chip: {
    width: "max-content",
    padding: "8px 12px",
    backgroundColor: "#cceaff",
    color: "#333",
  },
  cancelButton: {
    height: 56,
    width: 177,
    borderRadius: 8,
    fontSize: 17,
    fontWeight: 700,
    color: "#979797",
    textTransform: "none",
    marginRight: 20,
  },
  tabHide: {
    position: "relative",
    width: "72px",
    height: `calc(100vh - 239px)`,
    borderRadius: "8px",
    border: "1px solid #BDCCD9",
    background: "#FFF",
  },
  dialogContent: {
    width: "375px",
    color: "#3C3C3C",
    textAlign: "center",
    fontFamily: "Lato",
    // font-size: 20px;/
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
    margin: "auto",
  },
  modalText: {
    margin: "auto",
    textAlign: "center",
    fontFamily: "Lato",
    fontSize: "14px",
  },
  chartBox: {
    height: "500px",
  },
  emptyBox: {
    // position: "absolute",
    top: "36%",
    textAlign: "center",
    margin: "0px 20px",
    fontfamily: "Lato",
  },
  icon: {
    width: "36px",
    height: "44px",
    background: "white",
    boxShadow: "0 0 8px rgba(98,127,172,.2)",
    borderRadius: "15px",
  },
  toastSuccess: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
    fontFamily: "Lato",
    fontSize: "16px",
    width: "300px",
    boxShadow: "0px 6px 15px -3px rgba(0, 0, 0, 0.15)",
    position: "absolute",
    bottom: "45px",
    left: "-12%",
    transform: "translateX(50%)",
    marginBottom: "20px",
  },
  heading: {
    fontFamily: "Lato",
    fontSize: "16px",
    color: "#64748B",
  },
  headingKey: {
    fontWeight: 600,
    margin: "0px 2px",
  },
  headingValue: {
    fontWeight: 400,
  },
  tabBar: {
    display: "flex",
    justifyContent: "space-between",
  },
  MuiTabsindicator: {
    display: "none",
  },
  listStyle: {
    height: "100%",
    minHeight: `calc(100vh - 405px)`,
    maxHeight: `calc(100vh - 405px)`,
    padding: "0px 20px",
    overflowY: "auto",
    scrollbarWidth: "thin",
    marginTop: "20px",
    scrollbarHeight: "92.759px",
    scrollbarColor: "#44AFFF #F3F5F7",
  },
  commentBubble: {
    padding: 10,
    borderRadius: 8, 
    left: 100,
    display: "flex",
    cursor: "pointer",
    boxShadow: "0px 6px 15px -3px rgba(0,0,0,0.2)",
    position: 'absolute',
    background: "#fff",
    justifyContent: 'center', 
    maxWidth: 150,
    alignItems: 'center', 
    top: 200,
  },
  reportDiv: {
    position: "relative",
    width: "100%",
    padding: "5px",
    overflowY: "scroll",
    whiteSpace: "pre-wrap",
    maxHeight: "358px",
    minHeight: "358px",
    fontFamily: "Lato",
    fontSize: 16,
    fontStyle: "normal",
    color: "#979797",
    scrollbarWidth: "thin",
    scrollbarColor: "#44AFFF #F3F5F7",
  },
};
// Customizable Area End
