import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { getStorageData, isEmpty } from "../../../framework/src/Utilities";
import moment from 'moment';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  name: any;
  lastName: any;
  department: any;
  Occupation: any;
  Status: any;
  StartDate: any;
  Manager: any;
  userInfo: any;
  isEdit: boolean;
  isDisable: boolean;
  imgProfile: any;
  selectImage: any;
  profileImage: string;
  role: string;
  manager: string;
  status: string;
  startDate: string;
  userId: string;
  isEmployee: boolean;
  uploadImage: any;
  profileText: string;
  lastUpdated: string;
  isAvater: boolean;
  avatarData: any[];
  isAdmin: boolean;
  imageError:string | null;
  job:string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class UserProfileBasicBlockController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUserCallID = "";
  saveCallId = "";
  getAvatarCallID = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      name: "",
      lastName: "",
      department: "",
      Occupation: "",
      Status: "",
      StartDate: "",
      Manager: "",
      userInfo: {},
      isEdit: false,
      isDisable: true,
      imgProfile: "",
      selectImage: {},
      profileImage: "",
      role: "",
      manager: "",
      status: "",
      startDate: "",
      userId: "",
      isEmployee: true,
      uploadImage: {},
      profileText: "",
      lastUpdated: "",
      isAvater: true,
      avatarData: [{ url: "" }],
      isAdmin:false,
      imageError:null,
      job:""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.getUserCallID) {
      const { data } = responseJson;
      const {attributes}=data;
      const {profile_text,image,data:attributesData}=attributes;
      const {
          last_updated_on,
          start_date,
          status,
          manager,
          name,
          department,
          role,
          user_job_title
        } = attributesData;

        const imageUrl = image && image.url;
        
        const formattedDate = moment(last_updated_on).format('Do MMM YYYY');
        
        this.setState({ 
          userInfo: attributesData,
          name,
          department:department||"",
          role,
          manager,
          status,
          job:user_job_title,
          userId: data.id,
          profileText:profile_text && (profile_text).toUpperCase(),
          startDate:
            start_date && new Date(start_date).toISOString().split("T")[0],
          lastUpdated: formattedDate,
          profileImage: imageUrl,
          selectImage: imageUrl
        },()=>this.upload());
      } else if (apiRequestCallId === this.saveCallId) {
        this.getUserInfo();
      } else if (apiRequestCallId === this.getAvatarCallID) {
        this.setState({
          avatarData: [
            { url: responseJson.avatar_url },
            { url: "" }
          ]
        });
      } else {
        console.log(errorReponse);
      }
    }
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getUserInfo();
    this.getAvatar();
    const role = await getStorageData("role");
    this.setState({
      isDisable: this.roleMapping[role],
      isEmployee: role === "Employee" ? true : false,
      isAdmin :role === "Admin" ? true : false,
    });
    // Customizable Area End
  }

  roleMapping: { [key: string]: boolean } = {
    Admin: false,
    Employee: true,
    Manager: true
  };

  apiCall = async (data: any) => {
    const token = await getStorageData("token");
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        this.state.isAvater ? body : JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  getUserInfo = async () => {
    this.getUserCallID = await this.apiCall({
      contentType: configJSON.contentTypeApiGetUserProfile,
      method: configJSON.methodTypeApiGetUserProfile,
      endPoint: configJSON.getUserApiEndPoint
    });
  };

  handleEdit = () => {
    this.setState(prevState => ({ isEdit: !prevState.isEdit }));
    this.upload()
  };
  chooseAvatar = (item: any) => {
    this.setState(
      { selectImage: item.url,imageError:null, isAvater: isEmpty(item.url) ? false : true },
      () => this.upload()
    );
  };
  handleUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const imageUrl = event.target.files[0];
      const fileName=imageUrl.name;
      const fileType=fileName.split('.').pop()?.toLowerCase()?? ''
      const acceptFileType=['jpg','png','jpeg']
      const error=acceptFileType.includes(fileType)?null:'Please upload .jpeg, .jpg and .png file';
      this.setState({imageError:error})
      this.setState({
        selectImage: URL.createObjectURL(imageUrl),
        uploadImage: imageUrl,
        isAvater: true
      });
    }
  };
  urlToFile = (url: any, filename: any, mimeType: any) => {
    return fetch(url)
      .then(response => response.arrayBuffer())
      .then(buffer => new File([buffer], filename, { type: mimeType }));
  };
  upload = () => {
    this.urlToFile(
      this.state.selectImage,
      `${this.state.name}.png`,
      "image/png"
    ).then(file => {
      this.setState({ uploadImage: this.state.selectImage ? file : "" });
    });
  };

  handleChange = (event: any) => {
    const { name, value } = event.target;
    switch (name) {
      case "name":
        this.setState({ name: value });
        break;
      case "department":
        this.setState({ department: value });
        break;
      case "role":
        this.setState({ role: value });
        break;
      case "job":
        this.setState({ job: value });
        break;
      case "manager":
        this.setState({ manager: value });
        break;
      case "status":
        this.setState({ status: value });
        break;
      case "startDate":
        this.setState({ startDate: value });
        break;
    }
  };

  saveUpdateHandler = async () => {
    const formBody = new FormData();
    formBody.append("account[image]", this.state.uploadImage);
    formBody.append('account[name]', this.state.name)
    formBody.append('account[department]', this.state.department)
    formBody.append('account[user_job_title]', this.state.job)
    const body = {
      account: {
        image: null
      }
    };
    this.saveCallId = await this.apiCall({
      method: "PUT",
      contentType: this.state.isAvater
        ? undefined
        : configJSON.contentTypeApiGetUserProfile,
      endPoint: `bx_block_user/users/update_profile`,
      body: this.state.isAvater ? formBody : body
    });
  };

  handleSave = () => {
    this.handleEdit();
    this.saveUpdateHandler();
  };

  getAvatar = async () => {
    this.getAvatarCallID = await this.apiCall({
      contentType: configJSON.contentTypeApiGetUserProfile,
      method: configJSON.methodTypeApiGetUserProfile,
      endPoint: configJSON.avatarApiEndPoint
    });
  };

  readonly departmentOption: any[] =[
    configJSON.sales,
    configJSON.customerService,
    configJSON.operations,
    configJSON.linehaul,
    configJSON.finance,
    configJSON.billing,
    configJSON.pricing,
    configJSON.humanResources,
    configJSON.informationTechnology,
    configJSON.executive
  ];

  readonly jobTitle:any[]=[
    {
      key:configJSON.administrationProfile,
      value:configJSON.administrationValueProfile
    },
    {
      key:configJSON.linehaulProfile,
      value:configJSON.linehaulValueProfile
    },
    {
      key:configJSON.operationsProfile,
      value:configJSON.operationsValueProfile
    },
    {
      key:configJSON.salesClientProfile,
      value:configJSON.salesClientValueProfile
    },
    {
      key:configJSON.sfoProfile,
      value:configJSON.sfoValueProfile
    },
    {
      key:configJSON.ontProfile,
      value:configJSON.ontValueProfile
    },
    {
      key:configJSON.ewrProfile,
      value:configJSON.ewrValueProfile
    },
    {
      key:configJSON.dalProfile,
      value:configJSON.dalValueProfile
    }, 
    {
      key:configJSON.htxProfile,
      value:configJSON.htxValueProfile
    },
    {
      key:configJSON.atmProfile,
      value:configJSON.atmValueProfile
    },
    {
      key:configJSON.laxProfile,
      value:configJSON.laxValueProfile
    },
    {
      key:configJSON.lrdProfile,
      value:configJSON.lrdValueProfile
    },
    {
      key:configJSON.pricingProfile,
      value:configJSON.pricingValueProfile
    },
    {
      key:configJSON.agentProfile,
      value:configJSON.agentValueProfile
    },
    {
      key:configJSON.directorOperationsProfile,
      value:configJSON.directorOperationsValueProfile
    },
    {
      key:configJSON.directorSalesProfile,
      value:configJSON.directorSalesValueProfile
    },
    {
      key:configJSON.crossBorderProfile,
      value:configJSON.crossBorderValueProfile
    },
    {
      key:configJSON.applicationDevelopmentProfile,
      value:configJSON.applicationDevelopmentValueProfile
    },
    {
      key:configJSON.infrastructureProfile,
      value:configJSON.infrastructureValueProfile
    },
    {
      key:configJSON.humanResourcesProfile,
      value:configJSON.humanResourcesValueProfile
    },
    {
      key:configJSON.servicesManagerProfile,
      value:configJSON.servicesManagerValueProfile
    },
    {
      key:configJSON.safetyManagerProfile,
      value:configJSON.safetyManagerValueProfile
    },
    {
      key:configJSON.chiefTechnologyProfile,
      value:configJSON.chiefTechnologyValueProfile
    },
    {
      key:configJSON.chiefInformationProfile,
      value:configJSON.chiefInformationValueProfile
    },
    {
      key:configJSON.chiefFinancialProfile,
      value:configJSON.chiefFinancialValueProfile
    },
    {
      key:configJSON.executiveOfficerProfile,
      value:configJSON.executiveOfficerValueProfile
    },{
      key:configJSON.businessAnalystKeyProfile,
      value:configJSON.businessAnalystProfile
    },
    {
      key:configJSON.srNetworkKeyProfile,
      value:configJSON.srNetworkProfile
    },
    {
      key:configJSON.srSystemsKeyProfile,
      value:configJSON.srSystemsProfile
    },
    {
      key:configJSON.projectManagerKeyProfile,
      value:configJSON.projectManagerProfile
    },
    {
      key:configJSON.applicationDeveloperKeyProfile,
      value:configJSON.applicationDeveloperProfile
    },{
      key:configJSON.srApplicationsKeyProfile,
      value:configJSON.srApplicationsProfile
    },{
      key:configJSON.srSystemsAdministratorKeyProfile,
      value:configJSON.srSystemsAdministratorProfile
    },{
      key:configJSON.systemsAdministratorKeyProfile,
      value:configJSON.systemsAdministratorProfile
    },{
      key:configJSON.technicalArchitectKeyProfile,
      value:configJSON.technicalArchitectProfile
    },{
      key:configJSON.employeeOppProfile,
      value:configJSON.employeeOppProfile
    },{
      key:configJSON.supervisorProfile,
      value:configJSON.supervisorProfile
    },{
      key:configJSON.managerOppProfile,
      value:configJSON.managerOppProfile
    },{
      key:configJSON.vicePresidentKeyProfile,
      value:configJSON.vicePresidentProfile
    }
  ]

  // Customizable Area End
}
