import React from "react";
// Customizable Area Start
import {
  Container,
  Card,
  CardContent,
  Button,
  Typography,
  AppBar,
  Toolbar,
  Grid,
  Table,
  TableBody,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  Dialog,
  DialogContent,
  Menu, 
  MenuItem,
  Divider,
  Avatar
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Sidebar from "../../../components/src/Sidebar.web";
import EmployeeReportController, {
  Props,
} from "./EmployeeReportController.web";
import {icDoc, icEdit2, icMore, icMoreWhite, icTick, icTrash} from "./assets"
import moment from "moment";
import ReportPreview from "./ReportPreview.web";
import Automaticreminders from "../../automaticreminders/src/Automaticreminders.web";
const drawerWidth = 280;

const theme = createTheme({
  overrides: {
    MuiMenu: {
      list: {
        padding: 0
      }
    },
    MuiListItem: {
      button: {
        "&:hover": {
          backgroundColor: "#CCCCCC", 
        }
      }
    }
  }
});
// Customizable Area End

export default class History extends EmployeeReportController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Sidebar />

        <AppBar style={styles.appBar}>
          <Toolbar>
            <Container 
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: 'center',
                padding: 20,
                maxWidth:'100%'
              }}>
              <Grid item xs={8}>
                <Typography
                  style={{
                    fontFamily: 'Lato',
                    fontWeight: 800,
                    fontSize: 32,
                    lineHeight: "40px",
                    color: "#014A80"
                  }}
                >
                  <b>History</b>
                </Typography>
              </Grid>
              <Grid
                item
              >
                <div style={{display:'flex'}}>
                <div style={{
                  width: 170,
                  height: 56,
                  display: "flex",
                  alignItems: "center",
                  borderRadius: 8,
                  backgroundColor: "white",
                  padding: '0 8px',
                  marginRight:'10px'
                }}>
                <div>
                  <Avatar variant="square" src={this.state.currentUser.image} style={{ width: 44, height: 45 }} >
                  {(this.state.currentUser.profile_text||'').toUpperCase()}
                  </Avatar>
                </div>
                <div style={{ marginLeft: 20 }}>
                  <p
                    style={{
                      fontFamily: "Lato",
                      fontSize: 16,
                      fontWeight: 400,
                      fontStyle: "normal",
                      color: "#64748B",
                      wordBreak: "break-word"
                    }}
                  >
                    {this.state.currentUser.name}
                  </p>
                </div>
                </div>
                <Automaticreminders
                    navigation={this.props.navigation}
                    id=""
                  />
                  </div>
              </Grid>

            </Container>
          </Toolbar>
        </AppBar>

        <main style={styles.content}>
          <Container className="flex" maxWidth="lg" style={{paddingTop: 10 ,maxWidth:'100%'}}>
            <Card style={{marginTop: 90}}>
              <CardContent style={{ padding: 0 }}>
                {this.state.error? 
                <p style={{textAlign: 'center'}}>{this.state.error}</p> 
                :
                <TableContainer>
                <Table style={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow style={{backgroundColor: "#F4F9FD"}}>
                      <TableCell align="center">Serial no</TableCell>
                      <TableCell align="center">Name</TableCell>
                      <TableCell align="center">Department</TableCell>
                      <TableCell align="center">Date</TableCell>
                      <TableCell align="center">Time</TableCell>
                      <TableCell align="center"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.employeeReportData?.map((report, index) => (
                      <TableRow key={report.data.id}>
                        <TableCell align="center">
                          {report.data.attributes.serial_number}
                        </TableCell>
                        <TableCell align="center">
                          {report.data.attributes.name}
                        </TableCell>
                        <TableCell align="center">
                          {report.data.attributes.department?.replace(/_/g, ' ')}
                        </TableCell>
                        <TableCell align="center">
                          {report.data.attributes.date}
                        </TableCell>
                        <TableCell align="center">
                          {report.data.attributes.time}
                        </TableCell>
                        <TableCell align="center">
                          {report.data.id === this.state.viewMoreReportId ?
                            <Button>
                              <span style={{ background: "#026AB7", borderRadius: 100 }}>
                                <img id="ic_more"
                                  src={icMoreWhite}
                                  alt="view more" />
                              </span>
                            </Button> :
                            <Button data-testid="button-viewmore" style={{ background: "transparent" }}
                              disableRipple
                              onClick={(event) => this.handleViewMore(event, report.data.id)} >
                              <img id="ic_more"
                                src={icMore}
                                alt="view more" />
                            </Button>
                          }
                        </TableCell>
                        <Menu
                          id="menu-viewmore"
                          anchorEl={this.state.anchorViewMore}
                          keepMounted
                          open={Boolean(this.state.anchorViewMore)}
                          onClose={this.handleCloseViewMore}
                          getContentAnchorEl={null}
                          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                          transformOrigin={{ vertical: "top", horizontal: "right" }}
                          TransitionProps={{ style: { width: 211, marginTop: 5, borderRadius: 8, padding: 0 } }}
                        >
                          <MenuItem data-testid="option-view" style={styles.viewMoreOption}
                          onClick={this.viewDetailReport}>
                            <img src={icDoc} style={styles.viewMoreIcon}/> View Report
                          </MenuItem>
                          <Divider style={{ width: "95%", margin: "3px auto" }} />
                          <MenuItem onClick={this.gotoEdit} data-testid="option-edit" style={styles.viewMoreOption}>
                            <img src={icEdit2} style={styles.viewMoreIcon}/> Edit Report
                          </MenuItem>
                          <Divider style={{ width: "95%", margin: "3px auto" }} />
                          <MenuItem onClick={this.handleOpenModalDelete}
                             data-testid="option-delete" style={{...styles.viewMoreOption, color: "#DB2323"}}>
                            <img src={icTrash} style={styles.viewMoreIcon} /> Delete Report
                          </MenuItem>
                        </Menu>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <Pagination
                  id="report-pagination"
                  count={Math.ceil(this.state.totalReport/this.state.pageSize)}
                  page={this.state.page}
                  style={styles.pagination}
                  hideNextButton
                  hidePrevButton
                  boundaryCount={2}
                  siblingCount={1}
                  renderItem={(item) => {
                    return item.type === 'page' ?
                      <div 
                        id="report-pagination-item"
                        onClick={() => this.onChangePage(item.page)}
                        style={item.page===this.state.page ? styles.activePaginationItem : styles.paginationItem}>
                        {item.page}
                      </div>
                      :
                      <div style={styles.paginationItem}>...</div>
                  }}
                />
              </TableContainer>
                }
               
              </CardContent>
            </Card>
          </Container>
        </main>
        <Dialog
          id="dialog-delete-history"
          open={this.state.openModalDelete}
          onClose={this.handleCloseModalDelete}
          PaperProps={{
            style: { width: 501, margin: "auto", borderRadius: 8 },
          }}
        >
          <DialogContent>
            {/* Add your content for the Modal here */}
            <Grid container spacing={2} style={{ marginTop: 30, padding: "0 40px 10px 40px" }}>
              <p style={{textAlign: "center"}}>
                Are you sure you want to delete report of "{this.state.detailReport.name}" dated {moment(this.state.detailReport.lastChange).format("Do MMMM, h.mma")}?
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 20,
                  marginBottom: 35,
                  width: "100%"
                }}
              >
                 <Button
                  id="btn-close-delete"
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{
                    width: 177,
                    height: 56,
                    borderRadius: 8,
                    background: "transparent",
                    border: "1px solid #979797",
                    color: "#979797",
                    boxShadow: "none",
                    textTransform: "none",
                    fontSize: 20
                 }}
                 onClick={this.handleCloseModalDelete}
                >
                  <b>No</b>
                </Button>
                <Button
                  id="btn-delete-report"
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{
                    width: 177,
                    height: 56,
                    borderRadius: 8,
                    backgroundColor: "#014A80",
                    textTransform: "none",
                    boxShadow: "none",
                    fontSize: 20
                  }}
                  onClick={this.deleteReport}
                >
                  <b>Yes</b>
                </Button>
              </div>
            </Grid>
          </DialogContent>
        </Dialog> 

        <Dialog
          id="dialog-delete-history"
          open={this.state.openSuccessModal}
          onClose={this.handleCloseSuccessModal}
          PaperProps={{
            style: { width: 501, margin: "auto", borderRadius: 8 },
          }}
        >
          <DialogContent>
            {/* Add your content for the Modal here */}
            <Grid container spacing={2} style={{ marginTop: 20, padding: "0 40px 10px 40px" }}>
              <img src={icTick} style={{margin: "auto"}} />
              <p style={{textAlign: "center", width: "100%", fontFamily: "Lato", fontSize: 20, lineHeight: "24px"}}>
                You've successfully deleted <br/> the report.
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 15,
                  marginBottom: 35,
                  width: "100%"
                }}
              >
                <Button
                  id="btn-close-success"
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{
                    width: 177,
                    height: 56,
                    borderRadius: 8,
                    backgroundColor: "#014A80",
                    textTransform: "none",
                    boxShadow: "none",
                    fontSize: 20
                  }}
                  onClick={this.handleCloseSuccessModal}
                >
                  <b>Okay</b>
                </Button>
              </div>
            </Grid>
          </DialogContent>
        </Dialog> 
      <ReportPreview 
          navigation={""} 
          id={this.state.viewMoreReportId} 
          openModal={this.state.openModal} 
          handleCloseModal={this.handleCloseModal}
          lastChange={this.state.lastChange}
          detailReport={this.state.detailReport} 
          isCommentAdd={false}
          isHistory={true}
          userImage={this.state.image}
        />
    </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  appBar: {
    border: 0,
    boxShadow: "none",
    backgroundColor: "#CCE9FF",
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
  },
  content: {
    backgroundColor: "#CCE9FF",
    flexGrow: 1,
    marginLeft: drawerWidth,
    height: "100vh"
  },
  note: {
    color: "#979797",
    fontFamily: "Lato",
    fontSize: 12,
    lineHeight: "24px",
    margin: 0
  },
  time_badge: {
      fontFamily: "Lato",
      fontSize: 12,
      fontStyle: "normal",
      color: "#979797",
      lineHeight: "18px"
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    margin: "2rem 0",
    cursor: "pointer"
  }, 
  activePaginationItem: {
    backgroundColor: '#026AB7',
    padding: '0.25rem 0.65rem',
    width: "max-content",
    borderRadius: '2rem',
    color: "#FFFFFF",
    fontFamily: "Lato",
    fontWeight: 400, 
    lineHeight: '22px',
    fontSize: 14,
    margin: '0 0.5rem'
  },
  paginationItem: {
    padding: '0.25rem 0.65rem',
    width: "max-content",
    borderRadius: '2rem',
    color: "#CCCCCC",
    fontFamily: "Lato",
    fontWeight: 400, 
    lineHeight: '22px',
    fontSize: 14,
    margin: '0 0.5rem'
  },
  comment: {
    backgroundColor: "#FFFFFF",
    borderRadius: 8,
    boxShadow: "0px 6px 15px -3px rgba(0, 0, 0, 0.15)",
    padding: "5px 10px",
  },
  viewMoreOption : {
    padding: "15px 20px",
    fontFamily: "Lato",
    fontSize: 16
  },
  viewMoreIcon: {
    marginRight: 10
  }
};
// Customizable Area End
