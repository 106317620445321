export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const icMoreWhite = require("../assets/ic_more_white.png");
export const icMore = require("../assets/ic_more.png");
export const icEdit =require("../assets/ic_edit.png");
export const icTrash = require("../assets/ic_trash.png");
export const icArrow = require("../assets/ic_arrow.png");
export const imgSearch=require("../assets/ic_search.png");
export const imgSuccess=require("../assets/ic_success.png");
export const imgCamera = require("../assets/ic_camera.png");
