export const imgSussess = require("../assets/ic_success.png");
export const ic_arrow_right = require("../assets/ic_arrow_right.png");
export const ic_edit = require("../assets/ic_edit.png");

export const arrow_right = require("../assets/arrow_right.png");
export const ic_toast = require("../assets/toast.png");
export const ic_arrow_left = require("../assets/ic_arrow_left.png");
export const note = require("../assets/note.png");
export const ic_message_text = require("../assets/ic_message_text.png");
export const iconMessage = require("../assets/ic_message.png");
export const iconMessageBox = require("../assets/ic_message_box.png");
export const iconUploadBox = require("../assets/ic_upload_box.png");
export const iconUploadFileBox = require("../assets/ic_upload_file.png");
export const icMore = require("../assets/ic_more.png");
export const icEdit2 = require("../assets/ic_edit_2.png");
export const icTrash = require("../assets/ic_trash.png");
export const icDoc = require("../assets/ic_doc.png");
export const icMoreWhite = require("../assets/ic_more_white.png");
export const icTick = require("../assets/ic_tick.png");
export const icMessageAdd = require("../assets/ic_message_add.png");
export const icMessageBig = require("../assets/ic_message_big.png");
export const icArrowRightFilled = require("../assets/ic_arrow_right_filled.png");
export const icArrowLeftFilled = require("../assets/ic_arrow_left_filled.png");
export const icFilledMessageText = require("../assets/message-filled-color-img.png");
export const ic_filled_note = require("../assets/ic_filled_note.png");
export const icReminder = require("../assets/ic_reminder.png");
export const icArrowLeftBlue = require("../assets/ic_arrow_left_blue.png");

export const icArrowComment = require("../assets/ic_arrow.png");
export const ic_notification = require("../assets/ic_notification.png");
export const ic_success_icon = require("../assets/ic_success_Icon.png");
export const ic_avatar1 = require("../assets/avatar1.png");
export const ic_avatar2 = require("../assets/avatar2.png");
export const ic_avatar3 = require("../assets/avatar3.png");
export const ic_avatar4 = require("../assets/avatar4.png");
export const ic_avatar5 = require("../assets/avatar5.png");
