import React from "react";
// Customizable Area Start
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Image,
  ScrollView,
} from "react-native";
import { leftArrow, rightArrow } from "./assets";
import Skin from "./personas-avatar/components/skin";
import Hair from "./personas-avatar/components/hair";
import FacialHair from "./personas-avatar/components/facial-hair";
import Body from "./personas-avatar/components/body";
import Eyes from "./personas-avatar/components/eyes";
import Mouth from "./personas-avatar/components/mouth";
import Nose from "./personas-avatar/components/nose";
import Svg from "react-native-svg";
// Customizable Area End
import SelectOptionsAvatarController, {
  Props,
  configJSON,
} from "./SelectOptionsAvatarController";

export default class SelectOptionsAvatar extends SelectOptionsAvatarController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderSkinColors = () => {
    return (
      <View style={styles.selectorWrapper}>
        <TouchableOpacity
          style={styles.titleWrapper}
          testID="btnToggle"
          onPress={() =>
            this.props.openToggle(
              this.props.open === this.props.type ? null : this.props.type
            )
          }
        >
          <Text style={styles.selectorTitle}>{this.props.title}</Text>
          <Image
            source={rightArrow}
            style={
              this.props.open === this.props.type
                ? styles.collapseIconActive
                : styles.collapseIcon
            }
          />
        </TouchableOpacity>
        {this.props.open === this.props.type && (
          <View style={styles.selectorInnerWrapper}>
            <Svg width="80px" height="80px" viewBox="0 0 64 64">
              <Skin color={this.props.colors[this.props.selectedColor]} />
            </Svg>
            <View style={styles.selectorContent}>
              {Object.entries(this.props.colors).map(
                (skin: any, index: number) => {
                  return (
                    <TouchableOpacity
                      key={index}
                      testID={"btnColorPress1" + index}
                      onPress={() => this.props.onColorPress(skin[0])}
                      style={[styles.colors, { backgroundColor: skin[1] }]}
                    />
                  );
                }
              )}
            </View>
          </View>
        )}
      </View>
    );
  };
  // Customizable Area End

  render = () => {
    // Customizable Area Start
    if (this.props.type === "skinColors") {
      return this.renderSkinColors();
    }
    return (
      <View style={styles.selectorWrapper}>
        <TouchableOpacity
          style={styles.titleWrapper}
          testID="btnToggle"
          onPress={() =>
            this.props.openToggle(
              this.props.open === this.props.type ? null : this.props.type
            )
          }
        >
          <Text style={styles.selectorTitle}>{this.props.title}</Text>
          <Image
            source={rightArrow}
            style={
              this.props.open === this.props.type
                ? styles.collapseIconActive
                : styles.collapseIcon
            }
          />
        </TouchableOpacity>
        {this.props.open === this.props.type && (
          <View style={styles.selectorInnerWrapper}>
            <View style={styles.hairSelectorContent}>
              {this.props.icons && (
                <View style={styles.container}>
                  <TouchableOpacity
                    style={styles.leftArrowStyle}
                    testID="btnLeftArrow"
                    onPress={() => this.leftArrow()}
                  >
                    <Image source={leftArrow} style={styles.iconStyle} />
                  </TouchableOpacity>
                  <ScrollView
                    testID="scrollView"
                    style={styles.scrollContainer}
                    horizontal={true}
                    ref={(c) => (this.svgRef = c)}
                    onScroll={this.handleScroll}
                    scrollEventThrottle={16}
                  >
                    {this.props.icons &&
                      Object.entries(this.props.icons).map(
                        (icon: any, index: number) => {
                          return (
                            <TouchableOpacity
                              key={index}
                              testID={"btnIcon" + index}
                              style={[
                                styles.selectedIconContainer,
                                {
                                  backgroundColor:
                                    icon[0] === this.props.selectedIcon
                                      ? "#DCDCDC"
                                      : "#f8f8f8",
                                },
                              ]}
                              onPress={() => this.props.onIconPress(icon[0])}
                            >
                              <Svg
                                width="80px"
                                height="80px"
                                viewBox="0 0 64 64"
                              >
                                {this.props.type === "hairs" && (
                                  <Hair
                                    value={icon[0]}
                                    color={
                                      this.props.colors[
                                        this.props.selectedColor
                                      ]
                                    }
                                  />
                                )}
                                {this.props.type === "facialHairs" && (
                                  <FacialHair
                                    value={icon[0]}
                                    color={
                                      this.props.colors[
                                        this.props.selectedColor
                                      ]
                                    }
                                  />
                                )}
                                {this.props.type === "body" && (
                                  <Body
                                    value={icon[0]}
                                    color={
                                      this.props.colors[
                                        this.props.selectedColor
                                      ]
                                    }
                                  />
                                )}
                                {this.props.type === "eyes" && (
                                  <Eyes value={icon[0]} />
                                )}
                                {this.props.type === "mouths" && (
                                  <Mouth value={icon[0]} />
                                )}
                                {this.props.type === "noses" && (
                                  <Nose
                                    value={icon[0]}
                                    color={this.props.selectedColor}
                                  />
                                )}
                              </Svg>
                            </TouchableOpacity>
                          );
                        }
                      )}
                  </ScrollView>
                  <TouchableOpacity
                    style={styles.rightArrowStyle}
                    testID="btnRightArrow"
                    onPress={() => this.rightArrow()}
                  >
                    <Image source={rightArrow} style={styles.iconStyle} />
                  </TouchableOpacity>
                </View>
              )}

              <View style={styles.selectorContent}>
                {this.props.colors &&
                  Object.entries(this.props.colors).map(
                    (hc: any, index: number) => {
                      return (
                        <TouchableOpacity
                          key={index}
                          testID={"btnColorPress" + index}
                          onPress={() => this.props.onColorPress(hc[0])}
                          style={[styles.colors, { backgroundColor: hc[1] }]}
                        />
                      );
                    }
                  )}
              </View>
            </View>
          </View>
        )}
      </View>
    );
    // Customizable Area End
  };
}

// Customizable Area Start
const styles = StyleSheet.create({
  selectorWrapper: {
    borderWidth: 0.5,
    borderColor: "#7c7f91",
    borderRadius: 5,
    marginTop: 15,
  },
  selectorTitle: {
    fontSize: 16,
    margin: 10,
    color: "#7c7f91",
    flex: 1,
  },
  selectorInnerWrapper: {
    flex: 1,
    flexDirection: "row",
  },
  selectorContent: {
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
    paddingLeft: 5,
    marginBottom: 5,
  },
  hairSelectorContent: {
    flex: 1,
    flexDirection: "column",
    flexWrap: "wrap",
  },
  colors: {
    height: 30,
    width: 30,
    marginLeft: 8,
    marginBottom: 8,
    borderRadius: 5,
  },
  titleWrapper: {
    flex: 1,
    flexDirection: "row",
  },
  collapseIconActive: {
    width: 30,
    height: 30,
    marginTop: 5,
    marginRight: 5,
    transform: [{ rotate: "-90deg" }],
  },
  collapseIcon: {
    width: 30,
    height: 30,
    marginTop: 5,
    marginRight: 5,
    transform: [{ rotate: "90deg" }],
  },
  container: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
  },
  scrollContainer: {
    flex: 1,
    flexDirection: "row",
    overflow: "hidden",
    paddingBottom: 15,
  },
  selectedIconContainer: {
    margin: 5,
    borderRadius: 5,
  },
  leftArrowStyle: {
    alignItems: "flex-start",
    borderRadius: 30,
  },
  rightArrowStyle: {
    alignItems: "flex-end",
  },
  iconStyle: {
    width: 30,
    height: 30,
  },
});
// Customizable Area End
