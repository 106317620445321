import React, { CSSProperties } from "react";
// Customizable Area Start
import {
  Button,
  Grid,
  Dialog,
  DialogContent,
  TextField,
  Divider,
  Checkbox
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import SearchEmployeeController, {Props} from "./SearchEmployeeController.web";
import { icBubble, icTick } from "./assets";

const theme = createTheme({
  overrides: {
    MuiOutlinedInput: {
      notchedOutline: {
        border: "none"
      }
    },
  }
});
// Customizable Area End

export default class SearchEmployee extends SearchEmployeeController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  convertManagerName = () => {
    if(this.props.addEmpType==='add') return this.props.currentManager.name
    else return this.state.targetManager.name
  }

  renderSuccess = () => {
    const convertCopyMove = (type: 'copy' | 'move' | 'add' | null) => {
      if(type==='copy') return "copied"
      if(type==='move') return 'moved'
      if(type==='add') return 'added'
    }
    const numberOfEmps = this.props.addEmpType === 'add' ? this.state.listPickedEmployee.length : this.props.listSelectedEmployee?.length
    return <Dialog
      id="dialog-success"
      open={this.state.openSuccessModal}
      onClose={this.handleCloseSuccessModal}
      PaperProps={{
        style: { width: 501, margin: "auto", borderRadius: 8 },
      }}
    >
      <DialogContent>
        <Grid container spacing={2} style={{ marginTop: 20, padding: "0 40px 10px 40px" }}>
          <img src={icTick} style={{ margin: "auto" }} />
          <p style={{ textAlign: "center", width: "100%", fontFamily: "Lato", fontSize: 20, lineHeight: "24px" }}>
          You've successfully {convertCopyMove(this.props.addEmpType)} {numberOfEmps} employees to {this.convertManagerName()} profile
          </p>
          <div
            style={{
              width: "100%",
              marginTop: 15,
              marginBottom: 35,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              id="btn-close-success"
              type="submit"
              variant="contained"
              color="primary"
              style={styles.buttonConfirm as CSSProperties}
              onClick={this.handleCloseSuccessModal}
            >
              <b>Okay</b>
            </Button>
          </div>
        </Grid>
      </DialogContent>
    </Dialog>
  }

  renderDialogConfirm = () => {
    return <Dialog
      id="dialog-confirm"
      open={this.state.openConfirmAction}
      onClose={this.handleCloseConfirmAction}
      PaperProps={{
        style: { width: 501, margin: "auto", borderRadius: 8 },
      }}
    >
      <DialogContent>
        <Grid container spacing={2} style={{ marginTop: 20, padding: "0 40px 10px 40px" }}>
          <p style={{ textAlign: "center", width: "100%", fontFamily: "Lato", fontSize: 20, lineHeight: "24px" }}>
            Are you sure you wanted to {this.props.addEmpType} employees to {this.convertManagerName()} profile?
          </p>
          <Grid container style={{ width: "90%", margin: "auto", marginTop: 20 }}>
            <Grid item xs={6} style={{ paddingRight: 10 }}>
              <Button
                id="btn-cancel"
                type="submit"
                color="primary"
                variant="contained"
                style={{ ...styles.btn, ...styles.btnCancel, textTransform: 'none' }}
                onClick={this.handleCloseConfirmAction}
              >
                <b>Cancel</b>
              </Button>
            </Grid>
            <Grid item xs={6} style={{ paddingLeft: 10 }}>
              <Button
                id="btn-apply"
                type="submit"
                variant="contained"
                color="primary"
                onClick={this.handleEmployeeAction}
                style={{ ...styles.btn, ...styles.btnOK, textTransform: 'none' }}
              >
                Yes
              </Button>
            </Grid>
          </Grid>

        </Grid>
      </DialogContent>
    </Dialog>
  }
  convertText = (addType: 'copy' | 'move' | 'add' | null) => {
    if(addType==='add') return 'add'
    if(addType==='copy') return 'copy'
    if(addType==='move') return 'move'
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Dialog
          id="ar-manager-profile-dialog"
          open={this.props.openSearch}
          PaperProps={{ style: { minWidth: 587, margin: "auto", borderRadius: 8 } }}
        >
          <DialogContent style={{ padding: 0, overflow: "hidden" }}>
            <div
              style={{
                borderRadius: "9px 9px 0 0",
                backgroundColor: "#F4F9FD",
                padding: "24px 40px",
                display: "flex",
                alignItems: "center", 
                justifyContent: "center"
              }}
            >
             {this.props.addEmpType === 'copy' && <span style={styles.searchHeader}>Copy employees</span>}
             {this.props.addEmpType === 'move' && <span style={styles.searchHeader}>Move employees</span>}
             {this.props.addEmpType === 'add' && <span style={styles.searchHeader}>Add employees</span>}

            </div>
            <p style={{...styles.title, textAlign:'center'}}>Search & Select the manager/employee 
            to which you want to {this.convertText(this.props.addEmpType)} the selected employees</p>

            {/* Add your content for the Modal here */}
            <Grid container spacing={2} style={{ ...styles.searchDialog }}>
              <Grid item sm={12} style={{padding: "0 20px"}}>
                <TextField
                  data-testid={"name_tid"}
                  type="text"
                  name="name"
                  variant="outlined"
                  value={this.state.searchKeyword}
                  onChange={this.handleChangeSearchValue}
                  style={styles.textField}
                  InputProps={{ style: styles.InputProps, startAdornment: (
                    <img style={{objectFit: "cover", marginRight: 5, width: 24, height: 24}} src={icBubble} />
                  ) }}
                  placeholder="Search Employee"
                  fullWidth
                />
                <div onScroll={this.handleLoadMoreEmployee}
                  id="list-emp"
                  style={{ overflowY: 'auto', height: 250, scrollbarWidth: "thin", scrollbarColor: "#44AFFF #F3F5F7", }}>
                  {
                    this.state.listEmp.map(emp =>
                      <div
                        data-testid="item-emp"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column"
                        }}
                        onKeyDown={() => this.handleSelectAction(emp.data)}
                        onClick={() => this.handleSelectAction(emp.data)} key={emp.data.id}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: "15px auto", width: "100%" }}>
                          <div style={{ display: "flex", alignItems: "center"}}>
                            <img src={emp.data.attributes.image?.url}
                              style={{ width: 40, height: 40, borderRadius: 100, border: '2px solid #CCCCCC', objectFit: "cover" }} />
                            <div style={{ marginLeft: 10 }}>
                              <span
                                style={{
                                  fontWeight: 400,
                                  fontSize: 16,
                                  color: "#595959"
                                }}>
                                {emp.data.attributes.data.Name}
                              </span>
                            </div>
                          </div>
                         
                          {(this.state.listPickedEmployee.includes(emp.data.id) || this.state.targetManager.id === emp.data.id) &&
                           <div style={{ marginRight: 10 }}>
                            <Checkbox style={{ color: "#44AFFF" }} checked={true} />
                          </div>}
                        </div>
                        <Divider />
                      </div>
                    )}
                     {this.state.isLoading && <p style={{textAlign: 'center'}}>Loading...</p>}
                </div>
              </Grid>
            </Grid>
            <Grid container style={{ width: "90%", margin: "auto", marginTop: 20 }}>
              <Grid item xs={6} style={{paddingRight: 10}}>
                <Button
                  id="btn-cancel-search"
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{...styles.btn, ...styles.btnCancel, textTransform: 'none'}}
                  onClick={this.handleCancelSearch}
                >
                  <b>Cancel</b>
                </Button>
              </Grid>
              <Grid item xs={6} style={{paddingLeft: 10}}>
                <Button
                  id="btn-ok"
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={this.handleOpenConfirmAction}
                  style={{...styles.btn,...styles.btnOK, textTransform: 'none', 
                    backgroundColor: this.state.targetManager.id==='' && this.state.listPickedEmployee.length===0? '#979797' : "#014A80"}}
                  disabled={this.state.targetManager.id==='' && this.state.listPickedEmployee.length===0}
                >
                  {this.props.addEmpType === 'copy' && <b>Paste</b>}
                  {this.props.addEmpType === 'move' && <b>Move</b>}
                  {this.props.addEmpType === 'add' && <b>Add</b>}

                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
        {this.renderSuccess()}
        {this.renderDialogConfirm()}
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
  avt: { 
    width: 204, 
    height: 204, 
    borderRadius: 100, 
  },
  searchHeader: {
    fontSize: 24,
    fontWeight: 700,
    lineHeight: "28px",
    color: "#3C3C3C",
    fontFamily: "Lato"
  },
  searchDialog: {
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "20px 0",
    borderColor: "rgba(204, 204, 204, 1.0)",
    borderBottom: 0, 
    boxShadow: "2px 2px 10px 2px rgba(0, 0, 0, 0.1)",
    borderRadius: 8,
    width: "90%",
    margin: "20px auto"
  },
  btn: {
    width: "100%",
    height: 48,
    borderRadius: 8,
    fontSize: 20,
    fontFamily: "Inter"
  },
  btnOK: {
    backgroundColor: "#014A80",
    marginTop: 20,
    marginBottom: 20,
    boxShadow: "none",
  },
  btnCancel: {
    border: "1px solid #979797",
    marginTop: 20,
    marginBottom: 20,
    marginRight: 20,
    color: "#979797",
    background: "transparent",
    boxShadow: "none",
  },
  title: { 
    fontSize: 16, 
    fontWeight: 500, 
    lineHeight: "22px", 
    fontFamily: 'Lato',
    marginBottom: 5,
    width: "70%",
    margin: "20px auto"
  },
  textField:  { 
    backgroundColor: "#F4F4F4", 
    borderRadius: 8,
    marginBottom: 5,
  },
  InputProps: { 
    borderRadius: 8, 
    fontSize: 16,
    border: "1px solid #CBD5E1"
},
 buttonConfirm: {
  width: 177,
  height: 56,
  borderRadius: 8,
  backgroundColor: "#014A80",
  textTransform: "none",
  boxShadow: "none",
  fontSize: 20
}
};
// Customizable Area End
