import React, { useEffect, useState } from "react";
import {
  ListItem,
  Drawer,
  List,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import HomeIcon from "@material-ui/icons/Home";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { ImageBackground } from "react-native";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import HistoryIcon from "@material-ui/icons/History";
import AssessmentIcon from "@material-ui/icons/Assessment";
import Divider from "@material-ui/core/Divider";
import { Link ,Redirect} from "react-router-dom";
import path from "path";
import { getStorageData ,removeStorageData} from "../../framework/src/Utilities";
const drawerWidth = 280;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  appBar: {
    border: 0,
    boxShadow: "none",
    backgroundColor: "#CCE9FF",
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
  },
  content: {
    backgroundColor: "#CCE9FF", // Set the background color here
    flexGrow: 1,
    padding: theme.spacing(3),
    marginLeft: drawerWidth,
    height: "100vh",
  },
}));

const Sidebar = () => {
  const classes = useStyles();

  const [activeTab, setActiveTab] = useState<string | null>(null);

  const [token, setToken] = useState<boolean>();
  
  const handleTabClick = (tabName: string) => {
    setActiveTab(tabName);
  };

  const handleLogout= async()=>{
    await removeStorageData('token')
    await removeStorageData('role')
    await removeStorageData('reminder')
  };

  const userType = localStorage.getItem("role");
  //const userType = 'Employee'
  
 const setCheckToken=async()=>{
  const isToken= await getStorageData('token')
  setToken(!!isToken)
 };

  useEffect(() => {
    // Parse the pathname to determine the active tab
    const pathname = location.pathname;
    const editHistoryPath = pathname.split("/").slice(0, -1).join("/")
    setCheckToken();
    if (pathname === "/VisualAnalytics") {
      setActiveTab("home");
    } else if (pathname === "/history" || editHistoryPath === "/edit-history") {
      setActiveTab("history");
    } else if (pathname === "/employeereport") {
      setActiveTab("employeereport");
    } else if (pathname === "/userProfile") {
      setActiveTab("userProfile");
    } else if(pathname === "/EmailAccountRegistration"){
      setActiveTab("EmailAccountRegistration")
    }  else if(pathname === "/assignRelationships"){
      setActiveTab("AssignRelationships")
    } else if(pathname === "/adminconsole"){
      setActiveTab("Adminconsole")
    } else if(pathname === "/organisation-hierarchy"){
      setActiveTab("OrganisationHierarchy")
    } else {
      setActiveTab(null); // No matching route, set activeTab to null
    }
  }, [location.pathname]);

  if (token === false) {
    return <Redirect to='/login' />;
  }
  if (userType === "Admin") {
    // Admin sidebar content
    return (
      <div>
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <Toolbar />
          <ImageBackground
            source={require("../../blocks/global_assets/logo.png")}
            style={{ width: 204, height: 103, marginLeft: 25 }}
          />

          {/* Main List */}
          <div style={{ flexGrow: 1, padding: 10 }}>
            <List>
            <ListItem
                button
                component={Link}
                to="/adminconsole"
                onClick={() => handleTabClick("Adminconsole")}
                style={
                  activeTab === "Adminconsole"
                    ? {
                        background: "#CCE9FF",
                        color: "#026AB7",
                        borderLeft: "3px solid ",
                        borderColor: "#026AB7",
                      }
                    : {}
                }
              >
                <ListItemIcon>
                  <ImageBackground
                    source={require(activeTab === "Adminconsole"?"../../blocks/global_assets/active_admin_console.png": "../../blocks/global_assets/admin_console.png")}
                    style={{width: 28, height: 28 }}
                  />

                </ListItemIcon>
                <ListItemText primary="Admin Console" />
            </ListItem>
            <ListItem
                button
                component={Link}
                to="/assignRelationships"
                onClick={() => handleTabClick("AssignRelationships")}
                style={
                  activeTab === "AssignRelationships"
                    ? {
                        background: "#CCE9FF",
                        color: "#026AB7",
                        borderLeft: "3px solid ",
                        borderColor: "#026AB7",
                      }
                    : {}
                }
              >
                <ListItemIcon>
                  <ImageBackground
                    source={require( activeTab === "AssignRelationships" ?"../../blocks/global_assets/active_assign_relationships.png":"../../blocks/global_assets/assign_relationships.png")}
                    style={{ width: 28, height: 28 }}
                  />

                </ListItemIcon>
                <ListItemText primary="Assign Relationships" />
            </ListItem>
              <ListItem
                button
                component={Link}
                to="/EmailAccountRegistration"
                onClick={() => handleTabClick("EmailAccountRegistration")}
                style={
                  activeTab === "EmailAccountRegistration"
                    ? {
                        background: "#CCE9FF",
                        color: "#026AB7",
                        borderLeft: "3px solid ",
                        borderColor: "#026AB7",
                      }
                    : {}
                }
              >
                <ListItemIcon>
                  <ImageBackground
                    source={require( activeTab === "EmailAccountRegistration" ?"../../blocks/global_assets/active_account_create.png":"../../blocks/global_assets/account_create.png")}
                    style={{ width: 28, height: 28 }}
                  />

                </ListItemIcon>
                <ListItemText primary="Create Account" />
              </ListItem>
              <ListItem
                button
                component={Link}
                to="organisation-hierarchy"
                onClick={() => handleTabClick("OrganisationHierarchy")}
                style={
                  activeTab === "OrganisationHierarchy"
                    ? {
                        background: "#CCE9FF",
                        color: "#026AB7",
                        borderLeft: "3px solid ",
                        borderColor: "#026AB7",
                      }
                    : {}
                }
              >
                <ListItemIcon>
                  <ImageBackground
                    source={require( activeTab === "OrganisationHierarchy" ?"../../blocks/global_assets/active_org_hierarchy.png":"../../blocks/global_assets/org_hierarchy.png")}
                    style={{ width: 28, height: 28 }}
                  />

                </ListItemIcon>
                <ListItemText primary="Organization Hierarchy" />
              </ListItem>

              <Divider />
              <ListItem
                button
                component={Link}
                to="/userProfile"
                onClick={() => handleTabClick("userProfile")}
                style={
                  activeTab === "userProfile"
                    ? {
                        background: "#CCE9FF",
                        color: "#026AB7",
                        borderLeft: "3px solid ",
                        borderColor: "#026AB7",
                      }
                    : {}
                }
              >
                <ListItemIcon>
                  <ImageBackground
                    source={require( activeTab === "userProfile" ?"../../blocks/global_assets/active_ic_profile.png":"../../blocks/global_assets/ic_profile.png")}
                    style={{width: 28, height: 28 }}
                  />
                </ListItemIcon>
                <ListItemText primary="Profile" />
              </ListItem>
            </List>
          </div>

          {/* Logout Link */}
          <div>
            <List onClick={handleLogout}>
              <ListItem button component={Link} to="/login">
                <ListItemIcon>
                <ImageBackground
                    source={require("../../blocks/global_assets/logout.png")}
                    style={{width: 28, height: 28 }}
                  />                </ListItemIcon>
                <ListItemText primary="Logout" style={{ color: "red" }} />
              </ListItem>
            </List>
          </div>
        </Drawer>
      </div>
    );
  } else if (userType === "Manager") {
    // Manager sidebar content
    return (
      <div>
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <Toolbar />
          <ImageBackground
            source={require("../../blocks/global_assets/logo.png")}
            style={{ width: 204, height: 103, marginLeft: 25 }}
          />

          {/* Main List */}
          <div style={{ flexGrow: 1, padding: 10 }}>
            <List>
              <ListItem
                button
                component={Link}
                to="/VisualAnalytics"
                onClick={() => handleTabClick("home")}
                style={
                  activeTab === "home"
                    ? {
                        background: "#CCE9FF",
                        color: "#026AB7",
                        borderLeft: "3px solid ",
                        borderColor: "#026AB7",
                      }
                    : {}
                }
              >
                <ListItemIcon>
                <ImageBackground
                    source={require( activeTab === "home" ?"../../blocks/global_assets/active_home.png":"../../blocks/global_assets/home.png")}
                    style={{width: 28, height: 28 }}
                  />
                </ListItemIcon>
                <ListItemText primary="Home" />
              </ListItem>
              <ListItem
                button
                component={Link}
                to="/history"
                onClick={() => handleTabClick("history")}
                style={
                  activeTab === "history"
                    ? {
                        background: "#CCE9FF",
                        color: "#026AB7",
                        borderLeft: "3px solid ",
                        borderColor: "#026AB7",
                      }
                    : {}
                }
              >
                <ListItemIcon>
                <ImageBackground
                    source={require( activeTab === "history" ?"../../blocks/global_assets/active_history.png":"../../blocks/global_assets/history.png")}
                    style={{width: 28, height: 28 }}
                  />
                </ListItemIcon>
                <ListItemText primary="History" />
              </ListItem>
              <ListItem
                button
                component={Link}
                to="/employeereport"
                onClick={() => handleTabClick("employeereport")}
                style={
                  activeTab === "employeereport"
                    ? {
                        background: "#CCE9FF",
                        color: "#026AB7",
                        borderLeft: "3px solid ",
                        borderColor: "#026AB7",
                      }
                    : {}
                }
              >
                <ListItemIcon>
                <ImageBackground
                    source={require( activeTab === "employeereport" ?"../../blocks/global_assets/active_employee_report.png":"../../blocks/global_assets/employee_report.png")}
                    style={{width: 28, height: 28 }}
                  />
                </ListItemIcon>
                <ListItemText primary="Employee Report" />
              </ListItem>
              <Divider />
              <ListItem
                button
                component={Link}
                to="/userProfile"
                onClick={() => handleTabClick("userProfile")}
                style={
                  activeTab === "userProfile"
                    ? {
                        background: "#CCE9FF",
                        color: "#026AB7",
                        borderLeft: "3px solid ",
                        borderColor: "#026AB7",
                      }
                    : {}
                }
              >
                <ListItemIcon>
                <ImageBackground
                    source={require( activeTab === "userProfile" ?"../../blocks/global_assets/active_ic_profile.png":"../../blocks/global_assets/ic_profile.png")}
                    style={{width: 28, height: 28 }}
                  />
                </ListItemIcon>
                <ListItemText primary="Profile" />
              </ListItem>
            </List>
          </div>

          {/* Logout Link */}
          <div>
            <List onClick={()=>handleLogout()}>
              <ListItem button component={Link} to="/login">
                <ListItemIcon>
                <ImageBackground
                    source={require("../../blocks/global_assets/logout.png")}
                    style={{width: 28, height: 28 }}
                  />                </ListItemIcon>
                <ListItemText primary="Logout" style={{ color: "red" }} />
              </ListItem>
            </List>
          </div>
        </Drawer>
      </div>
    );
  } else if (userType === "Employee") {
    // Employee sidebar content
    return (
      <div>
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <Toolbar />
          <ImageBackground
            source={require("../../blocks/global_assets/logo.png")}
            style={{ width: 204, height: 103, marginLeft: 25 }}
          />

          {/* Main List */}
          <div style={{ flexGrow: 1, padding: 10 }}>
            <List>
              <ListItem
                button
                component={Link}
                to="/VisualAnalytics"
                onClick={() => handleTabClick("home")}
                style={
                  activeTab === "home"
                    ? {
                        background: "#CCE9FF",
                        color: "#026AB7",
                        borderLeft: "3px solid ",
                        borderColor: "#026AB7",
                      }
                    : {}
                }
              >
                <ListItemIcon>
                  <ImageBackground
                    source={require( activeTab === "home" ?"../../blocks/global_assets/active_home.png":"../../blocks/global_assets/home.png")}
                    style={{width: 28, height: 28 }}
                  />
                </ListItemIcon>
                <ListItemText primary="Home" />
              </ListItem>
              <ListItem
                button
                component={Link}
                to="/history"
                onClick={() => handleTabClick("history")}
                style={
                  activeTab === "history"
                    ? {
                        background: "#CCE9FF",
                        color: "#026AB7",
                        borderLeft: "3px solid ",
                        borderColor: "#026AB7",
                      }
                    : {}
                }
              >
                <ListItemIcon>
                  <ImageBackground
                    source={require( activeTab === "history" ?"../../blocks/global_assets/active_history.png":"../../blocks/global_assets/history.png")}
                    style={{width: 28, height: 28 }}
                  />
                </ListItemIcon>
                <ListItemText primary="History" />
              </ListItem>

              <ListItem
                button
                component={Link}
                to="/employeereport"
                onClick={() => handleTabClick("employeereport")}
                style={
                  activeTab === "employeereport"
                    ? {
                        background: "#CCE9FF",
                        color: "#026AB7",
                        borderLeft: "3px solid ",
                        borderColor: "#026AB7",
                      }
                    : {}
                }
              >
                <ListItemIcon>
                  <ImageBackground
                    source={require( activeTab === "employeereport" ?"../../blocks/global_assets/active_employee_report.png":"../../blocks/global_assets/employee_report.png")}
                    style={{width: 28, height: 28 }}
                  />
                </ListItemIcon>
                <ListItemText primary="Employee Report" />
              </ListItem>

              <Divider />
              <ListItem
                button
                component={Link}
                to="/userProfile"
                onClick={() => handleTabClick("userProfile")}
                style={
                  activeTab === "userProfile"
                    ? {
                        background: "#CCE9FF",
                        color: "#026AB7",
                        borderLeft: "3px solid ",
                        borderColor: "#026AB7",
                      }
                    : {}
                }
              >
                <ListItemIcon>
                  <ImageBackground
                    source={require( activeTab === "userProfile" ?"../../blocks/global_assets/active_ic_profile.png":"../../blocks/global_assets/ic_profile.png")}
                    style={{width: 28, height: 28 }}
                  />
                </ListItemIcon>
                <ListItemText primary="Profile" />
              </ListItem>
            </List>
          </div>

          {/* Logout Link */}
          <div>
            <List onClick={handleLogout}>
              <ListItem button component={Link} to="/login">
                <ListItemIcon>
                <ImageBackground
                    source={require("../../blocks/global_assets/logout.png")}
                    style={{width: 28, height: 28 }}
                  />
                </ListItemIcon>
                <ListItemText primary="Logout" style={{ color: "red" }} />
              </ListItem>
            </List>
          </div>
        </Drawer>
      </div>
    );
  } else {
    return null;
  }
};

export default Sidebar;
