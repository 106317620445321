import React from "react";
// Customizable Area Start
import { Grid, Button } from "@material-ui/core";
import { ImageBackground } from "react-native";
// Customizable Area End

import TermsAndConditionContoller, {
  Props,
} from "./TermsAndConditionController.web";

export default class TermsAndConditions extends TermsAndConditionContoller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const renderTerms = (termsText: string) => {
      const paragraphs = termsText.split('\n').map((line, index) => {
        if (line.includes('<b>') || line.includes('</b>')) {
          // Split the line into parts based on <b> and </b>
          const parts = line.split(/<b>|<\/b>/);
          const isLastParagraph = line.includes('</b>') && !line.includes('<b>')

          return (
            <p key={index}>
              {parts.map((part, partIndex) =>
                (partIndex % 2 !== 0 && !isLastParagraph) || (partIndex % 2 === 0 && isLastParagraph ) ? (
                  <strong key={partIndex}>{part}</strong>
                ) : (
                  part // non bold text
                )
              )}
            </p>
          );
        } else {
          return <p key={index}>{line}</p>;
        }
      });

      return <div>{paragraphs}</div>;
    };
    return (
      <Grid container spacing={0} style={{background:'#fff'}}>
        <Grid item xs={6}>
          <ImageBackground
            source={require("../../global_assets/image_daylight.png")}
            style={{ width: 685, height: 850 }}
          />
        </Grid>
        <Grid
          item
          container
          xs={6}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid xs={12} md={8} item style={{ marginTop: 40 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <ImageBackground
                source={require("../../global_assets/logo.png")}
                style={{ width: 211, height: 107 }}
              />
              <h3 style={{ textAlign: "center" }}>
                <b>Welcome!</b>
              </h3>
              <h4
                style={{ textAlign: "center", marginTop: 15, color: "#026AB7" }}
              >
                <b>Terms & Conditions</b>
              </h4>

              {/* Add your content */}
              <Grid
                container
                spacing={2}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 10,
                  overflowY: "auto",
                }}
              >
                {/* Third Grid: Scrollable terms and conditions */}
                <Grid
                  item
                  xs={12}
                  style={{ flexGrow: 1, overflow: "auto", maxHeight: "45vh" }}
                >
                  <p
                    style={{
                      fontFamily: "Lato",
                      fontSize: 15,
                      fontStyle: "normal",
                      color: "#595959",
                      lineHeight: 1.5,
                    }}
                  >
                     {renderTerms(this.state.termsAndConditions)}
                  </p>
                                </Grid>
              </Grid>
            </div>
            <div style={{ marginTop: 20, marginBottom: 20, marginLeft: 5 }}>
              <Button
                data-test-id="TestId-I-Agree"
                variant="contained"
                color="primary"
                style={{
                  width: 440,
                  height: 60,
                  borderRadius: 8,
                  backgroundColor: "#014A80",
                  marginTop: 10,
                  marginBottom: 10,
                  textTransform: "none",
                }}
                onClick={
                  this.navigateToLogin
                }
              >
                {" "}
                <b>I agree</b>
              </Button>
              <Button
                data-test-id="TestId-Back"
                variant="outlined"
                color="primary"
                style={{
                  width: 440,
                  height: 60,
                  borderRadius: 8,
                  textTransform: "none",
                }}
                onClick={this.backToLogin}
              >
                <b>Back</b>
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
    );
    // Customizable Area End
  }
  }
// Customizable Area Start

// Customizable Area End
