import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import React from "react";
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface User {
  name: string,
  department: string, 
  role: string,
  image: string,
  profile_text:string,
}

export interface Manager {
  id: string,
  name: string, 
  department: string, 
  email: string, 
  image: string,
  role: string,
  profileText: string
}

const defaulManagerInfo = {
  id: "", 
  name: "", 
  department: "",
  email: "",
  role: "",
  image: "",
  profileText: ""
}

// Customizable Area End
export interface Props {
  navigation: any;
  id: string;

  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  openModal: boolean;
  listManagerData: any[];
  page: number;
  pageSize: number;
  currentUser: User | null;
  totalManager: number,
  error: string,
  listEmp: any[];
  empPage: number;
  totalEmp: number
  isSelect: 'select' | 'all' | 'cancel',
  selectAll: boolean,
  managerInfo: Manager
  isLoading: boolean
  isListEmpLoading: boolean
  openModalProfile: boolean,
  ancholAddEmp: HTMLElement | null,
  openSearch: boolean,
  addEmpType: 'move' | 'copy' | 'add' | null,
  listSelectedEmp: any[],
  managerRelationship: 'edit' | 'view' | ''
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class AssignRelationshipsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getManagerListId: string = "";
  getUserInfoId: string = "";
  getListEmpId: string="";
  getManagerInfoId: string="";
  grantPermissionId: string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      listManagerData: [],
      openModal: false,
      page: 1,
      pageSize: 8,
      currentUser: null,
      totalManager: 0,
      error: "", 
      listEmp: [],
      empPage: 1,
      totalEmp: 0,
      isSelect: 'select',
      selectAll: false,
      isLoading: true,
      isListEmpLoading: true,
      managerInfo: defaulManagerInfo, 
      openModalProfile: false,
      ancholAddEmp: null,
      openSearch: false,
      addEmpType: null,
      listSelectedEmp: [],
      managerRelationship: ''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getListManager(this.state.page);
    this.getUserInformation();
  }

  loadMoreEmployee = () => {
    const element = document.getElementById("list-emp");
    const scrollTop = element?.scrollTop ?? 0;
    const clientHeight = element?.clientHeight??0;
    const scrollHeight = element?.scrollHeight??0;
    const needLoadMore = this.state.listEmp.length < this.state.totalEmp;

    if(scrollTop + 3*clientHeight >= scrollHeight && !this.state.isListEmpLoading && needLoadMore){
      this.setState({empPage: this.state.empPage+1, isListEmpLoading: true});
      this.getListEmployee(this.state.empPage+1, this.state.managerInfo.id)
    }
  }

  viewDetail = (id: string)  => {
    this.setState({listEmp: [...[]], isListEmpLoading: true, totalEmp: 0, managerInfo: defaulManagerInfo})
    this.handleOpenModal();
    this.getManagerInfo(id);
  }

  onChangePage = (page: number) => {
    this.setState({page})
    this.getListManager(page);
  }

  onOpenMenuMoveCopy = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ancholAddEmp: event.currentTarget})
  }

  onCloseMenuMoveCopy = () => {
    this.setState({ancholAddEmp: null})
  }

  handleClearSelectedEmp = () => {
    this.setState({listSelectedEmp: [...[]], isSelect: "select"})
  }

  handleOpenModal = () => {
    this.setState({ openModal: true, ancholAddEmp: null });
  };

  handleCloseModal = () => {
    this.setState({ openModal: false});
    this.handleClearSelectedEmp()
  };

  handleOkModal = () => {
    let canEdit: boolean = false, canView: boolean = false;
    const relationship = this.state.managerRelationship
    if (relationship === 'edit') { canEdit = true }
    else { canView = true }
    this.handleGrantPermission(canView, canEdit, this.state.managerInfo.id)
    this.setState({ openModal: false});
    this.handleClearSelectedEmp();
    this.getListManager(this.state.page)
  }
  
  handleOpenModalProfile = () => {
    this.setState({openModalProfile: true})
  }

  handleCloseModalProfile = () => {
    this.setState({openModalProfile : false})
  }

  handleOpenSearch = (addEmpType: 'move' | 'copy' | 'add') => {
    this.setState({openSearch: true, openModal: false, addEmpType})
  }

  handleCloseSearch =() => {
    this.setState({openSearch: false})
  }

  handleChangeSelect = () => {
    let { isSelect, listSelectedEmp, selectAll } = this.state;
    let currentSelectType = isSelect;
    let nextSelectType = isSelect;

    if (currentSelectType === 'select') { 
      nextSelectType = 'all';
      selectAll = false;
     }
    else if (currentSelectType === 'all') {
      nextSelectType = 'cancel';
      this.handleSelectAllEmployee();
      selectAll = true;
    }
    else if (currentSelectType === 'cancel') { 
      nextSelectType = 'select'; 
      listSelectedEmp = [];
      selectAll = false;
      this.handleSelectAllEmployee()
    }
    this.setState({ isSelect: nextSelectType, listSelectedEmp: [...listSelectedEmp], selectAll })
  }

  handleSelectEmployee = (id: string | number) => {
    const { listSelectedEmp } = this.state;
    let { selectAll } = this.state
    if (listSelectedEmp.includes(id)) {
      const index = listSelectedEmp.indexOf(id);
      listSelectedEmp.splice(index, 1)
      selectAll = false
    } else {
      listSelectedEmp.push(id);
    }
    this.setState({ listSelectedEmp: [...listSelectedEmp], selectAll });
  }

  handleSelectAllEmployee = () => {
    const {listSelectedEmp, listEmp, isSelect} = this.state;
    let {selectAll} = this.state;
    const deSelect = isSelect === 'cancel'
    listEmp.forEach(emp => {
      const id = emp.data.id
      if (listSelectedEmp.includes(id) && deSelect) {
        const index = listSelectedEmp.indexOf(id);
        listSelectedEmp.splice(index, 1)
        selectAll = false
      } else if(!listSelectedEmp.includes(id)) {
        listSelectedEmp.push(id);
      }
    });
    this.setState({listSelectedEmp: [...listSelectedEmp], selectAll})
  }

  convertRelationShip = (relationship: string) => {
    if(relationship === 'can_edit') return "Can Edit"
    if(relationship === 'can_view') return "Can View"
    return "Can View"
  }

  handleChangeRelationship = (relationship: 'edit' | 'view') => {
    this.setState({ managerRelationship: relationship });
  }

  handleGrantPermission = async (canView: boolean, canEdit: boolean, userId: string) => {
    this.grantPermissionId = await this.apiARCall({
      contentType: "application/json",
      method: "POST",
      endPoint: `bx_block_user/users/grant_permissions?can_view=${canView}&can_edit=${canEdit}&user_id=${userId}`,
    });
  }

  getUserInformation = async () => {
    this.getUserInfoId = await this.apiARCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_user/users/current_user_info`,
    });
  }

  getListEmployee = async (page: number, managerId: number | string) => {
    this.getListEmpId = await this.apiARCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_admin/admins/manager_employees?manager_id=${managerId}&page=${page}`,
    });
  }

  getManagerInfo = async (id: string) => {
    this.getManagerInfoId = await this.apiARCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_user/users/${id}`,
    });
  }

  handleReGetListManager = () => {
    this.setState({isLoading: true, listManagerData: [...[]], totalManager: 0, page: 1})
    this.getListManager(1)
  }

  getListManager = async (page: number) => {
    this.getManagerListId = await this.apiARCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_user/users/?page=${page}`,
    });
  };

  handleTokenError = (responseAr: any) => {
    if (
      responseAr.errors &&
      responseAr.errors.length > 0 &&
      responseAr.errors[0].token
    ) {
      this.props.navigation.navigate("EmailAccountLogin")
    }
  }

  handleManagerInfo = (responseAr: any) => {
    const { attributes } = responseAr.data;
    const { data } = attributes;
    let managerRelationship: 'edit' | 'view' | '' = "view";
    if(data.Can_edit) managerRelationship = 'edit'
    else if(data.Can_view) managerRelationship = 'view'
    const managerInfo = {
      id: responseAr.data.id,
      name: data.Name,
      department: data.Department,
      role: data.Role,
      email: data?.Email,
      image: attributes.image?.url,
      profileText: attributes?.profile_text
    }
    this.setState({ managerInfo: { ...managerInfo }, listEmp: [], managerRelationship }, () => {
      this.getListEmployee(1, responseAr.data.id);
    })
  }

  apiARCall = async (data: any) => {
    const token = await getStorageData("token");
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
      token: token,
    };

    const reqMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    runEngine.sendMessage(reqMessage.id, reqMessage);
    return reqMessage.messageId;
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiReqId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseAr = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorAr = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiReqId === this.getManagerListId) {
        this.handleTokenError(responseAr)
        if (responseAr.data) {
          this.setState({
            listManagerData: responseAr.data,
            totalManager: responseAr.total_manager_counts,
            isLoading: false
          });
        }
        else {
          this.setState({ error: responseAr.errors })
        }
      } else if (apiReqId === this.getUserInfoId) {
        const {attributes} = responseAr?.data ?? {};
        const {data,profile_text,image} = attributes
        const currentUser = {
          name: data.name,
          department: data.department,
          role: data.role,
          profile_text: profile_text,
          image: image?.url,
        }
        this.setState({ currentUser: { ...currentUser } });
      } else if (apiReqId === this.getListEmpId) {
        this.setState({
          listEmp: [...this.state.listEmp, ...responseAr.data],
          totalEmp: responseAr.total_manager_counts,
          isListEmpLoading: false
        })
      } else if (apiReqId === this.getManagerInfoId) {
        if (responseAr.data) {
          this.handleManagerInfo(responseAr)
        }
      }
       else if (errorAr) {
        console.log(errorAr);
      }
    }
    // Customizable Area End
  }
}
