Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "automaticreminders";
exports.labelBodyText = "automaticreminders Body";

exports.btnExampleTitle = "CLICK ME";
exports.latestReportApiEndPoint='bx_block_report/reports/show_last_report';
exports.jsonApiContentType = "application/json";
exports.getApiMethodType = "GET";
exports.reminderText="Reminder";
exports.reminderAlertText="Please submit your report before 9AM today.";
exports.remindButtonText="Remind later";
exports.goToReportButtonText="Go to report";
exports.tomorromRemindText="The last date to submit your report is tomorrow";
exports.tomorromRemindText1="Please submit your report before";
exports.tomorromRemindText2="9AM tomorrow.";
exports.automaticRemainderApiEndPoint="bx_block_report/reports/automatic_remainder";


// Customizable Area End