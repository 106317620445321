import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, isEmpty } from "../../../framework/src/Utilities";
import moment from "moment";
import React from "react";
interface Hover {
  [key: number]: boolean;
}
export interface DetailReport { 
  id: string, 
  name: string,
  date: string, 
  time: string, 
  report: string,
  user_name: string, 
  department: string,
  image: string,
  lastChange: string, 
  user_job_title:string
}
import jobTitleMapping from "../../../components/src/JobTitleMapping";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  info: any;
  data: any;
  token: any;
  openModal:boolean;
  googleChartData: any;
  detailReport: DetailReport;
  textInputValue: string;
  checkButtonStatus: boolean;
  isHovering: Hover;
  isPublished: boolean;
  userInfo: any;
  isCancel: boolean;
  reportSaveTime: any;
  reportId: string;
  reportStatus:string;
  showPublishStatus:boolean;
  lastChange:string;
  reportAutoSaveTime: Date,
  isDrower: boolean,
  selectedTab: number,
  reportGrid:number,
  anchorViewReport: HTMLElement | null,
  fileInput: HTMLInputElement | null;
  draggedItem:string,
  draggedItemIndex:number,
  chartGrid:number,
  viewMoreReportId: string,
  fileContent: string | ArrayBuffer,
  editReportId: string
  profileText: string;
  listComments: any[];
  showReportToast: boolean;
  employeeReportList:any;
  pageBreak:string;
  userProfile:string;
  userName:string;
  isReportAction:boolean;
  reportStatusMessage:string;
  selectedComment: number;
  newCommentPosition: any,
  newComment: string,
  isAddNewComment: boolean,
  showCommentBubble: boolean,
  havePermissionComment: boolean
  report:string
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class VisualAnalyticsController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiGetDataCallId: any;
  // Customizable Area Start
  cmtRef: React.RefObject<HTMLDivElement>
  divRef: React.RefObject<HTMLDivElement>

  createEmployeeReportCallId: string = "";
  getLatestReportCallId: string = "";
  deleteReportCallId: string = "";
  getEmployeeReportCallId:string = "";
  userCallID="";
  updateEmployeeReportCallId:string = "";
  getListCommentId: string ="";
  createCommentCallId : string = ""
  checkPermissionId: string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: null,
      openModal: false,
      info: {
        labels: [],
        data: [],
        barColors: [],
      },
      data: {
        weekly: {
          labels: ["week1", "week2", "week3", "week4", "week5"],
          data: [[5], [9], [3], [6], [2]],
          barColors: ["#7db6b0"],
        },
        monthly: {
          labels: [
            "Jun",
            "Fab",
            "Mar",
            "Apr",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nom",
            "Dec",
          ],
          data: [[9], [5], [6], [3], [2], [7], [1], [4], [2], [6], []],
          barColors: ["#7db6b0"],
        },
      },
      newComment: "",
      isAddNewComment: false,
      detailReport: {
        id: "-1",
        name: "",
        date: "",
        time: "",
        report: "", 
        user_name: "",
        department: "",
        image: "",
        lastChange: "",
        user_job_title:""
      },
      googleChartData: ["Title", "Value"],
      textInputValue: "",
      checkButtonStatus: true,
      isHovering: {},
      isPublished: false,
      userInfo: {},
      isCancel: false,
      reportSaveTime: {},
      reportAutoSaveTime: new Date(),
      reportId: "",
      reportStatus:"Published",
      showPublishStatus:false,
      draggedItem: "",
      draggedItemIndex:0,
      lastChange:"",
      isDrower:true,
      reportGrid:6,
      chartGrid:5,
      selectedTab: 0,
      editReportId: "",
      userProfile:"",
      userName:"",
      profileText:'',
      listComments: [],
      selectedComment: 0,
      anchorViewReport:null,
      viewMoreReportId:"",
      fileInput:null,
      fileContent:"",
      showReportToast:false,
      employeeReportList:[],
      pageBreak:"\n \n ------------------------------- Page Break ----------------------------- \n \n",
      isReportAction:false,
      reportStatusMessage:configJSON.successfullyPublishedText,
      newCommentPosition: {top: 0, left: 0},
      showCommentBubble: false,
      havePermissionComment: false,
      report:''
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.cmtRef = React.createRef()
    this.divRef = React.createRef()

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token });
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id
    ) {
      this.apiGetDataCallId = null;

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      this.apiGetData(apiRequestCallId,responseJson);
      if (apiRequestCallId === this.getListCommentId) {
        this.handleListCommentData(responseJson)
        this.setState({isAddNewComment: false, newComment: ""})
      } 
      if (apiRequestCallId === this.createCommentCallId) {
        this.handleCreateCommentResponse()
        return;
      }
      if (apiRequestCallId === this.checkPermissionId) {
        this.setState({havePermissionComment: responseJson.message?.toLowerCase() === "permission granted"});
      }
      if (apiRequestCallId === this.getEmployeeReportCallId) {
        this.handleEmployeeReportData(responseJson)
      }
      else {
        console.log('responseJson =', responseJson);
      }
    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.getUserInfo();
    this.getLatestReport();
    if (this.props.navigation.getParam("id")) {
      this.setState({ editReportId: this.props.navigation.getParam("id") }, () => {
        this.getListComments(this.state.editReportId)
      })
    }
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  // Customizable Area Start
  statusMapping: { [key: string]: string } ={
    "Published":'report',
    "Waiting":'draft'
  };

  handleEmployeeReportData = (responseJson: any) => {
    const { report_data } = responseJson;
    const transformedReport = report_data.map((item:any) => {
      if (item.report_data && item.report_data.data && item.report_data.data.type==='employee_report') { 
          const reportData= item.report_data.data.attributes;  
          return {...item.report_data.data,...item,attributes:{...reportData,user_name:reportData.name}};
      } else if (item.report_data.data.type==='subordinate') {
          const rpoData=item.report_data.data.attributes
          return {...item,...item.report_data.data,attributes:{...rpoData,...rpoData.data,user_name:rpoData.data.name}};
      }
      return null;
  }).filter((item: any) => item !== null);

    if(report_data) this.setState({ employeeReportList: transformedReport?.filter((eachData: any) => eachData.attributes.report !== null)  || []});
  }

  handleCreateCommentResponse = () => {
    const commentableId = this.state.editReportId !== "" ? this.state.editReportId : this.state.reportId
    this.getListComments(commentableId);
  }

  goBack = () => {
    this.props.navigation.goBack()
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if(this.state.editReportId !== prevState.editReportId) {
      this.getLatestReport();
      this.checkManagerPermission(this.state.editReportId);
  }
}
  handleSelectComment = (id: number) => {
    this.setState({showCommentBubble: true}, () => {
      this.setState({selectedComment: id})
    })
  }

  handleShowCommentBubble = () => {
    this.setState({showCommentBubble: !this.state.showCommentBubble, isAddNewComment: false})
  }
  
  handleFormatTime = (timeInSec: number) => {
    const sec_num = Math.floor(timeInSec);
    const day = Math.floor(sec_num / 86400);
    const hour = Math.floor((sec_num - day * 86400) / 3600);
    const min = Math.floor((sec_num - day * 86400 - hour * 3600) / 60);
    const sec = sec_num % 60;

    if (sec_num < 60) {
      return `${sec} ${sec === 1 ? 'second' : 'seconds'}`;
    } else if (sec_num < 3600) {
      return `${min} ${min === 1 ? 'minute' : 'minutes'}`;
    } else if (sec_num < 86400) {
      return `${hour} ${hour === 1 ? 'hour' : 'hours'} and ${min} ${min === 1 ? 'minute' : 'minutes'}`;
    } else {
      return `${day} ${day === 1 ? 'day' : 'days'}`;
    }
  }; 

  handleChangeNewComment = (value: string) => {
    this.setState({newComment: value})
  }

  createComment = async ()=>{
    const body = new FormData();
    const commentableId = this.state.editReportId === "" ? this.state.reportId : this.state.editReportId
    body.append("comment[commentable_type]","BxBlockReport::Report");
    body.append("comment[commentable_id]", commentableId);
    body.append("comment[comment]",this.state.newComment);
    body.append("comment[coordX]", this.state.newCommentPosition.left);
    body.append("comment[coordY]", this.state.newCommentPosition.top);
    this.createCommentCallId = await this.apiCall({
      method: "POST",
      endPoint: `${configJSON.createCommentsApiEndPoint}`,
      body,
    });
  };

  apiGetData=(apiRequestCallId:string,responseJson:any)=>{
    if (apiRequestCallId === this.createEmployeeReportCallId) {
      if(this.state.reportStatus==='Published'){this.handlePublished(true);this.actionModalClose()}
      this.getLatestReport();
    } else if (apiRequestCallId === this.getLatestReportCallId) {
      const {data}=responseJson;
      const reportKey = this.statusMapping[data?.attributes?.status];
      const current = new Date();  
      if(data?.id){
      this.getListComments(data.id)
      this.checkManagerPermission(data.id)
      const lastChange = moment.utc(data.attributes?.last_change).local().toDate()
      const duration = (current.valueOf() - lastChange.valueOf())/1000;
      this.setState({
        textInputValue: data.attributes[reportKey],
        reportSaveTime:data.attributes,
        reportAutoSaveTime: new Date(),
        lastChange: this.handleFormatTime(duration),
        reportId: data.id,
        checkButtonStatus: data.attributes[reportKey]?.length <= 0,
        report:data.attributes.report,
      });
      }
    } else if (apiRequestCallId  === this.deleteReportCallId) {
      this.handlePublished(false);
      this.handleCancel(false);
      this.setState({ textInputValue: ''});
      
    }else if(apiRequestCallId === this.userCallID){
      this.handleUserInfoResponse(responseJson)
    }else if(apiRequestCallId === this.updateEmployeeReportCallId){
      const {errors}=responseJson;
      this.getLatestReport();
      if(!errors && this.state.reportStatus==='Published'){this.handlePublished(true);this.actionModalClose();}
         }
  }

  handleUserInfoResponse = (responseJson: any) => {
    const { data } = responseJson;
    const { attributes } = data;
    if (attributes.data.role === "Manager") {
      this.getEmployeeReport();
      this.setState({ selectedTab: 0})
    } else {
      this.setState({ selectedTab: 1, havePermissionComment: true})
    }
    this.setState({
      userInfo: { ...attributes, ...attributes.data },
      profileText: (attributes.profile_text || '').toUpperCase(),
      userName: attributes.data.name,
      userProfile: attributes.image ? attributes.image.url : ""
    });
  }

 createOrUpdateReport = async (keepboth?: boolean) => {
    let latestDataText = null;
    const body = new FormData();
    const {editReportId, reportStatus, textInputValue, userInfo,reportId} = this.state

    if(textInputValue.includes(this.state.pageBreak)) {
      latestDataText = textInputValue.replace(/-+ Page Break -+/g,"");
    }
    body.append("report[status]", reportStatus);
    body.append("report[report]", latestDataText === null ? textInputValue : latestDataText);
    const edReportId = editReportId !== "" ? editReportId : reportId;
    if (!edReportId) body.append("report[name]", userInfo.name);
    const method = edReportId && !keepboth ? 'PUT' : configJSON.postApiMethodType;
    const endPoint = edReportId && !keepboth? `bx_block_report/reports/${edReportId}` : configJSON.useBlockReportApiEndPoint;
    this.createEmployeeReportCallId = await this.apiCall({
      method,
      endPoint,
      body,
    });
  }; 

  checkManagerPermission =  async (reportId: any) => {
    this.checkPermissionId = await this.apiCall({
      contentType: configJSON.jsonApiContentType,
      method: configJSON.getApiMethodType,
      endPoint: `bx_block_comments/comments/check_access?report_id=${reportId}`, 
    });
  };

  getLatestReport = async () => {
    this.getLatestReportCallId = await this.apiCall({
      contentType: configJSON.jsonApiContentType,
      method: configJSON.getApiMethodType,
      endPoint: !isEmpty(this.state.editReportId)  ?
        `${configJSON.useBlockReportApiEndPoint}/${this.state.editReportId}` :  configJSON.latestReportApiEndPoint,
    });
  };

  getUserInfo = async () => {
    this.userCallID = await this.apiCall({
      contentType: configJSON.jsonApiContentType,
      method: configJSON.getApiMethodType,
      endPoint: configJSON.getUserApiEndPoint,
    });
  };

  getEmployeeReport = async () => {
    this.getEmployeeReportCallId = await this.apiCall({
      contentType: configJSON.jsonApiContentType,
      method: configJSON.getApiMethodType,
      endPoint: configJSON.getEmployeeReport,
    });
    console.log('getEmployeeReportCallId id1 =>', this.getEmployeeReportCallId);
  };

  deleteReport = async () => {
    this.deleteReportCallId = await this.apiCall({
      contentType: configJSON.jsonApiContentType,
      method: configJSON.deleteApiMethodType,
      endPoint: `${configJSON.useBlockReportApiEndPoint}/${this.state.reportId}`,
    });
  };

  updateReport = async () => {
    const body = new FormData();
    const {textInputValue,reportStatus, userInfo,reportId} = this.state
    body.append("report[report]", textInputValue);
    body.append("report[name]", userInfo.name);
    body.append("report[status]", reportStatus);
    const method ='PUT';
    const endPoint = `bx_block_report/reports/${reportId}`;
    this.updateEmployeeReportCallId = await this.apiCall({
      method,
      endPoint,
      body,
    });
  };

  createReport= ()=>{
  const {editReportId,report} = this.state
  if(isEmpty(report)|| editReportId){
    this.setState({reportStatusMessage:configJSON.successfullyPublishedText,reportStatus: 'Published', showPublishStatus:true }, () => {
      this.createOrUpdateReport();
    });
  } else{ this.setState({isReportAction:true})}
  };

  getListComments = async (id: string) => {
    this.getListCommentId = await this.apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_comments/comments?commentable_id=${id}&type=Home`,
    });
  }

  apiCall = async (data: any) => {
    const { contentType, method, endPoint, body } = data;
    const token = await getStorageData("token");
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    const header = {
      "Content-Type": contentType,
      token: token,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  handleTabChange = (newValue: number) => {
    const showCommentBubble = this.state.userInfo?.data?.role === "Manager" && newValue===1;
    this.setState({ selectedTab: newValue, isAddNewComment: false, showCommentBubble });
  };

  handleCloseAddComment = () => {
    this.setState({isAddNewComment: false})
  }
  handleSearch = (searchText: string) => {
    const current = new Date();
    const lastChange = Math.abs(current.valueOf() - this.state.reportAutoSaveTime.valueOf()) / 1000;
    this.setState({
      reportStatus: 'Waiting',
      textInputValue: searchText,
      lastChange: this.handleFormatTime(lastChange)
    }, () => {
      this.state.reportId? this.updateReport():
      this.createOrUpdateReport();
    })

  };

  handleShowCommentInput = (event: any) => {
    const divRect = event.target.getBoundingClientRect();
    const scrollX = event.target.scrollLeft;
    const scrollY = event.target.scrollTop;
    const test = {
      left: event.clientX - divRect.left + scrollX,
      top: event.clientY - divRect.top + scrollY,
    };
    if(this.state.havePermissionComment) this.setState({newCommentPosition: {...test}, isAddNewComment: true})
  }

  debouncedSearch = this.debounce(this.handleSearch, 900);

  debounce(func: any, delay: number) {
    let timeout: string | number | NodeJS.Timeout | undefined;
    return function (...args: any) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func(...args);
      }, delay);
    };
  }

  handleInputChange = (searchText: string) => {
    // Call the debounced function when the user stops typing
    this.debouncedSearch(searchText);
  };

  handleImportAll = () => {
    const {employeeReportList} = this.state;
    const empReport=employeeReportList.filter((item:any)=>item.type ==='employee_report' && item.can_edit);
    const remaningReport=employeeReportList.filter((item:any)=>item.type !=='employee_report' || !item.can_edit);
    if(empReport.length <=0 ){
      return;
    }
    const val = "";
    const allReport  = empReport.length > 0 && empReport.map((eachData:any)=>{
    const eachInfo=eachData.attributes;
    const inputDate = moment(eachInfo.date, 'D MMMM YYYY');
    const formattedDate = inputDate.format('DD/MM/YYYY');
    const time=eachInfo.time.substring("Time:".length)
    const jobTitle=jobTitleMapping[eachInfo.user_job_title]||""
    return val +`${eachInfo.user_name},${jobTitle}` +`\n${formattedDate} ${time}\n \n`+ eachData.attributes.report
   })
   const textReader = allReport.join(this.state.pageBreak);
   this.setState((prevState) => ({
    textInputValue: prevState.textInputValue !== "" ? prevState.textInputValue   +this.state.pageBreak+ textReader:textReader,
  }),()=>this.setInputValue(this.state.textInputValue));
   this.setState({employeeReportList:remaningReport, checkButtonStatus: textReader.length > 0 ? false : true});
  };

  handleMouseEnter = (index: number) => {
    this.setState({
      isHovering: { [index]: true },
    });
  };

  handleOpenModal = () => {
    this.setState({ openModal: true, anchorViewReport: null });
  };

  handleViewReport = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, employeeData:any) => {
        this.setState({anchorViewReport: event.currentTarget, viewMoreReportId: employeeData.id, detailReport:{
      id: employeeData.id, user_name: employeeData.attributes.user_name, date: employeeData.attributes.date, report: employeeData.attributes.report, image: employeeData.attributes.image && employeeData.attributes.image.url
      , time: employeeData.attributes.time, lastChange: "",
      name: employeeData.attributes.name,
      department: employeeData.attributes.department,
      user_job_title:employeeData.attributes.user_job_title
    } })
    this.handleOpenModal();
  }

  handleCloseModal = () => {
    this.setState({ openModal: false, viewMoreReportId: "",detailReport:{
      id: "-1",
      name: "",
      date: "",
      time: "",
      report: "",
      user_name: "",
      department: "",
      image: "",
      lastChange: "",
      user_job_title: ""
    } });
  };
  
  handleMouseLeave = (index: number) => {
    this.setState({
      isHovering: { [index]: false },
    });
  };

  handleDragStart = (index:number) =>{
    this.setState({draggedItemIndex: index});
  }

  handleDragOver = (event: any) => {
    event.preventDefault();
  }

  handleDrop = (event:any) => {
    event.preventDefault();
    let updatedText;
    const draggedIndex:any = this.state.draggedItemIndex;
    const draggedText = this.state.employeeReportList[this.state.draggedItemIndex].attributes.report;
    const draggedInfo = this.state.employeeReportList[this.state.draggedItemIndex].attributes
    const id = this.state.employeeReportList[this.state.draggedItemIndex].id
    const updatedData = [...this.state.employeeReportList];
    updatedData.splice(draggedIndex, 1);
    this.getListComments(id)
    this.checkManagerPermission(id)
    this.setState({employeeReportList:updatedData});
    this.setState((prevState) => ({
      draggedItem: prevState.draggedItem + draggedText !==null ? draggedText : "" + " ",
    }));
    const inputDate = moment(draggedInfo.date, 'D MMMM YYYY');
    const formattedDate = inputDate.format('DD/MM/YYYY');
    const time=draggedInfo.time.substring("Time:".length);
    const jobTitle=jobTitleMapping[draggedInfo.user_job_title]||'';
    if (this.state.textInputValue !== "") {
      updatedText = this.state.textInputValue + this.state.pageBreak + `${draggedInfo.user_name},${jobTitle}`+`\n${formattedDate} ${time}\n \n`+ draggedText;
    } else if (draggedText == null) {
      updatedText = " ";
    } else {
      updatedText = `${draggedInfo.user_name},${jobTitle}`+`\n${formattedDate} ${time}\n \n`+ draggedText;
    }
    this.setInputValue(updatedText)
    this.setState({textInputValue:updatedText, checkButtonStatus: updatedText.length > 0 ? false : true});
  }
  // web events
  setInputValue = (text: string) => {
    this.setState({ textInputValue: text });
    this.setState({ checkButtonStatus: text.length > 0 ? false : true });
    this.handleInputChange(text);
  };
  handlePublished = (isCheck: boolean) => {
    this.setState({ isPublished: isCheck });
  };
  handleCancel = (cancel: boolean) => {
    this.setState({
      isCancel: cancel,
    });
  };
  handleModalClose = () => {
    this.handlePublished(false);
    this.handleCancel(false);
  };

  handleShowTest = () => {
    this.setState({showReportToast: true});
    setTimeout(()=>{
      this.setState({showReportToast: false});
    },3000)
  }

  setClearReportValue = () => {
    this.setState({ textInputValue: "" });
    this.setState({ reportStatus: "Waiting" });
  };  

  handleModalAction = () => {
    if (this.state.isCancel) {
      this.setState({textInputValue: "", reportStatus: "Waiting"}, () => {
         this.handleInputChange("")
         this.handleModalClose()
         this.handleShowTest() 
         this.getUserInfo() 
      })
    } else {
      this.handlePublished(false);
    }
  };
  
  handleModalOpenAction = () => {
    this.handlePublished(true);
    this.handleCancel(true);
  };

  handleTabSelected = (tab:number) => {
    this.setState({selectedTab:tab,  isDrower: true,reportGrid:6,chartGrid:5 });
  }

  handleDrower = () => {
    this.setState(
      (prevState) => ({
        isDrower: !prevState.isDrower,
        selectedTab: prevState.isDrower? 0:1,
        isAddNewComment: false, 
        reportGrid: prevState.isDrower ? 11 : 6,
        chartGrid: prevState.isDrower ? 0 : 5,
        })
    );
  };

  actionModalClose = () =>{
    this.setState({isReportAction:false})
  };

  replaceReport = () => {
  this.setState({reportStatusMessage:configJSON.replaceStatusMessageText, reportStatus: 'Published', showPublishStatus:true }, () => {
    this.updateReport();
  });
  };

  keepBothReport = () => {
    this.setState({ reportStatusMessage:configJSON.keepStatusMessageText,reportStatus: 'Published', showPublishStatus:true }, () => {
      this.createOrUpdateReport(true);
    });
  };
  
  handleListCommentData = (response: any) => {
    if(response.data) {
      response.data.forEach((item: any )=> {
        const {attributes} = item
        const current = new Date();
        const lastChange = moment.utc(attributes.data?.created_at).local().toDate()
        const duration = (current.valueOf() - lastChange.valueOf())/1000;
        item.lastChange = this.handleFormatTime(duration)
      });
      this.setState({listComments: [...response.data] })
    }else{
      this.setState({listComments:[]})
    }
  }
  
  checkReportType =(item:any)=>{
    return item.type==='employee_report'&& item.can_edit?true:false
  }

  checkReportShow=(type:any)=>{
    return type==='employee_report'?"flex":'none'
  }
 // Customizable Area End
}
