const jobTitleMapping:{ [key: string]: string }={
    VP_Administration:"VP, Administration",
    VP_Linehaul:"VP, Linehaul",
    VP_Operations:"VP, Operations",
    "VP_Sales_and_Client_Services":"VP, Sales and Client Services",
    SCM_SFO:"SCM, SFO",
    SCM_ONT:"SCM, ONT",
    SCM_EWR:"SCM, EWR",
    SCM_DAL:"SCM, DAL",
    SCM_HTX:"SCM, HTX",
    SCM_ATM:"SCM, ATM",
    SCM_LAX:"SCM, LAX",
    SCM_LRD:"SCM, LRD",
    Director_Pricing:"Director, Pricing",
    'Director_Agent_Operations':"Director, Agent Operations",
    Director_Operations:"Director, Operations",
    Director_Sales:"Director, Sales",
    'Director_Cross_Border_Business':"Director, Cross Border Business",
    'Director_Application_Development':"Director, Application Development",
    Director_Infrastructure:"Director, Infrastructure",
    "Director_Human_Resources":"Director, Human Resources",
    "Client_Services_Manager":"Client Services Manager",
    Safety_Manager:"Safety Manager",
    "Chief_Technology_Officer":"Chief Technology Officer",
    "Chief_Information_Officer":"Chief Information Officer",
    "Chief_Financial_Officer":"Chief Financial Officer",
    Executive_Officer:"Executive Officer",
    Business_Analyst_Controller:'Business Analyst Controller',
    Sr_Network_Engineer:'Sr. Network Engineer',
    Sr_Systems_Engineer:'Sr. Systems Engineer',
    Project_Manager:'Project Manager',
    Application_Developer:'Application Developer',
    Sr_Applications_Developer:'Sr. Applications Developer',
    Sr_Systems_Administrator:'Sr. Systems Administrator',
    Systems_Administrator:'Systems Administrator',
    Technical_Architect:'Technical Architect',
    Employee:'Employee',
    Supervisor:'Supervisor',
    Manager:'Manager',
    Vice_President:'Vice President'
}

export default jobTitleMapping