import React from "react";
// Customizable Area Start
import {
  Container,
  Card,
  CardContent,
  Button,
  Dialog,
  DialogContent,
  Typography,
  AppBar,
  Toolbar,
  Grid,
  Table,
  TableBody,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  Paper,
  Avatar,
  Menu, 
  MenuItem,
  Divider
} from "@material-ui/core";
import Sidebar from "../../../components/src/Sidebar.web";
import EmployeeReportController, {
  Props, configJSON,
} from "./EmployeeReportController.web";
import {icDoc, icEdit2, icMore, icMoreWhite, icTrash, icTick} from "./assets";
import Automaticreminders from "../../automaticreminders/src/Automaticreminders.web";
import { Pagination } from "@material-ui/lab";
import ReportPreview from "./ReportPreview.web";
import moment from "moment";
const drawerWidth = 280;
// Customizable Area End

export default class EmployeeReport extends EmployeeReportController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

ExtractedDialogContent = (detailReport:any, handleCloseModalDelete:any, deleteReport:any ) => {
  return (
    <Grid container spacing={2} style={{ marginTop: 30, padding: "0 40px 10px 40px" }}>
      <p style={{ textAlign: "center" }}>
        Are you sure you want to delete report of "{detailReport?.name}" dated {moment(detailReport.lastChange).format("Do MMMM, h.mma")}?
      </p>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 20,
          marginBottom: 35,
          width: "100%"
        }}
      >
        <Button
          id="btn-close-delete"
          type="submit"
          variant="contained"
          color="primary"
          style={{
            width: 177,
            height: 56,
            borderRadius: 8,
            background: "transparent",
            border: "1px solid #979797",
            color: "#979797",
            boxShadow: "none",
            textTransform: "none",
            fontSize: 20
          }}
          onClick={handleCloseModalDelete}
        >
          <b>No</b>
        </Button>
        <Button
          id="btn-delete-report"
          type="submit"
          variant="contained"
          color="primary"
          style={{
            width: 177,
            height: 56,
            borderRadius: 8,
            backgroundColor: "#014A80",
            textTransform: "none",
            boxShadow: "none",
            fontSize: 20
          }}
          onClick={deleteReport}
        >
          <b>Yes</b>
        </Button>
      </div>
    </Grid>
  );
};

SuccessModalContent = ( onClose :any) => (
  <Grid container spacing={2} style={{ marginTop: 20, padding: "0 40px 10px 40px" }}>
    <img src={icTick} style={{ margin: "auto" }} />
    <p style={{ textAlign: "center", width: "100%", fontFamily: "Lato", fontSize: 20, lineHeight: "24px" }}>
      You've successfully deleted <br /> the report.
    </p>
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: 15,
        marginBottom: 35,
        width: "100%"
      }}
    >
      <Button
        id="btn-close-success"
        type="submit"
        variant="contained"
        color="primary"
        style={{
          width: 177,
          height: 56,
          borderRadius: 8,
          backgroundColor: "#014A80",
          textTransform: "none",
          boxShadow: "none",
          fontSize: 20
        }}
        onClick={onClose}
      >
        <b>Okay</b>
      </Button>
    </div>
  </Grid>
);

renderPagination = (item:any) => {
  return item.type === 'page' ?
            <div 
              id="report-pagination"
              onClick={() => this.onChangePage(item.page)}
              style={item.page===this.state.page ? styles.activePaginationItems : styles.paginationItems}>
              {item.page}
            </div>
            :
        <div style={styles.paginationItems}>...</div>
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    const isAdd=this.state.detailReport.status==='Published'?true:false
    return (
      //Required for all blocks
      <div>
        {/* Sidebar */}
        <Sidebar />
        {/* Sidebar  end*/}

        <main style={styles.content}>
           {/* header */}
           <AppBar style={styles.appBar}>
            <Toolbar>
              <Grid
                data-test-id="header"
                container
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                style={styles.mainGrid}
              >
                <Grid item>
                  <Typography 
                  style={styles.typographyHeading as React.CSSProperties}>
                    <b>{configJSON.employeeReportHeadingText}</b>
                  </Typography>
                </Grid>
                <Grid style={{
                  display: "flex",
                  marginRight: "10px"
                }}>

                  <Grid
                    item
                    style={{ display: "flex" }}
                  >
                    <div
                      style={styles.avatarDiv}>
                      <div>
                        <Avatar
                          variant="square"
                          src={this.state.image}
                          style={{ width: 44, height: 45 }}
                        >
                          {this.state.currentUser?.profile_text}
                        </Avatar>

                      </div>

                      <div style={{ marginLeft: 20 }}>
                        <p
                          style={styles.userNameDiv as React.CSSProperties}
                        >
                          {this.state.userName}
                        </p>
                      </div>
                    </div>
                    <Automaticreminders
                      navigation={this.props.navigation}
                      id=""
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          {/* header end */}
          <Container className="flex" style={{ marginTop: 80, borderRadius: "15px",minWidth:'100%' }}>
            <Card>
              <CardContent style={{ padding: 0 }}>
                  {this.state.error? 
                  <p style={{textAlign: 'center'}}>{this.state.error}</p> 
                  :
                  <TableContainer component={Paper}>
                    <Table style={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead style={{background: "#F4F9FD"}}>
                        <TableRow>
                          <TableCell>Serial no</TableCell>
                          <TableCell align="center">Name</TableCell>
                          <TableCell align="center">Department</TableCell>
                          <TableCell align="center">Status</TableCell>
                          <TableCell align="center">Date</TableCell>
                          <TableCell align="center">Time</TableCell>
                          <TableCell align="center">Action</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {this.state.employeeReportData.map((report, index) => (
                          <TableRow key={index.toString()}>
                            <TableCell align="center" style={{color:"#979797"}}>
                              {report.data.attributes.serial_number||report.serial_number}
                            </TableCell>
                            <TableCell align="center" style={{color:"#979797"}}>
                              {report.data.attributes.name}
                            </TableCell>
                            <TableCell align="center" style={{color:"#979797"}}>
                              {report.data.attributes.department?.replace(/_/g, ' ')}
                            </TableCell>
                            <TableCell align="center">
                              <Button
                                style={{
                                  width: 109,
                                  height: 35,
                                  borderRadius: 8,
                                  backgroundColor:report.data.attributes.status==='Published'? "#14B823":"#CCC",
                                  borderWidth: 0,
                                  borderColor: "rgba(151, 151, 151, 1.0)",
                                  textTransform: "none",
                                  color:'#fff',
                                  padding:'10px 16px',
                                }}
                              >
                                {report.data.attributes.status}
                              </Button>
                            </TableCell>
                            <TableCell align="center" style={{color:"#979797"}}>
                              {report.data.attributes.date}
                            </TableCell>
                            <TableCell align="center" style={{color:"#979797"}} >
                              {report.data.attributes.time || "N/A"}
                            </TableCell> 
                            <TableCell align="center">
                              <Button
                                style={{
                                  width: 82,
                                  height: 36,
                                  borderRadius: 8,
                                  backgroundColor: "#F4F4F4",
                                  textTransform: "none",
                                }}
                                data-test-id="button-viewmore-employee"
                                onClick={() => this.handleViewReport(report.data.id)} > 
                                View
                              </Button>
                          </TableCell>
                          
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <Pagination
                    test-data-id="report-paginations"
                    count={Math.ceil(this.state.totalReport/this.state.pageSize)}
                    page={this.state.page}
                    style={styles.paginations}
                    hideNextButton
                    hidePrevButton
                    boundaryCount={2}
                    siblingCount={1}
                    renderItem={(item) => this.renderPagination(item)}
                  />
                  </TableContainer>}
              </CardContent>
            </Card>
          </Container>
          <Dialog
          id="dialog-delete-employeeReport"
          open={this.state.openModalDelete}
          onClose={this.handleCloseModalDelete}
          PaperProps={{
            style: { width: 501, margin: "auto", borderRadius: 8 },
          }}
          >
          <DialogContent>
            {/* Add your content for the Modal here */}
          {this.ExtractedDialogContent(this.state.detailReport,this.handleCloseModalDelete,this.deleteReport)} 
          </DialogContent>
        </Dialog>
            
        <Dialog
          id="dialog-delete-employeeReport"
          open={this.state.openSuccessModal}
          onClose={this.handleCloseSuccessModal}
          PaperProps={{
            style: { width: 501, margin: "auto", borderRadius: 8 },
          }}
        >
          <DialogContent>
            {/* Add your content for the Modal here */}
            {this.SuccessModalContent(this.handleCloseSuccessModal)}
          </DialogContent>
        </Dialog>
          <ReportPreview
          navigation={""} 
          id={this.state.viewMoreReportId} 
          openModal={this.state.openModal} 
          handleCloseModal={this.handleCloseModal}
          lastChange={this.state.lastChange}
          detailReport={{...this.state.detailReport,report:this.state.detailReport.draft||this.state.detailReport.report}} 
          isCommentAdd={isAdd}
          isHistory={false}
          userImage={this.state.image}
        />
        </main>
      </div>
    );
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
}
// Customizable Area Start
const styles = {
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  appBar: {
    border: 0,
    boxShadow: "none",
    backgroundColor: "#CCE9FF",
    // marginLeft: drawerWidth,
    // width: `calc(100% - ${drawerWidth}px)`,
  },
  content: {
    backgroundColor: "#CCE9FF",
    flexGrow: 1,
    padding: "20px",
    marginLeft: drawerWidth,
    height: "100vh",
  },
  paginations: {
    display: 'flex',
    justifyContent: 'center',
    margin: "2rem 0",
    cursor: "pointer"
  }, 
  activePaginationItems: {
    backgroundColor: '#026AB7',
    padding: '0.25rem 0.65rem',
    width: "max-content",
    borderRadius: '2rem',
    color: "#FFFFFF",
    fontFamily: "Lato",
    fontWeight: 400, 
    lineHeight: '22px',
    fontSize: 14,
    margin: '0 0.5rem'
  },
  paginationItems: {
    padding: '0.25rem 0.65rem',
    width: "max-content",
    borderRadius: '2rem',
    color: "#CCCCCC",
    fontFamily: "Lato",
    fontWeight: 400, 
    lineHeight: '22px',
    fontSize: 14,
    margin: '0 0.5rem'
  },
  mainGrid:{ 
    margin: "12px 0px 20px" 
  },
  typographyHeading:{ 
    marginLeft: 290, 
    color: "#026AB7",
    fontFamily: 'Lato',
    fontWeight: 800,
    fontSize: '32px'
  },
  avatarDiv:{
    minWidth: 170,
    height: 56,
    display: "flex",
    alignItems: "center",
    borderRadius: 8,
    backgroundColor: "white",
    padding: '0 8px',
    marginRight:'10px'
  },
  userNameDiv:{
    fontFamily: "Lato",
    fontSize: 16,
    fontWeight: 400,
    fontStyle: "normal",
    textAlign: "right",
    color: "#64748B",
  },
  viewMoreOption : {
    padding: "15px 20px",
    fontFamily: "Lato",
    fontSize: 16
  },
  viewMoreIcon: {
    marginRight: 10
  }

};
// Customizable Area End
