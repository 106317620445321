import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import React from "react";
import moment from "moment";
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface User {
  name: string;
  department: string;
  role: string;
  image: string;
  profile_text: string;
}

export interface DetailReport {
  id: string;
  name: string;
  date: string;
  time: string;
  report: string;
  user_name: string;
  department: string;
  image: string;
  lastChange: string;
  draft: string;
  status:string;
  user_job_title:string;
}
// Customizable Area End
export interface Props {
  navigation: any;
  id: string;

  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  openModal: boolean;
  employeeReportData: any[];
  page: number;
  pageSize: number;
  currentUser: User ;
  detailReport: DetailReport;
  totalReport: number;
  error: string;
  lastChange: string;
  anchorViewMore: HTMLElement | null;
  openModalDelete: boolean;
  viewMoreReportId: string;
  openSuccessModal: boolean;
  image: string;
  userName: string;
  havePermissionComment: boolean;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class EmployeeReportController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getEmployeeReportCallId: string = "";
  getDetailReportId: string = "";
  getUserInfoId: string = "";
  deleteReportId: string = "";
  checkPermissionId: string = "";
  interval: ReturnType<typeof setInterval> = setInterval(() => {});

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      employeeReportData: [],
      openModal: false,
      page: 1,
      pageSize: 8,
      currentUser: {
        role:'',
        name:'',
        department:'',
        image:'',
        profile_text:'',
      },
      detailReport: {
        id: "-1",
        name: "",
        date: "",
        time: "",
        report: "",
        user_name: "",
        department: "",
        image: "",
        lastChange: "",
        draft: "",
        status:'',
        user_job_title:''
      },
      totalReport: 0,
      error: "",
      lastChange: "",
      anchorViewMore: null,
      openModalDelete: false,
      viewMoreReportId: "",
      openSuccessModal: false,
      havePermissionComment: false,
      image: "",
      userName: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getUserInformation();
  }

  gotoEdit = () => {
    this.props.navigation.navigate("EditReport", {
      id: this.state.viewMoreReportId,
    });
  };

  handleViewMore = (event: React.MouseEvent<HTMLButtonElement>, id: string) => {
    this.setState({
      anchorViewMore: event.currentTarget,
      viewMoreReportId: id,
      openModal: false,
    });
  };

  handleCloseViewMore = () => {
    this.setState({
      anchorViewMore: null,
      viewMoreReportId: "",
      openModal: false,
    });
  };

  onChangePage = (page: number) => {
    this.setState({ page });
    this.getEmployeeReport(page);
  };

  handleOpenModalDelete = () => {
    this.setState({ openModalDelete: true, anchorViewMore: null });
    this.getDetailReport(this.state.viewMoreReportId);
  };
  handleCloseModalDelete = () => {
    this.setState({ openModalDelete: false, viewMoreReportId: "" });
  };
  handleOpenSuccessModal = () => {
    this.setState({ openSuccessModal: true });
  };
  handleCloseSuccessModal = () => {
    this.setState({ openSuccessModal: false });
  };

  handleOpenModal = () => {
    this.setState({ openModal: true, anchorViewMore: null });
  };

  handleCloseModal = () => {
    this.setState({ openModal: false, viewMoreReportId: "",detailReport:{
      id: "",
      name: "",
      date: "",
      time: "",
      report: "",
      user_name: "",
      department: "",
      image: "",
      lastChange: "",
      draft: "",
      status: "",
      user_job_title:""
    } });
    clearInterval(this.interval);
  };

  handleFormatTime = (timeInSec: number) => {
    const sec_num = Math.floor(timeInSec);
    const day = Math.floor(sec_num / 86400);
    const hour = Math.floor((sec_num - day * 86400) / 3600);
    const min = Math.floor((sec_num - day * 86400 - hour * 3600) / 60);

    if (sec_num >= 60 && sec_num < 3600) {
      return `${min} ${min > 1 ? "minutes" : "minute"}`;
    }
    if (sec_num === 3600) {
      return "1 hour";
    }
    if (sec_num > 3600 && sec_num < 86400) {
      return `${hour} ${hour > 1 ? "hours" : "hour"} and ${min} ${
        min > 1 ? "minutes" : "minute"
      }`;
    }
    if (sec_num >= 86400) {
      return `${day} ${day > 1 ? "days" : "day"}`;
    }
    return "few minutes";
  };

  handleUpdateLastChange = () => {
    const current = new Date();
    const lastChange = moment
      .utc(this.state.detailReport.lastChange)
      .local()
      .toDate();
    const duration = (current.valueOf() - lastChange.valueOf()) / 1000;
    this.setState({
      lastChange: this.handleFormatTime(duration),
    });
  };

  viewDetailReport = () => {
    const id = this.state.viewMoreReportId;
    this.handleOpenModal();
    this.getDetailReport(id);
  };

  deleteReport = async () => {
    const id = this.state.viewMoreReportId;
    this.deleteReportId = await this.apiCall({
      contentType: configJSON.jsonApiContentType,
      method: configJSON.deleteApiMethodType,
      endPoint: `bx_block_report/reports/${id}`,
    });
  };

  getUserInformation = async () => {
    this.getUserInfoId = await this.apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_user/users/current_user_info`,
    });
  };

  getDetailReport = async (id: number | string) => {
    this.getDetailReportId = await this.apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_report/reports/${id}`,
    });
  };

  getHistoryReport= async(page: number)=>{
    this.getEmployeeReportCallId = await this.apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint:`bx_block_report/reports/?page=${page}&type=History`,
    });
  }

  getReport=async(page: number)=>{
    const urlMapping: { [key: string]: string } ={
      'Manager':`bx_block_user/users/user_report/?page=${page}`,
      'Employee':`bx_block_report/reports/?page=${page}&type=Other`
    }
    const role=this.state.currentUser.role
    this.getEmployeeReportCallId = await this.apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint:urlMapping[role],
    });
  }

  getEmployeeReport = async (page: number) => {
    const type = window.location.pathname;
    const reportMapping: { [key: string]: any } ={
      "/history":this.getHistoryReport,
      "/employeereport":this.getReport
    }
    const fun=reportMapping[type]  
    await fun(page)  
  };

  apiCall = async (data: any) => {
    const token = await getStorageData("token");
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  handleViewReport = (id: string) => {
    this.setState({ viewMoreReportId: id });
    this.handleOpenModal();
    this.getDetailReport(id);
  };

  handleUserInfoResponse = (responseJson: any) => {
    const { attributes } = responseJson.data;
    if (attributes.data.role === "Manager") {
      // will add this later
      // this.checkCommentPermission();
    } else {
      this.setState({ havePermissionComment: true });
    }
    this.setState({
      currentUser: {
        ...attributes,
        ...attributes.data,
        image: attributes.image?.url,
      },
      image: attributes.image?.url,
      userName: attributes.data.name,
    },()=>this.getEmployeeReport(this.state.page));
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson.status === 500) {
        this.props.navigation.navigate("EmailAccountLogin");
      }

      if (apiRequestCallId === this.getEmployeeReportCallId) {
        if (responseJson.reports) {
          this.setState({
            employeeReportData: responseJson.reports.filter((item:any)=>item.data!==null),
            totalReport: responseJson.total_count,
          });
        } else {
          this.setState({ error: responseJson.message });
        }
      } else if (apiRequestCallId === this.getUserInfoId) {
        this.handleUserInfoResponse(responseJson);
      } else if (apiRequestCallId === this.getDetailReportId) {
        const res = responseJson.data;
        const { attributes } = res;
        const current = new Date();
        const lastChange = moment.utc(attributes.last_change).local().toDate();
        const duration = (current.valueOf() - lastChange.valueOf()) / 1000;
        this.setState({
          detailReport: {
            id: res.id,
            name: attributes.name,
            date: attributes.date,
            time: attributes.time,
            report: attributes.report,
            department: attributes.department,
            user_name: attributes.user_name,
            image: attributes.image?.url,
            lastChange: attributes.last_change,
            draft: attributes.draft,
            status:attributes.status,
            user_job_title:attributes.user_job_title,
          },
          lastChange: this.handleFormatTime(duration),
        });
        this.interval = setInterval(this.handleUpdateLastChange, 60000);
      } else if (apiRequestCallId === this.deleteReportId) {
        this.handleCloseModalDelete();
        this.handleOpenSuccessModal();
        this.getEmployeeReport(1);
      } else {
        console.log(errorReponse);
      }
    }
    // Customizable Area End
  }
}
