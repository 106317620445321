import React from "react";
import {
  Grid,
  TextField,
  Button,
  Link,
  InputAdornment,
  IconButton,
  Checkbox
} from "@material-ui/core";
import { ImageBackground } from "react-native";
import { Visibility, VisibilityOff } from "@material-ui/icons";

// Customizable Area End

import EmailAccountLoginControlle, {
  Props,
  configJSON
} from "./EmailAccountLoginController.web";

export default class EmailAccountLogin extends EmailAccountLoginControlle {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    return (
      <div style={{ background: "#fff" }}>
        <Grid container spacing={0}>
          <Grid item xs={6}>
            <ImageBackground
              source={require("../../global_assets/image_daylight.png")}
              style={{ width: 685, height: 850 }}
            />
          </Grid>
          {!this.state.isForgot ? (
            <Grid
              item
              container
              direction="row"
              md={4}
              style={{ marginLeft: "155px", marginTop: "130px" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginLeft: 70
                }}
              >
                <ImageBackground
                  source={require("../../global_assets/logo.png")}
                  style={{ width: 211, height: 107 }}
                />
                <h3 style={{ textAlign: "center" }}>
                  <b>Welcome!</b>
                </h3>
                <h4
                  style={{
                    textAlign: "center",
                    marginTop: 20,
                    color: "#026AB7"
                  }}
                >
                  <b>Log In</b>
                </h4>
              </div>

              <div>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <div>
                      {this.state.showMessage && (
                        <div style={styles.errorShow}>
                          {this.state.inActiveMessage}
                        </div>
                      )}
                    </div>
                    <label
                      style={{
                        fontSize: 14,
                        fontWeight: 500,
                        fontStyle: "normal",
                        color: "#595959"
                      }}
                    >
                      {configJSON.emailText}
                    </label>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 20,
                        maxWidth: 400,
                        marginTop: 10
                      }}
                    >
                      <TextField
                        data-testid={"usernameInputTestID"}
                        type="text"
                        name="username"
                        variant="outlined"
                        placeholder={configJSON.emailPlaceholderText}
                        value={this.state.username}
                        error={!!this.state.errors.username}
                        style={{
                          backgroundColor: this.state.errors.username
                            ? "#FFE7E7"
                            : "#F3F5F7"
                        }}
                        onChange={e => this.handleOnChange(e)}
                        fullWidth
                        required
                      />
                      {this.state.showMessage && this.state.errors.username && (
                        <div style={styles.errorShow}>
                          <ImageBackground
                            source={require("../../global_assets/alert_icon.png")}
                            style={{
                              width: 20,
                              height: 20,
                              marginRight: "5px"
                            }}
                          />
                          {this.state.errors.username}
                        </div>
                      )}
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <label
                      style={{
                        fontSize: 14,
                        fontWeight: 500,
                        fontStyle: "normal",
                        color: "#595959"
                      }}
                    >
                      Password
                    </label>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 20,
                        maxWidth: 400,
                        marginTop: 10
                      }}
                    >
                      <TextField
                        data-testid={"passwordInputTestID"}
                        type={this.state.showPassword ? "text" : "password"}
                        name="password"
                        variant="outlined"
                        style={{
                          backgroundColor: this.state.errors.password
                            ? "#FFE7E7"
                            : "#F3F5F7"
                        }}
                        value={this.state.password}
                        placeholder={configJSON.passWordPlaceholderText}
                        error={!!this.state.errors.password}
                        onChange={e => this.handleOnChange(e)}
                        fullWidth
                        required
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                data-testid={"passwordShowButtonTestID"}
                                onClick={() =>
                                  this.setState({
                                    showPassword: !this.state.showPassword
                                  })
                                }
                                edge="end"
                              >
                                {this.state.showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                      {this.state.showMessage && this.state.errors.password && (
                        <div style={styles.errorShow}>
                          <ImageBackground
                            source={require("../../global_assets/alert_icon.png")}
                            style={{
                              width: 20,
                              height: 20,
                              marginRight: "5px"
                            }}
                          />
                          {this.state.errors.password}
                        </div>
                      )}
                      <div
                        onClick={this.handleForgot}
                        data-testid={"forgotTestID"}
                        style={{
                          textAlign: "right",
                          fontFamily: "Lato",
                          fontSize: 14,
                          fontWeight: 400,
                          fontStyle: "normal",
                          color: "#44AFFF",
                          cursor: "pointer",
                          textDecoration: "underline"
                        }}
                      >
                        Forgot Password?
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={12} style={styles.gridItem}>
                    <label
                      style={{
                        color: "#979797",
                        display: "flex",
                        alignItems: "center",
                        fontFamily: "Lato",
                        fontSize: 14,
                        fontWeight: 400,
                        fontStyle: "normal"
                      }}
                    >
                      <Checkbox
                        data-testid={"checkboxTestID"}
                        checked={this.state.isChecked}
                        onChange={this.handleCheckboxChange}
                        name="agree"
                        style={{ color: this.state.textColor }}
                      />
                      <span style={{ marginLeft: "8px" }}>
                        I agree to the{" "}
                        <Link
                          href="/TermsConditions"
                          style={{
                            fontFamily: "Lato",
                            fontSize: 14,
                            fontWeight: 400,
                            fontStyle: "normal",
                            color: "#014A80"
                          }}
                        >
                          <b>terms & conditions</b>
                        </Link>
                      </span>
                    </label>
                  </Grid>
                  <Grid item xs={12} style={styles.gridItem}>
                    <label
                      style={{
                        color: "#979797",
                        display: "flex",
                        alignItems: "center",
                        fontFamily: "Lato",
                        fontSize: 14,
                        fontWeight: 400,
                        fontStyle: "normal"
                      }}
                    >
                      <Checkbox
                        data-testid={"rememberBoxTestID"}
                        checked={this.state.isRemember}
                        onChange={this.handleCheckRemember}
                        name="checkRemember"
                        style={{ color: this.state.isRemember ? "green" : "" }}
                      />
                      <span style={{ marginLeft: "8px" }}>
                        {configJSON.remindTest}
                      </span>
                    </label>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      data-testid={"loginButtonTestID"}
                      onClick={this.handleSubmit}
                      variant="contained"
                      color="primary"
                      fullWidth
                      style={{
                        width: 400,
                        height: 60,
                        backgroundColor: "#014A80",
                        textTransform: "none",
                        fontSize: "18px"
                      }}
                    >
                      {" "}
                      <b>Log in</b>
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          ) : (
            <Grid
              item
              container
              direction="row"
              md={4}
              style={{ margin: "auto" }}
            >
              <div style={{ textAlign: "center" }}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <ImageBackground
                    source={require("../../global_assets/forgotimage.png")}
                    style={{ width: 48, height: 48 }}
                  />
                </div>
                <div style={styles.forgotHead}>{configJSON.forgotText}</div>
                <div style={styles.forgotDes}>{configJSON.forgotNoteText}</div>
                <Button
                  data-testid={"backButtonTestID"}
                  onClick={this.handleForgot}
                  variant="contained"
                  color="primary"
                  fullWidth
                  style={{
                    width: 400,
                    height: 60,
                    backgroundColor: "#014A80",
                    textTransform: "none",
                    fontSize: "18px"
                  }}
                >
                  <b>{configJSON.backButtonText}</b>
                </Button>
              </div>
            </Grid>
          )}
        </Grid>
      </div>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
const styles = {
  title: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center"
  },
  logo: {
    width: 30,
    height: 30
  },
  searchInput: {
    backgroundColor: "white"
  },
  searchButton: {
    marginRight: 0
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    backgroundColor: "#CCE9FF",
    padding: 10,
    paddingRight: 25
  },
  Container: {
    backgroundColor: "#CCE9FF"
  },
  label: {
    color: "#979797"
  },
  progressBar: {
    marginBottom: 10
  },
  forgotHead: {
    color: "#014A80",
    fontFamily: "Lato",
    fontSize: "20px",
    fontWeight: 700,
    marginTop: "20px"
  },
  forgotDes: {
    color: "#979797",
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: 400,
    margin: "15px 0 30px"
  },
  errorShow: {
    color: "var(--red, #DB2323)",
    fontFamily: "Lato",
    fontSize: "12px",
    fontWeight: 400,
    marginTop: "-15px",
    display: "flex",
    alignItems: "center"
  },
  gridItem: {
    paddingTop: 0,
    paddingBottom: 0
  }
};
// Customizable Area End
