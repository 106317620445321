import React from "react";
// Customizable Area Start
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import OrganizationChartController, { Props } from "./OrganizationChartController.web";
import {
    Grid, TextField, Avatar
} from "@material-ui/core";
import { icBubble, icEditArrow, icEye } from "./assets";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
export const StyledTextField = styled(TextField)({
    "&.MuiOutlinedInput-root": {
        "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#CBD5E1"
        }
    }
})
const theme = createTheme({
    overrides: {
        MuiOutlinedInput: {
            notchedOutline: {
                border: "none"
            }
        },
    }
});
// Customizable Area End

export default class OrganizationChart extends OrganizationChartController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    orgChart = {
        name: 'CEO',
        role: "Admin",
        image: "https://insider30-356361-ruby.b356361.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBallDIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--6397cb16e71194cb79ff9e6f2f6c626b639473c2/img.jpeg",
    }
    itradData = (childData: any) => {
        return <li>{this.labelBox(childData)}
            {childData.employees.length > 0 && this.child(childData?.employees)}
        </li>
    }

    child = (data: any) => {
        if (data.length > 0) {
            return <ul>{data.map((item: any) => this.itradData(item))}</ul>
        }
    }

    borderColorMapping: { [key: string]: string } = {
        'Admin': "4px solid var(--Primary_900, #014A80)",
        'Employee': "4px solid var(--Green, #14B823)",
        'Manager': "4px solid var(--Status-Amber-400, #F59E0B)"
    }

    iconMapping: { [key: string]: string } = {
        'can_edit': icEditArrow,
        'can_view': icEye
    }

    labelBox = (data: any) => {
        const id = data?.user?.id;
        return <div style={{ ...styles.mainNode, borderBottom: this.borderColorMapping[data?.user?.role] }}>
            <div style={styles.parentNode}>
                <div style={{ display: 'flex' }}>
                    <img width={42} height={42} src={data.user.image_url} alt="" />
                    <div style={{ marginLeft: '10px', textAlign: 'initial' }}> <div style={styles.name}>{data?.user?.name}</div> <div style={styles.role}> {data.user.role}</div></div>
                </div>
                <div><div style={styles.accesDiv}>
                    <img src={this.iconMapping[data.user.access.permission]} alt="" /></div> <div style={{ cursor: 'pointer' }} data-test-id="drop-down" onClick={() => this.handleDropDown(id)}>{this.state.isOpenClose[id] ? <ExpandLessIcon /> : <ExpandMoreIcon />}</div></div>
            </div>
            {this.state.isOpenClose[id] && <div style={styles.infoBox as React.CSSProperties}>
                <div style={styles.border} />
                <div style={styles.infoDiv}>User ID : {data.user.user_name}</div>
                <div style={styles.infoDiv}>Email ID : {data.user.email}</div>
                <div style={styles.infoDiv}>Department : {data.user.department?.replace(/_/g, ' ')}</div>
                <div style={styles.infoDiv}>Relationship : {data.user.access.permission?.replace(/_/g, ' ')}</div>
            </div>}
        </div>
    }

    checkListState = () => {
        if (this.state.isListLoading) {
            return <div>Loading...</div>
        }
        return <div>You have no record yet!</div>
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <style>

                    {`

            * {
                padding: 0px;
                margin: 0px;
                box-sizing: border-box;
            }
           .tree {
            width: max-content;
            height: auto;
            text-align: center;
            margin:auto;
        }
        .tree ul {
            padding-top: 20px;
            position: relative;
            transition: .5s;
            display:flex;
        }
        .tree li {
            display: inline-table;
            text-align: center;
            list-style-type: none;
            position: relative;
            padding: 10px;
            transition: .5s;
        }
        .tree li::before, .tree li::after {
            content: '';
            position: absolute;
            top: 0;
            right: 50%;
            border-top: 1px solid #ccc;
            width: 51%;
            height: 10px;
        }
        .tree li::after {
            right: auto;
            left: 50%;
            border-left: 1px solid #ccc;
        }
        .tree li:only-child::after, .tree li:only-child::before {
            display: none;
        }
        .tree li:only-child {
            padding-top: 0;
        }
        .tree li:first-child::before, .tree li:last-child::after {
            border: 0 none;
        }
        .tree li:last-child::before {
            border-right: 1px solid #ccc;
            border-radius: 0 5px 0 0;
            -webkit-border-radius: 0 5px 0 0;
            -moz-border-radius: 0 5px 0 0;
        }
        .tree li:first-child::after {
            border-radius: 5px 0 0 0;
            -webkit-border-radius: 5px 0 0 0;
            -moz-border-radius: 5px 0 0 0;
        }
        .tree ul ul::before {
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            border-left: 1px solid #ccc;
            width: 0;
            height: 20px;
        }
        .tree li a {
            border: 1px solid #ccc;
            padding: 10px;
            display: inline-grid;
            border-radius: 5px;
            text-decoration-line: none;
            border-radius: 5px;
            transition: .5s;
        }

        ul.child {
            display: flex;
            flex-direction: column;
            margin-left: 175px;
        }
        
        .child li::after {
            left: 0%;
            border-right: 1px solid #ccc;
            border-left: none;
        }
        
        .tree  ul .child::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0%;
            border-left: 1px solid #ccc;
            width: 0;
            height: 20px;
        }
        
        .tree .child li::after {
            border-radius: 3px;
        }
        
        .tree .child li {
            border-left: 1px solid #ccc;
            display: inline-table;
            text-align: center;
            list-style-type: none;
            position: relative;
            padding: 10px;
            transition: .5s;
        }
        
        .tree .child li:last-child {
            border-left: none;
        }
          `}

                </style>
                <Grid container style={{ marginTop: '28px' }} >
                    <Grid item sm={8} style={{ overflow: 'scroll', padding: '10px', marginTop: '10px' }}>

                        {Object.keys(this.state.orgData).length > 0 ? <div className="row"> <div className='tree'>
                            <ul>
                                <li> {this.labelBox(this.state?.orgData)}
                                    {this.child(this.state?.employeesData)}
                                </li>
                            </ul>
                        </div> </div> : null}
                    </Grid>
                    <Grid item sm={4} style={{ padding: '0px 16px', boxShadow: '0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)' }}>
                        <StyledTextField
                            data-test-id="input-search-employee"
                            type="text"
                            name="search-emp"
                            variant="outlined"
                            value={this.state.searchValue}
                            onChange={this.handleSearchValue}
                            style={styles.searchBar}
                            InputProps={{
                                style: styles.SearchInputProps, startAdornment: (
                                    <img style={{ objectFit: "cover", marginRight: 5, width: 24, height: 24 }} src={icBubble} />
                                )
                            }}
                            placeholder="Search Employee"
                            fullWidth
                        />
                        <div onScroll={this.loadMoreUser} data-testid='scrollId' id="list-emp" style={{
                            marginTop: '10px', height: '65vh', maxHeight: '100vh', overflowY: 'scroll', scrollbarWidth: "thin",
                            scrollbarColor: "#44AFFF #F3F5F7"
                        }}>
                            {this.state.userList.length > 0 ? this.state.userList?.map((item: any) => {
                                return <div
                                    key={item.id}
                                    data-test-id="select-user"
                                    onClick={() => this.showSelectUser(item)}
                                    style={{...styles.userDiv,background:item.id===this.state.selectUserId?'rgb(216, 233, 250) none repeat scroll 0% 0%':''}}
                                >
                                    <Avatar
                                        src={item.url}
                                        style={{ width: 38, height: 38 }}
                                    />
                                    <div style={{ textAlign: 'left', paddingLeft: '10px' }}>
                                        <div style={styles.label}><span >Name :</span><span>{item.Name}</span></div>
                                        <div style={styles.label}><span >Department :</span><span>{item.Department?.replace(/_/g, ' ')}</span></div>
                                    </div>
                                </div>
                            }) : this.checkListState()}
                        </div>
                    </Grid>
                </Grid>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const styles = {
    parentNode: {
        display: 'flex',
        padding: '8px 0px 8px 8px',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '230px',
        boxShadow: ' 0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)',
        background:'#fff'
    },
    border: {
        border: '1px solid #ccc',
        height: '0px',
        margin: '5px 0px',
        boxShadow: '0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)',
    },
    mainNode: {
        boxShadow: '0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)',
        borderRadius: '8px',
        display: 'inline-block',
        borderBottom: "4px solid var(--Primary_900, #014A80)",
        width: '230px',
        background:'#fff'
    },

    childNode: {
        boxShadow: '0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)',
        borderRadius: '8px',
        display: 'flex',
        width: '200px',
        padding: '8px 0px 8px 8px',
        justifyContent: 'center',
        alignItems: 'center',
        background:'#fff'
    },
    name: {
        fontFamily: 'Lato',
        fontSize: '16px',
        color: 'var(--Black_700, #3C3C3C)'
    },
    role: {
        fontFamily: 'Lato',
        fontSize: '14px',
        color: 'var(--Neutrals-Cool-gray-500, #64748B)'
    },
    searchBar: {
        backgroundColor: "#F1F5F9",
        borderRadius: 8,
        margin: "10px auto",
        width: "100%",
    },
    SearchInputProps: {
        borderRadius: 8,
        fontSize: 16,
        border: "1px solid #CBD5E1"
    },
    label: {
        color: '#595959',
        fontFamily: 'Lato',
        fontSize: '16px',
        fontWeight: 400,
    },
    infoBox: {
        textAlign: 'initial', color: '#64748B', padding: '5px',
        boxShadow: '0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)',
    },
    infoDiv: {
        marginBottom: '5px',
        fontFamily: 'Lato',
        fontSize: '13px',
    },
    accesDiv: {
        width: '30px', height: '24px', borderRadius: '26px 0px 0px 26px',
        background: 'var(--Primary_700, #026AB7)'
    },
    userDiv: {
        display: 'flex', cursor: 'pointer',
        padding: '14px',
        boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.08)',
        borderRadius: '8px', margin: '5px 0px'
    }


};
// Customizable Area End
