import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  AppBar,
  Grid,
  Toolbar,
  Avatar,
  Card,
  CardContent,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Menu,
  MenuItem,
  Divider,
  FormControl,
  Select,
  TextField,
  DialogContent,
  Dialog
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { Pagination } from "@material-ui/lab";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import Adminlist from "./AdminList.web";
type FieldTypeKey = 'true' | 'false';

const drawerWidth = 280;
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import Adminconsole2Controller, {
  Props,
  configJSON,
} from "./Adminconsole2Controller";
import Sidebar from "../../../components/src/Sidebar.web";
import { icEdit, icMore, icMoreWhite, icTrash, imgCamera, imgSearch, imgSuccess } from "./assets";

export default class Adminconsole2 extends Adminconsole2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderLoading = (isLoading: boolean) => {
    if (isLoading) {
      return <div> <p style={{ textAlign: "center" }}>Loading...</p></div>
    }
  }
  renderPassword = () => {
    return <Container className="flex" style={{ paddingTop: 10 ,maxWidth:'100%'}}>
      <Card style={{ marginTop: 90, borderRadius: 8, padding: 10 }}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div>
            <div style={{display: 'flex',justifyContent: 'center'}}>
              <Avatar
                src={this.state.editImage}
                style={{
                  ...webStyle.showAvatar,
                  backgroundColor: this.state.editImage
                    ? ""
                    : "#026AB7"
                }}
              >
                {this.state.profileText}
              </Avatar>
            </div>
            <div style={webStyle.nameHeading as React.CSSProperties}>{this.state.name}</div>
            <div>
              <div style={webStyle.password}>
                <label style={webStyle.passwordLable}>
                  {configJSON.oldPasswordText}
                </label>
                <div style={webStyle.box as React.CSSProperties}
                >
                  <TextField
                    data-test-id="oldPasswordInputTestID"
                    name="oldPassword"
                    placeholder={configJSON.passWordPlaceholderText}
                    type={
                      this.fieldType[this.state.showOldPassword.toString() as FieldTypeKey]
                    }
                    value={this.state.oldPassword}
                    error={!!this.state.errors.oldPassword}
                    onChange={(e: any) => this.handleChange(e)}
                    variant="outlined"
                    style={webStyle.inputBox}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          data-test-id="showOldPasswordShowButtonTestID"
                        >
                          <IconButton
                            onClick={() =>
                              this.setState({
                                showOldPassword: !this.state
                                  .showOldPassword
                              })
                            }
                            edge="end"
                          >
                            {this.state.showOldPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </div>
                <div style={webStyle.error}>{this.state.errors.oldPassword}</div>
              </div>
              <div style={webStyle.password}>
                <label style={webStyle.passwordLable}>
                  {configJSON.createNewPasswordText}
                </label>
                <div style={webStyle.box as React.CSSProperties}
                >
                  <TextField
                    data-test-id="createNewPasswordInputTestID"
                    name="createNewPassword"
                    placeholder={configJSON.passWordPlaceholderText}
                    type={
                      this.fieldType[this.state.showNewPassword.toString() as FieldTypeKey]
                    }
                    value={this.state.newPassword}
                    error={!!this.state.errors.newPassword}
                    onChange={(e: any) => this.handleChange(e)}
                    variant="outlined"
                    style={webStyle.inputBox}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          data-test-id="showCreateNewPasswordShowButtonTestID"
                        >
                          <IconButton
                            onClick={() =>
                              this.setState({
                                showNewPassword: !this.state
                                  .showNewPassword
                              })
                            }
                            edge="end"
                          >
                            {this.state.showNewPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </div>
                <div style={webStyle.error}>{this.state.errors.newPassword}</div>
              </div>
              <div style={webStyle.password}>
                <label style={webStyle.passwordLable}>
                  {configJSON.confirmPasswordText}
                </label>
                <div style={webStyle.box as React.CSSProperties}
                >
                  <TextField
                    data-test-id="confirmPasswordInputTestID"
                    name="confirmPassword"
                    placeholder={configJSON.passWordPlaceholderText}
                    type={
                      this.fieldType[this.state.showConfirmPassword.toString() as FieldTypeKey]
                    }
                    value={this.state.confirmPassword}
                    error={!!this.state.errors.confirmPassword}
                    onChange={(e: any) => this.handleChange(e)}
                    variant="outlined"
                    style={webStyle.inputBox}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          data-test-id="showConfirmPasswordShowButtonTestID"
                        >
                          <IconButton
                            onClick={() =>
                              this.setState({
                                showConfirmPassword: !this.state
                                  .showConfirmPassword
                              })
                            }
                            edge="end"
                          >
                            {this.state.showConfirmPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </div>
                <div style={webStyle.error}>{this.state.errors.confirmPassword}</div>
              </div>
              <div style={{...webStyle.error,marginTop:'10px'}}>{this.state.passWordError}</div>
              <div style={webStyle.password}>
                <Button
                  data-test-id="changePasswordButtonTestID"
                  onClick={this.handleChangePassword}
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{
                    backgroundColor: "#014A80",
                    textTransform: "none",
                    height: '64px',
                    fontWeight: 700,
                    fontSize: '18px',
                    width: 400,
                  }}
                >
                  {configJSON.changePasswordButtonText}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Card>
      <Dialog
        data-test-id="dialog-password-change"
        open={this.state.passwordChangeSuccessfully}
        onClose={this.handleClosePasswordModal}
        PaperProps={{
          style: { width: 501, margin: "auto", borderRadius: 8 },
        }}
      >
        <DialogContent>
          <Grid container spacing={2} style={{ textAlign: "center", marginTop: 30, padding: "0 40px 10px 40px" }}>
            <div style={{ margin: 'auto' }}><img src={imgSuccess} alt="success" /></div>
            <p style={{ textAlign: "center" }}>
              {configJSON.changePasswordMessageTest1}
              <b>{this.state.selectUser.Name}</b>
              {configJSON.changePasswordMessageTest2}
            </p>
            <Button
              data-test-id="btn-successfully-password-change"
              type="submit"
              variant="contained"
              color="primary"
              style={{
                width: '100%',
                height: 56,
                borderRadius: 8,
                backgroundColor: "#014A80",
                textTransform: "none",
                boxShadow: "none",
                fontSize: 20,
                margin: '15px 0'
              }}
              onClick={this.passwordSuccessfullyChange}
            >
              <b>{configJSON.adminButtonText}</b>
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    </Container>
  }
  renderEdit = () => {
    return <Container className="flex"  style={{ paddingTop: 10,maxWidth:'100%' }}>
      <Card style={{ marginTop: 90, borderRadius: 8, padding: 10}}>
        <div>
          <Grid container style={{ marginTop: 50,overflow:'auto' }}>
            <Grid
              container
              item
              sm={12} xs={12} lg={6}  xl={6}
              style={{
                marginBottom: 30,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div>
                <div style={webStyle.profile as React.CSSProperties}>
                  {configJSON.profilePictureText}
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Avatar
                    src={this.state.editImage}
                    style={{
                      ...webStyle.showAvatar,
                      backgroundColor: this.state.editImage
                        ? ""
                        : "#026AB7"
                    }}
                  >
                    {this.state.userDetails.profile_text}
                  </Avatar>
                </div>
                <div>
                  <label htmlFor="fileInput">
                    <Button
                      variant="outlined"
                      startIcon={<img src={imgCamera} alt="" width={24} height={24} />}
                      component="span"
                      style={{
                        width: "100%",
                        marginTop: 25,
                        textTransform: "none",
                        height: "56px",
                        color: "#979797",
                        fontSize: "16px"
                      }}
                    >
                      {configJSON.uploadPhoto}
                      <input
                        data-test-id="edit-upload-image"
                        type="file"
                        accept=".jpg,.png,.jpeg"
                        onChange={this.handleUpload}
                        style={{ display: "none" }}
                        id="fileInput"
                        name="profileImage"
                      />
                    </Button>
                  </label>
                </div>
                <div>
                  <Button
                    variant="outlined"
                    style={{
                      backgroundColor: "#F9DB45",
                      marginTop: 15,
                      height: "56px",
                      width: "300px",
                      fontWeight: 700,
                      textTransform: "none",
                    }}
                    data-test-id="password-changes-id"
                    onClick={this.changePassword}
                  >
                    {configJSON.changePasswordButtonText}
                  </Button>
                </div>
              </div>
            </Grid>
            <Grid item  container sm={12} xs={12} lg={6}  xl={6}style={{ marginBottom: 30,justifyContent: "center",
              alignItems: "center" }}>
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 18,
                    maxWidth: 470,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <label style={webStyle.label}>
                      {configJSON.nameText}
                    </label>
                    <div
                      style={webStyle.box as React.CSSProperties}
                    >
                      <TextField
                        data-test-id="nameTestID"
                        name="name"
                        value={this.state.name}
                        variant="outlined"
                        style={webStyle.inputBox}
                        onChange={this.handleChange}
                        error={!!this.state.errors.name}
                      />
                    </div>
                    <div style={webStyle.error}>{this.state.errors.name}</div>
                  </div>
                  <div>
                    <label style={webStyle.label}>
                      {configJSON.emailText}
                    </label>
                    <div
                      style={webStyle.box as React.CSSProperties}
                    >
                      <TextField
                        name="email"
                        data-test-id="emailTestID"
                        value={this.state.email}
                        variant="outlined"
                        style={webStyle.inputBox}
                        onChange={this.handleChange}
                        error={!!this.state.errors.email}
                      />
                    </div>
                    <div style={webStyle.error}>{this.state.errors.email}</div>
                  </div>
                  <div>
                    <label style={webStyle.label}>
                      {configJSON.passWordPlaceholderText}
                    </label>
                    <div style={webStyle.box as React.CSSProperties}
                    >
                      <TextField
                        data-test-id="currentPasswordInputTestID"
                        name="currentPassword"
                        placeholder={configJSON.passWordPlaceholderText}
                        type={
                          this.fieldType[this.state.showConfirmPassword.toString() as FieldTypeKey]
                        }
                        value={this.state.currentPassword}
                        onChange={(e: any) => this.handleChange(e)}
                        variant="outlined"
                        disabled
                        style={webStyle.inputBox}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              data-test-id="showCurrentPasswordShowButtonTestID"
                            >
                              <IconButton
                                onClick={() =>
                                  this.setState({
                                    showConfirmPassword: !this.state
                                      .showConfirmPassword
                                  })
                                }
                                edge="end"
                              >
                                {this.state.showConfirmPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    </div>
                  </div>
                  <div>
                    <FormControl variant="outlined" fullWidth>
                      <label style={webStyle.label}>{configJSON.departmentText}</label>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 20,
                          maxWidth: 500,
                        }}
                      >
                        <Select
                          native
                          onChange={(e: any) => { this.handleChange(e) }}
                          inputProps={{
                            name: 'department',
                            id: 'outlined-simple',
                          }}
                          data-test-id="departmentdInputTestID"
                          name="department"
                          style={webStyle.inputBox}
                          value={this.state.department}
                          error={!!this.state.errors.department}

                        >
                          <option aria-label="None" value="" style={{ color: 'rgb(174 176 177)' }}>
                            {configJSON.departmentPlaceholderText}
                          </option>
                          <option value={configJSON.departmentOptionText1}>{configJSON.departmentOptionText1}</option>
                          <option value='Customer_Service'>{configJSON.departmentOptionText2}</option>
                          <option value={configJSON.departmentOptionText3}>{configJSON.departmentOptionText3}</option>
                          <option value={configJSON.departmentOptionText4}>{configJSON.departmentOptionText4}</option>
                          <option value={configJSON.departmentOptionText5}>{configJSON.departmentOptionText5}</option>
                          <option value={configJSON.departmentOptionText6}>{configJSON.departmentOptionText6}</option>
                          <option value={configJSON.departmentOptionText7}>{configJSON.departmentOptionText7}</option>
                          <option value='Human_Resources'>{configJSON.departmentOptionText8}</option>
                          <option value='Information_Technology'>{configJSON.departmentOptionText9}</option>
                          <option value={configJSON.departmentOptionText10}>{configJSON.departmentOptionText10}</option>
                        </Select>
                      </div>
                      <div style={webStyle.error}>{this.state.errors.department}</div>
                    </FormControl>
                  </div>
                  <div>
                    <FormControl variant="outlined" fullWidth>
                      <label style={webStyle.label}>{configJSON.roleText}</label>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 20,
                          maxWidth: 500,
                        }}
                      >
                        <Select
                          native
                          onChange={(e: any) => { this.handleChange(e) }}
                          label="Role"
                          inputProps={{
                            name: 'role',
                            id: 'outlined-simple',
                          }}
                          data-test-id="roleInputTestID"
                          name="role"
                          style={webStyle.inputBox}
                          value={this.state.role}
                          error={!!this.state.errors.role}
                        >
                          <option aria-label="None" value="" >
                            {configJSON.rolePlaceholderText}
                          </option>
                          <option value={configJSON.employeeText}>{configJSON.employeeText}</option>
                          <option value={configJSON.managerText}>{configJSON.managerText}</option>
                        </Select>
                      </div>
                      <div style={webStyle.error}>{this.state.errors.role}</div>
                    </FormControl>
                  </div>
                  <div>
                    <FormControl variant="outlined" fullWidth>
                      <label style={{ marginRight: "8px" }}>{configJSON.jobTitleText}</label>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 20,
                          maxWidth: 400,
                        }}
                      >
                        <Select
                          native
                          value={this.state.job}
                          onChange={(e: any) => { this.handleChange(e) }}
                          inputProps={{
                            name: 'job',
                            id: 'outlined-simple',
                          }}
                          data-test-id="jobInputTestID"
                          name="job"
                          error={!!this.state.errors.job}
                          style={webStyle.inputBox}
                        >
                          <option aria-label="None" value="" >
                            {configJSON.jobPlaceholder}
                          </option>
                          {this.jobTitle.map((item) => <option key={item.value} value={item.value}>{item.key}</option>)}
                        </Select>
                      </div>
                    </FormControl>
                  </div>
                  <Button
                    data-test-id="CreateAccountButtonTestID"
                    onClick={this.handleSubmit}
                    disabled={this.state.isSave}
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{
                      backgroundColor:this.state.isSave?"#979797":"#014A80",
                      textTransform: "none",
                      height: '64px',
                      fontWeight: 700,
                      fontSize: '18px',
                      width: 400,
                      color:this.state.isSave?'#fff':'',
                    }}
                  >
                    {configJSON.saveUpdateButtonText}
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </Card>
      <Dialog
        data-test-id="dialog-successfully-update"
        open={this.state.isSuccessfullyUpdate}
        onClose={this.handleCloseModal}
        PaperProps={{
          style: { width: 501, margin: "auto", borderRadius: 8 },
        }}
      >
        <DialogContent>
          <Grid container spacing={2} style={{ textAlign: "center", marginTop: 30, padding: "0 40px 10px 40px" }}>
            <div style={{ margin: 'auto',width:'100%' }}><img src={imgSuccess} alt="success" /></div>
            <p style={{ textAlign: "center",width:'100%' }}>
              {configJSON.successfullyUpdateText}
              <b>{this.state.selectUser.Name}</b>
            </p>
            <Button
              data-test-id="btn-successfully-update"
              type="submit"
              variant="contained"
              color="primary"
              style={{
                width: '100%',
                height: 56,
                borderRadius: 8,
                backgroundColor: "#014A80",
                textTransform: "none",
                boxShadow: "none",
                fontSize: 20,
                margin: '15px 0'
              }}
              onClick={this.successfullyUpdate}
            >
              <b>{configJSON.adminButtonText}</b>
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    </Container>
  }

  componentMapping: { [key in 'true' | 'false']: any } = {
    'false': this.renderEdit,
    'true': this.renderPassword
  }

  renderComponent = () => {
    const component=this.componentMapping[this.state.isEditPassword as unknown  as FieldTypeKey]
    return component()
  }
   
  renderAdminList=()=>{
    return <Adminlist navigation={undefined} id={""}/>
  }
  compMapping: { [key in 'true' | 'false']: any } = {
    'false': this.renderComponent,
    'true': this.renderAdminList,
  }
 
  renderCheckComponent =()=>{
    const component=this.compMapping[this.state.isAdminList as unknown  as FieldTypeKey]
    return component()
  }

  renderHeaderHeading = () =>{
    if(this.state.isAdminList){
      return <b style={webStyle.heading}>
          <div  style={{display:'flex',cursor:'pointer'}}  onClick={this.handleAdminList}>
          <ArrowLeftIcon style={{fontSize: 46}}/>{configJSON.adminListTest}</div>
          </b>
    }

    return <b style={webStyle.heading}>
      {this.state.isEdit?<div  data-test-id="backButton" style={{display:'flex',cursor:'pointer'}}  onClick={this.goBack}>
        <ArrowLeftIcon style={{fontSize: 46}}/>{configJSON.headingEditText}</div>:configJSON.headingText}
    </b>
  }


  renderTableBody =()=>{
    return this.state.userList.map((item: any) => {
      return (
        <TableRow key={item.id}>
          <TableCell align="center">
            <Avatar src={item.url} style={{ width: 43, height: 43 }} />
          </TableCell>
          <TableCell align="center" style={webStyle.tableBody}>
            {item.Name}
          </TableCell>
          <TableCell align="center" style={webStyle.tableBody}>
            {item.Department?.replace(/_/g, ' ')}
          </TableCell>
          <TableCell align="center" style={webStyle.tableBody}>
            {item.role}
          </TableCell>
          <TableCell align="center" style={webStyle.tableBody}>
            {item.email}
          </TableCell>
          <TableCell align="center" style={webStyle.tableBody}>
            {item.Total_no_of_employees || 0}
          </TableCell>
          <TableCell align="center" style={webStyle.tableBody}>
            <FormControl>
              <Select
                labelId="select-label"
                data-test-id="roleSelect"
                id="select"
                value={item.permission||'can_view'}
                onChange={() => this.changeRole(item)}
                IconComponent={ExpandMoreIcon}
                input={
                  <Input
                    id="select-label"
                    style={{
                      border: '1px solid #CCC',
                      borderRadius: '5px',
                      padding: '5px 9px',
                      color: '#CCC'
                    }}
                  />
                }
              >
                <MenuItem value='can_view'>{configJSON.viewText}</MenuItem>
                <MenuItem value="can_edit">{configJSON.canEditTeXt}</MenuItem>
              </Select>
            </FormControl>
          </TableCell>
          <TableCell align="center">
            {item.id === this.state.viewMoreEmployeeId ?
              <Button>
                <span style={{ background: "#026AB7", borderRadius: 100 }}>
                  <img id="ic_more"
                    src={icMoreWhite}
                    alt="view more" />
                </span>
              </Button> :
              <Button data-test-id="buttonViewMore" style={{ background: "transparent" }}
                disableRipple
                onClick={(event) => this.handleView(event, item)}
              >
                <img id="ic_more"
                  src={icMore}
                  alt="view more" />
              </Button>
            }
          </TableCell>
          <Menu
            anchorEl={this.state.menuViewMore}
            keepMounted
            open={Boolean(this.state.menuViewMore)}
            data-test-id="menuViewMore"
            onClose={this.handleCloseMenu}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            TransitionProps={{ style: { width: 211, marginTop: 5, borderRadius: 8, padding: 0 } }}
          >
            <MenuItem onClick={this.handleEditUser} data-test-id="optionEdit" style={webStyle.viewMoreOption}>
              <img src={icEdit} style={webStyle.viewMoreIcon} /> {configJSON.editReportText}
            </MenuItem>
            <Divider style={{ width: "95%", margin: "3px auto" }} />
            <MenuItem onClick={this.handleDeleteModal}
              data-test-id="optionDelete" style={{ ...webStyle.viewMoreOption, color: "#DB2323" }}>
              <img src={icTrash} style={webStyle.viewMoreIcon} /> {configJSON.deleteReportText}
            </MenuItem>
          </Menu>
        </TableRow>
      )
    })
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Sidebar />
        <AppBar style={webStyle.appBar}>
          <Toolbar>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              style={{ margin: "12px 0px 20px" }}
            >
              <Grid item>
                <Typography style={{ marginLeft: 280, color: "#014A80" }}>
                  {this.renderHeaderHeading()}
                </Typography>
              </Grid>
              <Grid
                item
                style={{
                  minWidth: 170,
                  height: 56,
                  display: "flex",
                  alignItems: "center",
                  borderRadius: 8,
                  backgroundColor: "white",
                }}
              >
                <div>
                  <Avatar
                    variant="square"
                    src={this.state.currentUser?.image}
                    style={{ width: 44, height: 45 }}
                  >
                    {(this.state.currentUser?.profile_text || '').toUpperCase()}
                  </Avatar>
                </div>
                <div style={{ marginLeft: 20 }}>
                  <p
                    style={{
                      fontFamily: "Lato",
                      fontSize: 16,
                      fontWeight: 400,
                      fontStyle: "normal",
                      textAlign: "right",
                      color: "#64748B",
                    }}
                  >
                    {this.state.currentUser?.name}
                  </p>
                </div>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <main style={webStyle.content}>
          {!this.state.isEdit && !this.state.isAdminList ? <Container className="flex"  style={{ paddingTop: 10 ,maxWidth:'100%'}}>
            <Card style={{ marginTop: 90, borderRadius: 8, padding: 10 }}>
              <div style={{ display: 'flex' }}>
                <TextField
                  variant="outlined"
                  data-test-id="searchButton"
                  fullWidth
                  onChange={this.handleSearch}
                  placeholder={configJSON.searchPlaceholder}
                  style={{ background: '#F1F5F9', borderRadius: '8px' }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={imgSearch} alt="" />
                      </InputAdornment>
                    ),
                  }}
                />
                <Button
                  data-test-id="assignRelationshipButtonTestID"
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={() => this.redireactNav('AssignRelationships')}
                  style={{
                    backgroundColor: '#F9DB45',
                    marginLeft: 10,
                    height: '52px',
                    fontWeight: 600,
                    textTransform: "none",
                    color: '#000',
                    width: '335px'
                  }}

                >
                  {configJSON.assignText}
                </Button>
                <Button
                  data-test-id="CreateAccountButtonTestID"
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={() => this.redireactNav('EmailAccountRegistration')}
                  style={{
                    backgroundColor: "#014A80",
                    textTransform: "none",
                    height: '52px',
                    marginLeft: 10,
                    fontWeight: 600,
                    width: '335px'
                  }}
                >
                  {configJSON.createAccountText}
                </Button>
                <Button
                  data-test-id="AdminListButtonTestID"
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={this.handleAdminList}
                  style={{
                    backgroundColor: "#FFF",
                    textTransform: "none",
                    height: '52px',
                    marginLeft: 10,
                    fontWeight: 600,
                    width: '200px',
                    color:'#000'
                  }}
                >
                  {configJSON.adminListTest}
                </Button>
              </div>
            </Card>

            <Card style={{ marginTop: 20, borderRadius: 8 }}>
              <CardContent style={{ padding: 0 }}>
                <TableContainer>
                  <Table style={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow style={{ backgroundColor: "#F4F9FD" }}>
                        <TableCell align="center" style={webStyle.tabelHead}>{configJSON.userTest}</TableCell>
                        <TableCell align="center" style={webStyle.tabelHead}>{configJSON.employeeTest}</TableCell>
                        <TableCell align="center" style={webStyle.tabelHead}>{configJSON.departmentText}</TableCell>
                        <TableCell align="center" style={webStyle.tabelHead}>{configJSON.roleText}</TableCell>
                        <TableCell align="center" style={webStyle.tabelHead}>{configJSON.emailText}</TableCell>
                        <TableCell align="center" style={webStyle.tabelHead}>{configJSON.totalEmployeeText}</TableCell>
                        <TableCell align="center" style={webStyle.tabelHead}>{configJSON.accessText}</TableCell>
                        <TableCell align="center" style={webStyle.tabelHead}>{configJSON.actionText}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.renderTableBody()}
                    </TableBody>
                  </Table>
                  {this.renderLoading(this.state.isLoading)}
                  <Pagination
                    data-test-id="reportPagination"
                    count={Math.ceil(this.state.totalUser / this.state.pageSize)}
                    page={this.state.page}
                    style={webStyle.paginationAr}
                    hideNextButton
                    hidePrevButton
                    boundaryCount={2}
                    siblingCount={1}
                    renderItem={(item) => {
                      return item.type === 'page' ?
                        <div
                          data-test-id="manager-pagination-item"
                          onClick={() => this.onPageChange(item.page)}
                          style={item.page === this.state.page ? webStyle.activePaginationArItem : webStyle.paginationArItem}>
                          {item.page}
                        </div>
                        :
                        <div data-test-id="pagination-dot" style={webStyle.paginationArItem}>...</div>
                    }}
                  />
                </TableContainer>
              </CardContent>
            </Card>
          </Container> : this.renderCheckComponent()}
        </main>
        <Dialog
          data-test-id="dialog-delete"
          open={this.state.openDeleteModal}
          onClose={this.handleDeleteModal}
          PaperProps={{
            style: { width: 501, margin: "auto", borderRadius: 8 },
          }}
        >
          <DialogContent>
            <Grid container spacing={2} style={{ textAlign: "center", marginTop: 30, padding: "0 40px 10px 40px" }}>
              <div style={{ margin: 'auto',width:'100%' }}><img src={imgSuccess} alt="success" /></div>
              <p style={{ textAlign: "center",width:'100%' }}>
                {this.state.isDelete ? configJSON.alertText : configJSON.deleteMessageText}
                <b>{this.state.selectUser.Name}</b>
              </p>

              {this.state.isDelete ? <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 20,
                  marginBottom: 35,
                  width: "100%"
                }}
              >
                <Button
                  data-test-id="btn-close-delete"
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{
                    width: 177,
                    height: 56,
                    borderRadius: 8,
                    background: "transparent",
                    border: "1px solid #979797",
                    color: "#979797",
                    boxShadow: "none",
                    textTransform: "none",
                    fontSize: 20
                  }}
                  onClick={this.handleDeleteSuccessfully}
                >
                  <b>{configJSON.noText}</b>
                </Button>
                <Button
                  data-test-id="btn-delete-report"
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{
                    width: 177,
                    height: 56,
                    borderRadius: 8,
                    backgroundColor: "#014A80",
                    textTransform: "none",
                    boxShadow: "none",
                    fontSize: 20
                  }}
                  onClick={this.handleDeleteConform}
                >
                  <b>{configJSON.yesText}</b>
                </Button>
              </div> :
                <Button
                  data-test-id="btn-delete-successfully"
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{
                    width: '100%',
                    height: 56,
                    borderRadius: 8,
                    backgroundColor: "#014A80",
                    textTransform: "none",
                    boxShadow: "none",
                    fontSize: 20,
                    margin: '15px 0'
                  }}
                  onClick={this.handleDeleteSuccessfully}
                >
                  <b>{configJSON.okayText}</b>
                </Button>}
            </Grid>
          </DialogContent>
        </Dialog>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  // content: {
  //   flexGrow: 1,
  //   marginLeft: drawerWidth,
  //   height: "100vh",
  //   backgroundColor: "#CCE9FF",
  //   padding: 9,
  // },
  appBar: {
    border: 0,
    boxShadow: "none",
    backgroundColor: "#CCE9FF",
  },
  heading: {
    fontFamily: "Lato",
    fontSize: '32px',
    fontWeight: 800,
    display:'flex'
  },
  content: {
    backgroundColor: "#CCE9FF",
    flexGrow: 1,
    marginLeft: drawerWidth,
    // height: "100vh"
  },
  tabelHead: {
    fontWeight: 600,
  },
  paginationAr: {
    display: 'flex',
    justifyContent: 'center',
    margin: "2rem 0",
    cursor: "pointer"
  },
  activePaginationArItem: {
    backgroundColor: '#026AB7',
    padding: '0.25rem 0.65rem',
    width: "max-content",
    borderRadius: '2rem',
    color: "#FFFFFF",

    fontWeight: 400,
    lineHeight: '22px',
    fontSize: 14,
    margin: '0 0.5rem'
  },
  paginationArItem: {
    padding: '0.25rem 0.65rem',
    width: "max-content",
    borderRadius: '2rem',
    color: "#CCCCCC",

    fontWeight: 400,
    lineHeight: '22px',
    fontSize: 14,
    margin: '0 0.5rem'
  },
  viewMoreOption: {
    padding: "15px 20px",
    fontFamily: "Lato",
    fontSize: 16
  },
  viewMoreIcon: {
    marginRight: 10
  },
  tableBody: {
    fontFamily: "Lato",
    fontSize: 16,
    color: '#979797'
  },
  label: {
    fontSize: "16px",
    fontWeight: 500,
    color: "rgb(151, 151, 151)"
  },
  inputBox: {
    marginTop: "5px",
    backgroundColor: "#F3F5F7",
    border: "none",
    width: 400,
  },
  box: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
    maxWidth: 500,
    margin: "0 auto"
  },
  profile: {
    color: "#014A80",
    fontFamily: "Lato",
    fontSize: "24px",
    fontWeight: 700,
    marginBottom: "20px",
    textAlign: "center"
  },
  showAvatar: {
    width: 150,
    height: 150,
    border: "7px solid rgb(2 106 183",
    borderRadius: "100px",
    overflow: "hidden",
    fontSize: "48px",
    fontWeight: 700,
    fontFamily: "Inter"
  },
  nameHeading: {
    color: '#014A80',
    fontFamily: 'Lato',
    fontWeight: 700,
    textAlign: 'center',
    fontSize: '24px',
    marginTop: '26px',
  },
  passwordBox: {
    width: 400,
  },
  passwordLable: {
    color:'#475569',
    fontFamily: 'Lato',
  },
  password: {
    marginTop: '26px',
  },
  error: {
    color: 'red',
    fontSize: '12px',
    fontWeight: 400,
    marginTop: '2px',
    width:'400px',
  },
};
// Customizable Area End
