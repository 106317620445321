import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import React from "react";
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface Manager {
  id: string,
  name: string, 
}
// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  openSearch: boolean;
  addEmpType: 'move' | 'copy' | 'add' | null
  handleCloseSearch: () => void,
  // Customizable Area Start
  listSelectedEmployee: any[]
  handleSetDataDefault: () => void
  getListManager: () => void
  selectAll: boolean
  currentManager: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  listEmp: any[]
  totalEmp: number,
  isLoading: boolean,
  empPage: number,
  searchKeyword: string,
  listPickedEmployee: any[],
  targetManager: Manager,
  openSuccessModal: boolean
  openConfirmAction: boolean,
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class SearchEmployeeController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getListEmployeeId: string = "";
  getListManagerId: string = "";
  copyEmpId: string = ""
  addEmpId: string = ""
  timeOut: ReturnType<typeof setTimeout> = setTimeout(()=>{})

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
        listEmp: [],
        totalEmp: 0,
        isLoading: true,
        empPage: 1,
        searchKeyword: "",
        listPickedEmployee: [],
        targetManager : {
          id: "", 
          name: "",
        },
        openSuccessModal: false,
        openConfirmAction: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    if(this.props.addEmpType==="add") this.getListEmployee(1)
    else this.getListManager(1)
  }

  getListEmployee = async (page: number, keyword: string = "") => {
    this.getListEmployeeId = await this.apiSECall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_user/users/users_list?page=${page}&keyword=${keyword}&id=${this.props.currentManager.id}`,
    });
  }

  getListManager = async (page: number, keyword: string="") => {
    this.getListManagerId = await this.apiSECall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_user/users/search_manager?page=${page}&keyword=${keyword}`,
    });
  }

  handleLoadMoreEmployee = () => {
    const listEmp = document.getElementById("list-emp");
    const seScrolltop = listEmp?.scrollTop ?? 0;
    const seClientHeight = listEmp?.clientHeight??0;
    const seScrollHeight = listEmp?.scrollHeight??0;
    const needLoadMore = this.state.listEmp.length < this.state.totalEmp;

    if(seScrolltop + 3*seClientHeight >= seScrollHeight && !this.state.isLoading && needLoadMore){
      this.setState({empPage: this.state.empPage+1, isLoading: true});
      if(this.props.addEmpType==="add") this.getListEmployee(this.state.empPage+1)
      else this.getListManager(this.state.empPage+1)
    }
  }

  handleOpenSuccessModal = () => {
    this.setState({openSuccessModal: true});
  }
  handleCloseSuccessModal = () => {
    this.setState({openSuccessModal: false})
    this.props.handleCloseSearch()
    this.props.handleSetDataDefault()
    this.props.getListManager()
  }
  handleCancelSearch = () => {
    this.props.handleCloseSearch()
    this.props.handleSetDataDefault()
  }

  handleOpenConfirmAction = () => {
    this.setState({openConfirmAction: true});
  }
  handleCloseConfirmAction = () => {
    this.setState({openConfirmAction: false})
  }

  handleChangeSearchValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchKeyword = event.target.value
    this.setState({searchKeyword, targetManager: {id:"", name:""}})
    this.handleSearch(searchKeyword)
  }

  handleSearch = (keyword: string) => {
    clearTimeout(this.timeOut)
    this.timeOut = setTimeout(() => {
      this.setState({isLoading: true, listEmp: [...[]], empPage: 1})
      if(this.props.addEmpType==="add") this.getListEmployee(1, keyword)
      else this.getListManager(1, keyword)
    }, 1000)
  }

  handleSelectEmployee = (id: string | number) => {
    const {listPickedEmployee} = this.state;
    if (listPickedEmployee.includes(id)) {
      const index = listPickedEmployee.indexOf(id);
      listPickedEmployee.splice(index, 1)
    } else {
      listPickedEmployee.push(id);
    }
    this.setState({listPickedEmployee: [...listPickedEmployee]});
  }

  handleSelectManager = (manager: any) => {
    const targetManager = {
      id: manager.id,
      name: manager.attributes.data.Name
    }
    this.setState({targetManager: {...targetManager}})
  }

  handleSelectAction = (person: any) => {
    if(this.props.addEmpType === 'add') this.handleSelectEmployee(person.id)
    else this.handleSelectManager(person)
  }

  handleEmployeeAction = () => {
    const {addEmpType, listSelectedEmployee, selectAll, currentManager} = this.props
    const bodyCopyMove = {
      manager_id: this.state.targetManager.id,
      employee_ids: selectAll ? [] : listSelectedEmployee,
      perform_action: addEmpType,
      source_manager_id: selectAll ? currentManager.id : ""
    }
    const bodyAdd = {
      manager_id: currentManager.id,
      employee_ids: this.state.listPickedEmployee
    }
    if(addEmpType === 'copy' || addEmpType === 'move') this.handleCopyOrMoveEmployee(bodyCopyMove)
    else if(addEmpType === 'add') this.handleAddEmployee(bodyAdd)
  }

  handleCopyOrMoveEmployee = async (body: {}) => {
    this.copyEmpId = await this.apiSECall({
      contentType: "application/json",
      method: "POST",
      endPoint: `bx_block_admin/admins/copy_or_move_employees`,
      body: JSON.stringify(body)
    });
  }

  handleAddEmployee = async (body: {}) => {
    this.addEmpId = await this.apiSECall({
      contentType: "application/json",
      method: "POST",
      endPoint: `bx_block_admin/admins/add_employees`,
      body: JSON.stringify(body)
    });
  }

  apiSECall = async (data: any) => {
    const token = await getStorageData("token");
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
      token: token,
    };

    const reqSeMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    reqSeMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    reqSeMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    reqSeMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    reqSeMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    runEngine.sendMessage(reqSeMessage.id, reqSeMessage);
    return reqSeMessage.messageId;
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiSeReqId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseSe = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorSe = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiSeReqId === this.getListEmployeeId) {
        this.setState({
            listEmp: [...this.state.listEmp, ...responseSe.data],
            totalEmp: responseSe.No_of_Members,
            isLoading: false
          })
      } else  if (apiSeReqId === this.getListManagerId) {
        const {data} = responseSe;
       
        this.setState({
          listEmp: [...this.state.listEmp, ...data],
          totalEmp: responseSe.No_of_Members,
          isLoading: false
        })
      } else if (apiSeReqId === this.copyEmpId || apiSeReqId === this.addEmpId){
        this.handleOpenSuccessModal()
      }
       else if (errorSe) {
        console.log(errorSe);
      }
    }
    // Customizable Area End
  }
}
